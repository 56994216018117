/* tslint:disable */
/* eslint-disable */

/**
 * Indicates how initial advertisement search is performed
 * Possible values are:
 * - `ads`: List advertisements first.
 * - `categories`: List categories first.
 */
export enum AdInitialSearchTypeEnum {
  ADS = 'ads',
  CATEGORIES = 'categories'
}
