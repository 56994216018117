/* tslint:disable */
/* eslint-disable */

/**
 * Indicates a screen size for the modern frontend.
 * Possible values are:
 * - `desktop`: Desktop
 * - `feature`: Feature phone
 * - `mobile`: Smart phone
 * - `tablet`: Tablet
 */
export enum FrontendScreenSizeEnum {
  DESKTOP = 'desktop',
  FEATURE = 'feature',
  MOBILE = 'mobile',
  TABLET = 'tablet'
}
