<div *ngIf="otp" class="otp mb-4 d-flex flex-column">
  <countdown-button *ngFor="let action of otpActions; let first = first"
    [ngClass]="{'mt-2': !first}" (action)="action.onClick()" outline
    [disabledKey]="otpCountdownButtonDisabledKey(action.label)"
    [disabledSeconds]="30" [icon]="action.icon" [label]="action.label"
    [countdownAction]="otpCountdownAction"
    [inputDisabled]="otpButtonsDisabled$ | async">
  </countdown-button>
  <div *ngIf="virtualKeyboard && otp" class="mt-2 otpCountdownLabel">
    <br *ngIf="!(otpCountdownLabel$ | async)">
    <span *ngIf="otpCountdownLabel$ | async as otpCountdownLabel">
      {{ otpCountdownLabel }} </span>
  </div>
</div>
<ng-container *ngIf="virtualKeyboard; else textField">
  <div class="virtual-keyboard">
    <div class="virtual-keyboard-display">
      <div
        class="d-flex flex-grow-1 align-items-center justify-content-center char">
        <div *ngFor="let _ of enteredVKPassword; let first = first"
          [ngClass]="{'ml-2': !first}">&bull;</div>
      </div>
      <button type="button" class="btn btn-outline-primary"
        [disabled]="enteredVKPassword.length == 0" (click)="vkBack()">
        <icon size="18" [icon]="SvgIcon.Backspace"></icon>
      </button>
      <button type="button" class="btn btn-outline-primary ml-2"
        [disabled]="enteredVKPassword.length == 0" (click)="vkClear()">
        <icon size="18" [icon]="SvgIcon.X"></icon>
      </button>
    </div>
    <div class="virtual-keyboard-buttons">
      <div class="virtual-keyboard-buttons-row"
        *ngFor="let row of currentVKCombinations">
        <div *ngFor="let combination of row" class="virtual-keyboard-button"
          [ngStyle]="{width: (100 / row.length) + '%'}">
          <action-button class="w-100" outline (action)="vkKey(combination)"
            [disabled]="disabled || enteredVKPassword.length == passwordInput.buttons.length"
            [label]="combination" disableSpinner>
          </action-button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #textField>
  <div class="input-group">
    <div *ngIf="showIcon" class="input-group-prepend">
      <span class="input-group-text input-group-icon">
        <icon size="1.3rem" [icon]="SvgIcon.Lock"></icon>
      </span>
    </div>
    <input #passwordField [type]="passwordFieldType"
      [attr.maxlength]="passwordInput.maxLength" [formControl]="formControl"
      class="form-control" [autocomplete]="autocomplete"
      (keypress)="onKeypress($event)" (keyup.enter)="enter.emit(null)"
      [placeholder]="placeholder" [ngClass]="{
        'is-invalid': (formControl.statusChanges | async) === 'INVALID' && formControl.touched,
        'input-field-secret': ['text', 'tel'].includes(passwordFieldType)
      }">
  </div>
  <div *ngIf="otp" class="otpCountdownLabelColor">
    <br *ngIf="!(otpCountdownLabel$ | async)">
    <span *ngIf="otpCountdownLabel$ | async as otpCountdownLabel">
      {{ otpCountdownLabel }} </span>
  </div>
</ng-template>
<field-errors [control]="formControl"></field-errors>
