<page-layout [ready]="data$ | async" size="small" hideMenu>
  <page-content *ngIf="data" hideBack
    [layout]="(layout.ltmd$ | async) ? 'normal' : 'centered'" mode="tight"
    last [heading]="i18n.login.title" [mobileHeading]="i18n.login.mobileTitle"
    [ngClass]="{'with-left-area': uiLayout.leftAreaVisible$ | async}">
    <form [formGroup]="form" class="mt-3 mt-xs-0">
      <div class="pre-login-text"
        *ngIf="loggedOut || (layout.gtxxs$ | async)">
        <div *ngIf="loggedOut; else regularMessage"
          [innerHTML]="i18n.login.disconnected | trust">
        </div>
        <ng-template #regularMessage>
          <div [innerHTML]="i18n.login.message | trust"></div>
        </ng-template>
      </div>

      <div class="principal">
        <div class="input-group login-margin-bottom">
          <div class="input-group-prepend">
            <span class="input-group-text input-group-icon">
              <icon size="1.3rem" [icon]="SvgIcon.Person"></icon>
            </span>
          </div>
          <input #principal type="text" formControlName="principal"
            autocomplete="username" class="form-control" focused
            [placeholder]="i18n.login.principal"
            (keyup.enter)="password.focus()" [ngClass]="{
            'is-invalid': (form.get('principal').statusChanges | async) === 'INVALID' && form.get('principal').touched
          }">
        </div>
        <field-errors [control]="form.get('principal')"></field-errors>
      </div>

      <password-input #password class="d-block login-margin-bottom"
        formControlName="password" autocomplete="password" showIcon
        [passwordInput]="data.loginPasswordInput"
        (keyup.enter)="submit.click()"
        [placeholder]="data.accessPasswordType.name"></password-input>

      <action-button #submit actionKind="primary"
        class="login-margin-top login-margin-bottom mt-3 "
        [disabled]="requesting$ | async" (action)="doLogin($event)"
        [label]="i18n.general.submit">
      </action-button>

      <div *ngIf="data.identityProviders?.length > 0" class="mb-4">
        <button type="button" *ngFor="let idp of data.identityProviders"
          class="btn-action-primary identity-provider" [ngStyle]="{
          border: '1px solid ' + idp.borderColor,
          background: idp.backgroundColor,
          color: idp.textColor
        }" (click)="loginWith(idp)">
          <img *ngIf="idp.image" [src]="idp.image.url" [alt]="idp.name"
            class="aria-hidden">
          <span
            [innerHTML]="i18n.identityProvider.login(idp.name) | trust"></span>
        </button>
      </div>

      <a id="forgotPasswordLink" *ngIf="forgotPasswordEnabled"
        class="d-block login-margin-top login-margin-bottom"
        routerLink="/forgot-password">
        {{ i18n.login.forgotPassword }}
      </a>

      <a id="registerLink" *ngIf="registrationEnabled"
        class="d-block login-margin-top" routerLink="/users/registration">
        {{ i18n.login.register }}
      </a>
    </form>
  </page-content>
</page-layout>
