/* tslint:disable */
/* eslint-disable */

/**
 * The type of logo to request
 * Possible values are:
 * - `classic`: The logo used by the classic frontend
 * - `classicShortcut`: The shortcut icon used by the classic frontend
 * - `frontend`: The logo used by the new frontend
 * - `maskable`: A maskable icon used when installing the new frontend on Android devices
 * - `mobile`: The logo used by the mobile app
 * - `pay`: The logo used by the ticket / easy invoice interface
 * - `report`: The logo used on printed reports
 */
export enum LogoKind {
  CLASSIC = 'classic',
  CLASSIC_SHORTCUT = 'classicShortcut',
  FRONTEND = 'frontend',
  MASKABLE = 'maskable',
  MOBILE = 'mobile',
  PAY = 'pay',
  REPORT = 'report'
}
