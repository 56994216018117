/* tslint:disable */
/* eslint-disable */

/**
 * The possible sources to receive messages from
 * Possible values are:
 * - `system`: A message from system. Only for members and brokers.
 * - `user`: A message from a specific user.
 */
export enum MessageSourceEnum {
  SYSTEM = 'system',
  USER = 'user'
}
