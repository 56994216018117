/* tslint:disable */
/* eslint-disable */

/**
 * The possible pages to show on first access.
 * Possible values are:
 * - `home`: The home page
 * - `login`: The login page
 */
export enum FrontendLandingPageEnum {
  HOME = 'home',
  LOGIN = 'login'
}
