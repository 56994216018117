/* tslint:disable */
/* eslint-disable */

/**
 * The menu in which some content should be displayed.
 * Possible values are:
 * - `banking`: Banking operations
 * - `brokering`: Brokering
 * - `content`: Content
 * - `dashboard`: Logged user's dashboard
 * - `home`: Guest home page
 * - `login`: Login page
 * - `marketplace`: Marketplace
 * - `operators`: Operators
 * - `personal`: Personal
 * - `publicDirectory`: Public user directory
 * - `publicMarketplace`: Public advertisement search
 * - `registration`: Public user registration
 * - `users`: User directory
 */
export enum FrontendMenuEnum {
  BANKING = 'banking',
  BROKERING = 'brokering',
  CONTENT = 'content',
  DASHBOARD = 'dashboard',
  HOME = 'home',
  LOGIN = 'login',
  MARKETPLACE = 'marketplace',
  OPERATORS = 'operators',
  PERSONAL = 'personal',
  PUBLIC_DIRECTORY = 'publicDirectory',
  PUBLIC_MARKETPLACE = 'publicMarketplace',
  REGISTRATION = 'registration',
  USERS = 'users'
}
