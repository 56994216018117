/* tslint:disable */
/* eslint-disable */

/**
 * Indicates the initial status on voucher generation
 * Possible values are:
 * - `active`: Vouchers are initially active, that means their amount is defined at generation time and they are backed by an amount reservation in the system account, unless it is configured to be an unlimited account. That's why it is advised to use a limited system account for vouchers.
 * - `blocked`: Vouchers are initially blocked, with no backing units. Once the voucher is unblocked by a user, the corresponding reservation in the system account is done, and the voucher can then be redeemed.
 * - `inactive`: Vouchers are initially inactive, with no backing units. The voucher needs to be topped-up in order to be used.
 */
export enum VoucherGenerationStatusEnum {
  ACTIVE = 'active',
  BLOCKED = 'blocked',
  INACTIVE = 'inactive'
}
