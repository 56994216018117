/* tslint:disable */
/* eslint-disable */

/**
 * Either horizontal or vertical size of the font
 * Possible values are:
 * - `double`: Double size
 * - `normal`: Normal size
 */
export enum ReceiptPartSizeEnum {
  DOUBLE = 'double',
  NORMAL = 'normal'
}
