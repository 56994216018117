/* tslint:disable */
/* eslint-disable */

/**
 * Define the possible owners of a message
 * Possible values are:
 * - `system`: The owner of the message is the system and there is not an associated user as the owner
 * - `user`: The owner is the user who sent / received the message
 */
export enum MessageOwnerEnum {
  SYSTEM = 'system',
  USER = 'user'
}
