/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DataForFrontend } from '../models/data-for-frontend';
import { DataForFrontendHome } from '../models/data-for-frontend-home';
import { FrontendPageWithContent } from '../models/frontend-page-with-content';
import { FrontendScreenSizeEnum } from '../models/frontend-screen-size-enum';
import { FrontendSettings } from '../models/frontend-settings';


/**
 * Contains operations used by the new Cyclos frontend. Operations in this tag, as well as returned data, are subject to change without further notice.
 */
@Injectable({
  providedIn: 'root',
})
export class FrontendService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation dataForFrontend
   */
  static readonly DataForFrontendPath = '/frontend/data';

  /**
   * Returns data required for displaying the new frontend.
   *
   * Data for the new frontend.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dataForFrontend()` instead.
   *
   * This method doesn't expect any request body.
   */
  dataForFrontend$Response(params?: {

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

    /**
     * The currently displayed screen size in the frontend. Will only return content applicable to that screen size.
     */
    screenSize?: FrontendScreenSizeEnum;
  }): Observable<StrictHttpResponse<DataForFrontend>> {

    const rb = new RequestBuilder(this.rootUrl, FrontendService.DataForFrontendPath, 'get');
    if (params) {
      rb.query('fields', params.fields, {"explode":false});
      rb.query('screenSize', params.screenSize, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DataForFrontend>;
      })
    );
  }

  /**
   * Returns data required for displaying the new frontend.
   *
   * Data for the new frontend.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dataForFrontend$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dataForFrontend(params?: {

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

    /**
     * The currently displayed screen size in the frontend. Will only return content applicable to that screen size.
     */
    screenSize?: FrontendScreenSizeEnum;
  }): Observable<DataForFrontend> {

    return this.dataForFrontend$Response(params).pipe(
      map((r: StrictHttpResponse<DataForFrontend>) => r.body as DataForFrontend)
    );
  }

  /**
   * Path part for operation dataForFrontendHome
   */
  static readonly DataForFrontendHomePath = '/frontend/home';

  /**
   * Returns the data for the home page / dashboard.
   *
   * The returned data depends on whether there'a a logged user and on the Cyclos configuration.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dataForFrontendHome()` instead.
   *
   * This method doesn't expect any request body.
   */
  dataForFrontendHome$Response(params?: {

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

    /**
     * The currently displayed screen size in the frontend. Will only return content applicable to that screen size.
     */
    screenSize?: FrontendScreenSizeEnum;
  }): Observable<StrictHttpResponse<DataForFrontendHome>> {

    const rb = new RequestBuilder(this.rootUrl, FrontendService.DataForFrontendHomePath, 'get');
    if (params) {
      rb.query('fields', params.fields, {"explode":false});
      rb.query('screenSize', params.screenSize, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DataForFrontendHome>;
      })
    );
  }

  /**
   * Returns the data for the home page / dashboard.
   *
   * The returned data depends on whether there'a a logged user and on the Cyclos configuration.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dataForFrontendHome$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dataForFrontendHome(params?: {

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

    /**
     * The currently displayed screen size in the frontend. Will only return content applicable to that screen size.
     */
    screenSize?: FrontendScreenSizeEnum;
  }): Observable<DataForFrontendHome> {

    return this.dataForFrontendHome$Response(params).pipe(
      map((r: StrictHttpResponse<DataForFrontendHome>) => r.body as DataForFrontendHome)
    );
  }

  /**
   * Path part for operation viewFrontendPage
   */
  static readonly ViewFrontendPagePath = '/frontend/page/{key}';

  /**
   * Returns a frontend page with its content.
   *
   * Returns a frontend page with its content.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `viewFrontendPage()` instead.
   *
   * This method doesn't expect any request body.
   */
  viewFrontendPage$Response(params: {

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

    /**
     * Either id or internal name of the content page.
     */
    key: string;
  }): Observable<StrictHttpResponse<FrontendPageWithContent>> {

    const rb = new RequestBuilder(this.rootUrl, FrontendService.ViewFrontendPagePath, 'get');
    if (params) {
      rb.query('fields', params.fields, {"explode":false});
      rb.path('key', params.key, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FrontendPageWithContent>;
      })
    );
  }

  /**
   * Returns a frontend page with its content.
   *
   * Returns a frontend page with its content.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `viewFrontendPage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  viewFrontendPage(params: {

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

    /**
     * Either id or internal name of the content page.
     */
    key: string;
  }): Observable<FrontendPageWithContent> {

    return this.viewFrontendPage$Response(params).pipe(
      map((r: StrictHttpResponse<FrontendPageWithContent>) => r.body as FrontendPageWithContent)
    );
  }

  /**
   * Path part for operation saveFrontendSettings
   */
  static readonly SaveFrontendSettingsPath = '/frontend/settings';

  /**
   * Saves user preferences regarding the Cyclos frontend.
   *
   * Currently only allowed for administrators to choose whether to use the classic or new frontend
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveFrontendSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveFrontendSettings$Response(params?: {

    /**
     * The settings to save
     */
    body?: FrontendSettings
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FrontendService.SaveFrontendSettingsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Saves user preferences regarding the Cyclos frontend.
   *
   * Currently only allowed for administrators to choose whether to use the classic or new frontend
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveFrontendSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveFrontendSettings(params?: {

    /**
     * The settings to save
     */
    body?: FrontendSettings
  }): Observable<void> {

    return this.saveFrontendSettings$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getFrontendIcons
   */
  static readonly GetFrontendIconsPath = '/frontend/icons';

  /**
   * Returns a JSON object keyed by icon name and whose values are the SVG contents.
   *
   * Returns the HTML content of a given page.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFrontendIcons()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFrontendIcons$Response(params?: {

    /**
     * The icon names to return
     */
    names?: Array<string>;
  }): Observable<StrictHttpResponse<{
[key: string]: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, FrontendService.GetFrontendIconsPath, 'get');
    if (params) {
      rb.query('names', params.names, {"explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: string;
        }>;
      })
    );
  }

  /**
   * Returns a JSON object keyed by icon name and whose values are the SVG contents.
   *
   * Returns the HTML content of a given page.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFrontendIcons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFrontendIcons(params?: {

    /**
     * The icon names to return
     */
    names?: Array<string>;
  }): Observable<{
[key: string]: string;
}> {

    return this.getFrontendIcons$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: string;
}>) => r.body as {
[key: string]: string;
})
    );
  }

}
