/* tslint:disable */
/* eslint-disable */

/**
 * The reference/payment feedback direction in relation to a given user/payment.
 * Possible values are:
 * - `given`: The reference/payment feedback was given by the given user
 * - `received`: The reference/payment feedback was received by the given user
 */
export enum ReferenceDirectionEnum {
  GIVEN = 'given',
  RECEIVED = 'received'
}
