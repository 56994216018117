/* tslint:disable */
/* eslint-disable */

/**
 * The font style
 * Possible values are:
 * - `bold`: Bold font
 * - `normal`: Normal font
 * - `underline`: Underline font
 */
export enum ReceiptPartStyleEnum {
  BOLD = 'bold',
  NORMAL = 'normal',
  UNDERLINE = 'underline'
}
