/* tslint:disable */
/* eslint-disable */

/**
 * The voucher status.
 * Possible values are:
 * - `activationExpired`: The voucher was not activated before the deadline.
 * - `blocked`: The voucher was generated in blocked status.
 * - `canceled`: The voucher was canceled, and cannot be further used.
 * - `expired`: The voucher has expired without being redeemed.
 * - `inactive`: The voucher was generated in an inactive status and needs to be activated (via top-up) before being redeemed.
 * - `open`: The voucher has been generated active or bought, and is open.
 * - `pending`: The voucher has been bought, and the corresponding payment is pending for authorization.
 * - `redeemed`: The voucher had a fixed amount and was fully redeemed.
 */
export enum VoucherStatusEnum {
  ACTIVATION_EXPIRED = 'activationExpired',
  BLOCKED = 'blocked',
  CANCELED = 'canceled',
  EXPIRED = 'expired',
  INACTIVE = 'inactive',
  OPEN = 'open',
  PENDING = 'pending',
  REDEEMED = 'redeemed'
}
