/* tslint:disable */
/* eslint-disable */

/**
 * How a content page should be displayed in the modern frontend.
 * Possible values are:
 * - `card`: The content is shown in a card with regular padding
 * - `cardTight`: The content is shown in a card with no padding
 * - `full`: The content uses the full available space
 */
export enum FrontendContentLayoutEnum {
  CARD = 'card',
  CARD_TIGHT = 'cardTight',
  FULL = 'full'
}
