/* tslint:disable */
/* eslint-disable */

/**
 * Indicates a Cyclos frontend.
 * Possible values are:
 * - `classic`: The classic web frontend
 * - `new`: The new frontend
 */
export enum FrontendEnum {
  CLASSIC = 'classic',
  NEW = 'new'
}
