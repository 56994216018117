/* tslint:disable */
/* eslint-disable */

/**
 * The type of system alert.
 * Possible values are:
 * - `accountBalanceFixed`: Accounts with inconsistent account balances were found and fixed
 * - `accountFeeChargedNoFailures`: An account fee charge has finished without any failures
 * - `accountFeeChargedWithFailures`: An account fee charge has finished with at least one failure
 * - `applicationRestarted`: The application has been restarted, or started for the first time
 * - `custom`: Custom alerts thrown by scripts
 * - `customTranslationsInvalidated`: Custom translations were invalidated because the source (english) text has changed
 * - `emailSendingFailed`: An E-mail couldn't be sent
 * - `inconsistentBalanceBelowLimit`: A (limited) system account was found to have an inconsistent balance below the allowed limit
 * - `inconsistentDbSchema`: Differences between the current database schema where found according to the expected schema
 * - `maxBlockedUsersReached`: The max number of users blocked from the same IP has been reached
 * - `maxGlobalSmsReached`: The groups under a certain configuration have run out of SMS messages
 * - `maxIncorrectLoginAttempts`: Someone tried for a given number of tries to login with an invalid username
 * - `smsSendingFailed`: An SMS couldn't be sent
 */
export enum SystemAlertTypeEnum {
  ACCOUNT_BALANCE_FIXED = 'accountBalanceFixed',
  ACCOUNT_FEE_CHARGED_NO_FAILURES = 'accountFeeChargedNoFailures',
  ACCOUNT_FEE_CHARGED_WITH_FAILURES = 'accountFeeChargedWithFailures',
  APPLICATION_RESTARTED = 'applicationRestarted',
  CUSTOM = 'custom',
  CUSTOM_TRANSLATIONS_INVALIDATED = 'customTranslationsInvalidated',
  EMAIL_SENDING_FAILED = 'emailSendingFailed',
  INCONSISTENT_BALANCE_BELOW_LIMIT = 'inconsistentBalanceBelowLimit',
  INCONSISTENT_DB_SCHEMA = 'inconsistentDbSchema',
  MAX_BLOCKED_USERS_REACHED = 'maxBlockedUsersReached',
  MAX_GLOBAL_SMS_REACHED = 'maxGlobalSmsReached',
  MAX_INCORRECT_LOGIN_ATTEMPTS = 'maxIncorrectLoginAttempts',
  SMS_SENDING_FAILED = 'smsSendingFailed'
}
