<ng-container *ngFor="let root of roots">
  <a #dropdownAnchor [id]="menuAnchorId(root)" *ngIf="root.dropdown"
    class="dropdown-toggle" href="#" (onShown)="dropdownShown.emit(root)"
    (onHidden)="dropdownHidden.emit(root)"
    [attr.aria-controls]="'menu-dropdown-' + root.rootMenu"
    (mouseenter)="dropdown.show()" (mouseleave)="dropdown.hide()"
    (click)="onClick($event, dropdownAnchor, root, dropdown)" dropdown
    #dropdown="bs-dropdown" [ngClass]="ngClass(root)">
    <icon *ngIf="root.icon" [icon]="root.icon"></icon>
    <div class="menu-text">{{ root.label }}</div>
    <div *dropdownMenu class="dropdown-menu" role="listbox"
      [attr.id]="'menu-dropdown-' + root.rootMenu" attr.aria-labelledby="id"
      (click)="$event.preventDefault(); $event.stopPropagation();">
      <ng-container *ngFor="let entry of root.entries">
        <a #subAnchor [id]="menuAnchorId(entry)" [routerLink]="entry.url"
          (click)="onClick($event, subAnchor, entry, dropdown)"
          class="sub-menu-item" [ngClass]="{
                active: (breadcrumb.url$ | async) === entry.url
              }">
          <icon *ngIf="entry.icon" [icon]="entry.icon"></icon>
          <div class="sub-menu-text">{{ entry.label }}</div>
        </a>
      </ng-container>
    </div>
  </a>

  <a [id]="menuAnchorId(root)" *ngIf="!root.dropdown && root.entries[0]"
    #anchor [routerLink]="root.entries[0].url"
    [tooltip]="tooltipForNonActive && activeRoot !== root.rootMenu ? root.label : undefined"
    (click)="onClick($event, anchor, root)" [ngClass]="ngClass(root)">
    <icon [icon]="root.icon"></icon>
    <div class="menu-text">{{ root.label }}</div>
  </a>

</ng-container>
