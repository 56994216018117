<ng-container *ngIf="data$ | async; else spinner">
  <page-content *ngIf="data.content.layout !== FrontendContentLayoutEnum.FULL"
    [heading]="data.content.title" [mobileHeading]="data.content.title" last
    hideBack
    [mode]="data.content.layout === FrontendContentLayoutEnum.CARD_TIGHT ? 'tight' : 'normal'">
    <div class="custom-content-container"
      [innerHTML]="data.content.content | trust"></div>
  </page-content>
  <div class="custom-content-container"
    *ngIf="data.content.layout === FrontendContentLayoutEnum.FULL"
    [innerHTML]="data.content.content | trust"></div>
</ng-container>

<ng-template #spinner>
  <spinner></spinner>
</ng-template>
