<ng-container *ngIf="roots$ | async as roots">
  <ng-container
    *ngIf="density(roots, layout.breakpointChanges$ | async) as dens">
    <nav class="navbar shadow-sm" [ngClass]="{
        'full-width': uiLayout.fullWidth$ | async,
        'dense': dens === 'dense'
      }">
      <div class="navbar-container container-lg justify-content-start">
        <menus [roots]="roots" [menuType]="MenuType.BAR"
          [activeMenu]="activeMenu" [density]="dens"></menus>
      </div>
    </nav>
  </ng-container>
</ng-container>
