/* tslint:disable */
/* eslint-disable */

/**
 * Possible errors when topping-up a voucher.
 * Possible values are:
 * - `activationExpired`: The maximum activation time for the voucher has expired.
 * - `alreadyActivated`: The voucher allows a single top-up and has already been activated.
 * - `maxBalanceReached`: The voucher cannot be topped-up because the maximum allowed balance would be exceeded.
 * - `notAllowedForVoucher`: This voucher cannot be topped-up.
 * - `payment`: There was an error when performing the payment.
 * - `unexpected`: An unexpected error has occurred. See the `exceptionType` and `exceptionMessage` fields for the internal information.
 */
export enum TopUpVoucherErrorCode {
  ACTIVATION_EXPIRED = 'activationExpired',
  ALREADY_ACTIVATED = 'alreadyActivated',
  MAX_BALANCE_REACHED = 'maxBalanceReached',
  NOT_ALLOWED_FOR_VOUCHER = 'notAllowedForVoucher',
  PAYMENT = 'payment',
  UNEXPECTED = 'unexpected'
}
