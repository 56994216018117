/* tslint:disable */
/* eslint-disable */

/**
 * Possible actions that could be confirmed with a device for an external payment.
 * Possible values are:
 * - `cancel`: Cancel the external payment
 */
export enum ExternalPaymentActionEnum {
  CANCEL = 'cancel'
}
