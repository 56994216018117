/* tslint:disable */
/* eslint-disable */

/**
 * Possible actions that could be confirmed with a device for a recurring payment.
 * Possible values are:
 * - `block`: Block the recurring payment
 * - `cancel`: Cancel the recurring payment
 * - `modify`: Modifies the recurring payment like occurrences and scheduling
 * - `unblock`: Unblock the recurring payment
 */
export enum RecurringPaymentActionEnum {
  BLOCK = 'block',
  CANCEL = 'cancel',
  MODIFY = 'modify',
  UNBLOCK = 'unblock'
}
