/* tslint:disable */
/* eslint-disable */

/**
 * Indicates how a payment was created
 * Possible values are:
 * - `direct`: The payment was directly performed
 * - `easyInvoice`: The payment was created via easy invoicing
 * - `external`: The payment was generated by an external payment
 * - `import`: The payment was imported via the Banking menu. Not to be confused with the transfers import from the System menu, which generates transactions with `kind` = `import` instead.
 * - `order`: The payment was created by completing a webshop order (or cart checkout)
 * - `pos`: The payment was received via a POS operation
 * - `request`: The payment was created by accepting a payment request
 * - `ticket`: The payment was created by accepting a ticket (or receiving a QR-code payment)
 * - `voucherBuying`: The payment was performed to buy vouchers
 * - `voucherRedeeming`: The payment was performed to redeem a voucher
 * - `voucherRefunding`: The payment was performed to refund a bought voucher
 * - `voucherTopUp`: The payment was performed to top-up a voucher
 */
export enum PaymentCreationTypeEnum {
  DIRECT = 'direct',
  EASY_INVOICE = 'easyInvoice',
  EXTERNAL = 'external',
  IMPORT = 'import',
  ORDER = 'order',
  POS = 'pos',
  REQUEST = 'request',
  TICKET = 'ticket',
  VOUCHER_BUYING = 'voucherBuying',
  VOUCHER_REDEEMING = 'voucherRedeeming',
  VOUCHER_REFUNDING = 'voucherRefunding',
  VOUCHER_TOP_UP = 'voucherTopUp'
}
