/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LocalizationSettings } from '../models/localization-settings';
import { UserLocale } from '../models/user-locale';


/**
 * Provides access to different languages and localization settings.
 */
@Injectable({
  providedIn: 'root',
})
export class LocalizationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getLocales
   */
  static readonly GetLocalesPath = '/localization/locales';

  /**
   * Returns the list of available locales, this collection is already sent in DataForUI or MobileBaseData.
   *
   * Returns the list of available locales.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLocales()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocales$Response(params?: {
  }): Observable<StrictHttpResponse<Array<UserLocale>>> {

    const rb = new RequestBuilder(this.rootUrl, LocalizationService.GetLocalesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserLocale>>;
      })
    );
  }

  /**
   * Returns the list of available locales, this collection is already sent in DataForUI or MobileBaseData.
   *
   * Returns the list of available locales.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLocales$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocales(params?: {
  }): Observable<Array<UserLocale>> {

    return this.getLocales$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserLocale>>) => r.body as Array<UserLocale>)
    );
  }

  /**
   * Path part for operation saveLocalizationSettings
   */
  static readonly SaveLocalizationSettingsPath = '/localization/settings';

  /**
   * Saves the localization settings for the authenticated user.
   *
   * Saves the localization settings for the authenticated user such as the preferred language.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveLocalizationSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveLocalizationSettings$Response(params: {

    /**
     * The localization settings.
     */
    body: LocalizationSettings
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, LocalizationService.SaveLocalizationSettingsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Saves the localization settings for the authenticated user.
   *
   * Saves the localization settings for the authenticated user such as the preferred language.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveLocalizationSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveLocalizationSettings(params: {

    /**
     * The localization settings.
     */
    body: LocalizationSettings
  }): Observable<void> {

    return this.saveLocalizationSettings$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
