/* tslint:disable */
/* eslint-disable */

/**
 * An action over a user's password.
 * Possible values are:
 * - `activate`: A generated password was activated
 * - `allow_activation`: Allow user to generate the password
 * - `change`: Manually change a manual password, or generate a new generated password
 * - `disable`: Disables a password, making it unusable until being enabled again
 * - `enable`: Enables a disabled password
 * - `reset`: Resets a generated password, making it go back to the pending state
 * - `resetAndSend`: Resets a manual password to a generated value and send it to the user
 * - `unblock`: Unblocks a blocked password
 */
export enum PasswordActionEnum {
  ACTIVATE = 'activate',
  ALLOW_ACTIVATION = 'allow_activation',
  CHANGE = 'change',
  DISABLE = 'disable',
  ENABLE = 'enable',
  RESET = 'reset',
  RESET_AND_SEND = 'resetAndSend',
  UNBLOCK = 'unblock'
}
