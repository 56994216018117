/* tslint:disable */
/* eslint-disable */

/**
 * Indicates the type of a custom wizard.
 * Possible values are:
 * - `menu`: The wizard is executed on a menu item, even by guests
 * - `registration`: The wizard is used to register a user
 * - `system`: The wizard is executed by admins
 * - `user`: The wizard is executed over a user
 */
export enum WizardKind {
  MENU = 'menu',
  REGISTRATION = 'registration',
  SYSTEM = 'system',
  USER = 'user'
}
