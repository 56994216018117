/* tslint:disable */

import { BehaviorSubject } from 'rxjs'
import { InjectionToken, Provider } from '@angular/core';
import { I18nMeta } from './i18n-meta';

export const I18nInjectionToken = new InjectionToken<I18n>('I18nInjectionToken');
export const I18nProvider: Provider = {
    provide: I18nInjectionToken,
    useFactory: () => I18nMeta.get()
};

/**
 * Translation messages interface
 */
export interface I18n {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;


  /**
   * Returns the nested accessor for translation messages in `general`.
   */
  general: I18n$General;


  /**
   * Returns the nested accessor for translation messages in `error`.
   */
  error: I18n$Error;


  /**
   * Returns the nested accessor for translation messages in `menu`.
   */
  menu: I18n$Menu;


  /**
   * Returns the nested accessor for translation messages in `login`.
   */
  login: I18n$Login;


  /**
   * Returns the nested accessor for translation messages in `password`.
   */
  password: I18n$Password;


  /**
   * Returns the nested accessor for translation messages in `pendingAgreements`.
   */
  pendingAgreements: I18n$PendingAgreements;


  /**
   * Returns the nested accessor for translation messages in `securityQuestion`.
   */
  securityQuestion: I18n$SecurityQuestion;


  /**
   * Returns the nested accessor for translation messages in `dashboard`.
   */
  dashboard: I18n$Dashboard;


  /**
   * Returns the nested accessor for translation messages in `account`.
   */
  account: I18n$Account;


  /**
   * Returns the nested accessor for translation messages in `transaction`.
   */
  transaction: I18n$Transaction;


  /**
   * Returns the nested accessor for translation messages in `voucher`.
   */
  voucher: I18n$Voucher;


  /**
   * Returns the nested accessor for translation messages in `field`.
   */
  field: I18n$Field;


  /**
   * Returns the nested accessor for translation messages in `user`.
   */
  user: I18n$User;


  /**
   * Returns the nested accessor for translation messages in `operatorGroup`.
   */
  operatorGroup: I18n$OperatorGroup;


  /**
   * Returns the nested accessor for translation messages in `userStatus`.
   */
  userStatus: I18n$UserStatus;


  /**
   * Returns the nested accessor for translation messages in `agreements`.
   */
  agreements: I18n$Agreements;


  /**
   * Returns the nested accessor for translation messages in `groupMembership`.
   */
  groupMembership: I18n$GroupMembership;


  /**
   * Returns the nested accessor for translation messages in `brokers`.
   */
  brokers: I18n$Brokers;


  /**
   * Returns the nested accessor for translation messages in `phone`.
   */
  phone: I18n$Phone;


  /**
   * Returns the nested accessor for translation messages in `address`.
   */
  address: I18n$Address;


  /**
   * Returns the nested accessor for translation messages in `ad`.
   */
  ad: I18n$Ad;


  /**
   * Returns the nested accessor for translation messages in `message`.
   */
  message: I18n$Message;


  /**
   * Returns the nested accessor for translation messages in `notification`.
   */
  notification: I18n$Notification;


  /**
   * Returns the nested accessor for translation messages in `notificationSettings`.
   */
  notificationSettings: I18n$NotificationSettings;


  /**
   * Returns the nested accessor for translation messages in `connectedUser`.
   */
  connectedUser: I18n$ConnectedUser;


  /**
   * Returns the nested accessor for translation messages in `identityProvider`.
   */
  identityProvider: I18n$IdentityProvider;


  /**
   * Returns the nested accessor for translation messages in `operation`.
   */
  operation: I18n$Operation;


  /**
   * Returns the nested accessor for translation messages in `wizard`.
   */
  wizard: I18n$Wizard;


  /**
   * Returns the nested accessor for translation messages in `setting`.
   */
  setting: I18n$Setting;


  /**
   * Returns the nested accessor for translation messages in `privacySettings`.
   */
  privacySettings: I18n$PrivacySettings;


  /**
   * Returns the nested accessor for translation messages in `record`.
   */
  record: I18n$Record;


  /**
   * Returns the nested accessor for translation messages in `userAlert`.
   */
  userAlert: I18n$UserAlert;


  /**
   * Returns the nested accessor for translation messages in `systemAlert`.
   */
  systemAlert: I18n$SystemAlert;


  /**
   * Returns the nested accessor for translation messages in `document`.
   */
  document: I18n$Document;


  /**
   * Returns the nested accessor for translation messages in `token`.
   */
  token: I18n$Token;


  /**
   * Returns the nested accessor for translation messages in `reference`.
   */
  reference: I18n$Reference;


  /**
   * Returns the nested accessor for translation messages in `feedback`.
   */
  feedback: I18n$Feedback;


  /**
   * Returns the nested accessor for translation messages in `product`.
   */
  product: I18n$Product;


  /**
   * Returns the nested accessor for translation messages in `invite`.
   */
  invite: I18n$Invite;


  /**
   * Returns the nested accessor for translation messages in `consent`.
   */
  consent: I18n$Consent;


  /**
   * Returns the nested accessor for translation messages in `unsubscribe`.
   */
  unsubscribe: I18n$Unsubscribe;

}

export interface I18n$General {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `by`.
   * Default value: `By`
   */
  by: string;

  /**
   * Returns the value of translation message for key `changedBy`.
   * Default value: `Changed by`
   */
  changedBy: string;

  /**
   * Returns the value of translation message for key `type`.
   * Default value: `Type`
   */
  type: string;

  /**
   * Returns the value of translation message for key `category`.
   * Default value: `Category`
   */
  category: string;

  /**
   * Returns the value of translation message for key `period`.
   * Default value: `Period`
   */
  period: string;

  /**
   * Returns the value of translation message for key `period.to`.
   * Default value: `to`
   */
  periodTo: string;

  /**
   * Returns the value of translation message for key `now`.
   * Default value: `Now`
   */
  now: string;

  /**
   * Returns the value of translation message for key `action`.
   * Default value: `Action`
   */
  action: string;

  /**
   * Returns the value of translation message for key `actions`.
   * Default value: `Actions`
   */
  actions: string;

  /**
   * Returns the value of translation message for key `add`.
   * Default value: `Add`
   */
  add: string;

  /**
   * Returns the value of translation message for key `date`.
   * Default value: `Date`
   */
  date: string;

  /**
   * Returns the value of translation message for key `performedBy`.
   * Default value: `Performed by`
   */
  performedBy: string;

  /**
   * Returns the value of translation message for key `downloadUrl`.
   * Default value: `URL on new frontend`
   */
  downloadUrl: string;

  /**
   * Returns the value of translation message for key `status`.
   * Default value: `Status`
   */
  status: string;

  /**
   * Returns the value of translation message for key `comments`.
   * Default value: `Comments`
   */
  comments: string;

  /**
   * Returns the value of translation message for key `futureDate`.
   * Default value: `Future date`
   */
  futureDate: string;

  /**
   * Returns the value of translation message for key `beginDate`.
   * Default value: `Begin date`
   */
  beginDate: string;

  /**
   * Returns the value of translation message for key `endDate`.
   * Default value: `End date`
   */
  endDate: string;

  /**
   * Returns the value of translation message for key `creationDate`.
   * Default value: `Creation date`
   */
  creationDate: string;

  /**
   * Returns the value of translation message for key `expirationDate`.
   * Default value: `Expiration date`
   */
  expirationDate: string;

  /**
   * Returns the value of translation message for key `expirationBeginDate`.
   * Default value: `Expiration begin date`
   */
  expirationBeginDate: string;

  /**
   * Returns the value of translation message for key `expirationEndDate`.
   * Default value: `Expiration end date`
   */
  expirationEndDate: string;

  /**
   * Returns the value of translation message for key `newExpirationDate`.
   * Default value: `New expiration date`
   */
  newExpirationDate: string;

  /**
   * Returns the value of translation message for key `notApplied`.
   * Default value: `Not applied`
   */
  notApplied: string;

  /**
   * Returns the value of translation message for key `user`.
   * Default value: `User`
   */
  user: string;

  /**
   * Returns the value of translation message for key `operator`.
   * Default value: `Operator`
   */
  operator: string;

  /**
   * Returns the value of translation message for key `name`.
   * Default value: `Name`
   */
  name: string;

  /**
   * Returns the value of translation message for key `to`.
   * Default value: `to`
   */
  to: string;

  /**
   * Returns the value of translation message for key `description`.
   * Default value: `Description`
   */
  description: string;

  /**
   * Returns the value of translation message for key `details`.
   * Default value: `Details`
   */
  details: string;

  /**
   * Returns the value of translation message for key `download`.
   * Default value: `Download`
   */
  download: string;

  /**
   * Returns the value of translation message for key `image`.
   * Default value: `Image`
   */
  image: string;

  /**
   * Returns the value of translation message for key `keywords`.
   * Default value: `Keywords`
   */
  keywords: string;

  /**
   * Returns the value of translation message for key `distanceFilter`.
   * Default value: `Distance`
   */
  distanceFilter: string;

  /**
   * Returns the value of translation message for key `orderBy`.
   * Default value: `Order by`
   */
  orderBy: string;

  /**
   * Returns the value of translation message for key `orderBy.relevance`.
   * Default value: `Relevance`
   */
  orderByRelevance: string;

  /**
   * Returns the value of translation message for key `print`.
   * Default value: `Print`
   */
  print: string;

  /**
   * Returns the value of translation message for key `printed`.
   * Default value: `Printed`
   */
  printed: string;

  /**
   * Returns the value of translation message for key `all`.
   * Default value: `All`
   */
  all: string;

  /**
   * Returns the value of translation message for key `copyToClipboard`.
   * Default value: `Copy to clipboard`
   */
  copyToClipboard: string;

  /**
   * Returns the value of translation message for key `copyToClipboard.done`.
   * Default value: `Copied!`
   */
  copyToClipboardDone: string;

  /**
   * Returns the value of translation message for key `showFilters`.
   * Default value: `Show filters`
   */
  showFilters: string;

  /**
   * Returns the value of translation message for key `hideFilters`.
   * Default value: `Hide filters`
   */
  hideFilters: string;

  /**
   * Returns the value of translation message for key `moreFilters`.
   * Default value: `More filters`
   */
  moreFilters: string;

  /**
   * Returns the value of translation message for key `showMoreFilters`.
   * Default value: `Show more filters`
   */
  showMoreFilters: string;

  /**
   * Returns the value of translation message for key `lessFilters`.
   * Default value: `Less filters`
   */
  lessFilters: string;

  /**
   * Returns the value of translation message for key `showLessFilters`.
   * Default value: `Show less filters`
   */
  showLessFilters: string;

  /**
   * Returns the value of translation message for key `enabled`.
   * Default value: `Enabled`
   */
  enabled: string;

  /**
   * Returns the value of translation message for key `disabled`.
   * Default value: `Disabled`
   */
  disabled: string;

  /**
   * Returns the value of translation message for key `back`.
   * Default value: `Back`
   */
  back: string;

  /**
   * Returns the value of translation message for key `previous`.
   * Default value: `Previous`
   */
  previous: string;

  /**
   * Returns the value of translation message for key `next`.
   * Default value: `Next`
   */
  next: string;

  /**
   * Returns the value of translation message for key `view`.
   * Default value: `View`
   */
  view: string;

  /**
   * Returns the value of translation message for key `skip`.
   * Default value: `Skip`
   */
  skip: string;

  /**
   * Returns the value of translation message for key `viewHistory`.
   * Default value: `View history`
   */
  viewHistory: string;

  /**
   * Returns the value of translation message for key `edit`.
   * Default value: `Edit`
   */
  edit: string;

  /**
   * Returns the value of translation message for key `confirm`.
   * Default value: `Confirm`
   */
  confirm: string;

  /**
   * Returns the value of translation message for key `cancel`.
   * Default value: `Cancel`
   */
  cancel: string;

  /**
   * Returns the value of translation message for key `close`.
   * Default value: `Close`
   */
  close: string;

  /**
   * Returns the value of translation message for key `submit`.
   * Default value: `Submit`
   */
  submit: string;

  /**
   * Returns the value of translation message for key `finish`.
   * Default value: `Finish`
   */
  finish: string;

  /**
   * Returns the value of translation message for key `remove`.
   * Default value: `Remove`
   */
  remove: string;

  /**
   * Returns the value of translation message for key `remoteAddress`.
   * Default value: `IP address`
   */
  remoteAddress: string;

  /**
   * Returns the value of translation message for key `save`.
   * Default value: `Save`
   */
  save: string;

  /**
   * Returns the value of translation message for key `addNew`.
   * Default value: `Add new`
   */
  addNew: string;

  /**
   * Returns the value of translation message for key `yes`.
   * Default value: `Yes`
   */
  yes: string;

  /**
   * Returns the value of translation message for key `no`.
   * Default value: `No`
   */
  no: string;

  /**
   * Returns the value of translation message for key `notSet`.
   * Default value: `Not set`
   */
  notSet: string;

  /**
   * Returns the value of translation message for key `share`.
   * Default value: `Share`
   */
  share: string;

  /**
   * Returns the value of translation message for key `tiledView`.
   * Default value: `Tiled view`
   */
  tiledView: string;

  /**
   * Returns the value of translation message for key `listView`.
   * Default value: `List view`
   */
  listView: string;

  /**
   * Returns the value of translation message for key `categoriesView`.
   * Default value: `Categories view`
   */
  categoriesView: string;

  /**
   * Returns the value of translation message for key `map`.
   * Default value: `Map`
   */
  map: string;

  /**
   * Returns the value of translation message for key `map.view`.
   * Default value: `View map`
   */
  mapView: string;

  /**
   * Returns the value of translation message for key `noOptionsSelected`.
   * Default value: `No options selected`
   */
  noOptionsSelected: string;

  /**
   * Returns the value of translation message for key `noOptionSelected`.
   * Default value: `No option selected`
   */
  noOptionSelected: string;

  /**
   * Returns the value of translation message for key `removeItemConfirm`.
   * Default value: `Are you sure to remove this item?`
   */
  removeItemConfirm: string;

  /**
   * Returns the value of translation message for key `removeItemDone`.
   * Default value: `The item has been removed`
   */
  removeItemDone: string;

  /**
   * Returns the value of translation message for key `removeAllItemsConfirm`.
   * Default value: `Are you sure to remove all items?`
   */
  removeAllItemsConfirm: string;

  /**
   * Returns the value of translation message for key `currency`.
   * Default value: `Currency`
   */
  currency: string;

  /**
   * Returns the value of translation message for key `images`.
   * Default value: `Images`
   */
  images: string;

  /**
   * Returns the value of translation message for key `internalName`.
   * Default value: `Internal name`
   */
  internalName: string;

  /**
   * Returns the value of translation message for key `usersOfBroker`.
   * Default value: `Users of the broker`
   */
  usersOfBroker: string;

  /**
   * Returns the value of translation message for key `administration`.
   * Default value: `The administration`
   */
  administration: string;

  /**
   * Returns the value of translation message for key `maskHelp`.
   * Default value: `This masks can restrict which characters can be typed, restrict size and apply case transformations, using the following constructors.<br><br><ul><li><b>#, 0 or 9</b> - A number.</li><li><b>a or A</b> - A letter.</li><li><b>? or _</b> - Any character.</li><li><b>l or L</b> - A lowercase letter.</li><li><b>u or U</b> - An uppercase letter.</li><li><b>c or C</b> - A capitalized letter.</li><li><b>\\, \#, \0, ...</b> - Those literal characters. Remember that, when you write a string, '\\' means a single backslash. To use a backslash on the mask, you need 4 on the string.</li></ul>`
   */
  maskHelp: string;

  /**
   * Returns the value of translation message for key `sendCode`.
   * Default value: `Send code`
   */
  sendCode: string;

  /**
   * Returns the value of translation message for key `reloadPage`.
   * Default value: `Reload page`
   */
  reloadPage: string;

  /**
   * Returns the value of translation message for key `rangeTo`.
   * Default value: `to`
   */
  rangeTo: string;


  /**
   * Returns the value of translation message for key `downloadAs`.
   * Default value: `Download as {format}`
   */
  downloadAs(format: string | number): string;


  /**
   * Returns the value of translation message for key `remove.confirm`.
   * Default value: `Are you sure to remove the item {item}?`
   */
  removeConfirm(item: string | number): string;


  /**
   * Returns the value of translation message for key `remove.done`.
   * Default value: `The item {item} was removed`
   */
  removeDone(item: string | number): string;


  /**
   * Returns the value of translation message for key `remove.tooltip`.
   * Default value: `Remove {item}`
   */
  removeTooltip(item: string | number): string;


  /**
   * Returns the value of translation message for key `sentCodeTo`.
   * Default value: `The verification code was sent to {to}`
   */
  sentCodeTo(to: string | number): string;


  /**
   * Returns the nested accessor for translation messages in `sendMedium`.
   */
  sendMedium: I18n$General$SendMedium;


  /**
   * Returns the nested accessor for translation messages in `sessionExpired`.
   */
  sessionExpired: I18n$General$SessionExpired;


  /**
   * Returns the nested accessor for translation messages in `month`.
   */
  month: I18n$General$Month;


  /**
   * Returns the nested accessor for translation messages in `timeField`.
   */
  timeField: I18n$General$TimeField;


  /**
   * Returns the nested accessor for translation messages in `weekday`.
   */
  weekday: I18n$General$Weekday;


  /**
   * Returns the nested accessor for translation messages in `range`.
   */
  range: I18n$General$Range;


  /**
   * Returns the nested accessor for translation messages in `fileSize`.
   */
  fileSize: I18n$General$FileSize;


  /**
   * Returns the nested accessor for translation messages in `results`.
   */
  results: I18n$General$Results;


  /**
   * Returns the nested accessor for translation messages in `geolocation`.
   */
  geolocation: I18n$General$Geolocation;

}

export interface I18n$General$SendMedium {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `email`.
   * Default value: `E-mail`
   */
  email: string;

  /**
   * Returns the value of translation message for key `sms`.
   * Default value: `SMS`
   */
  sms: string;

}

export interface I18n$General$SessionExpired {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Session expired`
   */
  title: string;

  /**
   * Returns the value of translation message for key `message`.
   * Default value: `You have been logged-out.<br>You can keep viewing the same page or login again now.`
   */
  message: string;

  /**
   * Returns the value of translation message for key `loginAgain`.
   * Default value: `Login again`
   */
  loginAgain: string;

}

export interface I18n$General$Month {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;


  /**
   * Returns the nested accessor for translation messages in `long`.
   */
  long: I18n$General$Month$Long;


  /**
   * Returns the nested accessor for translation messages in `short`.
   */
  short: I18n$General$Month$Short;

}

export interface I18n$General$Month$Long {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `jan`.
   * Default value: `January`
   */
  jan: string;

  /**
   * Returns the value of translation message for key `feb`.
   * Default value: `February`
   */
  feb: string;

  /**
   * Returns the value of translation message for key `mar`.
   * Default value: `March`
   */
  mar: string;

  /**
   * Returns the value of translation message for key `apr`.
   * Default value: `April`
   */
  apr: string;

  /**
   * Returns the value of translation message for key `may`.
   * Default value: `May`
   */
  may: string;

  /**
   * Returns the value of translation message for key `jun`.
   * Default value: `June`
   */
  jun: string;

  /**
   * Returns the value of translation message for key `jul`.
   * Default value: `July`
   */
  jul: string;

  /**
   * Returns the value of translation message for key `aug`.
   * Default value: `August`
   */
  aug: string;

  /**
   * Returns the value of translation message for key `sep`.
   * Default value: `September`
   */
  sep: string;

  /**
   * Returns the value of translation message for key `oct`.
   * Default value: `October`
   */
  oct: string;

  /**
   * Returns the value of translation message for key `nov`.
   * Default value: `November`
   */
  nov: string;

  /**
   * Returns the value of translation message for key `dec`.
   * Default value: `December`
   */
  dec: string;

}

export interface I18n$General$Month$Short {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `jan`.
   * Default value: `Jan`
   */
  jan: string;

  /**
   * Returns the value of translation message for key `feb`.
   * Default value: `Feb`
   */
  feb: string;

  /**
   * Returns the value of translation message for key `mar`.
   * Default value: `Mar`
   */
  mar: string;

  /**
   * Returns the value of translation message for key `apr`.
   * Default value: `Apr`
   */
  apr: string;

  /**
   * Returns the value of translation message for key `may`.
   * Default value: `May`
   */
  may: string;

  /**
   * Returns the value of translation message for key `jun`.
   * Default value: `Jun`
   */
  jun: string;

  /**
   * Returns the value of translation message for key `jul`.
   * Default value: `Jul`
   */
  jul: string;

  /**
   * Returns the value of translation message for key `aug`.
   * Default value: `Aug`
   */
  aug: string;

  /**
   * Returns the value of translation message for key `sep`.
   * Default value: `Sep`
   */
  sep: string;

  /**
   * Returns the value of translation message for key `oct`.
   * Default value: `Oct`
   */
  oct: string;

  /**
   * Returns the value of translation message for key `nov`.
   * Default value: `Nov`
   */
  nov: string;

  /**
   * Returns the value of translation message for key `dec`.
   * Default value: `Dec`
   */
  dec: string;

}

export interface I18n$General$TimeField {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;


  /**
   * Returns the value of translation message for key `pattern`.
   * Default value: `{amount} {field}`
   */
  pattern($: {amount: string | number, field: string | number}): string;


  /**
   * Returns the nested accessor for translation messages in `plural`.
   */
  plural: I18n$General$TimeField$Plural;


  /**
   * Returns the nested accessor for translation messages in `singular`.
   */
  singular: I18n$General$TimeField$Singular;

}

export interface I18n$General$TimeField$Plural {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `days`.
   * Default value: `Days`
   */
  days: string;

  /**
   * Returns the value of translation message for key `hours`.
   * Default value: `Hours`
   */
  hours: string;

  /**
   * Returns the value of translation message for key `millis`.
   * Default value: `Milliseconds`
   */
  millis: string;

  /**
   * Returns the value of translation message for key `minutes`.
   * Default value: `Minutes`
   */
  minutes: string;

  /**
   * Returns the value of translation message for key `months`.
   * Default value: `Months`
   */
  months: string;

  /**
   * Returns the value of translation message for key `seconds`.
   * Default value: `Seconds`
   */
  seconds: string;

  /**
   * Returns the value of translation message for key `weeks`.
   * Default value: `Weeks`
   */
  weeks: string;

  /**
   * Returns the value of translation message for key `years`.
   * Default value: `Years`
   */
  years: string;

}

export interface I18n$General$TimeField$Singular {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `day`.
   * Default value: `Day`
   */
  day: string;

  /**
   * Returns the value of translation message for key `hour`.
   * Default value: `Hour`
   */
  hour: string;

  /**
   * Returns the value of translation message for key `milli`.
   * Default value: `Millisecond`
   */
  milli: string;

  /**
   * Returns the value of translation message for key `minute`.
   * Default value: `Minute`
   */
  minute: string;

  /**
   * Returns the value of translation message for key `month`.
   * Default value: `Month`
   */
  month: string;

  /**
   * Returns the value of translation message for key `second`.
   * Default value: `Second`
   */
  second: string;

  /**
   * Returns the value of translation message for key `week`.
   * Default value: `Week`
   */
  week: string;

  /**
   * Returns the value of translation message for key `year`.
   * Default value: `Year`
   */
  year: string;

}

export interface I18n$General$Weekday {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;


  /**
   * Returns the nested accessor for translation messages in `min`.
   */
  min: I18n$General$Weekday$Min;


  /**
   * Returns the nested accessor for translation messages in `long`.
   */
  long: I18n$General$Weekday$Long;

}

export interface I18n$General$Weekday$Min {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `sun`.
   * Default value: `S`
   */
  sun: string;

  /**
   * Returns the value of translation message for key `mon`.
   * Default value: `M`
   */
  mon: string;

  /**
   * Returns the value of translation message for key `tue`.
   * Default value: `T`
   */
  tue: string;

  /**
   * Returns the value of translation message for key `wed`.
   * Default value: `W`
   */
  wed: string;

  /**
   * Returns the value of translation message for key `thu`.
   * Default value: `T`
   */
  thu: string;

  /**
   * Returns the value of translation message for key `fri`.
   * Default value: `F`
   */
  fri: string;

  /**
   * Returns the value of translation message for key `sat`.
   * Default value: `S`
   */
  sat: string;

}

export interface I18n$General$Weekday$Long {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `sun`.
   * Default value: `Sunday`
   */
  sun: string;

  /**
   * Returns the value of translation message for key `mon`.
   * Default value: `Monday`
   */
  mon: string;

  /**
   * Returns the value of translation message for key `tue`.
   * Default value: `Tuesday`
   */
  tue: string;

  /**
   * Returns the value of translation message for key `wed`.
   * Default value: `Wednesday`
   */
  wed: string;

  /**
   * Returns the value of translation message for key `thu`.
   * Default value: `Thursday`
   */
  thu: string;

  /**
   * Returns the value of translation message for key `fri`.
   * Default value: `Friday`
   */
  fri: string;

  /**
   * Returns the value of translation message for key `sat`.
   * Default value: `Saturday`
   */
  sat: string;

}

export interface I18n$General$Range {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;


  /**
   * Returns the value of translation message for key `fromTo`.
   * Default value: `From {min} to {max}`
   */
  fromTo($: {min: string | number, max: string | number}): string;


  /**
   * Returns the value of translation message for key `from`.
   * Default value: `From {min}`
   */
  from(min: string | number): string;


  /**
   * Returns the value of translation message for key `to`.
   * Default value: `Up to {max}`
   */
  to(max: string | number): string;

}

export interface I18n$General$FileSize {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;


  /**
   * Returns the value of translation message for key `b`.
   * Default value: `{n} bytes`
   */
  b(n: string | number): string;


  /**
   * Returns the value of translation message for key `k`.
   * Default value: `{n}KB`
   */
  k(n: string | number): string;


  /**
   * Returns the value of translation message for key `m`.
   * Default value: `{n}MB`
   */
  m(n: string | number): string;

}

export interface I18n$General$Results {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `none`.
   * Default value: `No results match the search criteria`
   */
  none: string;

  /**
   * Returns the value of translation message for key `nextXxs`.
   * Default value: `Next`
   */
  nextXxs: string;

  /**
   * Returns the value of translation message for key `previousXxs`.
   * Default value: `Previous`
   */
  previousXxs: string;


  /**
   * Returns the value of translation message for key `withTotal`.
   * Default value: `Showing {first} - {last} of {total} results`
   */
  withTotal($: {first: string | number, last: string | number, total: string | number}): string;


  /**
   * Returns the value of translation message for key `noTotal`.
   * Default value: `Showing {first} - {last} results`
   */
  noTotal($: {first: string | number, last: string | number}): string;

}

export interface I18n$General$Geolocation {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `kilometersFrom`.
   * Default value: `km from`
   */
  kilometersFrom: string;

  /**
   * Returns the value of translation message for key `milesFrom`.
   * Default value: `miles from`
   */
  milesFrom: string;

  /**
   * Returns the value of translation message for key `current`.
   * Default value: `Current location`
   */
  current: string;

  /**
   * Returns the value of translation message for key `myAddress`.
   * Default value: `My address`
   */
  myAddress: string;

}

export interface I18n$Error {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `general`.
   * Default value: `There was an unexpected error while processing your request`
   */
  general: string;

  /**
   * Returns the value of translation message for key `serverOffline`.
   * Default value: `The server couldn't be contacted.<br>Please, try again later.`
   */
  serverOffline: string;

  /**
   * Returns the value of translation message for key `invalidRequest`.
   * Default value: `It is not possible to connect to the server.<br>Please make sure you are connected to the Internet and try again in a few seconds.`
   */
  invalidRequest: string;

  /**
   * Returns the value of translation message for key `networkInaccessible`.
   * Default value: `This network is not accessible by the specified user`
   */
  networkInaccessible: string;

  /**
   * Returns the value of translation message for key `queryParse`.
   * Default value: `Invalid keywords`
   */
  queryParse: string;

  /**
   * Returns the value of translation message for key `validation`.
   * Default value: `The action couldn't be processed, as there were validation errors`
   */
  validation: string;

  /**
   * Returns the value of translation message for key `staleEntity`.
   * Default value: `This data cannot be saved because it has been modified by someone else.<br>Please, load the page again and restart the operation.`
   */
  staleEntity: string;

  /**
   * Returns the value of translation message for key `removeDataInUse`.
   * Default value: `This item cannot be removed because it is currently in use.`
   */
  removeDataInUse: string;

  /**
   * Returns the value of translation message for key `login`.
   * Default value: `The given name / password are incorrect. Please, try again.`
   */
  login: string;

  /**
   * Returns the value of translation message for key `remoteAddressBlocked`.
   * Default value: `Your IP address is blocked for exceeding login attempts`
   */
  remoteAddressBlocked: string;

  /**
   * Returns the value of translation message for key `permission`.
   * Default value: `You don't have sufficient permissions to perform the requested action`
   */
  permission: string;

  /**
   * Returns the value of translation message for key `emailSending`.
   * Default value: `There was an error while sending the email. Please, try again later.`
   */
  emailSending: string;

  /**
   * Returns the value of translation message for key `smsSending`.
   * Default value: `There was an error while sending the SMS message. Please, try again later.`
   */
  smsSending: string;

  /**
   * Returns the value of translation message for key `loggedOut`.
   * Default value: `You have been disconnected. Please, login again and repeat the operation.`
   */
  loggedOut: string;

  /**
   * Returns the value of translation message for key `illegalAction`.
   * Default value: `The action you attempted to perform is invalid`
   */
  illegalAction: string;


  /**
   * Returns the value of translation message for key `uploadSizeExceeded`.
   * Default value: `The uploaded file exceeds the maximum allowed size of {size}`
   */
  uploadSizeExceeded(size: string | number): string;


  /**
   * Returns the value of translation message for key `maxItems`.
   * Default value: `Cannot add more than {max} elements`
   */
  maxItems(max: string | number): string;


  /**
   * Returns the nested accessor for translation messages in `notFound`.
   */
  notFound: I18n$Error$NotFound;


  /**
   * Returns the nested accessor for translation messages in `unauthorized`.
   */
  unauthorized: I18n$Error$Unauthorized;


  /**
   * Returns the nested accessor for translation messages in `securityAnswer`.
   */
  securityAnswer: I18n$Error$SecurityAnswer;


  /**
   * Returns the nested accessor for translation messages in `field`.
   */
  field: I18n$Error$Field;


  /**
   * Returns the nested accessor for translation messages in `geolocation`.
   */
  geolocation: I18n$Error$Geolocation;

}

export interface I18n$Error$NotFound {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `location`.
   * Default value: `The location you typed or tried to access was not found`
   */
  location: string;


  /**
   * Returns the value of translation message for key `typeKey`.
   * Default value: `The requested data could not be found: {type} with key {key}`
   */
  typeKey($: {type: string | number, key: string | number}): string;


  /**
   * Returns the value of translation message for key `type`.
   * Default value: `The requested data could not be found: {type}`
   */
  type(type: string | number): string;

}

export interface I18n$Error$Unauthorized {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `address`.
   * Default value: `Access not authorized from your location`
   */
  address: string;

  /**
   * Returns the value of translation message for key `url`.
   * Default value: `Access is not authorized from this URL`
   */
  url: string;

}

export interface I18n$Error$SecurityAnswer {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `invalid`.
   * Default value: `The given security answer is invalid`
   */
  invalid: string;

  /**
   * Returns the value of translation message for key `disabled`.
   * Default value: `By exceeding the number of security question attempts, this request has been aborted. Please, contact the administration`
   */
  disabled: string;

}

export interface I18n$Error$Field {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `invalid`.
   * Default value: `This field is invalid`
   */
  invalid: string;

  /**
   * Returns the value of translation message for key `required`.
   * Default value: `This field is required`
   */
  required: string;

  /**
   * Returns the value of translation message for key `passwordsMatch`.
   * Default value: `The passwords don't match`
   */
  passwordsMatch: string;

  /**
   * Returns the value of translation message for key `number`.
   * Default value: `Invalid numeric value`
   */
  number: string;

  /**
   * Returns the value of translation message for key `date`.
   * Default value: `Invalid date`
   */
  date: string;

  /**
   * Returns the value of translation message for key `invalidValuePattern`.
   * Default value: `The entered value was invalid`
   */
  invalidValuePattern: string;


  /**
   * Returns the value of translation message for key `minLength`.
   * Default value: `Should have at least {min} characters`
   */
  minLength(min: string | number): string;


  /**
   * Returns the value of translation message for key `minDate`.
   * Default value: `Should be {min} or after`
   */
  minDate(min: string | number): string;


  /**
   * Returns the value of translation message for key `maxDate`.
   * Default value: `Should be {max} or before`
   */
  maxDate(max: string | number): string;

}

export interface I18n$Error$Geolocation {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `denied`.
   * Default value: `No permission to get the current location. Please, grant this permission in your browser.`
   */
  denied: string;

  /**
   * Returns the value of translation message for key `deniedNoAddressAvailable`.
   * Default value: `No permission to get the current location and there is no address to select. Please, grant this permission in your browser.`
   */
  deniedNoAddressAvailable: string;

  /**
   * Returns the value of translation message for key `unavailable`.
   * Default value: `The current location is unavailable`
   */
  unavailable: string;

  /**
   * Returns the value of translation message for key `general`.
   * Default value: `Error getting the current location`
   */
  general: string;

}

export interface I18n$Menu {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `menu`.
   * Default value: `Menu`
   */
  menu: string;

  /**
   * Returns the value of translation message for key `login`.
   * Default value: `Login`
   */
  login: string;

  /**
   * Returns the value of translation message for key `language`.
   * Default value: `Language`
   */
  language: string;

  /**
   * Returns the value of translation message for key `register`.
   * Default value: `Register`
   */
  register: string;

  /**
   * Returns the value of translation message for key `logout`.
   * Default value: `Logout`
   */
  logout: string;

  /**
   * Returns the value of translation message for key `shoppingCart`.
   * Default value: `Shopping cart`
   */
  shoppingCart: string;

  /**
   * Returns the value of translation message for key `home`.
   * Default value: `Home`
   */
  home: string;

  /**
   * Returns the value of translation message for key `dashboard`.
   * Default value: `Dashboard`
   */
  dashboard: string;

  /**
   * Returns the value of translation message for key `banking`.
   * Default value: `Banking`
   */
  banking: string;

  /**
   * Returns the value of translation message for key `banking.accountsSummary`.
   * Default value: `Accounts summary`
   */
  bankingAccountsSummary: string;

  /**
   * Returns the value of translation message for key `banking.accountVisibility`.
   * Default value: `Show / hide accounts`
   */
  bankingAccountVisibility: string;

  /**
   * Returns the value of translation message for key `banking.payUser`.
   * Default value: `Payment to user`
   */
  bankingPayUser: string;

  /**
   * Returns the value of translation message for key `banking.paySystem`.
   * Default value: `Payment to system`
   */
  bankingPaySystem: string;

  /**
   * Returns the value of translation message for key `banking.paySelf`.
   * Default value: `Payment to self`
   */
  bankingPaySelf: string;

  /**
   * Returns the value of translation message for key `banking.paymentRequests`.
   * Default value: `Payment requests`
   */
  bankingPaymentRequests: string;

  /**
   * Returns the value of translation message for key `banking.paymentRequestsOverview`.
   * Default value: `Payment requests overview`
   */
  bankingPaymentRequestsOverview: string;

  /**
   * Returns the value of translation message for key `banking.externalPayments`.
   * Default value: `External payments`
   */
  bankingExternalPayments: string;

  /**
   * Returns the value of translation message for key `banking.externalPaymentsOverview`.
   * Default value: `External payments overview`
   */
  bankingExternalPaymentsOverview: string;

  /**
   * Returns the value of translation message for key `banking.pos`.
   * Default value: `Receive payment`
   */
  bankingPos: string;

  /**
   * Returns the value of translation message for key `banking.receiveQrPayment`.
   * Default value: `Receive QR-code`
   */
  bankingReceiveQrPayment: string;

  /**
   * Returns the value of translation message for key `banking.scheduledPayments`.
   * Default value: `Scheduled payments`
   */
  bankingScheduledPayments: string;

  /**
   * Returns the value of translation message for key `banking.scheduledPaymentsOverview`.
   * Default value: `Scheduled payments overview`
   */
  bankingScheduledPaymentsOverview: string;

  /**
   * Returns the value of translation message for key `banking.pendingMyAuth`.
   * Default value: `Awaiting my authorization`
   */
  bankingPendingMyAuth: string;

  /**
   * Returns the value of translation message for key `banking.authorizations`.
   * Default value: `Payment authorizations`
   */
  bankingAuthorizations: string;

  /**
   * Returns the value of translation message for key `banking.vouchers`.
   * Default value: `Vouchers`
   */
  bankingVouchers: string;

  /**
   * Returns the value of translation message for key `banking.myVouchers`.
   * Default value: `My vouchers`
   */
  bankingMyVouchers: string;

  /**
   * Returns the value of translation message for key `banking.voucherTransactions`.
   * Default value: `Voucher transactions`
   */
  bankingVoucherTransactions: string;

  /**
   * Returns the value of translation message for key `banking.voucherTransactionsRedeems`.
   * Default value: `Voucher redeems`
   */
  bankingVoucherTransactionsRedeems: string;

  /**
   * Returns the value of translation message for key `banking.transfersOverview`.
   * Default value: `Transfers overview`
   */
  bankingTransfersOverview: string;

  /**
   * Returns the value of translation message for key `banking.userBalancesOverview`.
   * Default value: `User balances overview`
   */
  bankingUserBalancesOverview: string;

  /**
   * Returns the value of translation message for key `banking.accountBalanceLimits`.
   * Default value: `Account balance limits`
   */
  bankingAccountBalanceLimits: string;

  /**
   * Returns the value of translation message for key `banking.accountPaymentLimits`.
   * Default value: `Account payment limits`
   */
  bankingAccountPaymentLimits: string;

  /**
   * Returns the value of translation message for key `operators`.
   * Default value: `Operators`
   */
  operators: string;

  /**
   * Returns the value of translation message for key `operators.operators`.
   * Default value: `Operators`
   */
  operatorsOperators: string;

  /**
   * Returns the value of translation message for key `operators.register`.
   * Default value: `Register operator`
   */
  operatorsRegister: string;

  /**
   * Returns the value of translation message for key `operators.groups`.
   * Default value: `Operator groups`
   */
  operatorsGroups: string;

  /**
   * Returns the value of translation message for key `brokering`.
   * Default value: `Brokering`
   */
  brokering: string;

  /**
   * Returns the value of translation message for key `brokering.users`.
   * Default value: `Users`
   */
  brokeringUsers: string;

  /**
   * Returns the value of translation message for key `brokering.register`.
   * Default value: `Register user`
   */
  brokeringRegister: string;

  /**
   * Returns the value of translation message for key `marketplace`.
   * Default value: `Marketplace`
   */
  marketplace: string;

  /**
   * Returns the value of translation message for key `marketplace.users`.
   * Default value: `Users`
   */
  marketplaceUsers: string;

  /**
   * Returns the value of translation message for key `marketplace.register`.
   * Default value: `Register user`
   */
  marketplaceRegister: string;

  /**
   * Returns the value of translation message for key `marketplace.directory`.
   * Default value: `Directory`
   */
  marketplaceDirectory: string;

  /**
   * Returns the value of translation message for key `marketplace.userSearch`.
   * Default value: `Users`
   */
  marketplaceUserSearch: string;

  /**
   * Returns the value of translation message for key `marketplace.businessDirectory`.
   * Default value: `Business directory`
   */
  marketplaceBusinessDirectory: string;

  /**
   * Returns the value of translation message for key `marketplace.systemMessages`.
   * Default value: `Messages`
   */
  marketplaceSystemMessages: string;

  /**
   * Returns the value of translation message for key `marketplace.advertisements`.
   * Default value: `Advertisements`
   */
  marketplaceAdvertisements: string;

  /**
   * Returns the value of translation message for key `marketplace.adInterests`.
   * Default value: `Advertisement interests`
   */
  marketplaceAdInterests: string;

  /**
   * Returns the value of translation message for key `marketplace.deliveryMethods`.
   * Default value: `Delivery methods`
   */
  marketplaceDeliveryMethods: string;

  /**
   * Returns the value of translation message for key `marketplace.inviteUsers`.
   * Default value: `Invite users`
   */
  marketplaceInviteUsers: string;

  /**
   * Returns the value of translation message for key `marketplace.webshopSettings`.
   * Default value: `Web shop settings`
   */
  marketplaceWebshopSettings: string;

  /**
   * Returns the value of translation message for key `marketplace.myAdvertisements`.
   * Default value: `My advertisements`
   */
  marketplaceMyAdvertisements: string;

  /**
   * Returns the value of translation message for key `marketplace.myPurchases`.
   * Default value: `My purchases`
   */
  marketplaceMyPurchases: string;

  /**
   * Returns the value of translation message for key `marketplace.mySales`.
   * Default value: `My sales`
   */
  marketplaceMySales: string;

  /**
   * Returns the value of translation message for key `marketplace.myWebshop`.
   * Default value: `My web shop`
   */
  marketplaceMyWebshop: string;

  /**
   * Returns the value of translation message for key `marketplace.unansweredQuestions`.
   * Default value: `Unanswered questions`
   */
  marketplaceUnansweredQuestions: string;

  /**
   * Returns the value of translation message for key `marketplace.connectedUsers`.
   * Default value: `Connected users`
   */
  marketplaceConnectedUsers: string;

  /**
   * Returns the value of translation message for key `marketplace.userAlerts`.
   * Default value: `User alerts`
   */
  marketplaceUserAlerts: string;

  /**
   * Returns the value of translation message for key `personal`.
   * Default value: `Personal`
   */
  personal: string;

  /**
   * Returns the value of translation message for key `personal.documents`.
   * Default value: `Documents`
   */
  personalDocuments: string;

  /**
   * Returns the value of translation message for key `personal.viewProfile`.
   * Default value: `My profile`
   */
  personalViewProfile: string;

  /**
   * Returns the value of translation message for key `personal.editProfile`.
   * Default value: `Edit profile`
   */
  personalEditProfile: string;

  /**
   * Returns the value of translation message for key `personal.contacts`.
   * Default value: `Contacts`
   */
  personalContacts: string;

  /**
   * Returns the value of translation message for key `personal.password`.
   * Default value: `Password`
   */
  personalPassword: string;

  /**
   * Returns the value of translation message for key `personal.passwords`.
   * Default value: `Passwords`
   */
  personalPasswords: string;

  /**
   * Returns the value of translation message for key `personal.identityProviders`.
   * Default value: `Identity providers`
   */
  personalIdentityProviders: string;

  /**
   * Returns the value of translation message for key `personal.agreements`.
   * Default value: `Agreements`
   */
  personalAgreements: string;

  /**
   * Returns the value of translation message for key `personal.messages`.
   * Default value: `Messages`
   */
  personalMessages: string;

  /**
   * Returns the value of translation message for key `personal.notifications`.
   * Default value: `Notifications`
   */
  personalNotifications: string;

  /**
   * Returns the value of translation message for key `personal.settings`.
   * Default value: `Settings`
   */
  personalSettings: string;

  /**
   * Returns the value of translation message for key `personal.references`.
   * Default value: `References`
   */
  personalReferences: string;

  /**
   * Returns the value of translation message for key `personal.feedbacks`.
   * Default value: `Feedbacks`
   */
  personalFeedbacks: string;

  /**
   * Returns the value of translation message for key `content`.
   * Default value: `Information`
   */
  content: string;

}

export interface I18n$Login {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Login`
   */
  title: string;

  /**
   * Returns the value of translation message for key `mobileTitle`.
   * Default value: `Login`
   */
  mobileTitle: string;

  /**
   * Returns the value of translation message for key `title.confirmation`.
   * Default value: `Login confirmation`
   */
  titleConfirmation: string;

  /**
   * Returns the value of translation message for key `mobileTitle.confirmation`.
   * Default value: `Login confirmation`
   */
  mobileTitleConfirmation: string;

  /**
   * Returns the value of translation message for key `message`.
   * Default value: `You can login with your username and password`
   */
  message: string;

  /**
   * Returns the value of translation message for key `disconnected`.
   * Default value: `You have been disconnected.<br>Please, login again in order to view the requested page.`
   */
  disconnected: string;

  /**
   * Returns the value of translation message for key `principal`.
   * Default value: `User`
   */
  principal: string;

  /**
   * Returns the value of translation message for key `password`.
   * Default value: `Password`
   */
  password: string;

  /**
   * Returns the value of translation message for key `forgotPassword`.
   * Default value: `Forgot your password or user?`
   */
  forgotPassword: string;

  /**
   * Returns the value of translation message for key `register`.
   * Default value: `Not a user yet? Register here.`
   */
  register: string;

  /**
   * Returns the value of translation message for key `confirmation.text`.
   * Default value: `You have successfully logged-in, but your session must to be confirmed in order to proceed.`
   */
  confirmationText: string;


  /**
   * Returns the nested accessor for translation messages in `error`.
   */
  error: I18n$Login$Error;

}

export interface I18n$Login$Error {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;


  /**
   * Returns the nested accessor for translation messages in `user`.
   */
  user: I18n$Login$Error$User;


  /**
   * Returns the nested accessor for translation messages in `password`.
   */
  password: I18n$Login$Error$Password;


  /**
   * Returns the nested accessor for translation messages in `confirmation`.
   */
  confirmation: I18n$Login$Error$Confirmation;

}

export interface I18n$Login$Error$User {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `blocked`.
   * Default value: `Sign in failed. Your user account has been blocked. Please, contact the administration.`
   */
  blocked: string;

  /**
   * Returns the value of translation message for key `disabled`.
   * Default value: `Sign in failed. Your user account has been disabled. Please, contact the administration.`
   */
  disabled: string;

  /**
   * Returns the value of translation message for key `pending`.
   * Default value: `Sign in failed. Your user account is pending for activation. You should have received an email with instructions to activate your account. If not, please, contact the administration.`
   */
  pending: string;

}

export interface I18n$Login$Error$Password {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `expired`.
   * Default value: `Sign in failed. Your password has expired. Please, contact the administration.`
   */
  expired: string;

  /**
   * Returns the value of translation message for key `disabled`.
   * Default value: `Sign in failed. Your password was disabled. Please, contact the administration.`
   */
  disabled: string;

  /**
   * Returns the value of translation message for key `reset`.
   * Default value: `Sign in failed. Your password has been reset. Please, contact the administration.`
   */
  reset: string;

  /**
   * Returns the value of translation message for key `indefinitelyBlocked`.
   * Default value: `Sign in failed. Your login password has been disabled by exceeding the maximum tries. Please, contact the administration.`
   */
  indefinitelyBlocked: string;

  /**
   * Returns the value of translation message for key `temporarilyBlocked`.
   * Default value: `Your password is temporarily blocked by exceeding the maximum tries`
   */
  temporarilyBlocked: string;

}

export interface I18n$Login$Error$Confirmation {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `missingDevice`.
   * Default value: `Sign in failed. You are required to confirm your login with a trusted mobile device, but you have none. Please, install the mobile app and activate is a trusted device first.`
   */
  missingDevice: string;


  /**
   * Returns the value of translation message for key `missingPassword`.
   * Default value: `Sign in failed. You are required to confirm the login with your {0} but you do not it active. Please, contact the administration.`
   */
  missingPassword($0: string | number): string;


  /**
   * Returns the value of translation message for key `missingBoth`.
   * Default value: `Sign in failed. You are required to confirm your login with either your {0} or a trusted mobile device, but you have none. Please, install the mobile app and activate is a trusted device first, or contact the administration.`
   */
  missingBoth($0: string | number): string;

}

export interface I18n$Password {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `confirm.mode`.
   * Default value: `Confirm with`
   */
  confirmMode: string;

  /**
   * Returns the value of translation message for key `confirm.mode.device`.
   * Default value: `Mobile phone`
   */
  confirmModeDevice: string;

  /**
   * Returns the value of translation message for key `confirm.mode.password`.
   * Default value: `Password`
   */
  confirmModePassword: string;

  /**
   * Returns the value of translation message for key `oldPassword`.
   * Default value: `Old password`
   */
  oldPassword: string;

  /**
   * Returns the value of translation message for key `newPassword`.
   * Default value: `New password`
   */
  newPassword: string;

  /**
   * Returns the value of translation message for key `passwordConfirmation`.
   * Default value: `Password confirmation`
   */
  passwordConfirmation: string;


  /**
   * Returns the value of translation message for key `confirm.password.placeholder`.
   * Default value: `Type here: {password}`
   */
  confirmPasswordPlaceholder(password: string | number): string;


  /**
   * Returns the value of translation message for key `otp.countdownLabel`.
   * Default value: `* You can sent the e-mail/sms again in {seconds} seconds.`
   */
  otpCountdownLabel(seconds: string | number): string;


  /**
   * Returns the value of translation message for key `otp.receiveBy.sent`.
   * Default value: `{medium} sent`
   */
  otpReceiveBySent(medium: string | number): string;


  /**
   * Returns the value of translation message for key `otp.sent`.
   * Default value: `The password was sent to {dest}`
   */
  otpSent(dest: string | number): string;


  /**
   * Returns the value of translation message for key `otp.sentToMail`.
   * Default value: `Sent to E-mail {mail}`
   */
  otpSentToMail(mail: string | number): string;


  /**
   * Returns the value of translation message for key `otp.sentToPhone`.
   * Default value: `Sent to phone {phone}`
   */
  otpSentToPhone(phone: string | number): string;


  /**
   * Returns the value of translation message for key `statusSince`.
   * Default value: `Since {date}`
   */
  statusSince(date: string | number): string;


  /**
   * Returns the nested accessor for translation messages in `confirmMessage`.
   */
  confirmMessage: I18n$Password$ConfirmMessage;


  /**
   * Returns the nested accessor for translation messages in `error`.
   */
  error: I18n$Password$Error;


  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  title: I18n$Password$Title;


  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  mobileTitle: I18n$Password$MobileTitle;


  /**
   * Returns the nested accessor for translation messages in `forgotten`.
   */
  forgotten: I18n$Password$Forgotten;


  /**
   * Returns the nested accessor for translation messages in `expired`.
   */
  expired: I18n$Password$Expired;


  /**
   * Returns the nested accessor for translation messages in `status`.
   */
  status: I18n$Password$Status;


  /**
   * Returns the nested accessor for translation messages in `action`.
   */
  action: I18n$Password$Action;

}

export interface I18n$Password$ConfirmMessage {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `active.device`.
   * Default value: `In order to confirm, scan this QR-code with your mobile phone.`
   */
  activeDevice: string;

  /**
   * Returns the value of translation message for key `active.deviceOrRenewablePassword`.
   * Default value: `In order to confirm, either scan this QR-code with your mobile phone, use the previously sent confirmation password or request a new one`
   */
  activeDeviceOrRenewablePassword: string;

  /**
   * Returns the value of translation message for key `active.deviceOrRenewablePassword.noMediums`.
   * Default value: `In order to confirm, either scan this QR-code with your mobile phone or use the previously sent confirmation password because you can not request for a new one`
   */
  activeDeviceOrRenewablePasswordNoMediums: string;

  /**
   * Returns the value of translation message for key `active.deviceOrNotActiveRenewablePassword`.
   * Default value: `In order to confirm, either scan this QR-code with your mobile phone, or request a confirmation password below`
   */
  activeDeviceOrNotActiveRenewablePassword: string;

  /**
   * Returns the value of translation message for key `active.renewablePassword`.
   * Default value: `In order to confirm use the previously sent confirmation password or request a new one`
   */
  activeRenewablePassword: string;

  /**
   * Returns the value of translation message for key `active.renewablePassword.noMediums`.
   * Default value: `In order to confirm use the previously sent confirmation password because you can not request for a new one`
   */
  activeRenewablePasswordNoMediums: string;

  /**
   * Returns the value of translation message for key `notActive.device`.
   * Default value: `This operation needs to be confirmed with a trusted device (mobile phone), please login to your mobile device, go to the configuration (settings icon) and make the device trusted`
   */
  notActiveDevice: string;

  /**
   * Returns the value of translation message for key `notActive.renewablePassword`.
   * Default value: `Please, request a confirmation password below in order to confirm`
   */
  notActiveRenewablePassword: string;


  /**
   * Returns the value of translation message for key `active.deviceOrPassword`.
   * Default value: `In order to confirm, either scan this QR-code with your mobile phone or supply your {password}`
   */
  activeDeviceOrPassword(password: string | number): string;


  /**
   * Returns the value of translation message for key `active.password`.
   * Default value: `In order to confirm, supply your {password}`
   */
  activePassword(password: string | number): string;


  /**
   * Returns the value of translation message for key `notActive.deviceOrPassword`.
   * Default value: `In order to confirm you must have a trusted mobile phone or supply your {password} but you do not have any`
   */
  notActiveDeviceOrPassword(password: string | number): string;


  /**
   * Returns the value of translation message for key `notActive.deviceOrRenewablePassword.noMediums`.
   * Default value: `In order to confirm you must have a trusted mobile phone or supply your {password} but you do not have any and can not request for a new password, please, contact the administration`
   */
  notActiveDeviceOrRenewablePasswordNoMediums(password: string | number): string;


  /**
   * Returns the value of translation message for key `notActive.password`.
   * Default value: `In order to confirm you need a {password}, but you do not have any`
   */
  notActivePassword(password: string | number): string;


  /**
   * Returns the value of translation message for key `notActive.renewablePassword.noMediums`.
   * Default value: `In order to confirm you need a {password}, but there are no possible mediums to receive it, please, contact the administration.`
   */
  notActiveRenewablePasswordNoMediums(password: string | number): string;

}

export interface I18n$Password$Error {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `indefinitelyBlocked`.
   * Default value: `Your password has been disabled by exceeding the maximum tries. Please, contact the administration.`
   */
  indefinitelyBlocked: string;

  /**
   * Returns the value of translation message for key `temporarilyBlocked`.
   * Default value: `Your password is temporarily blocked by exceeding the maximum tries.`
   */
  temporarilyBlocked: string;

  /**
   * Returns the value of translation message for key `expired`.
   * Default value: `Your password has expired. Please, contact the administration.`
   */
  expired: string;

  /**
   * Returns the value of translation message for key `otpInvalidated`.
   * Default value: `The one time password has been invalidated. Please, request a new one.`
   */
  otpInvalidated: string;


  /**
   * Returns the value of translation message for key `invalid`.
   * Default value: `The given {type} is invalid`
   */
  invalid(type: string | number): string;

}

export interface I18n$Password$Title {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `change.self`.
   * Default value: `Change your password`
   */
  changeSelf: string;

  /**
   * Returns the value of translation message for key `change.user`.
   * Default value: `Change user password`
   */
  changeUser: string;

  /**
   * Returns the value of translation message for key `forgotten`.
   * Default value: `Recover password or user`
   */
  forgotten: string;

  /**
   * Returns the value of translation message for key `manage.single.self`.
   * Default value: `Manage your password`
   */
  manageSingleSelf: string;

  /**
   * Returns the value of translation message for key `manage.multiple.self`.
   * Default value: `Manage your passwords`
   */
  manageMultipleSelf: string;

  /**
   * Returns the value of translation message for key `manage.single.user`.
   * Default value: `Manage user password`
   */
  manageSingleUser: string;

  /**
   * Returns the value of translation message for key `manage.multiple.user`.
   * Default value: `Manage user passwords`
   */
  manageMultipleUser: string;

  /**
   * Returns the value of translation message for key `securityAnswer`.
   * Default value: `Set your security answer`
   */
  securityAnswer: string;

  /**
   * Returns the value of translation message for key `history`.
   * Default value: `Passwords history`
   */
  history: string;

}

export interface I18n$Password$MobileTitle {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `change.self`.
   * Default value: `Password change`
   */
  changeSelf: string;

  /**
   * Returns the value of translation message for key `forgotten`.
   * Default value: `User / password recovery`
   */
  forgotten: string;

  /**
   * Returns the value of translation message for key `manage.single.self`.
   * Default value: `Password`
   */
  manageSingleSelf: string;

  /**
   * Returns the value of translation message for key `manage.multiple.self`.
   * Default value: `Passwords`
   */
  manageMultipleSelf: string;

  /**
   * Returns the value of translation message for key `manage.single.user`.
   * Default value: `User password`
   */
  manageSingleUser: string;

  /**
   * Returns the value of translation message for key `manage.multiple.user`.
   * Default value: `User passwords`
   */
  manageMultipleUser: string;

  /**
   * Returns the value of translation message for key `securityAnswer`.
   * Default value: `Security answer`
   */
  securityAnswer: string;

  /**
   * Returns the value of translation message for key `history`.
   * Default value: `Passwords history`
   */
  history: string;

}

export interface I18n$Password$Forgotten {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `preface`.
   * Default value: `Here you can recover your user and change your password by requesting a recovery code. You can use one of the following identification methods in the user field below:`
   */
  preface: string;

  /**
   * Returns the value of translation message for key `principal`.
   * Default value: `User`
   */
  principal: string;

  /**
   * Returns the value of translation message for key `captcha`.
   * Default value: `Captcha validation`
   */
  captcha: string;

  /**
   * Returns the value of translation message for key `code`.
   * Default value: `Verification code`
   */
  code: string;

  /**
   * Returns the value of translation message for key `sendMedium`.
   * Default value: `Receive code by`
   */
  sendMedium: string;

  /**
   * Returns the value of translation message for key `generate`.
   * Default value: `Generate password`
   */
  generate: string;

  /**
   * Returns the value of translation message for key `generated.done.email`.
   * Default value: `You should receive shortly an e-mail with your new password.`
   */
  generatedDoneEmail: string;

  /**
   * Returns the value of translation message for key `generated.done.sms`.
   * Default value: `You should receive shortly an SMS message with your new password.`
   */
  generatedDoneSms: string;

  /**
   * Returns the value of translation message for key `manual.done`.
   * Default value: `Your password has been successfully changed. You can now use it to login.`
   */
  manualDone: string;

  /**
   * Returns the value of translation message for key `title.changePassword`.
   * Default value: `Change your password`
   */
  titleChangePassword: string;

  /**
   * Returns the value of translation message for key `invalidUser`.
   * Default value: `The given user identification is either invalid or belongs to an user that cannot use the forgot password option. Please, contact the administration.`
   */
  invalidUser: string;


  /**
   * Returns the value of translation message for key `code.sent`.
   * Default value: `The verification code was sent to {to}`
   */
  codeSent(to: string | number): string;


  /**
   * Returns the value of translation message for key `code.blocked`.
   * Default value: `By exceeding the number of attempts, this request has been aborted and your password is now {status}.`
   */
  codeBlocked(status: string | number): string;

}

export interface I18n$Password$Expired {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `message.manual`.
   * Default value: `In order to proceed, you must change it.`
   */
  messageManual: string;

  /**
   * Returns the value of translation message for key `message.generated`.
   * Default value: `In order to proceed, a new value must be generated.<br>This value will only be displayed once, so, make sure you either memorize it or write it down.`
   */
  messageGenerated: string;

  /**
   * Returns the value of translation message for key `generateNew`.
   * Default value: `Generate new`
   */
  generateNew: string;


  /**
   * Returns the value of translation message for key `generatedValue`.
   * Default value: `The value for {type} is <data>{value}</data>.<br>This value won't be displayed again, so, make sure you either memorize it or write it down.`
   */
  generatedValue($: {type: string | number, value: string | number}): string;


  /**
   * Returns the value of translation message for key `preface`.
   * Default value: `Your {type} has expired.`
   */
  preface(type: string | number): string;


  /**
   * Returns the value of translation message for key `changed`.
   * Default value: `Your {type} was changed`
   */
  changed(type: string | number): string;

}

export interface I18n$Password$Status {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `active`.
   * Default value: `Active`
   */
  active: string;

  /**
   * Returns the value of translation message for key `disabled`.
   * Default value: `Disabled`
   */
  disabled: string;

  /**
   * Returns the value of translation message for key `expired`.
   * Default value: `Expired`
   */
  expired: string;

  /**
   * Returns the value of translation message for key `indefinitelyBlocked`.
   * Default value: `Blocked`
   */
  indefinitelyBlocked: string;

  /**
   * Returns the value of translation message for key `neverCreated`.
   * Default value: `Never created`
   */
  neverCreated: string;

  /**
   * Returns the value of translation message for key `pending`.
   * Default value: `Pending`
   */
  pending: string;

  /**
   * Returns the value of translation message for key `reset`.
   * Default value: `Reset`
   */
  reset: string;

  /**
   * Returns the value of translation message for key `temporarilyBlocked`.
   * Default value: `Temporarily blocked`
   */
  temporarilyBlocked: string;

}

export interface I18n$Password$Action {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `change`.
   * Default value: `Change`
   */
  change: string;

  /**
   * Returns the value of translation message for key `unblock`.
   * Default value: `Unblock`
   */
  unblock: string;

  /**
   * Returns the value of translation message for key `activate`.
   * Default value: `Activate`
   */
  activate: string;

  /**
   * Returns the value of translation message for key `enable`.
   * Default value: `Enable`
   */
  enable: string;

  /**
   * Returns the value of translation message for key `disable`.
   * Default value: `Disable`
   */
  disable: string;

  /**
   * Returns the value of translation message for key `resetAndSend`.
   * Default value: `Reset and send`
   */
  resetAndSend: string;

  /**
   * Returns the value of translation message for key `resetAndSendEmail`.
   * Default value: `Reset and send by e-mail`
   */
  resetAndSendEmail: string;

  /**
   * Returns the value of translation message for key `resetAndSendSms`.
   * Default value: `Reset and send by SMS`
   */
  resetAndSendSms: string;

  /**
   * Returns the value of translation message for key `resetGenerated`.
   * Default value: `Reset`
   */
  resetGenerated: string;

  /**
   * Returns the value of translation message for key `allowGeneration`.
   * Default value: `Allow generation`
   */
  allowGeneration: string;


  /**
   * Returns the value of translation message for key `change.generated.done`.
   * Default value: `The value for {type} is <data>{value}</data>.<br>Make sure to memorize it, as it won't be displayed again.`
   */
  changeGeneratedDone($: {type: string | number, value: string | number}): string;


  /**
   * Returns the value of translation message for key `change.done`.
   * Default value: `Your {type} was changed`
   */
  changeDone(type: string | number): string;


  /**
   * Returns the value of translation message for key `change.generated.confirm`.
   * Default value: `This will generate a new {type}, and the value will be displayed only once. Are you sure?`
   */
  changeGeneratedConfirm(type: string | number): string;


  /**
   * Returns the value of translation message for key `unblock.confirm`.
   * Default value: `Are you sure to unblock the {type}?`
   */
  unblockConfirm(type: string | number): string;


  /**
   * Returns the value of translation message for key `unblock.done`.
   * Default value: `{type} was unblocked`
   */
  unblockDone(type: string | number): string;


  /**
   * Returns the value of translation message for key `activate.confirm`.
   * Default value: `This will activate your {type}, and the generated value will be displayed only once. Are you sure?`
   */
  activateConfirm(type: string | number): string;


  /**
   * Returns the value of translation message for key `enable.confirm`.
   * Default value: `Are you sure to enable the {type}?`
   */
  enableConfirm(type: string | number): string;


  /**
   * Returns the value of translation message for key `enable.done`.
   * Default value: `{type} was enabled`
   */
  enableDone(type: string | number): string;


  /**
   * Returns the value of translation message for key `disable.confirm`.
   * Default value: `Are you sure to disable the {type}?`
   */
  disableConfirm(type: string | number): string;


  /**
   * Returns the value of translation message for key `disable.done`.
   * Default value: `{type} was disabled`
   */
  disableDone(type: string | number): string;


  /**
   * Returns the value of translation message for key `resetAndSendEmail.confirm`.
   * Default value: `Are you sure to reset the {type} and send it by e-mail to the user?`
   */
  resetAndSendEmailConfirm(type: string | number): string;


  /**
   * Returns the value of translation message for key `resetAndSendEmail.done`.
   * Default value: `{type} was reset and sent by e-mail`
   */
  resetAndSendEmailDone(type: string | number): string;


  /**
   * Returns the value of translation message for key `resetAndSendSms.confirm`.
   * Default value: `Are you sure to reset the {type} and send it by SMS to the user?`
   */
  resetAndSendSmsConfirm(type: string | number): string;


  /**
   * Returns the value of translation message for key `resetAndSendSms.done`.
   * Default value: `{type} was reset and sent by SMS`
   */
  resetAndSendSmsDone(type: string | number): string;


  /**
   * Returns the value of translation message for key `resetGenerated.confirm`.
   * Default value: `Are you sure to reset the {type}, allowing the user to generate it again?`
   */
  resetGeneratedConfirm(type: string | number): string;


  /**
   * Returns the value of translation message for key `resetGenerated.done`.
   * Default value: `{type} was reset`
   */
  resetGeneratedDone(type: string | number): string;


  /**
   * Returns the value of translation message for key `allowGeneration.confirm`.
   * Default value: `Are you sure to allow the user to generate their{type}?`
   */
  allowGenerationConfirm(type: string | number): string;


  /**
   * Returns the value of translation message for key `allowGeneration.done`.
   * Default value: `The user can now generate their {type}`
   */
  allowGenerationDone(type: string | number): string;

}

export interface I18n$PendingAgreements {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `message.previouslyAccepted`.
   * Default value: `The registration agreement has been updated.<br><br>In order to continue, you must agree with the new terms.`
   */
  messagePreviouslyAccepted: string;

  /**
   * Returns the value of translation message for key `message.firstTime`.
   * Default value: `In order to complete your registration, you must agree with these terms.`
   */
  messageFirstTime: string;

  /**
   * Returns the value of translation message for key `preface`.
   * Default value: `I hereby declare that I have read and agree with the following:`
   */
  preface: string;


  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  title: I18n$PendingAgreements$Title;


  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  mobileTitle: I18n$PendingAgreements$MobileTitle;

}

export interface I18n$PendingAgreements$Title {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `previouslyAccepted`.
   * Default value: `Updated agreement`
   */
  previouslyAccepted: string;

  /**
   * Returns the value of translation message for key `firstTime`.
   * Default value: `Registration agreement`
   */
  firstTime: string;

}

export interface I18n$PendingAgreements$MobileTitle {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `previouslyAccepted`.
   * Default value: `Updated agreement`
   */
  previouslyAccepted: string;

  /**
   * Returns the value of translation message for key `firstTime`.
   * Default value: `Registration agreement`
   */
  firstTime: string;

}

export interface I18n$SecurityQuestion {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `message`.
   * Default value: `The security question is required in case you need to reset your password. Once set, if you need to change it, contact the administration.`
   */
  message: string;

  /**
   * Returns the value of translation message for key `question`.
   * Default value: `Security question`
   */
  question: string;

  /**
   * Returns the value of translation message for key `answer`.
   * Default value: `Your answer`
   */
  answer: string;

  /**
   * Returns the value of translation message for key `set`.
   * Default value: `Your security answer was set`
   */
  set: string;

}

export interface I18n$Dashboard {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `quickAccess`.
   * Default value: `Quick access`
   */
  quickAccess: string;

  /**
   * Returns the value of translation message for key `accounts`.
   * Default value: `Accounts`
   */
  accounts: string;

  /**
   * Returns the value of translation message for key `accountStatus`.
   * Default value: `Account status`
   */
  accountStatus: string;

  /**
   * Returns the value of translation message for key `lastPayments`.
   * Default value: `Last payments`
   */
  lastPayments: string;

  /**
   * Returns the value of translation message for key `noPayments`.
   * Default value: `No payments`
   */
  noPayments: string;

  /**
   * Returns the value of translation message for key `latestAds`.
   * Default value: `Latest advertisements`
   */
  latestAds: string;

  /**
   * Returns the value of translation message for key `latestUsers`.
   * Default value: `Latest users`
   */
  latestUsers: string;


  /**
   * Returns the value of translation message for key `quickAccess.shortcutTemplate`.
   * Default value: `{shortcut}: {label}`
   */
  quickAccessShortcutTemplate($: {shortcut: string | number, label: string | number}): string;


  /**
   * Returns the nested accessor for translation messages in `action`.
   */
  action: I18n$Dashboard$Action;


  /**
   * Returns the nested accessor for translation messages in `passwords`.
   */
  passwords: I18n$Dashboard$Passwords;

}

export interface I18n$Dashboard$Action {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `account`.
   * Default value: `Account`
   */
  account: string;

  /**
   * Returns the value of translation message for key `accounts`.
   * Default value: `Accounts`
   */
  accounts: string;

  /**
   * Returns the value of translation message for key `payUser`.
   * Default value: `Pay user`
   */
  payUser: string;

  /**
   * Returns the value of translation message for key `paySystem`.
   * Default value: `Pay system`
   */
  paySystem: string;

  /**
   * Returns the value of translation message for key `pos`.
   * Default value: `Receive payment`
   */
  pos: string;

  /**
   * Returns the value of translation message for key `receiveQrPayment`.
   * Default value: `Receive QR-code payment`
   */
  receiveQrPayment: string;

  /**
   * Returns the value of translation message for key `contacts`.
   * Default value: `Contacts`
   */
  contacts: string;

  /**
   * Returns the value of translation message for key `directory`.
   * Default value: `Directory`
   */
  directory: string;

  /**
   * Returns the value of translation message for key `advertisements`.
   * Default value: `Marketplace`
   */
  advertisements: string;

  /**
   * Returns the value of translation message for key `editProfile`.
   * Default value: `Edit profile`
   */
  editProfile: string;

  /**
   * Returns the value of translation message for key `password`.
   * Default value: `Password`
   */
  password: string;

  /**
   * Returns the value of translation message for key `passwords`.
   * Default value: `Passwords`
   */
  passwords: string;

  /**
   * Returns the value of translation message for key `switchTheme`.
   * Default value: `Switch theme`
   */
  switchTheme: string;

  /**
   * Returns the value of translation message for key `classicFrontend`.
   * Default value: `Use classic frontend`
   */
  classicFrontend: string;

  /**
   * Returns the value of translation message for key `redeem`.
   * Default value: `Redeem voucher`
   */
  redeem: string;

  /**
   * Returns the value of translation message for key `topUp`.
   * Default value: `Top-up voucher`
   */
  topUp: string;

  /**
   * Returns the value of translation message for key `voucherTransactions`.
   * Default value: `Voucher transactions`
   */
  voucherTransactions: string;

  /**
   * Returns the value of translation message for key `voucherTransactionsRedeems`.
   * Default value: `Voucher redeems`
   */
  voucherTransactionsRedeems: string;

  /**
   * Returns the value of translation message for key `buy`.
   * Default value: `Buy vouchers`
   */
  buy: string;

  /**
   * Returns the value of translation message for key `send`.
   * Default value: `Buy and email voucher`
   */
  send: string;

  /**
   * Returns the value of translation message for key `vouchers`.
   * Default value: `My vouchers`
   */
  vouchers: string;

  /**
   * Returns the value of translation message for key `sendPaymentRequestToUser`.
   * Default value: `Request payment`
   */
  sendPaymentRequestToUser: string;

  /**
   * Returns the value of translation message for key `sendPaymentRequestToSystem`.
   * Default value: `Request payment from system`
   */
  sendPaymentRequestToSystem: string;

  /**
   * Returns the value of translation message for key `paymentRequests`.
   * Default value: `Payment requests`
   */
  paymentRequests: string;

  /**
   * Returns the value of translation message for key `settings`.
   * Default value: `Settings`
   */
  settings: string;

  /**
   * Returns the value of translation message for key `scheduledPayments`.
   * Default value: `Scheduled payments`
   */
  scheduledPayments: string;

  /**
   * Returns the value of translation message for key `payExternalUser`.
   * Default value: `Pay external user`
   */
  payExternalUser: string;

}

export interface I18n$Dashboard$Passwords {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `securityAnswer`.
   * Default value: `You should set the security question, which will be required in case you need to reset your password.`
   */
  securityAnswer: string;

  /**
   * Returns the value of translation message for key `proceed`.
   * Default value: `Click here to proceed`
   */
  proceed: string;


  /**
   * Returns the value of translation message for key `expired`.
   * Default value: `Your {type} has expired.`
   */
  expired(type: string | number): string;


  /**
   * Returns the value of translation message for key `reset`.
   * Default value: `Your {type} has reset.`
   */
  reset(type: string | number): string;


  /**
   * Returns the value of translation message for key `pending`.
   * Default value: `Your {type} is pending generation.`
   */
  pending(type: string | number): string;


  /**
   * Returns the value of translation message for key `neverCreated`.
   * Default value: `Your {type} needs to be set.`
   */
  neverCreated(type: string | number): string;

}

export interface I18n$Account {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `account`.
   * Default value: `Account`
   */
  account: string;

  /**
   * Returns the value of translation message for key `number`.
   * Default value: `Account number`
   */
  number: string;

  /**
   * Returns the value of translation message for key `transferFilter`.
   * Default value: `Filter`
   */
  transferFilter: string;

  /**
   * Returns the value of translation message for key `minAmount`.
   * Default value: `From amount`
   */
  minAmount: string;

  /**
   * Returns the value of translation message for key `maxAmount`.
   * Default value: `To amount`
   */
  maxAmount: string;

  /**
   * Returns the value of translation message for key `direction`.
   * Default value: `Direction`
   */
  direction: string;

  /**
   * Returns the value of translation message for key `incoming`.
   * Default value: `Incoming`
   */
  incoming: string;

  /**
   * Returns the value of translation message for key `outgoing`.
   * Default value: `Outgoing`
   */
  outgoing: string;

  /**
   * Returns the value of translation message for key `balance`.
   * Default value: `Balance`
   */
  balance: string;

  /**
   * Returns the value of translation message for key `reservedAmount`.
   * Default value: `Reserved amount`
   */
  reservedAmount: string;

  /**
   * Returns the value of translation message for key `availableBalance`.
   * Default value: `Available balance`
   */
  availableBalance: string;

  /**
   * Returns the value of translation message for key `negativeLimit`.
   * Default value: `Negative limit`
   */
  negativeLimit: string;

  /**
   * Returns the value of translation message for key `positiveLimit`.
   * Default value: `Positive limit`
   */
  positiveLimit: string;

  /**
   * Returns the value of translation message for key `totalIncome`.
   * Default value: `Total received`
   */
  totalIncome: string;

  /**
   * Returns the value of translation message for key `totalOutflow`.
   * Default value: `Total paid`
   */
  totalOutflow: string;

  /**
   * Returns the value of translation message for key `netInflow`.
   * Default value: `Net income`
   */
  netInflow: string;

  /**
   * Returns the value of translation message for key `system`.
   * Default value: `System account`
   */
  system: string;


  /**
   * Returns the value of translation message for key `accountInformation`.
   * Default value: `Account information: {accountName}`
   */
  accountInformation(accountName: string | number): string;


  /**
   * Returns the value of translation message for key `balanceOn`.
   * Default value: `Balance on {date}`
   */
  balanceOn(date: string | number): string;


  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  mobileTitle: I18n$Account$MobileTitle;


  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  title: I18n$Account$Title;


  /**
   * Returns the nested accessor for translation messages in `orderBy`.
   */
  orderBy: I18n$Account$OrderBy;


  /**
   * Returns the nested accessor for translation messages in `userBalances`.
   */
  userBalances: I18n$Account$UserBalances;


  /**
   * Returns the nested accessor for translation messages in `limits`.
   */
  limits: I18n$Account$Limits;


  /**
   * Returns the nested accessor for translation messages in `visibilitySettings`.
   */
  visibilitySettings: I18n$Account$VisibilitySettings;


  /**
   * Returns the nested accessor for translation messages in `balanceLimits`.
   */
  balanceLimits: I18n$Account$BalanceLimits;


  /**
   * Returns the nested accessor for translation messages in `paymentLimits`.
   */
  paymentLimits: I18n$Account$PaymentLimits;

}

export interface I18n$Account$MobileTitle {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `account`.
   * Default value: `Account`
   */
  account: string;

  /**
   * Returns the value of translation message for key `accountsSummary`.
   * Default value: `Accounts summary`
   */
  accountsSummary: string;

}

export interface I18n$Account$Title {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `accountsSummary`.
   * Default value: `Accounts summary`
   */
  accountsSummary: string;

}

export interface I18n$Account$OrderBy {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `dateDesc`.
   * Default value: `Date (newest first)`
   */
  dateDesc: string;

  /**
   * Returns the value of translation message for key `dateAsc`.
   * Default value: `Date (oldest first)`
   */
  dateAsc: string;

  /**
   * Returns the value of translation message for key `amountAsc`.
   * Default value: `Amount (lowest first)`
   */
  amountAsc: string;

  /**
   * Returns the value of translation message for key `amountDesc`.
   * Default value: `Amount (highest first)`
   */
  amountDesc: string;

}

export interface I18n$Account$UserBalances {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `negativeSince`.
   * Default value: `Negative since`
   */
  negativeSince: string;

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `User balances`
   */
  title: string;

  /**
   * Returns the value of translation message for key `mobileTitle`.
   * Default value: `User balances`
   */
  mobileTitle: string;

  /**
   * Returns the value of translation message for key `minBalance`.
   * Default value: `From balance`
   */
  minBalance: string;

  /**
   * Returns the value of translation message for key `maxBalance`.
   * Default value: `To balance`
   */
  maxBalance: string;

  /**
   * Returns the value of translation message for key `balancesSum`.
   * Default value: `Balances sum`
   */
  balancesSum: string;

  /**
   * Returns the value of translation message for key `balancesAverage`.
   * Default value: `Balances average`
   */
  balancesAverage: string;

  /**
   * Returns the value of translation message for key `balancesCount`.
   * Default value: `Balances count`
   */
  balancesCount: string;

  /**
   * Returns the value of translation message for key `total`.
   * Default value: `Total`
   */
  total: string;

  /**
   * Returns the value of translation message for key `fromYellowBalance`.
   * Default value: `From yellow balance`
   */
  fromYellowBalance: string;

  /**
   * Returns the value of translation message for key `toYellowBalance`.
   * Default value: `To yellow balance`
   */
  toYellowBalance: string;

  /**
   * Returns the value of translation message for key `beginNegativeSince`.
   * Default value: `Negative after`
   */
  beginNegativeSince: string;

  /**
   * Returns the value of translation message for key `endNegativeSince`.
   * Default value: `Negative before`
   */
  endNegativeSince: string;


  /**
   * Returns the nested accessor for translation messages in `orderBy`.
   */
  orderBy: I18n$Account$UserBalances$OrderBy;

}

export interface I18n$Account$UserBalances$OrderBy {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `alphabeticallyDesc`.
   * Default value: `Name (A-Z)`
   */
  alphabeticallyDesc: string;

  /**
   * Returns the value of translation message for key `alphabeticallyAsc`.
   * Default value: `Name (Z-A)`
   */
  alphabeticallyAsc: string;

  /**
   * Returns the value of translation message for key `balanceAsc`.
   * Default value: `Balance (lowest first)`
   */
  balanceAsc: string;

  /**
   * Returns the value of translation message for key `balanceDesc`.
   * Default value: `Balance (highest first)`
   */
  balanceDesc: string;

}

export interface I18n$Account$Limits {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `unlimited`.
   * Default value: `Unlimited`
   */
  unlimited: string;

  /**
   * Returns the value of translation message for key `mode`.
   * Default value: `Mode`
   */
  mode: string;

  /**
   * Returns the value of translation message for key `personalized`.
   * Default value: `Personalized`
   */
  personalized: string;

  /**
   * Returns the value of translation message for key `productDefault`.
   * Default value: `Product default`
   */
  productDefault: string;

}

export interface I18n$Account$VisibilitySettings {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Account visibility`
   */
  title: string;

  /**
   * Returns the value of translation message for key `mobileTitle`.
   * Default value: `Account visibility`
   */
  mobileTitle: string;

  /**
   * Returns the value of translation message for key `information`.
   * Default value: `At this page you can define which accounts you want to be visible.  When an account is hidden it won’t show up in the account summary. You won’t be able to make payments from an account when it is hidden, but hidden accounts can always receive payments.`
   */
  information: string;

  /**
   * Returns the value of translation message for key `accounts`.
   * Default value: `Accounts to show`
   */
  accounts: string;

  /**
   * Returns the value of translation message for key `saved`.
   * Default value: `The account visibility settings were saved`
   */
  saved: string;

}

export interface I18n$Account$BalanceLimits {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `negativeLimit`.
   * Default value: `Negative balance limit`
   */
  negativeLimit: string;

  /**
   * Returns the value of translation message for key `positiveLimit`.
   * Default value: `Positive balance limit`
   */
  positiveLimit: string;

  /**
   * Returns the value of translation message for key `personalizedNegativeLimit`.
   * Default value: `Personalized negative limit`
   */
  personalizedNegativeLimit: string;

  /**
   * Returns the value of translation message for key `personalizedPositiveLimit`.
   * Default value: `Personalized positive limit`
   */
  personalizedPositiveLimit: string;

  /**
   * Returns the value of translation message for key `fromNegativeLimit`.
   * Default value: `From negative limit`
   */
  fromNegativeLimit: string;

  /**
   * Returns the value of translation message for key `toNegativeLimit`.
   * Default value: `To negative limit`
   */
  toNegativeLimit: string;

  /**
   * Returns the value of translation message for key `fromPositiveLimit`.
   * Default value: `From positive limit`
   */
  fromPositiveLimit: string;

  /**
   * Returns the value of translation message for key `toPositiveLimit`.
   * Default value: `To positive limit`
   */
  toPositiveLimit: string;

  /**
   * Returns the value of translation message for key `negative`.
   * Default value: `Negative`
   */
  negative: string;

  /**
   * Returns the value of translation message for key `positive`.
   * Default value: `Positive`
   */
  positive: string;

  /**
   * Returns the value of translation message for key `confirm`.
   * Default value: `You must confirm to apply the new balance limits`
   */
  confirm: string;

  /**
   * Returns the value of translation message for key `saved`.
   * Default value: `The balance limits were saved`
   */
  saved: string;


  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  title: I18n$Account$BalanceLimits$Title;


  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  mobileTitle: I18n$Account$BalanceLimits$MobileTitle;

}

export interface I18n$Account$BalanceLimits$Title {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `list`.
   * Default value: `Account balance limits`
   */
  list: string;

  /**
   * Returns the value of translation message for key `view`.
   * Default value: `Account balance limits details`
   */
  view: string;

  /**
   * Returns the value of translation message for key `edit`.
   * Default value: `Account balance limits details`
   */
  edit: string;

  /**
   * Returns the value of translation message for key `history`.
   * Default value: `Account balance limits history`
   */
  history: string;

  /**
   * Returns the value of translation message for key `overview`.
   * Default value: `Account balance limits overview`
   */
  overview: string;

}

export interface I18n$Account$BalanceLimits$MobileTitle {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `list`.
   * Default value: `Balance limits`
   */
  list: string;

  /**
   * Returns the value of translation message for key `view`.
   * Default value: `Balance limits details`
   */
  view: string;

  /**
   * Returns the value of translation message for key `edit`.
   * Default value: `Balance limits details`
   */
  edit: string;

  /**
   * Returns the value of translation message for key `history`.
   * Default value: `Balance limits history`
   */
  history: string;

  /**
   * Returns the value of translation message for key `overview`.
   * Default value: `Balance limits overview`
   */
  overview: string;

}

export interface I18n$Account$PaymentLimits {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `paymentLimit`.
   * Default value: `Payment limit`
   */
  paymentLimit: string;

  /**
   * Returns the value of translation message for key `dailyLimit`.
   * Default value: `Daily limit`
   */
  dailyLimit: string;

  /**
   * Returns the value of translation message for key `weeklyLimit`.
   * Default value: `Weekly limit`
   */
  weeklyLimit: string;

  /**
   * Returns the value of translation message for key `monthlyLimit`.
   * Default value: `Monthly limit`
   */
  monthlyLimit: string;

  /**
   * Returns the value of translation message for key `yearlyLimit`.
   * Default value: `Yearly limit`
   */
  yearlyLimit: string;

  /**
   * Returns the value of translation message for key `personalizedPaymentLimit`.
   * Default value: `Personalized payment limit`
   */
  personalizedPaymentLimit: string;

  /**
   * Returns the value of translation message for key `personalizedDailyLimit`.
   * Default value: `Personalized daily limit`
   */
  personalizedDailyLimit: string;

  /**
   * Returns the value of translation message for key `personalizedWeeklyLimit`.
   * Default value: `Personalized weekly limit`
   */
  personalizedWeeklyLimit: string;

  /**
   * Returns the value of translation message for key `personalizedMonthlyLimit`.
   * Default value: `Personalized monthly limit`
   */
  personalizedMonthlyLimit: string;

  /**
   * Returns the value of translation message for key `personalizedYearlyLimit`.
   * Default value: `Personalized yearly limit`
   */
  personalizedYearlyLimit: string;

  /**
   * Returns the value of translation message for key `fromPaymentLimit`.
   * Default value: `From payment limit`
   */
  fromPaymentLimit: string;

  /**
   * Returns the value of translation message for key `fromDailyLimit`.
   * Default value: `From daily limit`
   */
  fromDailyLimit: string;

  /**
   * Returns the value of translation message for key `fromWeeklyLimit`.
   * Default value: `From weekly limit`
   */
  fromWeeklyLimit: string;

  /**
   * Returns the value of translation message for key `fromMonthlyLimit`.
   * Default value: `From monthly limit`
   */
  fromMonthlyLimit: string;

  /**
   * Returns the value of translation message for key `fromYearlyLimit`.
   * Default value: `From yearly limit`
   */
  fromYearlyLimit: string;

  /**
   * Returns the value of translation message for key `toPaymentLimit`.
   * Default value: `To payment limit`
   */
  toPaymentLimit: string;

  /**
   * Returns the value of translation message for key `toDailyLimit`.
   * Default value: `To daily limit`
   */
  toDailyLimit: string;

  /**
   * Returns the value of translation message for key `toWeeklyLimit`.
   * Default value: `To weekly limit`
   */
  toWeeklyLimit: string;

  /**
   * Returns the value of translation message for key `toMonthlyLimit`.
   * Default value: `To monthly limit`
   */
  toMonthlyLimit: string;

  /**
   * Returns the value of translation message for key `toYearlyLimit`.
   * Default value: `To yearly limit`
   */
  toYearlyLimit: string;

  /**
   * Returns the value of translation message for key `definedInPaymentType`.
   * Default value: `Defined in payment type`
   */
  definedInPaymentType: string;

  /**
   * Returns the value of translation message for key `confirm`.
   * Default value: `You must confirm to apply the new payment limits`
   */
  confirm: string;

  /**
   * Returns the value of translation message for key `saved`.
   * Default value: `The payment limits were saved`
   */
  saved: string;


  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  title: I18n$Account$PaymentLimits$Title;


  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  mobileTitle: I18n$Account$PaymentLimits$MobileTitle;

}

export interface I18n$Account$PaymentLimits$Title {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `list`.
   * Default value: `Accounts payment limits`
   */
  list: string;

  /**
   * Returns the value of translation message for key `view`.
   * Default value: `Account payment limits details`
   */
  view: string;

  /**
   * Returns the value of translation message for key `edit`.
   * Default value: `Account payment limits details`
   */
  edit: string;

  /**
   * Returns the value of translation message for key `history`.
   * Default value: `Account payment limits history`
   */
  history: string;

  /**
   * Returns the value of translation message for key `overview`.
   * Default value: `Account payment limits overview`
   */
  overview: string;

}

export interface I18n$Account$PaymentLimits$MobileTitle {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `list`.
   * Default value: `Payment limits`
   */
  list: string;

  /**
   * Returns the value of translation message for key `view`.
   * Default value: `Payment limits details`
   */
  view: string;

  /**
   * Returns the value of translation message for key `edit`.
   * Default value: `Payment limits details`
   */
  edit: string;

  /**
   * Returns the value of translation message for key `history`.
   * Default value: `Payment limits history`
   */
  history: string;

  /**
   * Returns the value of translation message for key `overview`.
   * Default value: `Payment limits overview`
   */
  overview: string;

}

export interface I18n$Transaction {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `number`.
   * Default value: `Transaction number`
   */
  number: string;

  /**
   * Returns the value of translation message for key `amount`.
   * Default value: `Amount`
   */
  amount: string;

  /**
   * Returns the value of translation message for key `type`.
   * Default value: `Payment type`
   */
  type: string;

  /**
   * Returns the value of translation message for key `fromTo`.
   * Default value: `From / to`
   */
  fromTo: string;

  /**
   * Returns the value of translation message for key `from`.
   * Default value: `From`
   */
  from: string;

  /**
   * Returns the value of translation message for key `to`.
   * Default value: `To`
   */
  to: string;

  /**
   * Returns the value of translation message for key `sender`.
   * Default value: `Sender`
   */
  sender: string;

  /**
   * Returns the value of translation message for key `receiver`.
   * Default value: `Receiver`
   */
  receiver: string;

  /**
   * Returns the value of translation message for key `senderOrReceiver`.
   * Default value: `Sender or receiver`
   */
  senderOrReceiver: string;

  /**
   * Returns the value of translation message for key `sent`.
   * Default value: `Sent`
   */
  sent: string;

  /**
   * Returns the value of translation message for key `received`.
   * Default value: `Received`
   */
  received: string;

  /**
   * Returns the value of translation message for key `direction`.
   * Default value: `Direction`
   */
  direction: string;

  /**
   * Returns the value of translation message for key `account`.
   * Default value: `Account`
   */
  account: string;

  /**
   * Returns the value of translation message for key `fromAccount`.
   * Default value: `From account`
   */
  fromAccount: string;

  /**
   * Returns the value of translation message for key `fromUser`.
   * Default value: `From user`
   */
  fromUser: string;

  /**
   * Returns the value of translation message for key `toAccount`.
   * Default value: `To account`
   */
  toAccount: string;

  /**
   * Returns the value of translation message for key `toUser`.
   * Default value: `To user`
   */
  toUser: string;

  /**
   * Returns the value of translation message for key `requiresAuthorization`.
   * Default value: `The payment will require authorization`
   */
  requiresAuthorization: string;

  /**
   * Returns the value of translation message for key `appliedFees`.
   * Default value: `Applied fees`
   */
  appliedFees: string;

  /**
   * Returns the value of translation message for key `totalAmount`.
   * Default value: `Total amount`
   */
  totalAmount: string;

  /**
   * Returns the value of translation message for key `dueAmount`.
   * Default value: `Due amount`
   */
  dueAmount: string;

  /**
   * Returns the value of translation message for key `dueDate`.
   * Default value: `Due date`
   */
  dueDate: string;

  /**
   * Returns the value of translation message for key `processDate`.
   * Default value: `Process date`
   */
  processDate: string;

  /**
   * Returns the value of translation message for key `processDateTooltip`.
   * Default value: `Select this option to schedule processing`
   */
  processDateTooltip: string;

  /**
   * Returns the value of translation message for key `nextOccurrence`.
   * Default value: `Next occurrence`
   */
  nextOccurrence: string;

  /**
   * Returns the value of translation message for key `channel`.
   * Default value: `Channel`
   */
  channel: string;

  /**
   * Returns the value of translation message for key `receivedBy`.
   * Default value: `Received by`
   */
  receivedBy: string;

  /**
   * Returns the value of translation message for key `chargedBack`.
   * Default value: `Charged back`
   */
  chargedBack: string;

  /**
   * Returns the value of translation message for key `chargebackOf`.
   * Default value: `Chargeback of`
   */
  chargebackOf: string;

  /**
   * Returns the value of translation message for key `chargedBackBy`.
   * Default value: `Charged back by`
   */
  chargedBackBy: string;

  /**
   * Returns the value of translation message for key `awaitingAuthorizationBy`.
   * Default value: `Awaiting authorization by`
   */
  awaitingAuthorizationBy: string;

  /**
   * Returns the value of translation message for key `awaitingAuthorizationBy.admin`.
   * Default value: `administrator`
   */
  awaitingAuthorizationByAdmin: string;

  /**
   * Returns the value of translation message for key `awaitingAuthorizationBy.broker`.
   * Default value: `broker`
   */
  awaitingAuthorizationByBroker: string;

  /**
   * Returns the value of translation message for key `awaitingAuthorizationBy.missingBroker`.
   * Default value: `(missing) broker`
   */
  awaitingAuthorizationByMissingBroker: string;

  /**
   * Returns the value of translation message for key `awaitingAuthorizationBy.payer`.
   * Default value: `payer`
   */
  awaitingAuthorizationByPayer: string;

  /**
   * Returns the value of translation message for key `awaitingAuthorizationBy.receiver`.
   * Default value: `receiver`
   */
  awaitingAuthorizationByReceiver: string;

  /**
   * Returns the value of translation message for key `authorizationComments`.
   * Default value: `Authorization comments`
   */
  authorizationComments: string;

  /**
   * Returns the value of translation message for key `actionPerformedBy`.
   * Default value: `Action performed by`
   */
  actionPerformedBy: string;

  /**
   * Returns the value of translation message for key `accountBalance`.
   * Default value: `Account balance`
   */
  accountBalance: string;

  /**
   * Returns the value of translation message for key `installments`.
   * Default value: `Installments`
   */
  installments: string;

  /**
   * Returns the value of translation message for key `installmentNumber`.
   * Default value: `Number`
   */
  installmentNumber: string;

  /**
   * Returns the value of translation message for key `occurrences`.
   * Default value: `Ocurrences`
   */
  occurrences: string;

  /**
   * Returns the value of translation message for key `occurrenceInterval`.
   * Default value: `Process every`
   */
  occurrenceInterval: string;

  /**
   * Returns the value of translation message for key `feesOnAuthorization`.
   * Default value: `Fees to be applied when authorized`
   */
  feesOnAuthorization: string;

  /**
   * Returns the value of translation message for key `noAccounts`.
   * Default value: `You don't have any accounts to perform the payment`
   */
  noAccounts: string;

  /**
   * Returns the value of translation message for key `noExternalPaymentPrincipalTypes`.
   * Default value: `There are no compatible user identification methods to perform the payment`
   */
  noExternalPaymentPrincipalTypes: string;

  /**
   * Returns the value of translation message for key `noTypes`.
   * Default value: `There are no possible payment types`
   */
  noTypes: string;

  /**
   * Returns the value of translation message for key `noTypesSelection`.
   * Default value: `There are no possible payment types for this account and user`
   */
  noTypesSelection: string;

  /**
   * Returns the value of translation message for key `transferKinds`.
   * Default value: `Transfer kinds`
   */
  transferKinds: string;

  /**
   * Returns the value of translation message for key `transferStatus`.
   * Default value: `Status`
   */
  transferStatus: string;

  /**
   * Returns the value of translation message for key `authorizationRoles`.
   * Default value: `Authorization roles`
   */
  authorizationRoles: string;

  /**
   * Returns the value of translation message for key `scheduling`.
   * Default value: `Scheduling`
   */
  scheduling: string;

  /**
   * Returns the value of translation message for key `scheduling.direct`.
   * Default value: `Pay now`
   */
  schedulingDirect: string;

  /**
   * Returns the value of translation message for key `scheduling.single`.
   * Default value: `Scheduled`
   */
  schedulingSingle: string;

  /**
   * Returns the value of translation message for key `scheduling.installments`.
   * Default value: `Monthly installments`
   */
  schedulingInstallments: string;

  /**
   * Returns the value of translation message for key `scheduling.recurring`.
   * Default value: `Recurring payments`
   */
  schedulingRecurring: string;

  /**
   * Returns the value of translation message for key `recurringPayment`.
   * Default value: `Recurring payment`
   */
  recurringPayment: string;

  /**
   * Returns the value of translation message for key `recurringPayment.nowManual`.
   * Default value: `Repeats until manually canceled, starting now`
   */
  recurringPaymentNowManual: string;

  /**
   * Returns the value of translation message for key `installmentsCount`.
   * Default value: `Number of installments`
   */
  installmentsCount: string;

  /**
   * Returns the value of translation message for key `firstInstallment`.
   * Default value: `First installment`
   */
  firstInstallment: string;

  /**
   * Returns the value of translation message for key `firstInstallment.date`.
   * Default value: `First installment date`
   */
  firstInstallmentDate: string;

  /**
   * Returns the value of translation message for key `repeatUntil`.
   * Default value: `Repeat until`
   */
  repeatUntil: string;

  /**
   * Returns the value of translation message for key `repeatUntil.manual`.
   * Default value: `Manually canceled`
   */
  repeatUntilManual: string;

  /**
   * Returns the value of translation message for key `repeatUntil.fixed`.
   * Default value: `Fixed number of occurrences`
   */
  repeatUntilFixed: string;

  /**
   * Returns the value of translation message for key `occurrencesCount`.
   * Default value: `Number of occurrences`
   */
  occurrencesCount: string;

  /**
   * Returns the value of translation message for key `firstOccurrence`.
   * Default value: `First occurrence`
   */
  firstOccurrence: string;

  /**
   * Returns the value of translation message for key `firstOccurrence.date`.
   * Default value: `First occurrence date`
   */
  firstOccurrenceDate: string;

  /**
   * Returns the value of translation message for key `processed`.
   * Default value: `The payment was successfully processed`
   */
  processed: string;

  /**
   * Returns the value of translation message for key `pending`.
   * Default value: `The payment was submitted for further authorization`
   */
  pending: string;

  /**
   * Returns the value of translation message for key `paymentRequestSent`.
   * Default value: `The payment request was successfully sent`
   */
  paymentRequestSent: string;

  /**
   * Returns the value of translation message for key `externalPaymentPerformed`.
   * Default value: `The external payment was successfully performed. Once the user registers, the payment will be done`
   */
  externalPaymentPerformed: string;

  /**
   * Returns the value of translation message for key `done.new`.
   * Default value: `New payment`
   */
  doneNew: string;

  /**
   * Returns the value of translation message for key `done.addReceiverToContacts`.
   * Default value: `Add receiver to contacts`
   */
  doneAddReceiverToContacts: string;

  /**
   * Returns the value of translation message for key `ticketShareTitle`.
   * Default value: `Invitation to perform a payment`
   */
  ticketShareTitle: string;

  /**
   * Returns the value of translation message for key `ticketShareUrl`.
   * Default value: `URL to share`
   */
  ticketShareUrl: string;

  /**
   * Returns the value of translation message for key `generatedTransaction`.
   * Default value: `Generated transaction`
   */
  generatedTransaction: string;

  /**
   * Returns the value of translation message for key `viewAuthorizations`.
   * Default value: `View authorizations`
   */
  viewAuthorizations: string;

  /**
   * Returns the value of translation message for key `viewThisTransfer`.
   * Default value: `View this transfer`
   */
  viewThisTransfer: string;

  /**
   * Returns the value of translation message for key `viewThisVoucher`.
   * Default value: `View this voucher`
   */
  viewThisVoucher: string;

  /**
   * Returns the value of translation message for key `payExternalUser`.
   * Default value: `Pay external user`
   */
  payExternalUser: string;

  /**
   * Returns the value of translation message for key `sendPaymentRequestAsSelfToSystem`.
   * Default value: `Send to system`
   */
  sendPaymentRequestAsSelfToSystem: string;

  /**
   * Returns the value of translation message for key `sendPaymentRequestAsSelfToUser`.
   * Default value: `Send a new request`
   */
  sendPaymentRequestAsSelfToUser: string;

  /**
   * Returns the value of translation message for key `sendPaymentRequestAsUserToSystem`.
   * Default value: `Send as user to system`
   */
  sendPaymentRequestAsUserToSystem: string;

  /**
   * Returns the value of translation message for key `sendPaymentRequestAsUserToUser`.
   * Default value: `Send as user`
   */
  sendPaymentRequestAsUserToUser: string;

  /**
   * Returns the value of translation message for key `sendPaymentRequestFromSystemToUser`.
   * Default value: `Send from system`
   */
  sendPaymentRequestFromSystemToUser: string;

  /**
   * Returns the value of translation message for key `authorizePending`.
   * Default value: `Authorize pending payment`
   */
  authorizePending: string;

  /**
   * Returns the value of translation message for key `authorizePending.done.stillPending`.
   * Default value: `The payment still needs another authorization in order to be processed`
   */
  authorizePendingDoneStillPending: string;

  /**
   * Returns the value of translation message for key `authorizePending.done`.
   * Default value: `The payment was authorized`
   */
  authorizePendingDone: string;

  /**
   * Returns the value of translation message for key `denyPending`.
   * Default value: `Deny pending payment`
   */
  denyPending: string;

  /**
   * Returns the value of translation message for key `denyPending.done`.
   * Default value: `The payment was denied`
   */
  denyPendingDone: string;

  /**
   * Returns the value of translation message for key `cancelAuthorization`.
   * Default value: `Cancel the authorization process`
   */
  cancelAuthorization: string;

  /**
   * Returns the value of translation message for key `cancelAuthorization.done`.
   * Default value: `The payment authorization was canceled`
   */
  cancelAuthorizationDone: string;

  /**
   * Returns the value of translation message for key `blockScheduling`.
   * Default value: `Block scheduling`
   */
  blockScheduling: string;

  /**
   * Returns the value of translation message for key `blockScheduling.message`.
   * Default value: `This will prevent scheduled installments from being automatically processed`
   */
  blockSchedulingMessage: string;

  /**
   * Returns the value of translation message for key `blockScheduling.done`.
   * Default value: `This scheduled payment will no longer be automatically processed`
   */
  blockSchedulingDone: string;

  /**
   * Returns the value of translation message for key `unblockScheduling`.
   * Default value: `Unblock scheduling`
   */
  unblockScheduling: string;

  /**
   * Returns the value of translation message for key `unblockScheduling.message`.
   * Default value: `This will resume automatic processing for scheduled installments`
   */
  unblockSchedulingMessage: string;

  /**
   * Returns the value of translation message for key `unblockScheduling.done`.
   * Default value: `This scheduled payment will be automatically processed`
   */
  unblockSchedulingDone: string;

  /**
   * Returns the value of translation message for key `cancelScheduled`.
   * Default value: `Cancel this scheduled payment`
   */
  cancelScheduled: string;

  /**
   * Returns the value of translation message for key `cancelScheduled.message`.
   * Default value: `This will permanently cancel this scheduled payment`
   */
  cancelScheduledMessage: string;

  /**
   * Returns the value of translation message for key `cancelScheduled.done`.
   * Default value: `This scheduled payment has been canceled`
   */
  cancelScheduledDone: string;

  /**
   * Returns the value of translation message for key `settleScheduled`.
   * Default value: `Settle the scheduled payment`
   */
  settleScheduled: string;

  /**
   * Returns the value of translation message for key `settleScheduled.message`.
   * Default value: `This will permanently mark all remaining installments as settled`
   */
  settleScheduledMessage: string;

  /**
   * Returns the value of translation message for key `settleScheduled.done`.
   * Default value: `This scheduled payment has been settled`
   */
  settleScheduledDone: string;

  /**
   * Returns the value of translation message for key `processScheduled`.
   * Default value: `Process this scheduled payment`
   */
  processScheduled: string;

  /**
   * Returns the value of translation message for key `processScheduled.message`.
   * Default value: `Are you sure to process now this scheduled payment?`
   */
  processScheduledMessage: string;

  /**
   * Returns the value of translation message for key `processScheduled.done`.
   * Default value: `This scheduled payment was processed`
   */
  processScheduledDone: string;

  /**
   * Returns the value of translation message for key `blockRecurring`.
   * Default value: `Block`
   */
  blockRecurring: string;

  /**
   * Returns the value of translation message for key `blockRecurring.message`.
   * Default value: `Are you sure to block this recurring payment, so occurrences won't be processed on the respective due dates?`
   */
  blockRecurringMessage: string;

  /**
   * Returns the value of translation message for key `blockRecurring.done`.
   * Default value: `This recurring payment has been blocked`
   */
  blockRecurringDone: string;

  /**
   * Returns the value of translation message for key `unblockRecurring`.
   * Default value: `Unblock`
   */
  unblockRecurring: string;

  /**
   * Returns the value of translation message for key `unblockRecurring.message`.
   * Default value: `Are you sure to unblock this recurring payment, so occurrences will be processed on the respective due dates?`
   */
  unblockRecurringMessage: string;

  /**
   * Returns the value of translation message for key `unblockRecurring.done`.
   * Default value: `This recurring payment has been unblocked`
   */
  unblockRecurringDone: string;

  /**
   * Returns the value of translation message for key `cancelRecurring`.
   * Default value: `Cancel this recurring payment`
   */
  cancelRecurring: string;

  /**
   * Returns the value of translation message for key `cancelRecurring.message`.
   * Default value: `This will permanently cancel the recurring payment and prevent any future occurrence`
   */
  cancelRecurringMessage: string;

  /**
   * Returns the value of translation message for key `cancelRecurring.done`.
   * Default value: `This recurring payment has been canceled`
   */
  cancelRecurringDone: string;

  /**
   * Returns the value of translation message for key `editRecurring`.
   * Default value: `Edit`
   */
  editRecurring: string;

  /**
   * Returns the value of translation message for key `editRecurring.done`.
   * Default value: `This recurring payment has been edited`
   */
  editRecurringDone: string;

  /**
   * Returns the value of translation message for key `chargebackTransfer`.
   * Default value: `Chargeback this transfer`
   */
  chargebackTransfer: string;

  /**
   * Returns the value of translation message for key `chargebackTransfer.message`.
   * Default value: `This will return the amount of this transfer to the payer`
   */
  chargebackTransferMessage: string;

  /**
   * Returns the value of translation message for key `chargebackTransfer.done`.
   * Default value: `This transfer was charged back`
   */
  chargebackTransferDone: string;

  /**
   * Returns the value of translation message for key `processInstallment`.
   * Default value: `Process this installment`
   */
  processInstallment: string;

  /**
   * Returns the value of translation message for key `processInstallment.done`.
   * Default value: `The installment was processed`
   */
  processInstallmentDone: string;

  /**
   * Returns the value of translation message for key `settleInstallment`.
   * Default value: `Settle this installment`
   */
  settleInstallment: string;

  /**
   * Returns the value of translation message for key `settleInstallment.done`.
   * Default value: `The installment was settled`
   */
  settleInstallmentDone: string;

  /**
   * Returns the value of translation message for key `processFailedOccurrence`.
   * Default value: `Process this failed occurrence`
   */
  processFailedOccurrence: string;

  /**
   * Returns the value of translation message for key `processFailedOccurrence.done`.
   * Default value: `The occurrence was processed`
   */
  processFailedOccurrenceDone: string;

  /**
   * Returns the value of translation message for key `acceptPaymentRequest`.
   * Default value: `Accept this payment request`
   */
  acceptPaymentRequest: string;

  /**
   * Returns the value of translation message for key `paymentRequest.scheduling.now`.
   * Default value: `Perform the payment now`
   */
  paymentRequestSchedulingNow: string;

  /**
   * Returns the value of translation message for key `paymentRequest.scheduling.date`.
   * Default value: `Schedule to a custom date`
   */
  paymentRequestSchedulingDate: string;

  /**
   * Returns the value of translation message for key `cancelPaymentRequest`.
   * Default value: `Cancel this payment request`
   */
  cancelPaymentRequest: string;

  /**
   * Returns the value of translation message for key `cancelPaymentRequest.done`.
   * Default value: `The payment request was canceled`
   */
  cancelPaymentRequestDone: string;

  /**
   * Returns the value of translation message for key `cancelExternalPayment`.
   * Default value: `Cancel this external payment`
   */
  cancelExternalPayment: string;

  /**
   * Returns the value of translation message for key `cancelExternalPayment.done`.
   * Default value: `The external payment was canceled`
   */
  cancelExternalPaymentDone: string;

  /**
   * Returns the value of translation message for key `rejectPaymentRequest`.
   * Default value: `Deny this payment request`
   */
  rejectPaymentRequest: string;

  /**
   * Returns the value of translation message for key `rejectPaymentRequest.done`.
   * Default value: `The payment request was denied`
   */
  rejectPaymentRequestDone: string;

  /**
   * Returns the value of translation message for key `changePaymentRequestExpiration`.
   * Default value: `Change expiration date`
   */
  changePaymentRequestExpiration: string;

  /**
   * Returns the value of translation message for key `changePaymentRequestExpiration.done`.
   * Default value: `The payment request expiration date was changed`
   */
  changePaymentRequestExpirationDone: string;

  /**
   * Returns the value of translation message for key `reschedulePaymentRequest`.
   * Default value: `Reschedule this payment request`
   */
  reschedulePaymentRequest: string;

  /**
   * Returns the value of translation message for key `reschedulePaymentRequest.done`.
   * Default value: `The payment request was reschedule`
   */
  reschedulePaymentRequestDone: string;

  /**
   * Returns the value of translation message for key `reschedulePaymentRequest.rescheduleTo`.
   * Default value: `Reschedule to`
   */
  reschedulePaymentRequestRescheduleTo: string;

  /**
   * Returns the value of translation message for key `reschedulePaymentRequest.rescheduleTo.date`.
   * Default value: `Reschedule to date`
   */
  reschedulePaymentRequestRescheduleToDate: string;

  /**
   * Returns the value of translation message for key `confirmPaymentRequest`.
   * Default value: `Are you sure to send this payment request?`
   */
  confirmPaymentRequest: string;

  /**
   * Returns the value of translation message for key `firstOccurrenceIsImmediate`.
   * Default value: `First occurrence is immediate`
   */
  firstOccurrenceIsImmediate: string;

  /**
   * Returns the value of translation message for key `firstInstallmentIsImmediate`.
   * Default value: `First installment is immediate`
   */
  firstInstallmentIsImmediate: string;

  /**
   * Returns the value of translation message for key `voucher.redeemed`.
   * Default value: `Redeemed voucher`
   */
  voucherRedeemed: string;

  /**
   * Returns the value of translation message for key `voucher.toppedUp`.
   * Default value: `Topped-up voucher`
   */
  voucherToppedUp: string;


  /**
   * Returns the value of translation message for key `fromTo.text`.
   * Default value: `{from} to {to}`
   */
  fromToText($: {from: string | number, to: string | number}): string;


  /**
   * Returns the value of translation message for key `installmentNumberOfTotal`.
   * Default value: `{number} of {total}`
   */
  installmentNumberOfTotal($: {number: string | number, total: string | number}): string;


  /**
   * Returns the value of translation message for key `recurringPayment.dateFixed`.
   * Default value: `Repeats {count} times, starting at {date}`
   */
  recurringPaymentDateFixed($: {count: string | number, date: string | number}): string;


  /**
   * Returns the value of translation message for key `ticketShareText`.
   * Default value: `{user} has invited you to pay {amount}`
   */
  ticketShareText($: {user: string | number, amount: string | number}): string;


  /**
   * Returns the value of translation message for key `awaitingAuthorizationBy.operatorOwner`.
   * Default value: `{user} or authorized operator`
   */
  awaitingAuthorizationByOperatorOwner(user: string | number): string;


  /**
   * Returns the value of translation message for key `noVisibleAccounts`.
   * Default value: `You don't have any visible accounts to perform the payment, please check the {0} page. In case the account was already marked as visible please contact the administration. `
   */
  noVisibleAccounts($0: string | number): string;


  /**
   * Returns the value of translation message for key `recurringPayment.dateManual`.
   * Default value: `Repeats until manually canceled, starting at {date}`
   */
  recurringPaymentDateManual(date: string | number): string;


  /**
   * Returns the value of translation message for key `recurringPayment.nowFixed`.
   * Default value: `Repeats {count} times, starting now`
   */
  recurringPaymentNowFixed(count: string | number): string;


  /**
   * Returns the value of translation message for key `recurringPayment.occurrenceInterval`.
   * Default value: `Process every {interval}`
   */
  recurringPaymentOccurrenceInterval(interval: string | number): string;


  /**
   * Returns the value of translation message for key `repeatUntil.numberOfOccurrences`.
   * Default value: `{number} occurrences`
   */
  repeatUntilNumberOfOccurrences(number: string | number): string;


  /**
   * Returns the value of translation message for key `paymentRequestAcceptScheduled`.
   * Default value: `The payment request was scheduled to be processed at {date}`
   */
  paymentRequestAcceptScheduled(date: string | number): string;


  /**
   * Returns the value of translation message for key `done.userAddedToContacts`.
   * Default value: `The user {contact} was added to contacts`
   */
  doneUserAddedToContacts(contact: string | number): string;


  /**
   * Returns the value of translation message for key `processInstallment.message`.
   * Default value: `Are you sure to process now the installment number {number}?`
   */
  processInstallmentMessage(number: string | number): string;


  /**
   * Returns the value of translation message for key `settleInstallment.message`.
   * Default value: `Are you sure to settle the installment number {number}?`
   */
  settleInstallmentMessage(number: string | number): string;


  /**
   * Returns the value of translation message for key `processFailedOccurrence.message`.
   * Default value: `Are you sure to process now the occurrence number {number}?`
   */
  processFailedOccurrenceMessage(number: string | number): string;


  /**
   * Returns the value of translation message for key `paymentRequest.scheduling.expiration`.
   * Default value: `Schedule to the expiration date ({date})`
   */
  paymentRequestSchedulingExpiration(date: string | number): string;


  /**
   * Returns the nested accessor for translation messages in `transferkind`.
   */
  transferkind: I18n$Transaction$Transferkind;


  /**
   * Returns the nested accessor for translation messages in `schedulingStatus`.
   */
  schedulingStatus: I18n$Transaction$SchedulingStatus;


  /**
   * Returns the nested accessor for translation messages in `status`.
   */
  status: I18n$Transaction$Status;


  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  title: I18n$Transaction$Title;


  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  mobileTitle: I18n$Transaction$MobileTitle;


  /**
   * Returns the nested accessor for translation messages in `confirmMessage`.
   */
  confirmMessage: I18n$Transaction$ConfirmMessage;


  /**
   * Returns the nested accessor for translation messages in `error`.
   */
  error: I18n$Transaction$Error;

}

export interface I18n$Transaction$Transferkind {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `accountFee`.
   * Default value: `Account fee`
   */
  accountFee: string;

  /**
   * Returns the value of translation message for key `chargeback`.
   * Default value: `Chargeback`
   */
  chargeback: string;

  /**
   * Returns the value of translation message for key `import`.
   * Default value: `Import`
   */
  import: string;

  /**
   * Returns the value of translation message for key `initialCredit`.
   * Default value: `Initial credit`
   */
  initialCredit: string;

  /**
   * Returns the value of translation message for key `installment`.
   * Default value: `Installment`
   */
  installment: string;

  /**
   * Returns the value of translation message for key `payment`.
   * Default value: `Payment`
   */
  payment: string;

  /**
   * Returns the value of translation message for key `transferFee`.
   * Default value: `Transfer fee`
   */
  transferFee: string;

}

export interface I18n$Transaction$SchedulingStatus {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `direct`.
   * Default value: `Direct payment`
   */
  direct: string;

  /**
   * Returns the value of translation message for key `closedRecurring`.
   * Default value: `Closed recurring payment`
   */
  closedRecurring: string;

  /**
   * Returns the value of translation message for key `canceledRecurring`.
   * Default value: `Canceled recurring payment`
   */
  canceledRecurring: string;


  /**
   * Returns the value of translation message for key `openInstallments`.
   * Default value: `{count} installments, next on {dueDate}`
   */
  openInstallments($: {count: string | number, dueDate: string | number}): string;


  /**
   * Returns the value of translation message for key `scheduledToDate`.
   * Default value: `Scheduled to {date}`
   */
  scheduledToDate(date: string | number): string;


  /**
   * Returns the value of translation message for key `closedInstallments`.
   * Default value: `{count} installments`
   */
  closedInstallments(count: string | number): string;


  /**
   * Returns the value of translation message for key `openRecurring`.
   * Default value: `Recurring payment, next on {date}`
   */
  openRecurring(date: string | number): string;

}

export interface I18n$Transaction$Status {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `open`.
   * Default value: `Open`
   */
  open: string;

  /**
   * Returns the value of translation message for key `closed`.
   * Default value: `Closed`
   */
  closed: string;

  /**
   * Returns the value of translation message for key `canceled`.
   * Default value: `Canceled`
   */
  canceled: string;

  /**
   * Returns the value of translation message for key `expired`.
   * Default value: `Expired`
   */
  expired: string;

  /**
   * Returns the value of translation message for key `approved`.
   * Default value: `Approved`
   */
  approved: string;

  /**
   * Returns the value of translation message for key `processed`.
   * Default value: `Processed`
   */
  processed: string;

  /**
   * Returns the value of translation message for key `authorized`.
   * Default value: `Authorized`
   */
  authorized: string;

  /**
   * Returns the value of translation message for key `pending`.
   * Default value: `Pending`
   */
  pending: string;

  /**
   * Returns the value of translation message for key `pendingAuthorization`.
   * Default value: `Pending authorization`
   */
  pendingAuthorization: string;

  /**
   * Returns the value of translation message for key `denied`.
   * Default value: `Denied`
   */
  denied: string;

  /**
   * Returns the value of translation message for key `blocked`.
   * Default value: `Blocked`
   */
  blocked: string;

  /**
   * Returns the value of translation message for key `scheduled`.
   * Default value: `Scheduled`
   */
  scheduled: string;

  /**
   * Returns the value of translation message for key `failed`.
   * Default value: `Failed`
   */
  failed: string;

  /**
   * Returns the value of translation message for key `settled`.
   * Default value: `Settled`
   */
  settled: string;

}

export interface I18n$Transaction$Title {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `pos`.
   * Default value: `Receive payment`
   */
  pos: string;

  /**
   * Returns the value of translation message for key `paymentSystemToSystem`.
   * Default value: `Payment between system accounts`
   */
  paymentSystemToSystem: string;

  /**
   * Returns the value of translation message for key `paymentSystemToUser`.
   * Default value: `Payment from system to user`
   */
  paymentSystemToUser: string;

  /**
   * Returns the value of translation message for key `paymentToSystem`.
   * Default value: `Payment to system`
   */
  paymentToSystem: string;

  /**
   * Returns the value of translation message for key `paymentToSelf`.
   * Default value: `Payment between own accounts`
   */
  paymentToSelf: string;

  /**
   * Returns the value of translation message for key `paymentToUser`.
   * Default value: `Payment to user`
   */
  paymentToUser: string;

  /**
   * Returns the value of translation message for key `paymentConfirmation`.
   * Default value: `Payment confirmation`
   */
  paymentConfirmation: string;

  /**
   * Returns the value of translation message for key `pendingMyAuthorization`.
   * Default value: `Pending my authorization`
   */
  pendingMyAuthorization: string;

  /**
   * Returns the value of translation message for key `authorizations`.
   * Default value: `Payment authorizations`
   */
  authorizations: string;

  /**
   * Returns the value of translation message for key `authorizationHistory`.
   * Default value: `Authorization history`
   */
  authorizationHistory: string;

  /**
   * Returns the value of translation message for key `scheduled`.
   * Default value: `Scheduled payments`
   */
  scheduled: string;

  /**
   * Returns the value of translation message for key `details.payment`.
   * Default value: `Payment details`
   */
  detailsPayment: string;

  /**
   * Returns the value of translation message for key `details.scheduled`.
   * Default value: `Scheduled payment details`
   */
  detailsScheduled: string;

  /**
   * Returns the value of translation message for key `details.recurring`.
   * Default value: `Recurring payment details`
   */
  detailsRecurring: string;

  /**
   * Returns the value of translation message for key `details.request`.
   * Default value: `Payment request details`
   */
  detailsRequest: string;

  /**
   * Returns the value of translation message for key `details.chargeback`.
   * Default value: `Chargeback details`
   */
  detailsChargeback: string;

  /**
   * Returns the value of translation message for key `details.ticket`.
   * Default value: `Ticket details`
   */
  detailsTicket: string;

  /**
   * Returns the value of translation message for key `details.external`.
   * Default value: `External payment details`
   */
  detailsExternal: string;

  /**
   * Returns the value of translation message for key `details.transfer`.
   * Default value: `Transfer details`
   */
  detailsTransfer: string;

  /**
   * Returns the value of translation message for key `parentTransfer`.
   * Default value: `Transfer that generated this one`
   */
  parentTransfer: string;

  /**
   * Returns the value of translation message for key `childTransfer`.
   * Default value: `Transfer generated by this one`
   */
  childTransfer: string;

  /**
   * Returns the value of translation message for key `childTransfers`.
   * Default value: `Transfers generated by this one`
   */
  childTransfers: string;

  /**
   * Returns the value of translation message for key `transfersOverview`.
   * Default value: `Transfers overview`
   */
  transfersOverview: string;

  /**
   * Returns the value of translation message for key `receiveQrPayment`.
   * Default value: `Receive QR-code payment`
   */
  receiveQrPayment: string;

  /**
   * Returns the value of translation message for key `paymentRequestSystemToUser`.
   * Default value: `Send system payment request to user`
   */
  paymentRequestSystemToUser: string;

  /**
   * Returns the value of translation message for key `paymentRequestToSystem`.
   * Default value: `Send payment request to system`
   */
  paymentRequestToSystem: string;

  /**
   * Returns the value of translation message for key `paymentRequestToUser`.
   * Default value: `Send payment request`
   */
  paymentRequestToUser: string;

  /**
   * Returns the value of translation message for key `paymentRequests`.
   * Default value: `Payment requests`
   */
  paymentRequests: string;

  /**
   * Returns the value of translation message for key `paymentRequestsOverview`.
   * Default value: `Payment requests overview`
   */
  paymentRequestsOverview: string;

  /**
   * Returns the value of translation message for key `externalPayments`.
   * Default value: `External payments`
   */
  externalPayments: string;

  /**
   * Returns the value of translation message for key `externalPaymentsOverview`.
   * Default value: `External payments overview`
   */
  externalPaymentsOverview: string;

  /**
   * Returns the value of translation message for key `externalPaymentFromSystem`.
   * Default value: `Pay external user from system`
   */
  externalPaymentFromSystem: string;

  /**
   * Returns the value of translation message for key `externalPaymentFromUser`.
   * Default value: `Pay external user`
   */
  externalPaymentFromUser: string;

  /**
   * Returns the value of translation message for key `sentVoucher`.
   * Default value: `Sent voucher`
   */
  sentVoucher: string;

  /**
   * Returns the value of translation message for key `boughtVoucher`.
   * Default value: `Bought voucher`
   */
  boughtVoucher: string;

  /**
   * Returns the value of translation message for key `boughtVouchers`.
   * Default value: `Bought vouchers`
   */
  boughtVouchers: string;

  /**
   * Returns the value of translation message for key `editRecurringPayment`.
   * Default value: `Edit recurring payment`
   */
  editRecurringPayment: string;

}

export interface I18n$Transaction$MobileTitle {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `pos`.
   * Default value: `Receive payment`
   */
  pos: string;

  /**
   * Returns the value of translation message for key `paymentSystemToSystem`.
   * Default value: `Pay system`
   */
  paymentSystemToSystem: string;

  /**
   * Returns the value of translation message for key `paymentSystemToUser`.
   * Default value: `Pay user`
   */
  paymentSystemToUser: string;

  /**
   * Returns the value of translation message for key `paymentToSystem`.
   * Default value: `Pay system`
   */
  paymentToSystem: string;

  /**
   * Returns the value of translation message for key `paymentToSelf`.
   * Default value: `Pay self`
   */
  paymentToSelf: string;

  /**
   * Returns the value of translation message for key `paymentToUser`.
   * Default value: `Pay user`
   */
  paymentToUser: string;

  /**
   * Returns the value of translation message for key `paymentConfirmation`.
   * Default value: `Confirmation`
   */
  paymentConfirmation: string;

  /**
   * Returns the value of translation message for key `pendingMyAuthorization`.
   * Default value: `To authorize`
   */
  pendingMyAuthorization: string;

  /**
   * Returns the value of translation message for key `authorizations`.
   * Default value: `Authorizations`
   */
  authorizations: string;

  /**
   * Returns the value of translation message for key `authorizationHistory`.
   * Default value: `History`
   */
  authorizationHistory: string;

  /**
   * Returns the value of translation message for key `scheduled`.
   * Default value: `Scheduled payments`
   */
  scheduled: string;

  /**
   * Returns the value of translation message for key `details.payment`.
   * Default value: `Payment`
   */
  detailsPayment: string;

  /**
   * Returns the value of translation message for key `details.scheduled`.
   * Default value: `Scheduled payment`
   */
  detailsScheduled: string;

  /**
   * Returns the value of translation message for key `details.recurring`.
   * Default value: `Recurring payment`
   */
  detailsRecurring: string;

  /**
   * Returns the value of translation message for key `details.request`.
   * Default value: `Payment request`
   */
  detailsRequest: string;

  /**
   * Returns the value of translation message for key `details.chargeback`.
   * Default value: `Chargeback`
   */
  detailsChargeback: string;

  /**
   * Returns the value of translation message for key `details.ticket`.
   * Default value: `Ticket`
   */
  detailsTicket: string;

  /**
   * Returns the value of translation message for key `details.external`.
   * Default value: `External payment`
   */
  detailsExternal: string;

  /**
   * Returns the value of translation message for key `details.transfer`.
   * Default value: `Transfer`
   */
  detailsTransfer: string;

  /**
   * Returns the value of translation message for key `parentTransfer`.
   * Default value: `Originating transfer`
   */
  parentTransfer: string;

  /**
   * Returns the value of translation message for key `childTransfer`.
   * Default value: `Originated transfer`
   */
  childTransfer: string;

  /**
   * Returns the value of translation message for key `childTransfers`.
   * Default value: `Originated transfers`
   */
  childTransfers: string;

  /**
   * Returns the value of translation message for key `transfersOverview`.
   * Default value: `Transfers overview`
   */
  transfersOverview: string;

  /**
   * Returns the value of translation message for key `receiveQrPayment`.
   * Default value: `QR-code payment`
   */
  receiveQrPayment: string;

  /**
   * Returns the value of translation message for key `paymentRequestSystemToUser`.
   * Default value: `System payment request`
   */
  paymentRequestSystemToUser: string;

  /**
   * Returns the value of translation message for key `paymentRequestToSystem`.
   * Default value: `Payment request`
   */
  paymentRequestToSystem: string;

  /**
   * Returns the value of translation message for key `paymentRequestToUser`.
   * Default value: `Payment request`
   */
  paymentRequestToUser: string;

  /**
   * Returns the value of translation message for key `paymentRequests`.
   * Default value: `Payment requests`
   */
  paymentRequests: string;

  /**
   * Returns the value of translation message for key `paymentRequestsOverview`.
   * Default value: `Payment requests overview`
   */
  paymentRequestsOverview: string;

  /**
   * Returns the value of translation message for key `externalPayments`.
   * Default value: `External payments`
   */
  externalPayments: string;

  /**
   * Returns the value of translation message for key `externalPaymentsOverview`.
   * Default value: `External payments overview`
   */
  externalPaymentsOverview: string;

  /**
   * Returns the value of translation message for key `externalPaymentFromSystem`.
   * Default value: `Pay external user`
   */
  externalPaymentFromSystem: string;

  /**
   * Returns the value of translation message for key `externalPaymentFromUser`.
   * Default value: `Pay external user`
   */
  externalPaymentFromUser: string;

  /**
   * Returns the value of translation message for key `sentVoucher`.
   * Default value: `Sent voucher`
   */
  sentVoucher: string;

  /**
   * Returns the value of translation message for key `boughtVoucher`.
   * Default value: `Bought voucher`
   */
  boughtVoucher: string;

  /**
   * Returns the value of translation message for key `boughtVouchers`.
   * Default value: `Bought vouchers`
   */
  boughtVouchers: string;

  /**
   * Returns the value of translation message for key `editRecurringPayment`.
   * Default value: `Edit recurring payment`
   */
  editRecurringPayment: string;

}

export interface I18n$Transaction$ConfirmMessage {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `active.device`.
   * Default value: `In order to confirm the payer must scan this QR-code with its mobile phone..`
   */
  activeDevice: string;

  /**
   * Returns the value of translation message for key `active.deviceOrRenewablePassword`.
   * Default value: `In order to confirm the payer can scan this QR-code with its mobile phone, use the password already received or request for a new one`
   */
  activeDeviceOrRenewablePassword: string;

  /**
   * Returns the value of translation message for key `active.deviceOrRenewablePassword.noMediums`.
   * Default value: `In order to confirm the payer can scan this QR-code with its mobile phone or must use the previously received confirmation password because he can not request for a new one`
   */
  activeDeviceOrRenewablePasswordNoMediums: string;

  /**
   * Returns the value of translation message for key `active.deviceOrNotActiveRenewablePassword`.
   * Default value: `In order to confirm the payer can scan this QR-code with its mobile phone or request a confirmation password below`
   */
  activeDeviceOrNotActiveRenewablePassword: string;

  /**
   * Returns the value of translation message for key `active.renewablePassword`.
   * Default value: `In order to confirm the payer must use the previously received confirmation password or request a new one`
   */
  activeRenewablePassword: string;

  /**
   * Returns the value of translation message for key `active.renewablePassword.noMediums`.
   * Default value: `In order to confirm the payer must use the previously received confirmation password or request a new one`
   */
  activeRenewablePasswordNoMediums: string;

  /**
   * Returns the value of translation message for key `notActive.device`.
   * Default value: `In order to confirm the payer must have a trusted mobile phone but it does not`
   */
  notActiveDevice: string;

  /**
   * Returns the value of translation message for key `notActive.renewablePassword`.
   * Default value: `Please, request for the payer a confirmation password below in order to confirm`
   */
  notActiveRenewablePassword: string;


  /**
   * Returns the value of translation message for key `active.deviceOrPassword`.
   * Default value: `In order to confirm the payer can scan this QR-code with its mobile phone or supply the {password}`
   */
  activeDeviceOrPassword(password: string | number): string;


  /**
   * Returns the value of translation message for key `active.password`.
   * Default value: `In order to confirm the payer must supply its {password}`
   */
  activePassword(password: string | number): string;


  /**
   * Returns the value of translation message for key `notActive.deviceOrPassword`.
   * Default value: `In order to confirm the payer must have a trusted mobile phone or supply the {password} but it does not have any`
   */
  notActiveDeviceOrPassword(password: string | number): string;


  /**
   * Returns the value of translation message for key `notActive.deviceOrRenewablePassword.noMediums`.
   * Default value: `In order to confirm the payer must have a trusted mobile phone or supply the {password} but he does not have any and can not request for a new password, please, contact the administration`
   */
  notActiveDeviceOrRenewablePasswordNoMediums(password: string | number): string;


  /**
   * Returns the value of translation message for key `notActive.password`.
   * Default value: `In order to confirm the payer need a {password}, but he does not have any`
   */
  notActivePassword(password: string | number): string;


  /**
   * Returns the value of translation message for key `notActive.renewablePassword.noMediums`.
   * Default value: `In order to confirm the payer must supply the {password} but he does not have any and can not request for a new one, please, contact the administration`
   */
  notActiveRenewablePasswordNoMediums(password: string | number): string;

}

export interface I18n$Transaction$Error {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `minTime`.
   * Default value: `A minimum period of time should be awaited to make a payment of this type.`
   */
  minTime: string;

  /**
   * Returns the value of translation message for key `balance`.
   * Default value: `Insufficient balance to perform this operation`
   */
  balance: string;

  /**
   * Returns the value of translation message for key `upperLimit`.
   * Default value: `You cannot perform this payment because the upper balance limit of the destination account has been exceeded`
   */
  upperLimit: string;


  /**
   * Returns the value of translation message for key `daily.amount`.
   * Default value: `The maximum daily amount of {amount} was exceeded`
   */
  dailyAmount(amount: string | number): string;


  /**
   * Returns the value of translation message for key `daily.count`.
   * Default value: `The maximum allowed number of payments per day is {count}`
   */
  dailyCount(count: string | number): string;


  /**
   * Returns the value of translation message for key `weekly.amount`.
   * Default value: `The maximum weekly amount of {amount} was exceeded`
   */
  weeklyAmount(amount: string | number): string;


  /**
   * Returns the value of translation message for key `weekly.count`.
   * Default value: `The maximum allowed number of payments per week is {count}`
   */
  weeklyCount(count: string | number): string;


  /**
   * Returns the value of translation message for key `monthly.amount`.
   * Default value: `The maximum monthly amount of {amount} was exceeded`
   */
  monthlyAmount(amount: string | number): string;


  /**
   * Returns the value of translation message for key `monthly.count`.
   * Default value: `The maximum allowed number of payments per month is {count}`
   */
  monthlyCount(count: string | number): string;


  /**
   * Returns the value of translation message for key `yearly.amount`.
   * Default value: `The maximum yearly amount of {amount} was exceeded`
   */
  yearlyAmount(amount: string | number): string;

}

export interface I18n$Voucher {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `configuration`.
   * Default value: `Configuration`
   */
  configuration: string;

  /**
   * Returns the value of translation message for key `originAccount`.
   * Default value: `Origin account`
   */
  originAccount: string;

  /**
   * Returns the value of translation message for key `numberOfVouchers`.
   * Default value: `Number of vouchers`
   */
  numberOfVouchers: string;

  /**
   * Returns the value of translation message for key `amountPerVoucher`.
   * Default value: `Amount per voucher`
   */
  amountPerVoucher: string;

  /**
   * Returns the value of translation message for key `totalAmount`.
   * Default value: `Total amount`
   */
  totalAmount: string;

  /**
   * Returns the value of translation message for key `balance`.
   * Default value: `Balance`
   */
  balance: string;

  /**
   * Returns the value of translation message for key `buyerGroups`.
   * Default value: `Buyer groups`
   */
  buyerGroups: string;

  /**
   * Returns the value of translation message for key `pin`.
   * Default value: `Voucher PIN`
   */
  pin: string;

  /**
   * Returns the value of translation message for key `token`.
   * Default value: `Voucher code`
   */
  token: string;

  /**
   * Returns the value of translation message for key `transactions`.
   * Default value: `Transactions`
   */
  transactions: string;

  /**
   * Returns the value of translation message for key `transactionsRedeems`.
   * Default value: `Redeems`
   */
  transactionsRedeems: string;

  /**
   * Returns the value of translation message for key `owner`.
   * Default value: `Owner`
   */
  owner: string;

  /**
   * Returns the value of translation message for key `sentToEmail`.
   * Default value: `Sent to e-mail`
   */
  sentToEmail: string;

  /**
   * Returns the value of translation message for key `email`.
   * Default value: `E-mail`
   */
  email: string;

  /**
   * Returns the value of translation message for key `mobilePhone`.
   * Default value: `Mobile phone`
   */
  mobilePhone: string;

  /**
   * Returns the value of translation message for key `enableNotifications`.
   * Default value: `Enable notifications`
   */
  enableNotifications: string;

  /**
   * Returns the value of translation message for key `notificationsEnabled`.
   * Default value: `Notifications enabled`
   */
  notificationsEnabled: string;

  /**
   * Returns the value of translation message for key `expirationDateComments`.
   * Default value: `Expiration date comments`
   */
  expirationDateComments: string;

  /**
   * Returns the value of translation message for key `changeExpirationDate`.
   * Default value: `Change expiration date`
   */
  changeExpirationDate: string;

  /**
   * Returns the value of translation message for key `expirationDateChanged`.
   * Default value: `Expiration date changed`
   */
  expirationDateChanged: string;

  /**
   * Returns the value of translation message for key `assign`.
   * Default value: `Assign`
   */
  assign: string;

  /**
   * Returns the value of translation message for key `assigned`.
   * Default value: `The voucher was assigned`
   */
  assigned: string;

  /**
   * Returns the value of translation message for key `refundPayment`.
   * Default value: `Refund payment`
   */
  refundPayment: string;

  /**
   * Returns the value of translation message for key `refundDate`.
   * Default value: `Refund date`
   */
  refundDate: string;

  /**
   * Returns the value of translation message for key `otherCategory`.
   * Default value: `Other`
   */
  otherCategory: string;

  /**
   * Returns the value of translation message for key `showCategories`.
   * Default value: `Show categories`
   */
  showCategories: string;

  /**
   * Returns the value of translation message for key `listTypes`.
   * Default value: `List voucher types`
   */
  listTypes: string;

  /**
   * Returns the value of translation message for key `voucher`.
   * Default value: `Voucher`
   */
  voucher: string;


  /**
   * Returns the value of translation message for key `personal`.
   * Default value: `This voucher belongs to {buyer} and is limited for personal use.`
   */
  personal(buyer: string | number): string;


  /**
   * Returns the nested accessor for translation messages in `error`.
   */
  error: I18n$Voucher$Error;


  /**
   * Returns the nested accessor for translation messages in `redeem`.
   */
  redeem: I18n$Voucher$Redeem;


  /**
   * Returns the nested accessor for translation messages in `topUp`.
   */
  topUp: I18n$Voucher$TopUp;


  /**
   * Returns the nested accessor for translation messages in `transaction`.
   */
  transaction: I18n$Voucher$Transaction;


  /**
   * Returns the nested accessor for translation messages in `status`.
   */
  status: I18n$Voucher$Status;


  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  mobileTitle: I18n$Voucher$MobileTitle;


  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  title: I18n$Voucher$Title;


  /**
   * Returns the nested accessor for translation messages in `creationType`.
   */
  creationType: I18n$Voucher$CreationType;


  /**
   * Returns the nested accessor for translation messages in `buy`.
   */
  buy: I18n$Voucher$Buy;


  /**
   * Returns the nested accessor for translation messages in `send`.
   */
  send: I18n$Voucher$Send;


  /**
   * Returns the nested accessor for translation messages in `generate`.
   */
  generate: I18n$Voucher$Generate;


  /**
   * Returns the nested accessor for translation messages in `pinStatusForRedeem`.
   */
  pinStatusForRedeem: I18n$Voucher$PinStatusForRedeem;


  /**
   * Returns the nested accessor for translation messages in `unblockPin`.
   */
  unblockPin: I18n$Voucher$UnblockPin;


  /**
   * Returns the nested accessor for translation messages in `cancel`.
   */
  cancel: I18n$Voucher$Cancel;


  /**
   * Returns the nested accessor for translation messages in `resendEmail`.
   */
  resendEmail: I18n$Voucher$ResendEmail;


  /**
   * Returns the nested accessor for translation messages in `notificationSettings`.
   */
  notificationSettings: I18n$Voucher$NotificationSettings;


  /**
   * Returns the nested accessor for translation messages in `changePin`.
   */
  changePin: I18n$Voucher$ChangePin;


  /**
   * Returns the nested accessor for translation messages in `sort`.
   */
  sort: I18n$Voucher$Sort;


  /**
   * Returns the nested accessor for translation messages in `info`.
   */
  info: I18n$Voucher$Info;

}

export interface I18n$Voucher$Error {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;


  /**
   * Returns the value of translation message for key `totalOpenAmount`.
   * Default value: `The total amount of all open vouchers would exceed {maxAmount}, as defined in this voucher type. The current total open amount is {currentAmount}.`
   */
  totalOpenAmount($: {maxAmount: string | number, currentAmount: string | number}): string;


  /**
   * Returns the nested accessor for translation messages in `buy`.
   */
  buy: I18n$Voucher$Error$Buy;


  /**
   * Returns the nested accessor for translation messages in `redeem`.
   */
  redeem: I18n$Voucher$Error$Redeem;


  /**
   * Returns the nested accessor for translation messages in `topUp`.
   */
  topUp: I18n$Voucher$Error$TopUp;

}

export interface I18n$Voucher$Error$Buy {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `notAllowedForUser`.
   * Default value: `The current user is not allowed to buy vouchers from this type.`
   */
  notAllowedForUser: string;


  /**
   * Returns the value of translation message for key `amountForPeriod`.
   * Default value: `Buying on this voucher type is limited by period. Until {date} only {amount} is available for buying.`
   */
  amountForPeriod($: {date: string | number, amount: string | number}): string;


  /**
   * Returns the value of translation message for key `openAmount`.
   * Default value: `The current user exceeds the maximum allowed open amount of {maxAmount} for vouchers of this type. The current open amount is {currentAmount}.`
   */
  openAmount($: {maxAmount: string | number, currentAmount: string | number}): string;

}

export interface I18n$Voucher$Error$Redeem {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `user`.
   * Default value: `The current user cannot redeem vouchers from this type.`
   */
  user: string;

  /**
   * Returns the value of translation message for key `userBlocked`.
   * Default value: `You have been blocked by exceeding the number of allowed attempts.`
   */
  userBlocked: string;

  /**
   * Returns the value of translation message for key `insufficientBalance`.
   * Default value: `This voucher's balance is not enough to perform this redeem.`
   */
  insufficientBalance: string;


  /**
   * Returns the value of translation message for key `status`.
   * Default value: `This voucher cannot be redeemed. Its current status is: {status}.`
   */
  status(status: string | number): string;


  /**
   * Returns the value of translation message for key `notAllowedYet`.
   * Default value: `This voucher cannot be redeemed before {beginDate}.`
   */
  notAllowedYet(beginDate: string | number): string;


  /**
   * Returns the value of translation message for key `notAllowedToday`.
   * Default value: `This voucher cannot be redeemed today. The days allowed for redeeming are: {allowedDays}.`
   */
  notAllowedToday(allowedDays: string | number): string;


  /**
   * Returns the value of translation message for key `insufficientBalance.balance`.
   * Default value: `This voucher's balance of {balance} is not enough to perform this redeem.`
   */
  insufficientBalanceBalance(balance: string | number): string;

}

export interface I18n$Voucher$Error$TopUp {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `alreadyActivated`.
   * Default value: `This voucher is already active and cannot be topped-up again.`
   */
  alreadyActivated: string;

  /**
   * Returns the value of translation message for key `maxBalanceReached`.
   * Default value: `This top-up is not allowed because it would make the voucher go beyond its allowed maximum balance.`
   */
  maxBalanceReached: string;


  /**
   * Returns the value of translation message for key `status`.
   * Default value: `This voucher cannot be topped-up. Its current status is: {status}.`
   */
  status(status: string | number): string;


  /**
   * Returns the value of translation message for key `activationExpired`.
   * Default value: `This voucher can no longer be activated. It should have been activated before {date}.`
   */
  activationExpired(date: string | number): string;

}

export interface I18n$Voucher$Redeem {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `confirm`.
   * Default value: `The details of the voucher to be redeemed are given below. Please, review it below and confirm this action.`
   */
  confirm: string;

  /**
   * Returns the value of translation message for key `done`.
   * Default value: `The voucher was redeemed successfully.`
   */
  done: string;

  /**
   * Returns the value of translation message for key `redeem`.
   * Default value: `Redeem voucher`
   */
  redeem: string;

  /**
   * Returns the value of translation message for key `afterDate`.
   * Default value: `Can redeem after`
   */
  afterDate: string;

  /**
   * Returns the value of translation message for key `onDays`.
   * Default value: `Can redeem on`
   */
  onDays: string;

  /**
   * Returns the value of translation message for key `onDays.any`.
   * Default value: `Any day`
   */
  onDaysAny: string;

  /**
   * Returns the value of translation message for key `redeemer`.
   * Default value: `Redeem place`
   */
  redeemer: string;

  /**
   * Returns the value of translation message for key `date`.
   * Default value: `Redeem date`
   */
  date: string;

  /**
   * Returns the value of translation message for key `amount`.
   * Default value: `Redeem amount`
   */
  amount: string;

  /**
   * Returns the value of translation message for key `by`.
   * Default value: `Redeem performed by`
   */
  by: string;

  /**
   * Returns the value of translation message for key `payment`.
   * Default value: `Redeem payment`
   */
  payment: string;

}

export interface I18n$Voucher$TopUp {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `confirm`.
   * Default value: `The details of the voucher top-up are given below. Please, review it and confirm this action.`
   */
  confirm: string;

  /**
   * Returns the value of translation message for key `done`.
   * Default value: `The voucher was topped-up successfully.`
   */
  done: string;

  /**
   * Returns the value of translation message for key `topUp`.
   * Default value: `Top-up voucher`
   */
  topUp: string;

  /**
   * Returns the value of translation message for key `sendPinBy`.
   * Default value: `Send PIN by`
   */
  sendPinBy: string;

  /**
   * Returns the value of translation message for key `sendNotificationsBy`.
   * Default value: `Send notifications by`
   */
  sendNotificationsBy: string;

  /**
   * Returns the value of translation message for key `dontSendNotifications`.
   * Default value: `Don't send notifications`
   */
  dontSendNotifications: string;

  /**
   * Returns the value of translation message for key `manualPin`.
   * Default value: `Enter PIN manually`
   */
  manualPin: string;

  /**
   * Returns the value of translation message for key `pin`.
   * Default value: `Voucher PIN`
   */
  pin: string;

  /**
   * Returns the value of translation message for key `pinConfirm`.
   * Default value: `Confirm PIN`
   */
  pinConfirm: string;

}

export interface I18n$Voucher$Transaction {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `amount`.
   * Default value: `Amount`
   */
  amount: string;

  /**
   * Returns the value of translation message for key `date`.
   * Default value: `Date`
   */
  date: string;

  /**
   * Returns the value of translation message for key `user`.
   * Default value: `Place`
   */
  user: string;

  /**
   * Returns the value of translation message for key `userGroups`.
   * Default value: `Place groups`
   */
  userGroups: string;

  /**
   * Returns the value of translation message for key `payment`.
   * Default value: `Payment`
   */
  payment: string;

  /**
   * Returns the value of translation message for key `kind`.
   * Default value: `Transaction type`
   */
  kind: string;

  /**
   * Returns the value of translation message for key `redeem`.
   * Default value: `Redeem`
   */
  redeem: string;

  /**
   * Returns the value of translation message for key `topUp`.
   * Default value: `Top-up`
   */
  topUp: string;

  /**
   * Returns the value of translation message for key `chargeback`.
   * Default value: `Chargeback`
   */
  chargeback: string;

  /**
   * Returns the value of translation message for key `searchResultChargebackTitle`.
   * Default value: `Chargeback:`
   */
  searchResultChargebackTitle: string;

  /**
   * Returns the value of translation message for key `chargebackOf`.
   * Default value: `Chargeback of`
   */
  chargebackOf: string;

  /**
   * Returns the value of translation message for key `chargedBackBy`.
   * Default value: `Charged back by`
   */
  chargedBackBy: string;

  /**
   * Returns the value of translation message for key `viewTransaction`.
   * Default value: `View transaction`
   */
  viewTransaction: string;

}

export interface I18n$Voucher$Status {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `redeemed`.
   * Default value: `Redeemed`
   */
  redeemed: string;

  /**
   * Returns the value of translation message for key `inactive`.
   * Default value: `Inactive (unbacked)`
   */
  inactive: string;

  /**
   * Returns the value of translation message for key `pending`.
   * Default value: `Pending`
   */
  pending: string;

  /**
   * Returns the value of translation message for key `open`.
   * Default value: `Open`
   */
  open: string;

  /**
   * Returns the value of translation message for key `expired`.
   * Default value: `Expired`
   */
  expired: string;

  /**
   * Returns the value of translation message for key `canceled`.
   * Default value: `Canceled`
   */
  canceled: string;

  /**
   * Returns the value of translation message for key `activationExpired`.
   * Default value: `Activation expired`
   */
  activationExpired: string;

  /**
   * Returns the value of translation message for key `blocked`.
   * Default value: `Blocked`
   */
  blocked: string;

}

export interface I18n$Voucher$MobileTitle {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `buy`.
   * Default value: `Buy vouchers`
   */
  buy: string;

  /**
   * Returns the value of translation message for key `buyConfirmation`.
   * Default value: `Buy confirmation`
   */
  buyConfirmation: string;

  /**
   * Returns the value of translation message for key `send`.
   * Default value: `Buy and email`
   */
  send: string;

  /**
   * Returns the value of translation message for key `sendConfirmation`.
   * Default value: `Send confirmation`
   */
  sendConfirmation: string;

  /**
   * Returns the value of translation message for key `redeem`.
   * Default value: `Redeem voucher`
   */
  redeem: string;

  /**
   * Returns the value of translation message for key `topUp`.
   * Default value: `Top-up voucher`
   */
  topUp: string;

  /**
   * Returns the value of translation message for key `redeemDetails`.
   * Default value: `Voucher redeem`
   */
  redeemDetails: string;

  /**
   * Returns the value of translation message for key `topUpDetails`.
   * Default value: `Voucher top-up`
   */
  topUpDetails: string;

  /**
   * Returns the value of translation message for key `chargebackDetails`.
   * Default value: `Voucher chargeback`
   */
  chargebackDetails: string;

  /**
   * Returns the value of translation message for key `transactions`.
   * Default value: `Voucher transactions`
   */
  transactions: string;

  /**
   * Returns the value of translation message for key `transactionsRedeems`.
   * Default value: `Voucher redeems`
   */
  transactionsRedeems: string;

  /**
   * Returns the value of translation message for key `search`.
   * Default value: `Search vouchers`
   */
  search: string;

  /**
   * Returns the value of translation message for key `generate`.
   * Default value: `Generate vouchers`
   */
  generate: string;

  /**
   * Returns the value of translation message for key `generateConfirmation`.
   * Default value: `Generate confirmation`
   */
  generateConfirmation: string;

}

export interface I18n$Voucher$Title {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `buy`.
   * Default value: `Buy vouchers`
   */
  buy: string;

  /**
   * Returns the value of translation message for key `buyConfirmation`.
   * Default value: `Buy confirmation`
   */
  buyConfirmation: string;

  /**
   * Returns the value of translation message for key `send`.
   * Default value: `Buy and email voucher`
   */
  send: string;

  /**
   * Returns the value of translation message for key `sendConfirmation`.
   * Default value: `Send confirmation`
   */
  sendConfirmation: string;

  /**
   * Returns the value of translation message for key `redeem`.
   * Default value: `Redeem voucher`
   */
  redeem: string;

  /**
   * Returns the value of translation message for key `topUp`.
   * Default value: `Top-up voucher`
   */
  topUp: string;

  /**
   * Returns the value of translation message for key `redeemDetails`.
   * Default value: `Voucher redeem details`
   */
  redeemDetails: string;

  /**
   * Returns the value of translation message for key `topUpDetails`.
   * Default value: `Voucher top-up details`
   */
  topUpDetails: string;

  /**
   * Returns the value of translation message for key `chargebackDetails`.
   * Default value: `Voucher chargeback`
   */
  chargebackDetails: string;

  /**
   * Returns the value of translation message for key `transactions`.
   * Default value: `Search voucher transactions`
   */
  transactions: string;

  /**
   * Returns the value of translation message for key `transactionsRedeems`.
   * Default value: `Search voucher redeems`
   */
  transactionsRedeems: string;

  /**
   * Returns the value of translation message for key `search`.
   * Default value: `Search vouchers`
   */
  search: string;

  /**
   * Returns the value of translation message for key `generate`.
   * Default value: `Generate vouchers`
   */
  generate: string;

  /**
   * Returns the value of translation message for key `generateConfirmation`.
   * Default value: `Generate confirmation`
   */
  generateConfirmation: string;

}

export interface I18n$Voucher$CreationType {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `label`.
   * Default value: `Creation type`
   */
  label: string;

  /**
   * Returns the value of translation message for key `userLabel`.
   * Default value: `Show vouchers`
   */
  userLabel: string;

  /**
   * Returns the value of translation message for key `generated`.
   * Default value: `Generated`
   */
  generated: string;

  /**
   * Returns the value of translation message for key `bought`.
   * Default value: `Bought`
   */
  bought: string;

  /**
   * Returns the value of translation message for key `sent`.
   * Default value: `Sent`
   */
  sent: string;

}

export interface I18n$Voucher$Buy {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `amountRange`.
   * Default value: `Amount range`
   */
  amountRange: string;

  /**
   * Returns the value of translation message for key `minimumAmount`.
   * Default value: `Minimum amount`
   */
  minimumAmount: string;

  /**
   * Returns the value of translation message for key `maximumAmount`.
   * Default value: `Maximum amount`
   */
  maximumAmount: string;

  /**
   * Returns the value of translation message for key `buy`.
   * Default value: `Buy vouchers`
   */
  buy: string;

  /**
   * Returns the value of translation message for key `buyer`.
   * Default value: `Buyer`
   */
  buyer: string;

  /**
   * Returns the value of translation message for key `done`.
   * Default value: `The voucher(s) has been bought.`
   */
  done: string;

  /**
   * Returns the value of translation message for key `minTimeToRedeem`.
   * Default value: `Can be redeemed`
   */
  minTimeToRedeem: string;

  /**
   * Returns the value of translation message for key `payment`.
   * Default value: `Payment reference`
   */
  payment: string;

  /**
   * Returns the value of translation message for key `usage`.
   * Default value: `Usage`
   */
  usage: string;

  /**
   * Returns the value of translation message for key `usage.self`.
   * Default value: `I will use the voucher myself (only personal use is allowed)`
   */
  usageSelf: string;

  /**
   * Returns the value of translation message for key `usage.gift`.
   * Default value: `This voucher is a gift (redeem details won't be visible)`
   */
  usageGift: string;

  /**
   * Returns the value of translation message for key `usage.alwaysSelf`.
   * Default value: `Only personal use is allowed`
   */
  usageAlwaysSelf: string;


  /**
   * Returns the value of translation message for key `minTimeToRedeem.afterBuy`.
   * Default value: `{interval} after buying`
   */
  minTimeToRedeemAfterBuy(interval: string | number): string;

}

export interface I18n$Voucher$Send {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `send`.
   * Default value: `Buy and email`
   */
  send: string;

  /**
   * Returns the value of translation message for key `issuer`.
   * Default value: `Issuer`
   */
  issuer: string;

  /**
   * Returns the value of translation message for key `message`.
   * Default value: `Message`
   */
  message: string;


  /**
   * Returns the value of translation message for key `done`.
   * Default value: `The voucher was sent to {email}`
   */
  done(email: string | number): string;

}

export interface I18n$Voucher$Generate {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `generate`.
   * Default value: `Generate vouchers`
   */
  generate: string;

  /**
   * Returns the value of translation message for key `done`.
   * Default value: `The voucher(s) has been generated.`
   */
  done: string;

}

export interface I18n$Voucher$PinStatusForRedeem {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `status`.
   * Default value: `Voucher PIN status`
   */
  status: string;

  /**
   * Returns the value of translation message for key `blocked`.
   * Default value: `The PIN has been blocked by exceeding invalid attempts`
   */
  blocked: string;

  /**
   * Returns the value of translation message for key `notUsed`.
   * Default value: `The PIN is not used`
   */
  notUsed: string;

  /**
   * Returns the value of translation message for key `required`.
   * Default value: `The PIN is required for redeeming`
   */
  required: string;

  /**
   * Returns the value of translation message for key `subsequent`.
   * Default value: `The PIN is not used on first redeem, but required afterwards`
   */
  subsequent: string;

}

export interface I18n$Voucher$UnblockPin {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `unblockPin`.
   * Default value: `Unblock PIN`
   */
  unblockPin: string;

  /**
   * Returns the value of translation message for key `done`.
   * Default value: `The voucher PIN was unblocked`
   */
  done: string;

}

export interface I18n$Voucher$Cancel {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `cancelAndRefund`.
   * Default value: `Cancel & refund`
   */
  cancelAndRefund: string;

  /**
   * Returns the value of translation message for key `done`.
   * Default value: `This voucher has been canceled.`
   */
  done: string;

  /**
   * Returns the value of translation message for key `packConfirmation`.
   * Default value: `There are other vouchers belonging to the same pack that will be also canceled. Are you sure to continue?`
   */
  packConfirmation: string;

  /**
   * Returns the value of translation message for key `refundConfirmation`.
   * Default value: `Are you sure to cancel and refund this voucher?`
   */
  refundConfirmation: string;

  /**
   * Returns the value of translation message for key `confirmation`.
   * Default value: `Are you sure to cancel this voucher?`
   */
  confirmation: string;

}

export interface I18n$Voucher$ResendEmail {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `label`.
   * Default value: `Re-send e-mail`
   */
  label: string;

  /**
   * Returns the value of translation message for key `done`.
   * Default value: `The voucher was re-sent by e-mail`
   */
  done: string;


  /**
   * Returns the value of translation message for key `confirmation`.
   * Default value: `Are you sure to re-send this voucher to {email}?`
   */
  confirmation(email: string | number): string;

}

export interface I18n$Voucher$NotificationSettings {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `label`.
   * Default value: `Notifications`
   */
  label: string;

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Notification settings`
   */
  title: string;

  /**
   * Returns the value of translation message for key `done`.
   * Default value: `The notification settings were saved successfully`
   */
  done: string;

}

export interface I18n$Voucher$ChangePin {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `done`.
   * Default value: `The PIN was changed successfully`
   */
  done: string;

  /**
   * Returns the value of translation message for key `label`.
   * Default value: `Change PIN`
   */
  label: string;

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Change PIN`
   */
  title: string;

  /**
   * Returns the value of translation message for key `oldPin`.
   * Default value: `Current PIN`
   */
  oldPin: string;

  /**
   * Returns the value of translation message for key `newPin`.
   * Default value: `New PIN`
   */
  newPin: string;

  /**
   * Returns the value of translation message for key `pinConfirmation`.
   * Default value: `PIN confirmation`
   */
  pinConfirmation: string;

}

export interface I18n$Voucher$Sort {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `creationDateDesc`.
   * Default value: `Creation date descending`
   */
  creationDateDesc: string;

  /**
   * Returns the value of translation message for key `creationDateAsc`.
   * Default value: `Creation date ascending`
   */
  creationDateAsc: string;

  /**
   * Returns the value of translation message for key `expirationDateDesc`.
   * Default value: `Expiration date descending`
   */
  expirationDateDesc: string;

  /**
   * Returns the value of translation message for key `expirationDateAsc`.
   * Default value: `Expiration date ascending`
   */
  expirationDateAsc: string;

  /**
   * Returns the value of translation message for key `redeemDateDesc`.
   * Default value: `Redeem date descending`
   */
  redeemDateDesc: string;

  /**
   * Returns the value of translation message for key `redeemDateAsc`.
   * Default value: `Redeem date ascending`
   */
  redeemDateAsc: string;

}

export interface I18n$Voucher$Info {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Voucher information`
   */
  title: string;

  /**
   * Returns the value of translation message for key `message.token`.
   * Default value: `Here you can check your voucher status. Type in the voucher code below.`
   */
  messageToken: string;

  /**
   * Returns the value of translation message for key `message.pin`.
   * Default value: `Please, type in the voucher PIN below to proceed.`
   */
  messagePin: string;

  /**
   * Returns the value of translation message for key `message.pin.blockedVoucher`.
   * Default value: `The voucher is blocked, please type in the voucher PIN below to unblock it.`
   */
  messagePinBlockedVoucher: string;

  /**
   * Returns the value of translation message for key `home`.
   * Default value: `Home`
   */
  home: string;

  /**
   * Returns the value of translation message for key `exit`.
   * Default value: `Exit`
   */
  exit: string;

  /**
   * Returns the value of translation message for key `unblock`.
   * Default value: `Unblock voucher`
   */
  unblock: string;


  /**
   * Returns the nested accessor for translation messages in `forgotPin`.
   */
  forgotPin: I18n$Voucher$Info$ForgotPin;


  /**
   * Returns the nested accessor for translation messages in `activateGift`.
   */
  activateGift: I18n$Voucher$Info$ActivateGift;


  /**
   * Returns the nested accessor for translation messages in `changePin`.
   */
  changePin: I18n$Voucher$Info$ChangePin;

}

export interface I18n$Voucher$Info$ForgotPin {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Forgotten PIN`
   */
  title: string;

  /**
   * Returns the value of translation message for key `action`.
   * Default value: `Forgot PIN`
   */
  action: string;


  /**
   * Returns the value of translation message for key `messageAllMediums`.
   * Default value: `Please, confirm your request to receive the PIN by {email} and {sms}.`
   */
  messageAllMediums($: {email: string | number, sms: string | number}): string;


  /**
   * Returns the value of translation message for key `message`.
   * Default value: `Please, confirm your request to receive the PIN by {medium}.`
   */
  message(medium: string | number): string;


  /**
   * Returns the value of translation message for key `pinSentTo`.
   * Default value: `The PIN was sent to {to}`
   */
  pinSentTo(to: string | number): string;

}

export interface I18n$Voucher$Info$ActivateGift {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Voucher activation`
   */
  title: string;


  /**
   * Returns the value of translation message for key `message`.
   * Default value: `This is a gift voucher from {issuer}. In order to activate it, you need to define a PIN.`
   */
  message(issuer: string | number): string;


  /**
   * Returns the value of translation message for key `agreement`.
   * Default value: `By proceeding, I declare I've rightfully received this gift voucher from {issuer}.`
   */
  agreement(issuer: string | number): string;

}

export interface I18n$Voucher$Info$ChangePin {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `pinChanged`.
   * Default value: `The PIN was changed successfully`
   */
  pinChanged: string;

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Change PIN`
   */
  title: string;

  /**
   * Returns the value of translation message for key `oldPin`.
   * Default value: `Current PIN`
   */
  oldPin: string;

  /**
   * Returns the value of translation message for key `newPin`.
   * Default value: `New PIN`
   */
  newPin: string;

  /**
   * Returns the value of translation message for key `newPinConfirmation`.
   * Default value: `PIN confirmation`
   */
  newPinConfirmation: string;

  /**
   * Returns the value of translation message for key `action`.
   * Default value: `Change PIN`
   */
  action: string;

}

export interface I18n$Field {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;


  /**
   * Returns the nested accessor for translation messages in `distanceSelection`.
   */
  distanceSelection: I18n$Field$DistanceSelection;


  /**
   * Returns the nested accessor for translation messages in `privacy`.
   */
  privacy: I18n$Field$Privacy;


  /**
   * Returns the nested accessor for translation messages in `image`.
   */
  image: I18n$Field$Image;


  /**
   * Returns the nested accessor for translation messages in `file`.
   */
  file: I18n$Field$File;


  /**
   * Returns the nested accessor for translation messages in `user`.
   */
  user: I18n$Field$User;


  /**
   * Returns the nested accessor for translation messages in `camera`.
   */
  camera: I18n$Field$Camera;


  /**
   * Returns the nested accessor for translation messages in `html`.
   */
  html: I18n$Field$Html;

}

export interface I18n$Field$DistanceSelection {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `clear`.
   * Default value: `Clear`
   */
  clear: string;

  /**
   * Returns the value of translation message for key `selectTooltip`.
   * Default value: `Filter by max distance`
   */
  selectTooltip: string;


  /**
   * Returns the value of translation message for key `errorAddressNotLocated`.
   * Default value: `The {address} address was not geolocated yet.`
   */
  errorAddressNotLocated(address: string | number): string;

}

export interface I18n$Field$Privacy {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `name`.
   * Default value: `Privacy`
   */
  name: string;

  /**
   * Returns the value of translation message for key `private.tooltip`.
   * Default value: `This field is private. Click to allow other to view it.`
   */
  privateTooltip: string;

  /**
   * Returns the value of translation message for key `public.tooltip`.
   * Default value: `This field is visible by others. Click to make it private.`
   */
  publicTooltip: string;

}

export interface I18n$Field$Image {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `upload`.
   * Default value: `Upload a new image`
   */
  upload: string;

  /**
   * Returns the value of translation message for key `camera`.
   * Default value: `Capture from camera`
   */
  camera: string;

  /**
   * Returns the value of translation message for key `manage`.
   * Default value: `Reorder or remove images`
   */
  manage: string;

  /**
   * Returns the value of translation message for key `remove`.
   * Default value: `Remove this image`
   */
  remove: string;

  /**
   * Returns the value of translation message for key `manageMessage`.
   * Default value: `You can drag / drop images to reorder them`
   */
  manageMessage: string;

  /**
   * Returns the value of translation message for key `manageAfterConfirm`.
   * Default value: `After confirmation, please make sure to click the Save button in order for the changes to be applied.`
   */
  manageAfterConfirm: string;

}

export interface I18n$Field$File {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `upload`.
   * Default value: `Upload a new file`
   */
  upload: string;

  /**
   * Returns the value of translation message for key `manage`.
   * Default value: `Reorder or remove files`
   */
  manage: string;

  /**
   * Returns the value of translation message for key `remove`.
   * Default value: `Remove this file`
   */
  remove: string;

  /**
   * Returns the value of translation message for key `manage.message`.
   * Default value: `You can drag / drop files to reorder them`
   */
  manageMessage: string;

}

export interface I18n$Field$User {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `placeholder.allowSearch`.
   * Default value: `Type to search`
   */
  placeholderAllowSearch: string;

  /**
   * Returns the value of translation message for key `placeholder.principal`.
   * Default value: `Type the user identifier`
   */
  placeholderPrincipal: string;

  /**
   * Returns the value of translation message for key `contact.tooltip`.
   * Default value: `Pick from your contact list`
   */
  contactTooltip: string;

  /**
   * Returns the value of translation message for key `contact.title`.
   * Default value: `Select a contact`
   */
  contactTitle: string;

  /**
   * Returns the value of translation message for key `scanQrCode.tooltip`.
   * Default value: `Scan a QR-code with your camera`
   */
  scanQrCodeTooltip: string;

  /**
   * Returns the value of translation message for key `locate.tooltip`.
   * Default value: `Locate the user`
   */
  locateTooltip: string;

}

export interface I18n$Field$Camera {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `title.capture`.
   * Default value: `Capture image from your camera`
   */
  titleCapture: string;

  /**
   * Returns the value of translation message for key `title.qrCode`.
   * Default value: `Scan QR-code`
   */
  titleQrCode: string;

  /**
   * Returns the value of translation message for key `capture`.
   * Default value: `Capture`
   */
  capture: string;

  /**
   * Returns the value of translation message for key `noCameras`.
   * Default value: `No cameras found on this device.`
   */
  noCameras: string;

  /**
   * Returns the value of translation message for key `noPermission`.
   * Default value: `In order use this functionality, please, grant permission for the application to access your camera.`
   */
  noPermission: string;


  /**
   * Returns the value of translation message for key `fileName`.
   * Default value: `Camera {timestamp}.jpeg`
   */
  fileName(timestamp: string | number): string;

}

export interface I18n$Field$Html {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `undo`.
   * Default value: `Undo`
   */
  undo: string;

  /**
   * Returns the value of translation message for key `redo`.
   * Default value: `Redo`
   */
  redo: string;

  /**
   * Returns the value of translation message for key `font`.
   * Default value: `Font`
   */
  font: string;

  /**
   * Returns the value of translation message for key `font.default`.
   * Default value: `Default font`
   */
  fontDefault: string;

  /**
   * Returns the value of translation message for key `font.sansSerif`.
   * Default value: `Sans serif`
   */
  fontSansSerif: string;

  /**
   * Returns the value of translation message for key `font.serif`.
   * Default value: `Serif`
   */
  fontSerif: string;

  /**
   * Returns the value of translation message for key `font.monospace`.
   * Default value: `Monospace`
   */
  fontMonospace: string;

  /**
   * Returns the value of translation message for key `size`.
   * Default value: `Size`
   */
  size: string;

  /**
   * Returns the value of translation message for key `size.1`.
   * Default value: `Tiny`
   */
  size1: string;

  /**
   * Returns the value of translation message for key `size.2`.
   * Default value: `Small`
   */
  size2: string;

  /**
   * Returns the value of translation message for key `size.3`.
   * Default value: `Medium`
   */
  size3: string;

  /**
   * Returns the value of translation message for key `size.4`.
   * Default value: `Medium-larger`
   */
  size4: string;

  /**
   * Returns the value of translation message for key `size.5`.
   * Default value: `Large`
   */
  size5: string;

  /**
   * Returns the value of translation message for key `size.6`.
   * Default value: `Huge`
   */
  size6: string;

  /**
   * Returns the value of translation message for key `size.7`.
   * Default value: `Maximum`
   */
  size7: string;

  /**
   * Returns the value of translation message for key `block`.
   * Default value: `Formatting`
   */
  block: string;

  /**
   * Returns the value of translation message for key `block.h1`.
   * Default value: `Title 1`
   */
  blockH1: string;

  /**
   * Returns the value of translation message for key `block.h2`.
   * Default value: `Title 2`
   */
  blockH2: string;

  /**
   * Returns the value of translation message for key `block.h3`.
   * Default value: `Title 3`
   */
  blockH3: string;

  /**
   * Returns the value of translation message for key `block.h4`.
   * Default value: `Title 4`
   */
  blockH4: string;

  /**
   * Returns the value of translation message for key `block.h5`.
   * Default value: `Title 5`
   */
  blockH5: string;

  /**
   * Returns the value of translation message for key `block.h6`.
   * Default value: `Title 6`
   */
  blockH6: string;

  /**
   * Returns the value of translation message for key `block.p`.
   * Default value: `Paragraph`
   */
  blockP: string;

  /**
   * Returns the value of translation message for key `block.pre`.
   * Default value: `Preformatted`
   */
  blockPre: string;

  /**
   * Returns the value of translation message for key `align.left`.
   * Default value: `Left`
   */
  alignLeft: string;

  /**
   * Returns the value of translation message for key `align.right`.
   * Default value: `Right`
   */
  alignRight: string;

  /**
   * Returns the value of translation message for key `align.center`.
   * Default value: `Center`
   */
  alignCenter: string;

  /**
   * Returns the value of translation message for key `align.justify`.
   * Default value: `Justify`
   */
  alignJustify: string;

  /**
   * Returns the value of translation message for key `bold`.
   * Default value: `Bold`
   */
  bold: string;

  /**
   * Returns the value of translation message for key `italic`.
   * Default value: `Italic`
   */
  italic: string;

  /**
   * Returns the value of translation message for key `underline`.
   * Default value: `Underline`
   */
  underline: string;

  /**
   * Returns the value of translation message for key `strikethrough`.
   * Default value: `Strikethrough`
   */
  strikethrough: string;

  /**
   * Returns the value of translation message for key `removeFormat`.
   * Default value: `Remove format`
   */
  removeFormat: string;

  /**
   * Returns the value of translation message for key `list.bulleted`.
   * Default value: `Bulleted list`
   */
  listBulleted: string;

  /**
   * Returns the value of translation message for key `list.numbered`.
   * Default value: `Numbered list`
   */
  listNumbered: string;

  /**
   * Returns the value of translation message for key `indent.less`.
   * Default value: `Decrease indentation`
   */
  indentLess: string;

  /**
   * Returns the value of translation message for key `indent.more`.
   * Default value: `Increase indentation`
   */
  indentMore: string;

  /**
   * Returns the value of translation message for key `source`.
   * Default value: `Edit the HTML source`
   */
  source: string;

  /**
   * Returns the value of translation message for key `unlink`.
   * Default value: `Remove hyperlink`
   */
  unlink: string;


  /**
   * Returns the nested accessor for translation messages in `link`.
   */
  link: I18n$Field$Html$Link;


  /**
   * Returns the nested accessor for translation messages in `image`.
   */
  image: I18n$Field$Html$Image;

}

export interface I18n$Field$Html$Link {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `tooltip`.
   * Default value: `Insert hyperlink`
   */
  tooltip: string;

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Hyperlink properties`
   */
  title: string;

  /**
   * Returns the value of translation message for key `href`.
   * Default value: `Hyperlink location`
   */
  href: string;

  /**
   * Returns the value of translation message for key `text`.
   * Default value: `Displayed text`
   */
  text: string;

}

export interface I18n$Field$Html$Image {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `tooltip`.
   * Default value: `Insert image`
   */
  tooltip: string;

  /**
   * Returns the value of translation message for key `title.insert`.
   * Default value: `Select the image to insert`
   */
  titleInsert: string;

  /**
   * Returns the value of translation message for key `title.properties`.
   * Default value: `Image properties`
   */
  titleProperties: string;

  /**
   * Returns the value of translation message for key `source.url`.
   * Default value: `Insert the image from the Internet`
   */
  sourceUrl: string;

  /**
   * Returns the value of translation message for key `url`.
   * Default value: `Image URL`
   */
  url: string;

  /**
   * Returns the value of translation message for key `source.existing`.
   * Default value: `Select a previously uploaded image`
   */
  sourceExisting: string;

  /**
   * Returns the value of translation message for key `noImages`.
   * Default value: `There are no existing images`
   */
  noImages: string;

  /**
   * Returns the value of translation message for key `upload`.
   * Default value: `Upload new`
   */
  upload: string;

  /**
   * Returns the value of translation message for key `width`.
   * Default value: `Width`
   */
  width: string;

  /**
   * Returns the value of translation message for key `height`.
   * Default value: `Height`
   */
  height: string;

  /**
   * Returns the value of translation message for key `contrain`.
   * Default value: `Keep aspect ratio`
   */
  contrain: string;

  /**
   * Returns the value of translation message for key `float`.
   * Default value: `Align`
   */
  float: string;

  /**
   * Returns the value of translation message for key `floatNone`.
   * Default value: `None`
   */
  floatNone: string;

  /**
   * Returns the value of translation message for key `floatLeft`.
   * Default value: `Left`
   */
  floatLeft: string;

  /**
   * Returns the value of translation message for key `floatRight`.
   * Default value: `Right`
   */
  floatRight: string;

  /**
   * Returns the value of translation message for key `marginTop`.
   * Default value: `Top margin`
   */
  marginTop: string;

  /**
   * Returns the value of translation message for key `marginBottom`.
   * Default value: `Bottom margin`
   */
  marginBottom: string;

  /**
   * Returns the value of translation message for key `marginLeft`.
   * Default value: `Left margin`
   */
  marginLeft: string;

  /**
   * Returns the value of translation message for key `marginRight`.
   * Default value: `Right margin`
   */
  marginRight: string;

  /**
   * Returns the value of translation message for key `border`.
   * Default value: `Border`
   */
  border: string;

  /**
   * Returns the value of translation message for key `textExample`.
   * Default value: `This is an example text. It shows how the image will be positioned when there is text besides the image.`
   */
  textExample: string;

}

export interface I18n$User {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `statusEmptyLabel`.
   * Default value: `Show all active or blocked users`
   */
  statusEmptyLabel: string;

  /**
   * Returns the value of translation message for key `connected`.
   * Default value: `Online`
   */
  connected: string;

  /**
   * Returns the value of translation message for key `connectionStatus`.
   * Default value: `Connection status`
   */
  connectionStatus: string;

  /**
   * Returns the value of translation message for key `neverLogged`.
   * Default value: `Never logged`
   */
  neverLogged: string;

  /**
   * Returns the value of translation message for key `group`.
   * Default value: `Group`
   */
  group: string;

  /**
   * Returns the value of translation message for key `broker`.
   * Default value: `Broker`
   */
  broker: string;

  /**
   * Returns the value of translation message for key `groups`.
   * Default value: `Groups`
   */
  groups: string;

  /**
   * Returns the value of translation message for key `groupSet`.
   * Default value: `Group set`
   */
  groupSet: string;

  /**
   * Returns the value of translation message for key `group.filter`.
   * Default value: `Groups`
   */
  groupFilter: string;

  /**
   * Returns the value of translation message for key `registrationDate`.
   * Default value: `Registration date`
   */
  registrationDate: string;

  /**
   * Returns the value of translation message for key `activationDate`.
   * Default value: `Activation date`
   */
  activationDate: string;

  /**
   * Returns the value of translation message for key `name`.
   * Default value: `Full name`
   */
  name: string;

  /**
   * Returns the value of translation message for key `username`.
   * Default value: `Login name`
   */
  username: string;

  /**
   * Returns the value of translation message for key `email`.
   * Default value: `E-mail`
   */
  email: string;

  /**
   * Returns the value of translation message for key `email.pending`.
   * Default value: `E-mail pending validation`
   */
  emailPending: string;

  /**
   * Returns the value of translation message for key `operator.owner`.
   * Default value: `Operator owner`
   */
  operatorOwner: string;

  /**
   * Returns the value of translation message for key `operator.noGroup`.
   * Default value: `No group (has all permissions)`
   */
  operatorNoGroup: string;

  /**
   * Returns the value of translation message for key `operator.registration.addAnother`.
   * Default value: `Register another operator`
   */
  operatorRegistrationAddAnother: string;

  /**
   * Returns the value of translation message for key `noImageRequired`.
   * Default value: `Please, upload your profile image`
   */
  noImageRequired: string;

  /**
   * Returns the value of translation message for key `noImage`.
   * Default value: `No profile image`
   */
  noImage: string;

  /**
   * Returns the value of translation message for key `noImages`.
   * Default value: `No profile images`
   */
  noImages: string;

  /**
   * Returns the value of translation message for key `address.define`.
   * Default value: `Define address`
   */
  addressDefine: string;

  /**
   * Returns the value of translation message for key `address.add`.
   * Default value: `Add address`
   */
  addressAdd: string;

  /**
   * Returns the value of translation message for key `address.remove`.
   * Default value: `Remove this address`
   */
  addressRemove: string;

  /**
   * Returns the value of translation message for key `address.none`.
   * Default value: `There are currently no addresses`
   */
  addressNone: string;

  /**
   * Returns the value of translation message for key `phone.add`.
   * Default value: `Add phone`
   */
  phoneAdd: string;

  /**
   * Returns the value of translation message for key `phone.add.mobile`.
   * Default value: `Add mobile phone`
   */
  phoneAddMobile: string;

  /**
   * Returns the value of translation message for key `phone.add.landLine`.
   * Default value: `Add land-line phone`
   */
  phoneAddLandLine: string;

  /**
   * Returns the value of translation message for key `phone.remove`.
   * Default value: `Remove this phone`
   */
  phoneRemove: string;

  /**
   * Returns the value of translation message for key `phone.none`.
   * Default value: `There are currently no phones`
   */
  phoneNone: string;

  /**
   * Returns the value of translation message for key `contactInfo`.
   * Default value: `Contact information`
   */
  contactInfo: string;

  /**
   * Returns the value of translation message for key `contactInfo.add`.
   * Default value: `Add contact`
   */
  contactInfoAdd: string;

  /**
   * Returns the value of translation message for key `contactInfo.remove`.
   * Default value: `Remove contact`
   */
  contactInfoRemove: string;

  /**
   * Returns the value of translation message for key `contactInfo.none`.
   * Default value: `There are currently no additional contacts`
   */
  contactInfoNone: string;

  /**
   * Returns the value of translation message for key `contactInfo.atAddress`.
   * Default value: `At`
   */
  contactInfoAtAddress: string;

  /**
   * Returns the value of translation message for key `profileSaved`.
   * Default value: `The profile was saved`
   */
  profileSaved: string;

  /**
   * Returns the value of translation message for key `newEmailConfirmed`.
   * Default value: `Your new e-mail address was successfully confirmed`
   */
  newEmailConfirmed: string;

  /**
   * Returns the value of translation message for key `securityQuestion.message`.
   * Default value: `If you happen to forget your password, you will need to correctly answer the security question in order to reset the password.<br>This can be set later, but keep in mind that the password recovery won't work without it.`
   */
  securityQuestionMessage: string;

  /**
   * Returns the value of translation message for key `securityQuestion.empty`.
   * Default value: `Leave blank`
   */
  securityQuestionEmpty: string;

  /**
   * Returns the value of translation message for key `captcha`.
   * Default value: `Verification`
   */
  captcha: string;

  /**
   * Returns the value of translation message for key `contact`.
   * Default value: `Contact`
   */
  contact: string;

  /**
   * Returns the value of translation message for key `invitedBy`.
   * Default value: `Invited by`
   */
  invitedBy: string;

  /**
   * Returns the value of translation message for key `invitedByMe`.
   * Default value: `Invited by me`
   */
  invitedByMe: string;

  /**
   * Returns the value of translation message for key `creationBegin`.
   * Default value: `Registered after`
   */
  creationBegin: string;

  /**
   * Returns the value of translation message for key `creationEnd`.
   * Default value: `Registered before`
   */
  creationEnd: string;

  /**
   * Returns the value of translation message for key `acceptedAgreements`.
   * Default value: `Accepted agreements`
   */
  acceptedAgreements: string;

  /**
   * Returns the value of translation message for key `notAcceptedAgreements`.
   * Default value: `Not accepted agreements`
   */
  notAcceptedAgreements: string;

  /**
   * Returns the value of translation message for key `assignedProducts`.
   * Default value: `Assigned products`
   */
  assignedProducts: string;

  /**
   * Returns the value of translation message for key `beginUserActivationDate`.
   * Default value: `Activated after`
   */
  beginUserActivationDate: string;

  /**
   * Returns the value of translation message for key `endUserActivationDate`.
   * Default value: `Activated before`
   */
  endUserActivationDate: string;

  /**
   * Returns the value of translation message for key `beginLastLoginDate`.
   * Default value: `Last login after`
   */
  beginLastLoginDate: string;

  /**
   * Returns the value of translation message for key `endLastLoginDate`.
   * Default value: `Last login before`
   */
  endLastLoginDate: string;

  /**
   * Returns the value of translation message for key `activities`.
   * Default value: `Activities`
   */
  activities: string;


  /**
   * Returns the value of translation message for key `disconnectedStatus`.
   * Default value: `Off-line (last login: {date})`
   */
  disconnectedStatus(date: string | number): string;


  /**
   * Returns the value of translation message for key `operator.registration.active`.
   * Default value: `The operator {operator} has been activated.`
   */
  operatorRegistrationActive(operator: string | number): string;


  /**
   * Returns the value of translation message for key `operator.registration.inactive`.
   * Default value: `The operator {operator} has been registered, however, still needs activatation by the administration.`
   */
  operatorRegistrationInactive(operator: string | number): string;


  /**
   * Returns the value of translation message for key `operator.registration.pending`.
   * Default value: `The operator {operator} has been registered, however, still needs to validate their registration by confirming the e-mail.`
   */
  operatorRegistrationPending(operator: string | number): string;


  /**
   * Returns the value of translation message for key `passwordConfirmation`.
   * Default value: `{type} confirmation`
   */
  passwordConfirmation(type: string | number): string;


  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  title: I18n$User$Title;


  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  mobileTitle: I18n$User$MobileTitle;


  /**
   * Returns the nested accessor for translation messages in `profile`.
   */
  profile: I18n$User$Profile;


  /**
   * Returns the nested accessor for translation messages in `registration`.
   */
  registration: I18n$User$Registration;


  /**
   * Returns the nested accessor for translation messages in `orderBy`.
   */
  orderBy: I18n$User$OrderBy;

}

export interface I18n$User$Title {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `myProfile`.
   * Default value: `My profile`
   */
  myProfile: string;

  /**
   * Returns the value of translation message for key `editProfile`.
   * Default value: `Edit profile`
   */
  editProfile: string;

  /**
   * Returns the value of translation message for key `image`.
   * Default value: `Profile image`
   */
  image: string;

  /**
   * Returns the value of translation message for key `images`.
   * Default value: `Profile images`
   */
  images: string;

  /**
   * Returns the value of translation message for key `fields`.
   * Default value: `Profile fields`
   */
  fields: string;

  /**
   * Returns the value of translation message for key `phones`.
   * Default value: `Phones`
   */
  phones: string;

  /**
   * Returns the value of translation message for key `addresses`.
   * Default value: `Addresses`
   */
  addresses: string;

  /**
   * Returns the value of translation message for key `contactInfos`.
   * Default value: `Additional contacts`
   */
  contactInfos: string;

  /**
   * Returns the value of translation message for key `confirmation`.
   * Default value: `Confirmation`
   */
  confirmation: string;

  /**
   * Returns the value of translation message for key `registration`.
   * Default value: `User registration`
   */
  registration: string;

  /**
   * Returns the value of translation message for key `registration.confirmation`.
   * Default value: `Registration confirmation`
   */
  registrationConfirmation: string;

  /**
   * Returns the value of translation message for key `registration.done`.
   * Default value: `Registration successful`
   */
  registrationDone: string;

  /**
   * Returns the value of translation message for key `contactList`.
   * Default value: `Contact list`
   */
  contactList: string;

  /**
   * Returns the value of translation message for key `addContact`.
   * Default value: `Add a new contact`
   */
  addContact: string;

  /**
   * Returns the value of translation message for key `search`.
   * Default value: `Users search`
   */
  search: string;

  /**
   * Returns the value of translation message for key `directory`.
   * Default value: `Business directory`
   */
  directory: string;

  /**
   * Returns the value of translation message for key `myOperators`.
   * Default value: `Operators`
   */
  myOperators: string;

  /**
   * Returns the value of translation message for key `userOperators`.
   * Default value: `User operators`
   */
  userOperators: string;

  /**
   * Returns the value of translation message for key `operatorRegistration`.
   * Default value: `Operator registration`
   */
  operatorRegistration: string;

  /**
   * Returns the value of translation message for key `myBrokerings`.
   * Default value: `Assigned members`
   */
  myBrokerings: string;

  /**
   * Returns the value of translation message for key `userBrokerings`.
   * Default value: `Broker assigned members`
   */
  userBrokerings: string;

}

export interface I18n$User$MobileTitle {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `myProfile`.
   * Default value: `My profile`
   */
  myProfile: string;

  /**
   * Returns the value of translation message for key `userProfile`.
   * Default value: `User profile`
   */
  userProfile: string;

  /**
   * Returns the value of translation message for key `editProfile`.
   * Default value: `Edit profile`
   */
  editProfile: string;

  /**
   * Returns the value of translation message for key `registration`.
   * Default value: `Registration`
   */
  registration: string;

  /**
   * Returns the value of translation message for key `registration.confirmation`.
   * Default value: `Confirmation`
   */
  registrationConfirmation: string;

  /**
   * Returns the value of translation message for key `registration.done`.
   * Default value: `Success`
   */
  registrationDone: string;

  /**
   * Returns the value of translation message for key `contactList`.
   * Default value: `Contacts`
   */
  contactList: string;

  /**
   * Returns the value of translation message for key `search`.
   * Default value: `Users`
   */
  search: string;

  /**
   * Returns the value of translation message for key `directory`.
   * Default value: `Directory`
   */
  directory: string;

  /**
   * Returns the value of translation message for key `myOperators`.
   * Default value: `Operators`
   */
  myOperators: string;

  /**
   * Returns the value of translation message for key `userOperators`.
   * Default value: `Operators`
   */
  userOperators: string;

  /**
   * Returns the value of translation message for key `operatorRegistration`.
   * Default value: `New operator`
   */
  operatorRegistration: string;

  /**
   * Returns the value of translation message for key `myBrokerings`.
   * Default value: `Assigned members`
   */
  myBrokerings: string;

  /**
   * Returns the value of translation message for key `userBrokerings`.
   * Default value: `Broker members`
   */
  userBrokerings: string;

}

export interface I18n$User$Profile {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `accountsSummary`.
   * Default value: `Accounts summary`
   */
  accountsSummary: string;

  /**
   * Returns the value of translation message for key `accountVisibility`.
   * Default value: `Show / hide accounts`
   */
  accountVisibility: string;

  /**
   * Returns the value of translation message for key `noPermission`.
   * Default value: `You don't have permission to view the profile of this user`
   */
  noPermission: string;

  /**
   * Returns the value of translation message for key `showActions`.
   * Default value: `Show actions`
   */
  showActions: string;

  /**
   * Returns the value of translation message for key `hideActions`.
   * Default value: `Hide actions`
   */
  hideActions: string;

  /**
   * Returns the value of translation message for key `banking`.
   * Default value: `Banking`
   */
  banking: string;

  /**
   * Returns the value of translation message for key `management`.
   * Default value: `Management`
   */
  management: string;

  /**
   * Returns the value of translation message for key `edit`.
   * Default value: `Edit profile`
   */
  edit: string;

  /**
   * Returns the value of translation message for key `managePasswords`.
   * Default value: `Manage passwords`
   */
  managePasswords: string;

  /**
   * Returns the value of translation message for key `viewIdentityProviders`.
   * Default value: `View identity providers`
   */
  viewIdentityProviders: string;

  /**
   * Returns the value of translation message for key `addContact`.
   * Default value: `Add to contacts`
   */
  addContact: string;

  /**
   * Returns the value of translation message for key `removeContact`.
   * Default value: `Remove from contacts`
   */
  removeContact: string;

  /**
   * Returns the value of translation message for key `pay`.
   * Default value: `Make payment`
   */
  pay: string;

  /**
   * Returns the value of translation message for key `paySystemToUser`.
   * Default value: `Payment system to user`
   */
  paySystemToUser: string;

  /**
   * Returns the value of translation message for key `payAsUserToSystem`.
   * Default value: `Payment user to system`
   */
  payAsUserToSystem: string;

  /**
   * Returns the value of translation message for key `payAsUserToUser`.
   * Default value: `Payment user to user`
   */
  payAsUserToUser: string;

  /**
   * Returns the value of translation message for key `payAsUserToSelf`.
   * Default value: `Payment user to self`
   */
  payAsUserToSelf: string;

  /**
   * Returns the value of translation message for key `requestPayment`.
   * Default value: `Request payment`
   */
  requestPayment: string;

  /**
   * Returns the value of translation message for key `purchases`.
   * Default value: `Purchases`
   */
  purchases: string;

  /**
   * Returns the value of translation message for key `sales`.
   * Default value: `Sales`
   */
  sales: string;

  /**
   * Returns the value of translation message for key `viewScheduledPayments`.
   * Default value: `View scheduled payments`
   */
  viewScheduledPayments: string;

  /**
   * Returns the value of translation message for key `viewAuthorizedPayments`.
   * Default value: `View payment authorizations`
   */
  viewAuthorizedPayments: string;

  /**
   * Returns the value of translation message for key `paymentRequests`.
   * Default value: `Payment requests`
   */
  paymentRequests: string;

  /**
   * Returns the value of translation message for key `externalPayments`.
   * Default value: `External payments`
   */
  externalPayments: string;

  /**
   * Returns the value of translation message for key `viewAds`.
   * Default value: `View advertisements`
   */
  viewAds: string;

  /**
   * Returns the value of translation message for key `viewBrokerings`.
   * Default value: `View assigned members`
   */
  viewBrokerings: string;

  /**
   * Returns the value of translation message for key `viewBrokers`.
   * Default value: `View brokers`
   */
  viewBrokers: string;

  /**
   * Returns the value of translation message for key `viewOperators`.
   * Default value: `View operators`
   */
  viewOperators: string;

  /**
   * Returns the value of translation message for key `viewOperatorGroups`.
   * Default value: `View operator groups`
   */
  viewOperatorGroups: string;

  /**
   * Returns the value of translation message for key `viewWebshop`.
   * Default value: `View web shop`
   */
  viewWebshop: string;

  /**
   * Returns the value of translation message for key `adInterests`.
   * Default value: `Ad interests`
   */
  adInterests: string;

  /**
   * Returns the value of translation message for key `deliveryMethods`.
   * Default value: `Delivery methods`
   */
  deliveryMethods: string;

  /**
   * Returns the value of translation message for key `webshopSettings`.
   * Default value: `Web shop settings`
   */
  webshopSettings: string;

  /**
   * Returns the value of translation message for key `status.user`.
   * Default value: `User status`
   */
  statusUser: string;

  /**
   * Returns the value of translation message for key `status.operator`.
   * Default value: `Operator status`
   */
  statusOperator: string;

  /**
   * Returns the value of translation message for key `group.user`.
   * Default value: `User group`
   */
  groupUser: string;

  /**
   * Returns the value of translation message for key `group.operator`.
   * Default value: `Operator group`
   */
  groupOperator: string;

  /**
   * Returns the value of translation message for key `agreements`.
   * Default value: `Agreements`
   */
  agreements: string;

  /**
   * Returns the value of translation message for key `vouchers`.
   * Default value: `View vouchers`
   */
  vouchers: string;

  /**
   * Returns the value of translation message for key `voucherBuy`.
   * Default value: `Buy voucher`
   */
  voucherBuy: string;

  /**
   * Returns the value of translation message for key `voucherSend`.
   * Default value: `Buy and email voucher`
   */
  voucherSend: string;

  /**
   * Returns the value of translation message for key `voucherTransactions`.
   * Default value: `View voucher transactions`
   */
  voucherTransactions: string;

  /**
   * Returns the value of translation message for key `voucherTransactionsRedeems`.
   * Default value: `View voucher redeems`
   */
  voucherTransactionsRedeems: string;

  /**
   * Returns the value of translation message for key `voucherRedeem`.
   * Default value: `Redeem voucher`
   */
  voucherRedeem: string;

  /**
   * Returns the value of translation message for key `voucherTopUp`.
   * Default value: `Top-up voucher`
   */
  voucherTopUp: string;

  /**
   * Returns the value of translation message for key `notificationSettings`.
   * Default value: `Notification settings`
   */
  notificationSettings: string;

  /**
   * Returns the value of translation message for key `products`.
   * Default value: `Products`
   */
  products: string;

  /**
   * Returns the value of translation message for key `accountsBalanceLimits`.
   * Default value: `Account balance limits`
   */
  accountsBalanceLimits: string;

  /**
   * Returns the value of translation message for key `accountsPaymentLimits`.
   * Default value: `Account payment limits`
   */
  accountsPaymentLimits: string;

  /**
   * Returns the value of translation message for key `privacySettings`.
   * Default value: `Privacy settings`
   */
  privacySettings: string;

  /**
   * Returns the value of translation message for key `references`.
   * Default value: `References`
   */
  references: string;

  /**
   * Returns the value of translation message for key `feedbacks`.
   * Default value: `Feedbacks`
   */
  feedbacks: string;

  /**
   * Returns the value of translation message for key `activityInPeriod`.
   * Default value: `Account activity (last 30 days)`
   */
  activityInPeriod: string;

  /**
   * Returns the value of translation message for key `activityAllTime`.
   * Default value: `Account activity (all time)`
   */
  activityAllTime: string;

  /**
   * Returns the value of translation message for key `sendMessage`.
   * Default value: `Send message`
   */
  sendMessage: string;


  /**
   * Returns the value of translation message for key `addContact.done`.
   * Default value: `{user} was added to your contact list`
   */
  addContactDone(user: string | number): string;


  /**
   * Returns the value of translation message for key `removeContact.done`.
   * Default value: `{user} was removed from your contact list`
   */
  removeContactDone(user: string | number): string;


  /**
   * Returns the value of translation message for key `viewAccount`.
   * Default value: `View {account}`
   */
  viewAccount(account: string | number): string;


  /**
   * Returns the value of translation message for key `balance`.
   * Default value: `{balance} (current)`
   */
  balance(balance: string | number): string;


  /**
   * Returns the value of translation message for key `availableBalance`.
   * Default value: `{balance} (available)`
   */
  availableBalance(balance: string | number): string;


  /**
   * Returns the value of translation message for key `transactions`.
   * Default value: `{transactions} transactions`
   */
  transactions(transactions: string | number): string;


  /**
   * Returns the value of translation message for key `totalReceived`.
   * Default value: `{received} (total received)`
   */
  totalReceived(received: string | number): string;


  /**
   * Returns the value of translation message for key `totalPaid`.
   * Default value: `{paid} (total paid)`
   */
  totalPaid(paid: string | number): string;

}

export interface I18n$User$Registration {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `group.public`.
   * Default value: `Choose the group you want to participate`
   */
  groupPublic: string;

  /**
   * Returns the value of translation message for key `group.manager`.
   * Default value: `Choose the group for the new user`
   */
  groupManager: string;

  /**
   * Returns the value of translation message for key `skipActivationEmail`.
   * Default value: `Skip activation e-mail`
   */
  skipActivationEmail: string;

  /**
   * Returns the value of translation message for key `password.assign`.
   * Default value: `Assign password`
   */
  passwordAssign: string;

  /**
   * Returns the value of translation message for key `password.forceChange`.
   * Default value: `Force user to change`
   */
  passwordForceChange: string;

  /**
   * Returns the value of translation message for key `active.public`.
   * Default value: `You have been successfully registered, and your account is now active.`
   */
  activePublic: string;

  /**
   * Returns the value of translation message for key `inactive.public`.
   * Default value: `Your account was successfully created.<br>However, you will need to be activated by the administration.<br>You will be notified when your account is active.`
   */
  inactivePublic: string;

  /**
   * Returns the value of translation message for key `pending.public`.
   * Default value: `Your registration has been submitted, and needs to be verified.<br>You should receive an e-mail shortly with instructions on how to activate your account.<br>If you don't receive the e-mail, make sure to check your spam / junk folder.`
   */
  pendingPublic: string;

  /**
   * Returns the value of translation message for key `principal.multiple.preface`.
   * Default value: `You can login with the following data:`
   */
  principalMultiplePreface: string;

  /**
   * Returns the value of translation message for key `generatedPasswords.none`.
   * Default value: `You can now login with the password you have informed.`
   */
  generatedPasswordsNone: string;

  /**
   * Returns the value of translation message for key `viewProfile`.
   * Default value: `View profile`
   */
  viewProfile: string;

  /**
   * Returns the value of translation message for key `registerAnotherUser`.
   * Default value: `Register another user`
   */
  registerAnotherUser: string;


  /**
   * Returns the value of translation message for key `principal.single`.
   * Default value: `You can use your {principal} ({value}) on {channels}`
   */
  principalSingle($: {principal: string | number, value: string | number, channels: string | number}): string;


  /**
   * Returns the value of translation message for key `principal.multiple.item`.
   * Default value: `<b>{principal}</b> ({value}): can be used on {channels}`
   */
  principalMultipleItem($: {principal: string | number, value: string | number, channels: string | number}): string;


  /**
   * Returns the value of translation message for key `active.manager`.
   * Default value: `The user's account for {user} has been activated.`
   */
  activeManager(user: string | number): string;


  /**
   * Returns the value of translation message for key `inactive.manager`.
   * Default value: `The user {user} has been registered, however, still needs activatation by the administration.`
   */
  inactiveManager(user: string | number): string;


  /**
   * Returns the value of translation message for key `pending.manager`.
   * Default value: `The user {user} has been registered, however, still needs to validate their registration by confirming the e-mail.`
   */
  pendingManager(user: string | number): string;


  /**
   * Returns the value of translation message for key `generatedPasswords.single`.
   * Default value: `You will receive an e-mail shortly with your generated {type}.`
   */
  generatedPasswordsSingle(type: string | number): string;


  /**
   * Returns the value of translation message for key `generatedPasswords.multiple`.
   * Default value: `You will receive an e-mail shortly with the following generated passwords: {types}.`
   */
  generatedPasswordsMultiple(types: string | number): string;

}

export interface I18n$User$OrderBy {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `alphabeticallyAsc`.
   * Default value: `Name (A-Z)`
   */
  alphabeticallyAsc: string;

  /**
   * Returns the value of translation message for key `alphabeticallyDesc`.
   * Default value: `Name (Z-A)`
   */
  alphabeticallyDesc: string;

}

export interface I18n$OperatorGroup {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `accountAccess`.
   * Default value: `Account visibility`
   */
  accountAccess: string;

  /**
   * Returns the value of translation message for key `accountAccess.full`.
   * Default value: `All payments`
   */
  accountAccessFull: string;

  /**
   * Returns the value of translation message for key `accountAccess.incoming`.
   * Default value: `Only incoming payments`
   */
  accountAccessIncoming: string;

  /**
   * Returns the value of translation message for key `accountAccess.outgoing`.
   * Default value: `Only outgoing payments`
   */
  accountAccessOutgoing: string;

  /**
   * Returns the value of translation message for key `accountAccess.own`.
   * Default value: `Received or paid by operator`
   */
  accountAccessOwn: string;

  /**
   * Returns the value of translation message for key `accountAccess.none`.
   * Default value: `Not visible`
   */
  accountAccessNone: string;

  /**
   * Returns the value of translation message for key `performPayments`.
   * Default value: `Perform payments`
   */
  performPayments: string;

  /**
   * Returns the value of translation message for key `performPayments.requiresAuthorization.view`.
   * Default value: `, requires authorization`
   */
  performPaymentsRequiresAuthorizationView: string;

  /**
   * Returns the value of translation message for key `performPayments.requiresAuthorization`.
   * Default value: `Requires authorization`
   */
  performPaymentsRequiresAuthorization: string;

  /**
   * Returns the value of translation message for key `performPayments.maxAmountPerDay`.
   * Default value: `Daily limit`
   */
  performPaymentsMaxAmountPerDay: string;

  /**
   * Returns the value of translation message for key `authorizePayments`.
   * Default value: `Authorize payments`
   */
  authorizePayments: string;

  /**
   * Returns the value of translation message for key `paymentNotifications`.
   * Default value: `Payment notifications`
   */
  paymentNotifications: string;

  /**
   * Returns the value of translation message for key `paymentNotifications.above`.
   * Default value: `Only notify payments above`
   */
  paymentNotificationsAbove: string;

  /**
   * Returns the value of translation message for key `paymentNotifications.below`.
   * Default value: `Only notify payments below`
   */
  paymentNotificationsBelow: string;

  /**
   * Returns the value of translation message for key `chargebackPayments`.
   * Default value: `Chargeback payments`
   */
  chargebackPayments: string;

  /**
   * Returns the value of translation message for key `receivePayments`.
   * Default value: `Receive payments`
   */
  receivePayments: string;

  /**
   * Returns the value of translation message for key `requestPayments`.
   * Default value: `Request payments`
   */
  requestPayments: string;

  /**
   * Returns the value of translation message for key `voucherTransactions`.
   * Default value: `Voucher transactions`
   */
  voucherTransactions: string;

  /**
   * Returns the value of translation message for key `voucherTransactionsRedeems`.
   * Default value: `Voucher redeems`
   */
  voucherTransactionsRedeems: string;

  /**
   * Returns the value of translation message for key `restrictPaymentsToUsers`.
   * Default value: `Restrict payments to`
   */
  restrictPaymentsToUsers: string;

  /**
   * Returns the value of translation message for key `editOwnProfile`.
   * Default value: `Edit own profile`
   */
  editOwnProfile: string;

  /**
   * Returns the value of translation message for key `notifications`.
   * Default value: `Notifications`
   */
  notifications: string;

  /**
   * Returns the value of translation message for key `viewAdvertisements`.
   * Default value: `View advertisements`
   */
  viewAdvertisements: string;

  /**
   * Returns the value of translation message for key `manageAdvertisements`.
   * Default value: `Manage advertisements`
   */
  manageAdvertisements: string;

  /**
   * Returns the value of translation message for key `messages`.
   * Default value: `View / send messages`
   */
  messages: string;

  /**
   * Returns the value of translation message for key `records`.
   * Default value: `Records`
   */
  records: string;

  /**
   * Returns the value of translation message for key `brokering`.
   * Default value: `Perform brokering operations`
   */
  brokering: string;

  /**
   * Returns the value of translation message for key `runOperations`.
   * Default value: `Run operations`
   */
  runOperations: string;

  /**
   * Returns the value of translation message for key `created`.
   * Default value: `The operator group was created`
   */
  created: string;

  /**
   * Returns the value of translation message for key `saved`.
   * Default value: `The operator group was saved`
   */
  saved: string;


  /**
   * Returns the value of translation message for key `performPayments.maxAmountPerDay.view`.
   * Default value: `, max. {max} per day`
   */
  performPaymentsMaxAmountPerDayView(max: string | number): string;


  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  title: I18n$OperatorGroup$Title;


  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  mobileTitle: I18n$OperatorGroup$MobileTitle;


  /**
   * Returns the nested accessor for translation messages in `tokens`.
   */
  tokens: I18n$OperatorGroup$Tokens;

}

export interface I18n$OperatorGroup$Title {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `list`.
   * Default value: `Operator groups`
   */
  list: string;

  /**
   * Returns the value of translation message for key `view`.
   * Default value: `Operator group`
   */
  view: string;

  /**
   * Returns the value of translation message for key `new`.
   * Default value: `New operator group`
   */
  new: string;

  /**
   * Returns the value of translation message for key `edit`.
   * Default value: `Edit operator group`
   */
  edit: string;

  /**
   * Returns the value of translation message for key `general`.
   * Default value: `General`
   */
  general: string;

  /**
   * Returns the value of translation message for key `accountAccess`.
   * Default value: `Account access`
   */
  accountAccess: string;

  /**
   * Returns the value of translation message for key `generalAccount`.
   * Default value: `General account operations`
   */
  generalAccount: string;

}

export interface I18n$OperatorGroup$MobileTitle {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `list`.
   * Default value: `Operator groups`
   */
  list: string;

  /**
   * Returns the value of translation message for key `view`.
   * Default value: `Operator group`
   */
  view: string;

  /**
   * Returns the value of translation message for key `new`.
   * Default value: `New group`
   */
  new: string;

  /**
   * Returns the value of translation message for key `edit`.
   * Default value: `Edit group`
   */
  edit: string;

  /**
   * Returns the value of translation message for key `general`.
   * Default value: `General`
   */
  general: string;

  /**
   * Returns the value of translation message for key `accountAccess`.
   * Default value: `Account access`
   */
  accountAccess: string;

  /**
   * Returns the value of translation message for key `generalAccount`.
   * Default value: `General operations`
   */
  generalAccount: string;

}

export interface I18n$OperatorGroup$Tokens {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `label`.
   * Default value: `Own tokens / cards`
   */
  label: string;

  /**
   * Returns the value of translation message for key `enable`.
   * Default value: `Enable`
   */
  enable: string;

  /**
   * Returns the value of translation message for key `block`.
   * Default value: `Block`
   */
  block: string;

  /**
   * Returns the value of translation message for key `unblock`.
   * Default value: `Unblock`
   */
  unblock: string;

  /**
   * Returns the value of translation message for key `cancel`.
   * Default value: `Cancel`
   */
  cancel: string;

}

export interface I18n$UserStatus {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `current`.
   * Default value: `Current status`
   */
  current: string;

  /**
   * Returns the value of translation message for key `new`.
   * Default value: `New status`
   */
  new: string;

  /**
   * Returns the value of translation message for key `active`.
   * Default value: `Active`
   */
  active: string;

  /**
   * Returns the value of translation message for key `blocked`.
   * Default value: `Access blocked`
   */
  blocked: string;

  /**
   * Returns the value of translation message for key `disabled`.
   * Default value: `Disabled`
   */
  disabled: string;

  /**
   * Returns the value of translation message for key `pending`.
   * Default value: `Pending activation`
   */
  pending: string;

  /**
   * Returns the value of translation message for key `purged`.
   * Default value: `Purged (all private data is erased)`
   */
  purged: string;

  /**
   * Returns the value of translation message for key `removed`.
   * Default value: `Removed (no private data is erased)`
   */
  removed: string;


  /**
   * Returns the nested accessor for translation messages in `confirm`.
   */
  confirm: I18n$UserStatus$Confirm;


  /**
   * Returns the nested accessor for translation messages in `done`.
   */
  done: I18n$UserStatus$Done;


  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  title: I18n$UserStatus$Title;


  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  mobileTitle: I18n$UserStatus$MobileTitle;

}

export interface I18n$UserStatus$Confirm {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;


  /**
   * Returns the value of translation message for key `active`.
   * Default value: `Are you sure to activate {user}?`
   */
  active(user: string | number): string;


  /**
   * Returns the value of translation message for key `blocked`.
   * Default value: `Are you sure to block access to {user}?`
   */
  blocked(user: string | number): string;


  /**
   * Returns the value of translation message for key `disabled`.
   * Default value: `Are you sure to disable {user}?`
   */
  disabled(user: string | number): string;


  /**
   * Returns the value of translation message for key `purged`.
   * Default value: `Are you sure to permanently remove {user} and remove all private data?\nThis action is irreversible!`
   */
  purged(user: string | number): string;


  /**
   * Returns the value of translation message for key `removed`.
   * Default value: `Are you sure to permanently remove {user}?\nThis action is irreversible!`
   */
  removed(user: string | number): string;

}

export interface I18n$UserStatus$Done {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;


  /**
   * Returns the value of translation message for key `active`.
   * Default value: `{user} was activated`
   */
  active(user: string | number): string;


  /**
   * Returns the value of translation message for key `blocked`.
   * Default value: `{user} was blocked`
   */
  blocked(user: string | number): string;


  /**
   * Returns the value of translation message for key `disabled`.
   * Default value: `{user} was disabled`
   */
  disabled(user: string | number): string;


  /**
   * Returns the value of translation message for key `purged`.
   * Default value: `{user} was permanently removed, together with all personal data`
   */
  purged(user: string | number): string;


  /**
   * Returns the value of translation message for key `removed`.
   * Default value: `{user} was permanently removed`
   */
  removed(user: string | number): string;

}

export interface I18n$UserStatus$Title {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `status.user`.
   * Default value: `User status`
   */
  statusUser: string;

  /**
   * Returns the value of translation message for key `status.operator`.
   * Default value: `Operator status`
   */
  statusOperator: string;

  /**
   * Returns the value of translation message for key `change.user`.
   * Default value: `Change user status`
   */
  changeUser: string;

  /**
   * Returns the value of translation message for key `change.operator`.
   * Default value: `Change operator status`
   */
  changeOperator: string;

  /**
   * Returns the value of translation message for key `history.user`.
   * Default value: `User status history`
   */
  historyUser: string;

  /**
   * Returns the value of translation message for key `history.operator`.
   * Default value: `Operator status history`
   */
  historyOperator: string;

}

export interface I18n$UserStatus$MobileTitle {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `status.user`.
   * Default value: `User status`
   */
  statusUser: string;

  /**
   * Returns the value of translation message for key `status.operator`.
   * Default value: `Operator status`
   */
  statusOperator: string;

  /**
   * Returns the value of translation message for key `change.user`.
   * Default value: `Change status`
   */
  changeUser: string;

  /**
   * Returns the value of translation message for key `change.operator`.
   * Default value: `Change status`
   */
  changeOperator: string;

  /**
   * Returns the value of translation message for key `history.user`.
   * Default value: `Status history`
   */
  historyUser: string;

  /**
   * Returns the value of translation message for key `history.operator`.
   * Default value: `Status history`
   */
  historyOperator: string;

}

export interface I18n$Agreements {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `agreement`.
   * Default value: `Agreement`
   */
  agreement: string;

  /**
   * Returns the value of translation message for key `required`.
   * Default value: `Required agreements`
   */
  required: string;

  /**
   * Returns the value of translation message for key `optional`.
   * Default value: `Optional agreements`
   */
  optional: string;

  /**
   * Returns the value of translation message for key `optional.saved`.
   * Default value: `The selected agreements have been saved`
   */
  optionalSaved: string;

  /**
   * Returns the value of translation message for key `accepted`.
   * Default value: `Accepted`
   */
  accepted: string;

  /**
   * Returns the value of translation message for key `noAccepted`.
   * Default value: `No accepted agreements`
   */
  noAccepted: string;

  /**
   * Returns the value of translation message for key `message`.
   * Default value: `These are the agreements you have accepted. Click on the agreement name for more details.`
   */
  message: string;


  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  title: I18n$Agreements$Title;


  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  mobileTitle: I18n$Agreements$MobileTitle;

}

export interface I18n$Agreements$Title {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `accepted`.
   * Default value: `Accepted agreements`
   */
  accepted: string;

  /**
   * Returns the value of translation message for key `history`.
   * Default value: `Agreements history`
   */
  history: string;

}

export interface I18n$Agreements$MobileTitle {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `accepted`.
   * Default value: `Agreements`
   */
  accepted: string;

  /**
   * Returns the value of translation message for key `history`.
   * Default value: `Agreements history`
   */
  history: string;

}

export interface I18n$GroupMembership {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `current`.
   * Default value: `Current group`
   */
  current: string;

  /**
   * Returns the value of translation message for key `new`.
   * Default value: `New group`
   */
  new: string;

  /**
   * Returns the value of translation message for key `done.user`.
   * Default value: `The user group was changed`
   */
  doneUser: string;

  /**
   * Returns the value of translation message for key `done.operator`.
   * Default value: `The operator group was changed`
   */
  doneOperator: string;


  /**
   * Returns the value of translation message for key `confirm`.
   * Default value: `Are you sure to change {user} to group {group}?`
   */
  confirm($: {user: string | number, group: string | number}): string;


  /**
   * Returns the value of translation message for key `confirm.aliasOperator`.
   * Default value: `Are you sure to grant {user} the same permissions as the owner?`
   */
  confirmAliasOperator(user: string | number): string;


  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  title: I18n$GroupMembership$Title;


  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  mobileTitle: I18n$GroupMembership$MobileTitle;

}

export interface I18n$GroupMembership$Title {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `group.user`.
   * Default value: `User group`
   */
  groupUser: string;

  /**
   * Returns the value of translation message for key `group.operator`.
   * Default value: `Operator group`
   */
  groupOperator: string;

  /**
   * Returns the value of translation message for key `history.user`.
   * Default value: `User group history`
   */
  historyUser: string;

  /**
   * Returns the value of translation message for key `history.operator`.
   * Default value: `Operator group history`
   */
  historyOperator: string;

}

export interface I18n$GroupMembership$MobileTitle {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `group.user`.
   * Default value: `User group`
   */
  groupUser: string;

  /**
   * Returns the value of translation message for key `group.operator`.
   * Default value: `Operator group`
   */
  groupOperator: string;

  /**
   * Returns the value of translation message for key `history.user`.
   * Default value: `Group history`
   */
  historyUser: string;

  /**
   * Returns the value of translation message for key `history.operator`.
   * Default value: `Group history`
   */
  historyOperator: string;

}

export interface I18n$Brokers {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `mainBroker`.
   * Default value: `Main broker`
   */
  mainBroker: string;

  /**
   * Returns the value of translation message for key `setMain`.
   * Default value: `Set main`
   */
  setMain: string;

  /**
   * Returns the value of translation message for key `broker`.
   * Default value: `Broker`
   */
  broker: string;

  /**
   * Returns the value of translation message for key `brokerAdded`.
   * Default value: `The broker has been added successfully`
   */
  brokerAdded: string;


  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  title: I18n$Brokers$Title;


  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  mobileTitle: I18n$Brokers$MobileTitle;


  /**
   * Returns the nested accessor for translation messages in `action`.
   */
  action: I18n$Brokers$Action;

}

export interface I18n$Brokers$Title {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `list`.
   * Default value: `Brokers`
   */
  list: string;

  /**
   * Returns the value of translation message for key `new`.
   * Default value: `Add broker`
   */
  new: string;

  /**
   * Returns the value of translation message for key `history`.
   * Default value: `Brokers history`
   */
  history: string;

}

export interface I18n$Brokers$MobileTitle {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `list`.
   * Default value: `Brokers`
   */
  list: string;

  /**
   * Returns the value of translation message for key `new`.
   * Default value: `Add broker`
   */
  new: string;

  /**
   * Returns the value of translation message for key `history`.
   * Default value: `Brokers history`
   */
  history: string;

}

export interface I18n$Brokers$Action {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `add`.
   * Default value: `Added as broker`
   */
  add: string;

  /**
   * Returns the value of translation message for key `remove`.
   * Default value: `Removed a broker`
   */
  remove: string;

  /**
   * Returns the value of translation message for key `setMain`.
   * Default value: `Set as main`
   */
  setMain: string;

}

export interface I18n$Phone {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `mobile`.
   * Default value: `Mobile phone`
   */
  mobile: string;

  /**
   * Returns the value of translation message for key `landLine`.
   * Default value: `Land-line phone`
   */
  landLine: string;

  /**
   * Returns the value of translation message for key `name.mobile`.
   * Default value: `Mobile phone name`
   */
  nameMobile: string;

  /**
   * Returns the value of translation message for key `name.landLine`.
   * Default value: `Land-line phone name`
   */
  nameLandLine: string;

  /**
   * Returns the value of translation message for key `number.mobile`.
   * Default value: `Mobile phone number`
   */
  numberMobile: string;

  /**
   * Returns the value of translation message for key `number.landLine`.
   * Default value: `Land-line phone number`
   */
  numberLandLine: string;

  /**
   * Returns the value of translation message for key `phoneNumber`.
   * Default value: `Phone number`
   */
  phoneNumber: string;

  /**
   * Returns the value of translation message for key `extension`.
   * Default value: `Land-line extension`
   */
  extension: string;

  /**
   * Returns the value of translation message for key `enabledSms`.
   * Default value: `Enabled for SMS`
   */
  enabledSms: string;

  /**
   * Returns the value of translation message for key `verified`.
   * Default value: `Verified`
   */
  verified: string;


  /**
   * Returns the value of translation message for key `numberExtensionValue`.
   * Default value: `{number} ext. {extension}`
   */
  numberExtensionValue($: {number: string | number, extension: string | number}): string;


  /**
   * Returns the nested accessor for translation messages in `error`.
   */
  error: I18n$Phone$Error;


  /**
   * Returns the nested accessor for translation messages in `verify`.
   */
  verify: I18n$Phone$Verify;

}

export interface I18n$Phone$Error {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;


  /**
   * Returns the nested accessor for translation messages in `verify`.
   */
  verify: I18n$Phone$Error$Verify;

}

export interface I18n$Phone$Error$Verify {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `expired`.
   * Default value: `The verification code was not sent or has expired.<br> Please, send the code again to your phone and restart the process.`
   */
  expired: string;

  /**
   * Returns the value of translation message for key `invalid`.
   * Default value: `Invalid verification code`
   */
  invalid: string;

  /**
   * Returns the value of translation message for key `maxAttempts`.
   * Default value: `You have exceeded the number of allowed attempts.<br>Please, send the code again to your phone and restart the process.`
   */
  maxAttempts: string;

}

export interface I18n$Phone$Verify {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `code`.
   * Default value: `Code`
   */
  code: string;

  /**
   * Returns the value of translation message for key `send`.
   * Default value: `Send`
   */
  send: string;

  /**
   * Returns the value of translation message for key `message`.
   * Default value: `Click the button above to send the verification code to your phone`
   */
  message: string;


  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Verify {number} for SMS`
   */
  title(number: string | number): string;


  /**
   * Returns the value of translation message for key `sendDisabled`.
   * Default value: `Sent ({seconds})`
   */
  sendDisabled(seconds: string | number): string;


  /**
   * Returns the value of translation message for key `done`.
   * Default value: `The verification code was sent to {number}`
   */
  done(number: string | number): string;

}

export interface I18n$Address {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `address`.
   * Default value: `Address`
   */
  address: string;

  /**
   * Returns the value of translation message for key `line1`.
   * Default value: `Address line 1`
   */
  line1: string;

  /**
   * Returns the value of translation message for key `line2`.
   * Default value: `Address line 2`
   */
  line2: string;

  /**
   * Returns the value of translation message for key `buildingNumber`.
   * Default value: `Building number`
   */
  buildingNumber: string;

  /**
   * Returns the value of translation message for key `city`.
   * Default value: `City`
   */
  city: string;

  /**
   * Returns the value of translation message for key `complement`.
   * Default value: `Complement`
   */
  complement: string;

  /**
   * Returns the value of translation message for key `country`.
   * Default value: `Country`
   */
  country: string;

  /**
   * Returns the value of translation message for key `neighborhood`.
   * Default value: `Neighborhood`
   */
  neighborhood: string;

  /**
   * Returns the value of translation message for key `poBox`.
   * Default value: `Post-office box`
   */
  poBox: string;

  /**
   * Returns the value of translation message for key `region`.
   * Default value: `Region / state`
   */
  region: string;

  /**
   * Returns the value of translation message for key `street`.
   * Default value: `Street`
   */
  street: string;

  /**
   * Returns the value of translation message for key `zip`.
   * Default value: `Zip code`
   */
  zip: string;

}

export interface I18n$Ad {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `accept`.
   * Default value: `Accept`
   */
  accept: string;

  /**
   * Returns the value of translation message for key `acceptOrder`.
   * Default value: `Accept order`
   */
  acceptOrder: string;

  /**
   * Returns the value of translation message for key `addedProduct`.
   * Default value: `The product was added to cart`
   */
  addedProduct: string;

  /**
   * Returns the value of translation message for key `addedProductOrder`.
   * Default value: `The product was added to the order`
   */
  addedProductOrder: string;

  /**
   * Returns the value of translation message for key `addedProductAlreadyInCart`.
   * Default value: `The product was added to cart (this item has been added previously to your cart)`
   */
  addedProductAlreadyInCart: string;

  /**
   * Returns the value of translation message for key `addToCart`.
   * Default value: `Add to cart`
   */
  addToCart: string;

  /**
   * Returns the value of translation message for key `adInterestCreated`.
   * Default value: `The ad interest was created`
   */
  adInterestCreated: string;

  /**
   * Returns the value of translation message for key `adInterestSaved`.
   * Default value: `The ad interest was saved`
   */
  adInterestSaved: string;

  /**
   * Returns the value of translation message for key `addProducts`.
   * Default value: `Add products`
   */
  addProducts: string;

  /**
   * Returns the value of translation message for key `adSaved`.
   * Default value: `The advertisement was saved`
   */
  adSaved: string;

  /**
   * Returns the value of translation message for key `adHidden`.
   * Default value: `The advertisement has been hidden`
   */
  adHidden: string;

  /**
   * Returns the value of translation message for key `adUnhidden`.
   * Default value: `The advertisement is now visible`
   */
  adUnhidden: string;

  /**
   * Returns the value of translation message for key `allowDecimalQuantity`.
   * Default value: `Allow decimal quantity`
   */
  allowDecimalQuantity: string;

  /**
   * Returns the value of translation message for key `answer`.
   * Default value: `Answer`
   */
  answer: string;

  /**
   * Returns the value of translation message for key `authorize`.
   * Default value: `Authorize`
   */
  authorize: string;

  /**
   * Returns the value of translation message for key `authorized`.
   * Default value: `The advertisement has been authorized`
   */
  authorized: string;

  /**
   * Returns the value of translation message for key `askQuestion`.
   * Default value: `Ask a question`
   */
  askQuestion: string;

  /**
   * Returns the value of translation message for key `available`.
   * Default value: `Available`
   */
  available: string;

  /**
   * Returns the value of translation message for key `notAvailable`.
   * Default value: `Not available`
   */
  notAvailable: string;

  /**
   * Returns the value of translation message for key `backToDraft`.
   * Default value: `The advertisement is now in draft mode`
   */
  backToDraft: string;

  /**
   * Returns the value of translation message for key `basePrice`.
   * Default value: `Base price`
   */
  basePrice: string;

  /**
   * Returns the value of translation message for key `beginDate`.
   * Default value: `Published begin date`
   */
  beginDate: string;

  /**
   * Returns the value of translation message for key `cartEmpty`.
   * Default value: `This cart is empty. Search marketplace for products and services to buy`
   */
  cartEmpty: string;

  /**
   * Returns the value of translation message for key `checkout`.
   * Default value: `Checkout`
   */
  checkout: string;

  /**
   * Returns the value of translation message for key `changeQuantity`.
   * Default value: `Change quantity`
   */
  changeQuantity: string;

  /**
   * Returns the value of translation message for key `chargeType`.
   * Default value: `Charge type`
   */
  chargeType: string;

  /**
   * Returns the value of translation message for key `chooseSeller`.
   * Default value: `Choose a seller from the list below to continue with the shopping cart process`
   */
  chooseSeller: string;

  /**
   * Returns the value of translation message for key `chooseDeliveryMethod`.
   * Default value: `Choose delivery method`
   */
  chooseDeliveryMethod: string;

  /**
   * Returns the value of translation message for key `chooseDeliveryAddress`.
   * Default value: `Choose delivery address`
   */
  chooseDeliveryAddress: string;

  /**
   * Returns the value of translation message for key `choosePaymentType`.
   * Default value: `Choose payment type`
   */
  choosePaymentType: string;

  /**
   * Returns the value of translation message for key `customAddress`.
   * Default value: `Custom address`
   */
  customAddress: string;

  /**
   * Returns the value of translation message for key `customDeliveryMethod`.
   * Default value: `Custom delivery method`
   */
  customDeliveryMethod: string;

  /**
   * Returns the value of translation message for key `customQuantity`.
   * Default value: `Custom quantity...`
   */
  customQuantity: string;

  /**
   * Returns the value of translation message for key `discount`.
   * Default value: `Discount %`
   */
  discount: string;

  /**
   * Returns the value of translation message for key `endDate`.
   * Default value: `Published end date`
   */
  endDate: string;

  /**
   * Returns the value of translation message for key `deliveryAddress`.
   * Default value: `Delivery address`
   */
  deliveryAddress: string;

  /**
   * Returns the value of translation message for key `deliveryInformation`.
   * Default value: `Delivery information`
   */
  deliveryInformation: string;

  /**
   * Returns the value of translation message for key `deliveryPrice`.
   * Default value: `Delivery price`
   */
  deliveryPrice: string;

  /**
   * Returns the value of translation message for key `deliveryMethod`.
   * Default value: `Delivery method`
   */
  deliveryMethod: string;

  /**
   * Returns the value of translation message for key `deliveryMethods`.
   * Default value: `Delivery methods`
   */
  deliveryMethods: string;

  /**
   * Returns the value of translation message for key `deliveryPriceToBeConfirmed`.
   * Default value: `The delivery price is not yet known and the total amount is not the final one. After the seller has set the delivery price, you still can reject or confirm the order.`
   */
  deliveryPriceToBeConfirmed: string;

  /**
   * Returns the value of translation message for key `deliveryMethodCreated`.
   * Default value: `The delivery method was created`
   */
  deliveryMethodCreated: string;

  /**
   * Returns the value of translation message for key `deliveryMethodSaved`.
   * Default value: `The delivery method was saved`
   */
  deliveryMethodSaved: string;

  /**
   * Returns the value of translation message for key `deliveryType`.
   * Default value: `Delivery type`
   */
  deliveryType: string;

  /**
   * Returns the value of translation message for key `deliver`.
   * Default value: `Deliver`
   */
  deliver: string;

  /**
   * Returns the value of translation message for key `pickup`.
   * Default value: `Pickup point`
   */
  pickup: string;

  /**
   * Returns the value of translation message for key `pickupAddress`.
   * Default value: `Pickup address`
   */
  pickupAddress: string;

  /**
   * Returns the value of translation message for key `fixed`.
   * Default value: `Fixed`
   */
  fixed: string;

  /**
   * Returns the value of translation message for key `hasImages`.
   * Default value: `With images`
   */
  hasImages: string;

  /**
   * Returns the value of translation message for key `noImages`.
   * Default value: `No images for this advertisement`
   */
  noImages: string;

  /**
   * Returns the value of translation message for key `item`.
   * Default value: `Item`
   */
  item: string;

  /**
   * Returns the value of translation message for key `items`.
   * Default value: `Items`
   */
  items: string;

  /**
   * Returns the value of translation message for key `itemsNone`.
   * Default value: `There are currently no products`
   */
  itemsNone: string;

  /**
   * Returns the value of translation message for key `itemNotAvailable`.
   * Default value: `Not available`
   */
  itemNotAvailable: string;

  /**
   * Returns the value of translation message for key `itemsNoLongerAvailable`.
   * Default value: `- are no longer available.<br>`
   */
  itemsNoLongerAvailable: string;

  /**
   * Returns the value of translation message for key `itemsOutOfStock`.
   * Default value: `- are out of stock.<br>`
   */
  itemsOutOfStock: string;

  /**
   * Returns the value of translation message for key `minPrice`.
   * Default value: `Min. price`
   */
  minPrice: string;

  /**
   * Returns the value of translation message for key `maxPrice`.
   * Default value: `Max. price`
   */
  maxPrice: string;

  /**
   * Returns the value of translation message for key `minDeliveryTime`.
   * Default value: `Min. delivery time`
   */
  minDeliveryTime: string;

  /**
   * Returns the value of translation message for key `maxDeliveryTime`.
   * Default value: `Max. delivery time`
   */
  maxDeliveryTime: string;

  /**
   * Returns the value of translation message for key `matchFields`.
   * Default value: `Match the following ad fields`
   */
  matchFields: string;

  /**
   * Returns the value of translation message for key `name`.
   * Default value: `Title`
   */
  name: string;

  /**
   * Returns the value of translation message for key `negotiated`.
   * Default value: `Negotiated`
   */
  negotiated: string;

  /**
   * Returns the value of translation message for key `noDeliveryMethodsAvailable`.
   * Default value: `No delivery methods for the selected currency`
   */
  noDeliveryMethodsAvailable: string;

  /**
   * Returns the value of translation message for key `owner`.
   * Default value: `Publisher`
   */
  owner: string;

  /**
   * Returns the value of translation message for key `price`.
   * Default value: `Price`
   */
  price: string;

  /**
   * Returns the value of translation message for key `questionAsked`.
   * Default value: `The question has been submitted to the seller`
   */
  questionAsked: string;

  /**
   * Returns the value of translation message for key `questionRemoved`.
   * Default value: `The question was removed`
   */
  questionRemoved: string;

  /**
   * Returns the value of translation message for key `categories`.
   * Default value: `Categories`
   */
  categories: string;

  /**
   * Returns the value of translation message for key `creationDate`.
   * Default value: `Creation date`
   */
  creationDate: string;

  /**
   * Returns the value of translation message for key `showAllCategories`.
   * Default value: `Show all`
   */
  showAllCategories: string;

  /**
   * Returns the value of translation message for key `showCategories`.
   * Default value: `Show categories`
   */
  showCategories: string;

  /**
   * Returns the value of translation message for key `rootCategory`.
   * Default value: `Main`
   */
  rootCategory: string;

  /**
   * Returns the value of translation message for key `orderAccepted`.
   * Default value: `The order was processed`
   */
  orderAccepted: string;

  /**
   * Returns the value of translation message for key `orderRejected`.
   * Default value: `The order was rejected`
   */
  orderRejected: string;

  /**
   * Returns the value of translation message for key `orderSavedAsDraft`.
   * Default value: `The order was saved as draft`
   */
  orderSavedAsDraft: string;

  /**
   * Returns the value of translation message for key `orderSubmittedToBuyer`.
   * Default value: `The order was submitted to buyer`
   */
  orderSubmittedToBuyer: string;

  /**
   * Returns the value of translation message for key `publicationPeriod`.
   * Default value: `Publication period`
   */
  publicationPeriod: string;

  /**
   * Returns the value of translation message for key `productNumberMask`.
   * Default value: `Mask`
   */
  productNumberMask: string;

  /**
   * Returns the value of translation message for key `promotionalPeriod`.
   * Default value: `Promotional period`
   */
  promotionalPeriod: string;

  /**
   * Returns the value of translation message for key `promotionalPrice`.
   * Default value: `Promotional price`
   */
  promotionalPrice: string;

  /**
   * Returns the value of translation message for key `products`.
   * Default value: `Products`
   */
  products: string;

  /**
   * Returns the value of translation message for key `product`.
   * Default value: `Product`
   */
  product: string;

  /**
   * Returns the value of translation message for key `generated`.
   * Default value: `Generated`
   */
  generated: string;

  /**
   * Returns the value of translation message for key `manual`.
   * Default value: `Manual`
   */
  manual: string;

  /**
   * Returns the value of translation message for key `number`.
   * Default value: `Number`
   */
  number: string;

  /**
   * Returns the value of translation message for key `orderNumber`.
   * Default value: `Order number`
   */
  orderNumber: string;

  /**
   * Returns the value of translation message for key `prefix`.
   * Default value: `Prefix`
   */
  prefix: string;

  /**
   * Returns the value of translation message for key `length`.
   * Default value: `Length`
   */
  length: string;

  /**
   * Returns the value of translation message for key `suffix`.
   * Default value: `Suffix`
   */
  suffix: string;

  /**
   * Returns the value of translation message for key `quantity`.
   * Default value: `Quantity`
   */
  quantity: string;

  /**
   * Returns the value of translation message for key `question`.
   * Default value: `Question`
   */
  question: string;

  /**
   * Returns the value of translation message for key `questionAnswered`.
   * Default value: `The answer was submitted`
   */
  questionAnswered: string;

  /**
   * Returns the value of translation message for key `reject`.
   * Default value: `Reject`
   */
  reject: string;

  /**
   * Returns the value of translation message for key `rejectOrder`.
   * Default value: `Reject order`
   */
  rejectOrder: string;

  /**
   * Returns the value of translation message for key `rejected`.
   * Default value: `The advertisement has been rejected`
   */
  rejected: string;

  /**
   * Returns the value of translation message for key `showAddressOnMap`.
   * Default value: `Show address on map`
   */
  showAddressOnMap: string;

  /**
   * Returns the value of translation message for key `saveAndInsertNew`.
   * Default value: `Save and insert new`
   */
  saveAndInsertNew: string;

  /**
   * Returns the value of translation message for key `saveDraft`.
   * Default value: `Save draft`
   */
  saveDraft: string;

  /**
   * Returns the value of translation message for key `setAsDraft`.
   * Default value: `Set as draft to edit`
   */
  setAsDraft: string;

  /**
   * Returns the value of translation message for key `setPromotionalPeriod`.
   * Default value: `Set promotional period`
   */
  setPromotionalPeriod: string;

  /**
   * Returns the value of translation message for key `submitForAuthorization`.
   * Default value: `Submit for authorization`
   */
  submitForAuthorization: string;

  /**
   * Returns the value of translation message for key `submitToBuyer`.
   * Default value: `Submit to buyer`
   */
  submitToBuyer: string;

  /**
   * Returns the value of translation message for key `submitToBuyerConfirmation`.
   * Default value: `Are you sure to submit this order to the buyer? After this step, no further changes will be allowed in this order, and it will be awaiting for buyer's approval.`
   */
  submitToBuyerConfirmation: string;

  /**
   * Returns the value of translation message for key `subtotal`.
   * Default value: `Subtotal`
   */
  subtotal: string;

  /**
   * Returns the value of translation message for key `stockType`.
   * Default value: `Stock type`
   */
  stockType: string;

  /**
   * Returns the value of translation message for key `pendingForAuth`.
   * Default value: `The advertisement has been submitted for further authorization`
   */
  pendingForAuth: string;

  /**
   * Returns the value of translation message for key `hide`.
   * Default value: `Hide`
   */
  hide: string;

  /**
   * Returns the value of translation message for key `unhide`.
   * Default value: `Unhide`
   */
  unhide: string;

  /**
   * Returns the value of translation message for key `setDeliveryMethod`.
   * Default value: `Set delivery method`
   */
  setDeliveryMethod: string;

  /**
   * Returns the value of translation message for key `lowStockNotification`.
   * Default value: `Low stock notification`
   */
  lowStockNotification: string;

  /**
   * Returns the value of translation message for key `stockQuantity`.
   * Default value: `In stock quantity`
   */
  stockQuantity: string;

  /**
   * Returns the value of translation message for key `unlimited`.
   * Default value: `Unlimited`
   */
  unlimited: string;

  /**
   * Returns the value of translation message for key `unitPrice`.
   * Default value: `Unit price`
   */
  unitPrice: string;

  /**
   * Returns the value of translation message for key `minAllowedInCart`.
   * Default value: `Minimum allowed in cart`
   */
  minAllowedInCart: string;

  /**
   * Returns the value of translation message for key `maxAllowedInCart`.
   * Default value: `Maximum allowed in cart`
   */
  maxAllowedInCart: string;

  /**
   * Returns the value of translation message for key `productNumber`.
   * Default value: `Product number`
   */
  productNumber: string;

  /**
   * Returns the value of translation message for key `outOfStock`.
   * Default value: `Out of stock`
   */
  outOfStock: string;

  /**
   * Returns the value of translation message for key `authorizationNotes`.
   * Default value: `Authorization notes`
   */
  authorizationNotes: string;

  /**
   * Returns the value of translation message for key `questionsAndAnswers`.
   * Default value: `Questions & Answers`
   */
  questionsAndAnswers: string;

  /**
   * Returns the value of translation message for key `removeQuestion`.
   * Default value: `Remove question`
   */
  removeQuestion: string;

  /**
   * Returns the value of translation message for key `reserveAmount`.
   * Default value: `This amount will be reserved when the order is confirmed, and will be debited from your account once the order is accepted by the seller.`
   */
  reserveAmount: string;

  /**
   * Returns the value of translation message for key `order`.
   * Default value: `Order`
   */
  order: string;

  /**
   * Returns the value of translation message for key `orderWaitingForSellersApproval`.
   * Default value: `The order has been created and is waiting for sellers approval`
   */
  orderWaitingForSellersApproval: string;

  /**
   * Returns the value of translation message for key `total`.
   * Default value: `Total price`
   */
  total: string;

  /**
   * Returns the value of translation message for key `toBeConfirmedBySeller`.
   * Default value: `To be confirmed by seller`
   */
  toBeConfirmedBySeller: string;

  /**
   * Returns the value of translation message for key `webshopSettingsSaved`.
   * Default value: `The web shop settings were saved`
   */
  webshopSettingsSaved: string;

  /**
   * Returns the value of translation message for key `requiringMyConfirmation`.
   * Default value: `Requiring my confirmation`
   */
  requiringMyConfirmation: string;

  /**
   * Returns the value of translation message for key `buyer`.
   * Default value: `Buyer`
   */
  buyer: string;

  /**
   * Returns the value of translation message for key `seller`.
   * Default value: `Seller`
   */
  seller: string;

  /**
   * Returns the value of translation message for key `stock`.
   * Default value: `Stock`
   */
  stock: string;

  /**
   * Returns the value of translation message for key `waitingForBuyersApproval`.
   * Default value: `Waiting for buyers approval`
   */
  waitingForBuyersApproval: string;

  /**
   * Returns the value of translation message for key `waitingForSellersApproval`.
   * Default value: `Waiting for sellers approval`
   */
  waitingForSellersApproval: string;

  /**
   * Returns the value of translation message for key `remarks`.
   * Default value: `Remarks`
   */
  remarks: string;

  /**
   * Returns the value of translation message for key `listAds`.
   * Default value: `List advertisements`
   */
  listAds: string;


  /**
   * Returns the value of translation message for key `articlesNotice`.
   * Default value: `Please notice, some articles in your shopping cart:<br> {notice}`
   */
  articlesNotice(notice: string | number): string;


  /**
   * Returns the value of translation message for key `lowBalance`.
   * Default value: `- are exceeding your {currency} balance.`
   */
  lowBalance(currency: string | number): string;


  /**
   * Returns the value of translation message for key `byOwner`.
   * Default value: `By {owner}`
   */
  byOwner(owner: string | number): string;


  /**
   * Returns the nested accessor for translation messages in `adjustedQuantity`.
   */
  adjustedQuantity: I18n$Ad$AdjustedQuantity;


  /**
   * Returns the nested accessor for translation messages in `error`.
   */
  error: I18n$Ad$Error;


  /**
   * Returns the nested accessor for translation messages in `type`.
   */
  type: I18n$Ad$Type;


  /**
   * Returns the nested accessor for translation messages in `orderBy`.
   */
  orderBy: I18n$Ad$OrderBy;


  /**
   * Returns the nested accessor for translation messages in `status`.
   */
  status: I18n$Ad$Status;


  /**
   * Returns the nested accessor for translation messages in `orderStatus`.
   */
  orderStatus: I18n$Ad$OrderStatus;


  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  title: I18n$Ad$Title;


  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  mobileTitle: I18n$Ad$MobileTitle;

}

export interface I18n$Ad$AdjustedQuantity {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `max`.
   * Default value: `- the quantity demanded cannot be delivered, because there is a maximum of items you are allowed to have in your shopping cart. The quantity of this article is automatically reduced.<br>`
   */
  max: string;

  /**
   * Returns the value of translation message for key `min`.
   * Default value: `- the quantity demanded cannot be delivered, because there is a minimum of items you are allowed to have in your shopping cart. The quantity of this article is automatically increased.<br>`
   */
  min: string;

  /**
   * Returns the value of translation message for key `stock`.
   * Default value: `- the quantity demanded cannot be delivered, because the article is almost out of stock. The quantity of this article is automatically reduced.<br>`
   */
  stock: string;

}

export interface I18n$Ad$Error {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `cannotBuyFromSeller`.
   * Default value: `At the moment, you are not allowed to buy products from this user. Please contact your administrator.`
   */
  cannotBuyFromSeller: string;

  /**
   * Returns the value of translation message for key `cannotProceedToCheckout`.
   * Default value: `You can not proceed with checkout because some items are unavailable or you do not have enough balance`
   */
  cannotProceedToCheckout: string;

  /**
   * Returns the value of translation message for key `notEnoughStock`.
   * Default value: `The quantity demanded cannot be delivered, because the article is almost out of stock.`
   */
  notEnoughStock: string;

}

export interface I18n$Ad$Type {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `simple`.
   * Default value: `Advertisement`
   */
  simple: string;

  /**
   * Returns the value of translation message for key `webshop`.
   * Default value: `Web shop`
   */
  webshop: string;

}

export interface I18n$Ad$OrderBy {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `date`.
   * Default value: `Last published`
   */
  date: string;

  /**
   * Returns the value of translation message for key `distance`.
   * Default value: `Distance`
   */
  distance: string;

  /**
   * Returns the value of translation message for key `priceAsc`.
   * Default value: `Lowest price`
   */
  priceAsc: string;

  /**
   * Returns the value of translation message for key `priceDesc`.
   * Default value: `Highest price`
   */
  priceDesc: string;

  /**
   * Returns the value of translation message for key `relevance`.
   * Default value: `Relevance`
   */
  relevance: string;

}

export interface I18n$Ad$Status {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `active`.
   * Default value: `Published`
   */
  active: string;

  /**
   * Returns the value of translation message for key `disabled`.
   * Default value: `Disabled`
   */
  disabled: string;

  /**
   * Returns the value of translation message for key `draft`.
   * Default value: `Draft`
   */
  draft: string;

  /**
   * Returns the value of translation message for key `expired`.
   * Default value: `Expired`
   */
  expired: string;

  /**
   * Returns the value of translation message for key `hidden`.
   * Default value: `Hidden`
   */
  hidden: string;

  /**
   * Returns the value of translation message for key `pending`.
   * Default value: `Pending`
   */
  pending: string;

  /**
   * Returns the value of translation message for key `scheduled`.
   * Default value: `Scheduled`
   */
  scheduled: string;

}

export interface I18n$Ad$OrderStatus {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `draft`.
   * Default value: `Draft`
   */
  draft: string;

  /**
   * Returns the value of translation message for key `completed`.
   * Default value: `Completed`
   */
  completed: string;

  /**
   * Returns the value of translation message for key `rejectedByBuyer`.
   * Default value: `Rejected by buyer`
   */
  rejectedByBuyer: string;

  /**
   * Returns the value of translation message for key `rejectedBySeller`.
   * Default value: `Rejected by seller`
   */
  rejectedBySeller: string;

  /**
   * Returns the value of translation message for key `paymentPending`.
   * Default value: `Payment pending`
   */
  paymentPending: string;

  /**
   * Returns the value of translation message for key `paymentDenied`.
   * Default value: `Payment denied`
   */
  paymentDenied: string;

  /**
   * Returns the value of translation message for key `paymentCanceled`.
   * Default value: `Payment canceled`
   */
  paymentCanceled: string;

  /**
   * Returns the value of translation message for key `paymentExpired`.
   * Default value: `Payment expired`
   */
  paymentExpired: string;

  /**
   * Returns the value of translation message for key `pendingBuyer`.
   * Default value: `Pending by buyer`
   */
  pendingBuyer: string;

  /**
   * Returns the value of translation message for key `pendingSeller`.
   * Default value: `Pending by seller`
   */
  pendingSeller: string;

  /**
   * Returns the value of translation message for key `disposed`.
   * Default value: `Disposed`
   */
  disposed: string;

}

export interface I18n$Ad$Title {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `search`.
   * Default value: `Search advertisements`
   */
  search: string;

  /**
   * Returns the value of translation message for key `myAdvertisements`.
   * Default value: `My advertisements`
   */
  myAdvertisements: string;

  /**
   * Returns the value of translation message for key `myWebshop`.
   * Default value: `My web shop`
   */
  myWebshop: string;

  /**
   * Returns the value of translation message for key `viewAdvertisements`.
   * Default value: `View advertisements`
   */
  viewAdvertisements: string;

  /**
   * Returns the value of translation message for key `viewWebshop`.
   * Default value: `View web shop`
   */
  viewWebshop: string;

  /**
   * Returns the value of translation message for key `new`.
   * Default value: `New advertisement`
   */
  new: string;

  /**
   * Returns the value of translation message for key `edit`.
   * Default value: `Edit advertisement`
   */
  edit: string;

  /**
   * Returns the value of translation message for key `purchases`.
   * Default value: `Purchases`
   */
  purchases: string;

  /**
   * Returns the value of translation message for key `sales`.
   * Default value: `Sales`
   */
  sales: string;

  /**
   * Returns the value of translation message for key `order`.
   * Default value: `Order details`
   */
  order: string;

  /**
   * Returns the value of translation message for key `newOrder`.
   * Default value: `Create web shop order`
   */
  newOrder: string;

  /**
   * Returns the value of translation message for key `shoppingCart`.
   * Default value: `Shopping cart`
   */
  shoppingCart: string;

  /**
   * Returns the value of translation message for key `history`.
   * Default value: `Order history`
   */
  history: string;

  /**
   * Returns the value of translation message for key `deliveryAddress`.
   * Default value: `Delivery address`
   */
  deliveryAddress: string;

  /**
   * Returns the value of translation message for key `deliveryMethod`.
   * Default value: `Delivery method`
   */
  deliveryMethod: string;

  /**
   * Returns the value of translation message for key `deliveryMethodNew`.
   * Default value: `New delivery method`
   */
  deliveryMethodNew: string;

  /**
   * Returns the value of translation message for key `deliveryMethods`.
   * Default value: `Delivery methods`
   */
  deliveryMethods: string;

  /**
   * Returns the value of translation message for key `paymentType`.
   * Default value: `Payment type`
   */
  paymentType: string;

  /**
   * Returns the value of translation message for key `confirmOrder`.
   * Default value: `Confirm order`
   */
  confirmOrder: string;

  /**
   * Returns the value of translation message for key `webshopSettings`.
   * Default value: `Web shop settings`
   */
  webshopSettings: string;

  /**
   * Returns the value of translation message for key `unansweredQuestions`.
   * Default value: `Unanswered questions`
   */
  unansweredQuestions: string;

  /**
   * Returns the value of translation message for key `answerQuestion`.
   * Default value: `Answer question`
   */
  answerQuestion: string;

  /**
   * Returns the value of translation message for key `adInterests`.
   * Default value: `Advertisement interests`
   */
  adInterests: string;

  /**
   * Returns the value of translation message for key `adInterestNew`.
   * Default value: `New advertisement interest`
   */
  adInterestNew: string;

  /**
   * Returns the value of translation message for key `adInterest`.
   * Default value: `Advertisement interest`
   */
  adInterest: string;

}

export interface I18n$Ad$MobileTitle {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `search`.
   * Default value: `Advertisements`
   */
  search: string;

  /**
   * Returns the value of translation message for key `myAdvertisements`.
   * Default value: `My advertisements`
   */
  myAdvertisements: string;

  /**
   * Returns the value of translation message for key `myWebshop`.
   * Default value: `My web shop`
   */
  myWebshop: string;

  /**
   * Returns the value of translation message for key `viewAdvertisements`.
   * Default value: `View advertisements`
   */
  viewAdvertisements: string;

  /**
   * Returns the value of translation message for key `viewWebshop`.
   * Default value: `View web shop`
   */
  viewWebshop: string;

  /**
   * Returns the value of translation message for key `details`.
   * Default value: `Advertisement`
   */
  details: string;

  /**
   * Returns the value of translation message for key `new`.
   * Default value: `New advertisement`
   */
  new: string;

  /**
   * Returns the value of translation message for key `edit`.
   * Default value: `Edit advertisement`
   */
  edit: string;

  /**
   * Returns the value of translation message for key `purchases`.
   * Default value: `Purchases`
   */
  purchases: string;

  /**
   * Returns the value of translation message for key `sales`.
   * Default value: `Sales`
   */
  sales: string;

  /**
   * Returns the value of translation message for key `order`.
   * Default value: `Order details`
   */
  order: string;

  /**
   * Returns the value of translation message for key `newOrder`.
   * Default value: `Create web shop order`
   */
  newOrder: string;

  /**
   * Returns the value of translation message for key `shoppingCart`.
   * Default value: `Shopping cart`
   */
  shoppingCart: string;

  /**
   * Returns the value of translation message for key `history`.
   * Default value: `Order history`
   */
  history: string;

  /**
   * Returns the value of translation message for key `deliveryAddress`.
   * Default value: `Delivery address`
   */
  deliveryAddress: string;

  /**
   * Returns the value of translation message for key `deliveryMethod`.
   * Default value: `Delivery method`
   */
  deliveryMethod: string;

  /**
   * Returns the value of translation message for key `deliveryMethodNew`.
   * Default value: `New delivery method`
   */
  deliveryMethodNew: string;

  /**
   * Returns the value of translation message for key `deliveryMethods`.
   * Default value: `Delivery methods`
   */
  deliveryMethods: string;

  /**
   * Returns the value of translation message for key `paymentType`.
   * Default value: `Payment type`
   */
  paymentType: string;

  /**
   * Returns the value of translation message for key `confirmOrder`.
   * Default value: `Confirm order`
   */
  confirmOrder: string;

  /**
   * Returns the value of translation message for key `webshopSettings`.
   * Default value: `Web shop settings`
   */
  webshopSettings: string;

  /**
   * Returns the value of translation message for key `unansweredQuestions`.
   * Default value: `Unanswered questions`
   */
  unansweredQuestions: string;

  /**
   * Returns the value of translation message for key `answerQuestion`.
   * Default value: `Answer question`
   */
  answerQuestion: string;

  /**
   * Returns the value of translation message for key `adInterests`.
   * Default value: `Advertisement interests`
   */
  adInterests: string;

  /**
   * Returns the value of translation message for key `adInterestNew`.
   * Default value: `New advertisement interest`
   */
  adInterestNew: string;

  /**
   * Returns the value of translation message for key `adInterest`.
   * Default value: `Advertisement interest`
   */
  adInterest: string;

}

export interface I18n$Message {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `sendToBrokered`.
   * Default value: `My registered users`
   */
  sendToBrokered: string;

  /**
   * Returns the value of translation message for key `from`.
   * Default value: `From`
   */
  from: string;

  /**
   * Returns the value of translation message for key `fromSentTo`.
   * Default value: `From / Sent to`
   */
  fromSentTo: string;

  /**
   * Returns the value of translation message for key `markAsUnreadDone`.
   * Default value: `This message has been marked as unread`
   */
  markAsUnreadDone: string;

  /**
   * Returns the value of translation message for key `messageRestored`.
   * Default value: `This message has been restored from trash`
   */
  messageRestored: string;

  /**
   * Returns the value of translation message for key `replies`.
   * Default value: `Replies`
   */
  replies: string;

  /**
   * Returns the value of translation message for key `moveToTrashDone`.
   * Default value: `This message has been moved to trash`
   */
  moveToTrashDone: string;

  /**
   * Returns the value of translation message for key `removeDone`.
   * Default value: `This message has been permanently removed`
   */
  removeDone: string;

  /**
   * Returns the value of translation message for key `sentBy`.
   * Default value: `Sent by`
   */
  sentBy: string;

  /**
   * Returns the value of translation message for key `sentTo`.
   * Default value: `Sent to`
   */
  sentTo: string;

  /**
   * Returns the value of translation message for key `sendTo`.
   * Default value: `Send to`
   */
  sendTo: string;

  /**
   * Returns the value of translation message for key `subject`.
   * Default value: `Subject`
   */
  subject: string;

  /**
   * Returns the value of translation message for key `toUsers`.
   * Default value: `To users`
   */
  toUsers: string;

  /**
   * Returns the value of translation message for key `toGroups`.
   * Default value: `To groups`
   */
  toGroups: string;

  /**
   * Returns the value of translation message for key `send`.
   * Default value: `Send`
   */
  send: string;

  /**
   * Returns the value of translation message for key `messageSent`.
   * Default value: `The message was succesfully sent`
   */
  messageSent: string;


  /**
   * Returns the value of translation message for key `replyBody`.
   * Default value: `On {date}, {user} wrote:`
   */
  replyBody($: {date: string | number, user: string | number}): string;


  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  title: I18n$Message$Title;


  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  mobileTitle: I18n$Message$MobileTitle;


  /**
   * Returns the nested accessor for translation messages in `actions`.
   */
  actions: I18n$Message$Actions;


  /**
   * Returns the nested accessor for translation messages in `messageBox`.
   */
  messageBox: I18n$Message$MessageBox;


  /**
   * Returns the nested accessor for translation messages in `messageDestination`.
   */
  messageDestination: I18n$Message$MessageDestination;

}

export interface I18n$Message$Title {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `search`.
   * Default value: `Messages`
   */
  search: string;

  /**
   * Returns the value of translation message for key `details`.
   * Default value: `Message details`
   */
  details: string;

  /**
   * Returns the value of translation message for key `reply`.
   * Default value: `Reply message`
   */
  reply: string;

  /**
   * Returns the value of translation message for key `newMessage`.
   * Default value: `New message`
   */
  newMessage: string;

}

export interface I18n$Message$MobileTitle {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `search`.
   * Default value: `Messages`
   */
  search: string;

  /**
   * Returns the value of translation message for key `details`.
   * Default value: `Message details`
   */
  details: string;

  /**
   * Returns the value of translation message for key `reply`.
   * Default value: `Reply message`
   */
  reply: string;

  /**
   * Returns the value of translation message for key `newMessage`.
   * Default value: `New message`
   */
  newMessage: string;

}

export interface I18n$Message$Actions {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `newMessage`.
   * Default value: `New message`
   */
  newMessage: string;

  /**
   * Returns the value of translation message for key `moveToTrash`.
   * Default value: `Move to trash`
   */
  moveToTrash: string;

  /**
   * Returns the value of translation message for key `moveAllToTrash`.
   * Default value: `Move all to trash`
   */
  moveAllToTrash: string;

  /**
   * Returns the value of translation message for key `remove`.
   * Default value: `Remove`
   */
  remove: string;

  /**
   * Returns the value of translation message for key `reply`.
   * Default value: `Reply`
   */
  reply: string;

  /**
   * Returns the value of translation message for key `markAsUnread`.
   * Default value: `Mark as unread`
   */
  markAsUnread: string;

  /**
   * Returns the value of translation message for key `restore`.
   * Default value: `Restore`
   */
  restore: string;

}

export interface I18n$Message$MessageBox {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `messageBox`.
   * Default value: `Message box`
   */
  messageBox: string;

  /**
   * Returns the value of translation message for key `inbox`.
   * Default value: `Inbox`
   */
  inbox: string;

  /**
   * Returns the value of translation message for key `sent`.
   * Default value: `Sent`
   */
  sent: string;

  /**
   * Returns the value of translation message for key `trash`.
   * Default value: `Trash`
   */
  trash: string;

}

export interface I18n$Message$MessageDestination {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `user`.
   * Default value: `User`
   */
  user: string;

  /**
   * Returns the value of translation message for key `group`.
   * Default value: `Group(s)`
   */
  group: string;

  /**
   * Returns the value of translation message for key `brokered`.
   * Default value: `My registered users`
   */
  brokered: string;

  /**
   * Returns the value of translation message for key `system`.
   * Default value: `Administration`
   */
  system: string;

}

export interface I18n$Notification {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `message`.
   * Default value: `Message`
   */
  message: string;

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Notifications`
   */
  title: string;

  /**
   * Returns the value of translation message for key `mobileTitle`.
   * Default value: `Notifications`
   */
  mobileTitle: string;

  /**
   * Returns the value of translation message for key `onlyUnread`.
   * Default value: `Unread only`
   */
  onlyUnread: string;


  /**
   * Returns the nested accessor for translation messages in `actions`.
   */
  actions: I18n$Notification$Actions;


  /**
   * Returns the nested accessor for translation messages in `admin`.
   */
  admin: I18n$Notification$Admin;


  /**
   * Returns the nested accessor for translation messages in `user`.
   */
  user: I18n$Notification$User;

}

export interface I18n$Notification$Actions {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `remove`.
   * Default value: `Remove this notification`
   */
  remove: string;

  /**
   * Returns the value of translation message for key `removeAll`.
   * Default value: `Remove all`
   */
  removeAll: string;

  /**
   * Returns the value of translation message for key `markAllRead`.
   * Default value: `Mark all as read`
   */
  markAllRead: string;

  /**
   * Returns the value of translation message for key `settings`.
   * Default value: `Notification settings`
   */
  settings: string;

}

export interface I18n$Notification$Admin {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `adPendingAuthorization`.
   * Default value: `Ad pending for authorization`
   */
  adPendingAuthorization: string;

  /**
   * Returns the value of translation message for key `applicationErrors`.
   * Default value: `Application errors`
   */
  applicationErrors: string;

  /**
   * Returns the value of translation message for key `externalPaymentExpired`.
   * Default value: `External payment expired`
   */
  externalPaymentExpired: string;

  /**
   * Returns the value of translation message for key `externalPaymentPerformedFailed`.
   * Default value: `External payment could not be delivered`
   */
  externalPaymentPerformedFailed: string;

  /**
   * Returns the value of translation message for key `generatedVouchersAboutToExpire`.
   * Default value: `Generated vouchers about to expire`
   */
  generatedVouchersAboutToExpire: string;

  /**
   * Returns the value of translation message for key `generatedVouchersExpired`.
   * Default value: `Generated vouchers expiration`
   */
  generatedVouchersExpired: string;

  /**
   * Returns the value of translation message for key `networkCreated`.
   * Default value: `Network created`
   */
  networkCreated: string;

  /**
   * Returns the value of translation message for key `paymentAwaitingAuthorization`.
   * Default value: `Payments pending by authorization`
   */
  paymentAwaitingAuthorization: string;

  /**
   * Returns the value of translation message for key `paymentPerformed`.
   * Default value: `Payments`
   */
  paymentPerformed: string;

  /**
   * Returns the value of translation message for key `systemAlert`.
   * Default value: `System alerts`
   */
  systemAlert: string;

  /**
   * Returns the value of translation message for key `userAlert`.
   * Default value: `User alerts`
   */
  userAlert: string;

  /**
   * Returns the value of translation message for key `userRegistration`.
   * Default value: `New registered users`
   */
  userRegistration: string;

  /**
   * Returns the value of translation message for key `voucherBuyingAboutToExpire`.
   * Default value: `Voucher buying about to expire`
   */
  voucherBuyingAboutToExpire: string;

}

export interface I18n$Notification$User {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;


  /**
   * Returns the nested accessor for translation messages in `account`.
   */
  account: I18n$Notification$User$Account;


  /**
   * Returns the nested accessor for translation messages in `brokering`.
   */
  brokering: I18n$Notification$User$Brokering;


  /**
   * Returns the nested accessor for translation messages in `buyer`.
   */
  buyer: I18n$Notification$User$Buyer;


  /**
   * Returns the nested accessor for translation messages in `feedback`.
   */
  feedback: I18n$Notification$User$Feedback;


  /**
   * Returns the nested accessor for translation messages in `personal`.
   */
  personal: I18n$Notification$User$Personal;


  /**
   * Returns the nested accessor for translation messages in `reference`.
   */
  reference: I18n$Notification$User$Reference;


  /**
   * Returns the nested accessor for translation messages in `seller`.
   */
  seller: I18n$Notification$User$Seller;

}

export interface I18n$Notification$User$Account {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `allNonSmsPerformedPayments`.
   * Default value: `Payment performed`
   */
  allNonSmsPerformedPayments: string;

  /**
   * Returns the value of translation message for key `authorizedPaymentCanceled`.
   * Default value: `Authorized payment canceled`
   */
  authorizedPaymentCanceled: string;

  /**
   * Returns the value of translation message for key `authorizedPaymentDenied`.
   * Default value: `Authorized payment denied`
   */
  authorizedPaymentDenied: string;

  /**
   * Returns the value of translation message for key `authorizedPaymentExpired`.
   * Default value: `Authorized payment expired`
   */
  authorizedPaymentExpired: string;

  /**
   * Returns the value of translation message for key `authorizedPaymentSucceeded`.
   * Default value: `Authorized payment successful`
   */
  authorizedPaymentSucceeded: string;

  /**
   * Returns the value of translation message for key `voucherAboutToExpire`.
   * Default value: `Vouchers will expire in a few days`
   */
  voucherAboutToExpire: string;

  /**
   * Returns the value of translation message for key `voucherExpirationDateChanged`.
   * Default value: `Voucher expiration date was changed`
   */
  voucherExpirationDateChanged: string;

  /**
   * Returns the value of translation message for key `voucherExpired`.
   * Default value: `Voucher has expired`
   */
  voucherExpired: string;

  /**
   * Returns the value of translation message for key `voucherPinBlocked`.
   * Default value: `Voucher PIN blocked by exceeding attempts`
   */
  voucherPinBlocked: string;

  /**
   * Returns the value of translation message for key `voucherTopUp`.
   * Default value: `Voucher was topped-up`
   */
  voucherTopUp: string;

  /**
   * Returns the value of translation message for key `voucherRedeem`.
   * Default value: `Voucher was redeemed`
   */
  voucherRedeem: string;

  /**
   * Returns the value of translation message for key `externalPaymentExpired`.
   * Default value: `External payment expired`
   */
  externalPaymentExpired: string;

  /**
   * Returns the value of translation message for key `externalPaymentPerformedFailed`.
   * Default value: `External payment could not be delivered`
   */
  externalPaymentPerformedFailed: string;

  /**
   * Returns the value of translation message for key `externalPaymentReceivedFailed`.
   * Default value: `External payment could not be received`
   */
  externalPaymentReceivedFailed: string;

  /**
   * Returns the value of translation message for key `incomingRecurringPaymentCanceled`.
   * Default value: `Incoming recurring payment was canceled`
   */
  incomingRecurringPaymentCanceled: string;

  /**
   * Returns the value of translation message for key `incomingRecurringPaymentFailed`.
   * Default value: `Incoming recurring payment has failed`
   */
  incomingRecurringPaymentFailed: string;

  /**
   * Returns the value of translation message for key `incomingRecurringPaymentReceived`.
   * Default value: `Incoming recurring payment was received`
   */
  incomingRecurringPaymentReceived: string;

  /**
   * Returns the value of translation message for key `incomingScheduledPaymentCanceled`.
   * Default value: `Incoming scheduled payment canceled`
   */
  incomingScheduledPaymentCanceled: string;

  /**
   * Returns the value of translation message for key `incomingScheduledPaymentFailed`.
   * Default value: `Incoming scheduled payment failed`
   */
  incomingScheduledPaymentFailed: string;

  /**
   * Returns the value of translation message for key `incomingScheduledPaymentReceived`.
   * Default value: `Scheduled payment received`
   */
  incomingScheduledPaymentReceived: string;

  /**
   * Returns the value of translation message for key `limitChange`.
   * Default value: `Account limit changed`
   */
  limitChange: string;

  /**
   * Returns the value of translation message for key `paymentAwaitingAuthorization`.
   * Default value: `Payment awaiting authorization`
   */
  paymentAwaitingAuthorization: string;

  /**
   * Returns the value of translation message for key `paymentReceived`.
   * Default value: `Payment received`
   */
  paymentReceived: string;

  /**
   * Returns the value of translation message for key `paymentRequestCanceled`.
   * Default value: `Payment request canceled`
   */
  paymentRequestCanceled: string;

  /**
   * Returns the value of translation message for key `paymentRequestDenied`.
   * Default value: `Payment request denied`
   */
  paymentRequestDenied: string;

  /**
   * Returns the value of translation message for key `paymentRequestExpirationDateChanged`.
   * Default value: `Payment request expiration date changed`
   */
  paymentRequestExpirationDateChanged: string;

  /**
   * Returns the value of translation message for key `paymentRequestExpired`.
   * Default value: `Payment request expired`
   */
  paymentRequestExpired: string;

  /**
   * Returns the value of translation message for key `paymentRequestProcessed`.
   * Default value: `Payment request processed`
   */
  paymentRequestProcessed: string;

  /**
   * Returns the value of translation message for key `paymentRequestReceived`.
   * Default value: `Payment request received`
   */
  paymentRequestReceived: string;

  /**
   * Returns the value of translation message for key `recurringPaymentFailed`.
   * Default value: `Recurring payment occurrence has failed`
   */
  recurringPaymentFailed: string;

  /**
   * Returns the value of translation message for key `recurringPaymentOcurrenceProcessed`.
   * Default value: `Recurring payment was processed`
   */
  recurringPaymentOcurrenceProcessed: string;

  /**
   * Returns the value of translation message for key `scheduledPaymentFailed`.
   * Default value: `Scheduled payment failed`
   */
  scheduledPaymentFailed: string;

  /**
   * Returns the value of translation message for key `scheduledPaymentInstallmentProcessed`.
   * Default value: `Scheduled payment installment was processed`
   */
  scheduledPaymentInstallmentProcessed: string;

  /**
   * Returns the value of translation message for key `scheduledPaymentRequestFailed`.
   * Default value: `Scheduled payment request failed`
   */
  scheduledPaymentRequestFailed: string;

  /**
   * Returns the value of translation message for key `sentPaymentRequestExpirationDateChanged`.
   * Default value: `Sent payment request expiration date changed`
   */
  sentPaymentRequestExpirationDateChanged: string;

  /**
   * Returns the value of translation message for key `smsPerformedPayment`.
   * Default value: `SMS performed payment`
   */
  smsPerformedPayment: string;

  /**
   * Returns the value of translation message for key `ticketWebhookFailed`.
   * Default value: `Ticket approval could not be notified through webhook`
   */
  ticketWebhookFailed: string;


  /**
   * Returns the nested accessor for translation messages in `operator`.
   */
  operator: I18n$Notification$User$Account$Operator;

}

export interface I18n$Notification$User$Account$Operator {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `authorizedPaymentApprovedStillPending`.
   * Default value: `Pending payment by operator approved but still pending further authorization`
   */
  authorizedPaymentApprovedStillPending: string;

  /**
   * Returns the value of translation message for key `authorizedPaymentCanceled`.
   * Default value: `Pending payment by operator was canceled`
   */
  authorizedPaymentCanceled: string;

  /**
   * Returns the value of translation message for key `authorizedPaymentDenied`.
   * Default value: `Pending payment by operator was denied`
   */
  authorizedPaymentDenied: string;

  /**
   * Returns the value of translation message for key `authorizedPaymentExpired`.
   * Default value: `Authorized payment by operator has expired`
   */
  authorizedPaymentExpired: string;

  /**
   * Returns the value of translation message for key `authorizedPaymentSucceeded`.
   * Default value: `Pending payment by operator successful`
   */
  authorizedPaymentSucceeded: string;

  /**
   * Returns the value of translation message for key `paymentAwaitingAuthorization`.
   * Default value: `Operator payment awaiting authorization`
   */
  paymentAwaitingAuthorization: string;

}

export interface I18n$Notification$User$Brokering {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `adPendingAuthorization`.
   * Default value: `Ad is pending for authorization`
   */
  adPendingAuthorization: string;

  /**
   * Returns the value of translation message for key `memberAssigned`.
   * Default value: `Member assigned`
   */
  memberAssigned: string;

  /**
   * Returns the value of translation message for key `memberUnassigned`.
   * Default value: `Member unassigned`
   */
  memberUnassigned: string;

}

export interface I18n$Notification$User$Buyer {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `adInterestNotification`.
   * Default value: `New ad matching interest`
   */
  adInterestNotification: string;

  /**
   * Returns the value of translation message for key `adQuestionAnswered`.
   * Default value: `Ad question answered`
   */
  adQuestionAnswered: string;

  /**
   * Returns the value of translation message for key `orderCanceled`.
   * Default value: `Purchase order canceled`
   */
  orderCanceled: string;

  /**
   * Returns the value of translation message for key `orderPaymentCanceled`.
   * Default value: `Purchase order payment canceled`
   */
  orderPaymentCanceled: string;

  /**
   * Returns the value of translation message for key `orderPaymentDenied`.
   * Default value: `Purchase order payment denied`
   */
  orderPaymentDenied: string;

  /**
   * Returns the value of translation message for key `orderPaymentExpired`.
   * Default value: `Purchase order payment expired`
   */
  orderPaymentExpired: string;

  /**
   * Returns the value of translation message for key `orderPending`.
   * Default value: `Pending order`
   */
  orderPending: string;

  /**
   * Returns the value of translation message for key `orderPendingAuthorization`.
   * Default value: `Purchase order awaiting payment authorization`
   */
  orderPendingAuthorization: string;

  /**
   * Returns the value of translation message for key `orderPendingDeliveryData`.
   * Default value: `Order awaiting delivery information`
   */
  orderPendingDeliveryData: string;

  /**
   * Returns the value of translation message for key `orderProcessedBySeller`.
   * Default value: `Order accepted by seller`
   */
  orderProcessedBySeller: string;

  /**
   * Returns the value of translation message for key `orderRejectedBySeller`.
   * Default value: `Order rejected by seller`
   */
  orderRejectedBySeller: string;

  /**
   * Returns the value of translation message for key `buyerSalePending`.
   * Default value: `Order created`
   */
  buyerSalePending: string;

  /**
   * Returns the value of translation message for key `buyerSaleRejectedBySeller`.
   * Default value: `Sale order canceled`
   */
  buyerSaleRejectedBySeller: string;

}

export interface I18n$Notification$User$Feedback {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `changed`.
   * Default value: `Feedback changed`
   */
  changed: string;

  /**
   * Returns the value of translation message for key `created`.
   * Default value: `Feedback received`
   */
  created: string;

  /**
   * Returns the value of translation message for key `expirationReminder`.
   * Default value: `Feedback reminder`
   */
  expirationReminder: string;

  /**
   * Returns the value of translation message for key `optional`.
   * Default value: `Feedback optional`
   */
  optional: string;

  /**
   * Returns the value of translation message for key `replyCreated`.
   * Default value: `Feedback reply received`
   */
  replyCreated: string;

  /**
   * Returns the value of translation message for key `required`.
   * Default value: `Feedback required`
   */
  required: string;

}

export interface I18n$Notification$User$Personal {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `brokerAssigned`.
   * Default value: `Broker assigned`
   */
  brokerAssigned: string;

  /**
   * Returns the value of translation message for key `brokerUnassigned`.
   * Default value: `Broker unassigned`
   */
  brokerUnassigned: string;

  /**
   * Returns the value of translation message for key `maxSmsPerMonthReached`.
   * Default value: `Maximum SMS per month reached`
   */
  maxSmsPerMonthReached: string;

  /**
   * Returns the value of translation message for key `newToken`.
   * Default value: `New token / card`
   */
  newToken: string;

  /**
   * Returns the value of translation message for key `newTokenPendingActivation`.
   * Default value: `New token / card pending activation`
   */
  newTokenPendingActivation: string;

  /**
   * Returns the value of translation message for key `passwordStatusChanged`.
   * Default value: `Password status changed`
   */
  passwordStatusChanged: string;

  /**
   * Returns the value of translation message for key `tokenStatusChanged`.
   * Default value: `Token / card status changed`
   */
  tokenStatusChanged: string;

  /**
   * Returns the value of translation message for key `userStatusChanged`.
   * Default value: `User status changed`
   */
  userStatusChanged: string;

}

export interface I18n$Notification$User$Reference {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `changed`.
   * Default value: `Reference changed`
   */
  changed: string;

  /**
   * Returns the value of translation message for key `created`.
   * Default value: `Reference received`
   */
  created: string;

}

export interface I18n$Notification$User$Seller {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `adAuthorized`.
   * Default value: `Advertisement authorized`
   */
  adAuthorized: string;

  /**
   * Returns the value of translation message for key `adExpired`.
   * Default value: `Advertisement expired`
   */
  adExpired: string;

  /**
   * Returns the value of translation message for key `adLowStock`.
   * Default value: `Low quantity of article`
   */
  adLowStock: string;

  /**
   * Returns the value of translation message for key `adOutOfStock`.
   * Default value: `Article out of stock`
   */
  adOutOfStock: string;

  /**
   * Returns the value of translation message for key `adQuestionCreated`.
   * Default value: `New ad question`
   */
  adQuestionCreated: string;

  /**
   * Returns the value of translation message for key `adRejected`.
   * Default value: `Advertisement rejected`
   */
  adRejected: string;

  /**
   * Returns the value of translation message for key `orderCanceled`.
   * Default value: `Sale order canceled`
   */
  orderCanceled: string;

  /**
   * Returns the value of translation message for key `orderCreated`.
   * Default value: `New web shop order`
   */
  orderCreated: string;

  /**
   * Returns the value of translation message for key `orderPaymentCanceled`.
   * Default value: `Sale order payment canceled`
   */
  orderPaymentCanceled: string;

  /**
   * Returns the value of translation message for key `orderPaymentDenied`.
   * Default value: `Sale order payment denied`
   */
  orderPaymentDenied: string;

  /**
   * Returns the value of translation message for key `orderPaymentExpired`.
   * Default value: `Sale order payment expired`
   */
  orderPaymentExpired: string;

  /**
   * Returns the value of translation message for key `orderPendingAuthorization`.
   * Default value: `Sale order awaiting payment authorization`
   */
  orderPendingAuthorization: string;

  /**
   * Returns the value of translation message for key `orderPendingDeliveryData`.
   * Default value: `Delivery information requested`
   */
  orderPendingDeliveryData: string;

  /**
   * Returns the value of translation message for key `orderProcessedByBuyer`.
   * Default value: `Order accepted by buyer`
   */
  orderProcessedByBuyer: string;

  /**
   * Returns the value of translation message for key `orderRejectedByBuyer`.
   * Default value: `Order rejected`
   */
  orderRejectedByBuyer: string;

  /**
   * Returns the value of translation message for key `saleProcessedByBuyer`.
   * Default value: `Sale realized`
   */
  saleProcessedByBuyer: string;

}

export interface I18n$NotificationSettings {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Notification settings`
   */
  title: string;

  /**
   * Returns the value of translation message for key `mobileTitle`.
   * Default value: `Notification settings`
   */
  mobileTitle: string;

  /**
   * Returns the value of translation message for key `notAvailableSettings`.
   * Default value: `You are not allowed to change any settings.`
   */
  notAvailableSettings: string;

  /**
   * Returns the value of translation message for key `forwardMessages`.
   * Default value: `Forward internal messages to your email address`
   */
  forwardMessages: string;

  /**
   * Returns the value of translation message for key `emailMailings`.
   * Default value: `Receive email mailings`
   */
  emailMailings: string;

  /**
   * Returns the value of translation message for key `smsMailings`.
   * Default value: `Receive SMS mailings`
   */
  smsMailings: string;

  /**
   * Returns the value of translation message for key `internalNotification`.
   * Default value: `Internal notification`
   */
  internalNotification: string;

  /**
   * Returns the value of translation message for key `accounts`.
   * Default value: `Accounts`
   */
  accounts: string;

  /**
   * Returns the value of translation message for key `personal`.
   * Default value: `Personal`
   */
  personal: string;

  /**
   * Returns the value of translation message for key `brokering`.
   * Default value: `Brokering`
   */
  brokering: string;

  /**
   * Returns the value of translation message for key `payments`.
   * Default value: `Payments`
   */
  payments: string;

  /**
   * Returns the value of translation message for key `marketplaceAsBuyer`.
   * Default value: `Marketplace as buyer`
   */
  marketplaceAsBuyer: string;

  /**
   * Returns the value of translation message for key `marketplaceAsSeller`.
   * Default value: `Marketplace as seller`
   */
  marketplaceAsSeller: string;

  /**
   * Returns the value of translation message for key `feedbackAndReferences`.
   * Default value: `Feedback and references`
   */
  feedbackAndReferences: string;

  /**
   * Returns the value of translation message for key `paymentNotifications.above`.
   * Default value: `Only notify payments above`
   */
  paymentNotificationsAbove: string;

  /**
   * Returns the value of translation message for key `paymentNotifications.below`.
   * Default value: `Only notify payments below`
   */
  paymentNotificationsBelow: string;

  /**
   * Returns the value of translation message for key `saved`.
   * Default value: `The notification settings were saved`
   */
  saved: string;

  /**
   * Returns the value of translation message for key `enableAll`.
   * Default value: `Enable all`
   */
  enableAll: string;

  /**
   * Returns the value of translation message for key `disableAll`.
   * Default value: `Disable all`
   */
  disableAll: string;


  /**
   * Returns the value of translation message for key `smsCount`.
   * Default value: `You have used {count} of {total} free SMS messages this month.`
   */
  smsCount($: {count: string | number, total: string | number}): string;

}

export interface I18n$ConnectedUser {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Connected users`
   */
  title: string;

  /**
   * Returns the value of translation message for key `mobileTitle`.
   * Default value: `Connected users`
   */
  mobileTitle: string;

  /**
   * Returns the value of translation message for key `loggedInAt`.
   * Default value: `Logged in at`
   */
  loggedInAt: string;

  /**
   * Returns the value of translation message for key `channel`.
   * Default value: `Channel`
   */
  channel: string;

  /**
   * Returns the value of translation message for key `ipAddress`.
   * Default value: `IP Address`
   */
  ipAddress: string;

  /**
   * Returns the value of translation message for key `disconnect`.
   * Default value: `Disconnect user`
   */
  disconnect: string;

  /**
   * Returns the value of translation message for key `showConnected`.
   * Default value: `Show connected`
   */
  showConnected: string;


  /**
   * Returns the value of translation message for key `disconnected`.
   * Default value: `{user} has been disconnected`
   */
  disconnected(user: string | number): string;


  /**
   * Returns the nested accessor for translation messages in `viewConnected`.
   */
  viewConnected: I18n$ConnectedUser$ViewConnected;

}

export interface I18n$ConnectedUser$ViewConnected {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `operator`.
   * Default value: `View connected operators`
   */
  operator: string;

  /**
   * Returns the value of translation message for key `broker`.
   * Default value: `View connected brokers`
   */
  broker: string;

  /**
   * Returns the value of translation message for key `admin`.
   * Default value: `View connected admins`
   */
  admin: string;

  /**
   * Returns the value of translation message for key `member`.
   * Default value: `View connected members`
   */
  member: string;

}

export interface I18n$IdentityProvider {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `registration`.
   * Default value: `Register faster with your identity provider:`
   */
  registration: string;

  /**
   * Returns the value of translation message for key `noResults`.
   * Default value: `There are not identity providers added yet.`
   */
  noResults: string;

  /**
   * Returns the value of translation message for key `disconnected`.
   * Default value: `Disconnected`
   */
  disconnected: string;


  /**
   * Returns the value of translation message for key `login.noMatch`.
   * Default value: `No user found with e-mail {email}. You will need to login with your regular user and password. After logging-in, your {app} account will be automatically linked, so, on the next time, the 'Continue with {provider}' functionality will work directly.`
   */
  loginNoMatch($: {email: string | number, app: string | number, provider: string | number}): string;


  /**
   * Returns the value of translation message for key `login.noEmail`.
   * Default value: `Couldn't read an e-mail address from your {provider} account. You will need to login with your regular user and password. After logging-in, your {app} account will be automatically linked, so, on the next time, the 'Continue with {provider}' functionality will work directly.`
   */
  loginNoEmail($: {provider: string | number, app: string | number}): string;


  /**
   * Returns the value of translation message for key `popup`.
   * Default value: `Contacting {provider}. Please, wait...`
   */
  popup(provider: string | number): string;


  /**
   * Returns the value of translation message for key `login`.
   * Default value: `Continue with <b>{provider}</b>`
   */
  login(provider: string | number): string;


  /**
   * Returns the value of translation message for key `registration.disclaimer`.
   * Default value: `<b>Disclaimer:</b> None of your {app} data will be shared with these providers, and the only purpose of reading your personal data from them is to fill in the registration form and logging you in after you're registered.`
   */
  registrationDisclaimer(app: string | number): string;


  /**
   * Returns the value of translation message for key `connectedOn`.
   * Default value: `Connected on {date}`
   */
  connectedOn(date: string | number): string;


  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  title: I18n$IdentityProvider$Title;


  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  mobileTitle: I18n$IdentityProvider$MobileTitle;


  /**
   * Returns the nested accessor for translation messages in `action`.
   */
  action: I18n$IdentityProvider$Action;

}

export interface I18n$IdentityProvider$Title {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `manage.self`.
   * Default value: `Manage your identity providers`
   */
  manageSelf: string;

  /**
   * Returns the value of translation message for key `manage.user`.
   * Default value: `Manage user identity providers`
   */
  manageUser: string;

}

export interface I18n$IdentityProvider$MobileTitle {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `manage.self`.
   * Default value: `Identity providers`
   */
  manageSelf: string;

  /**
   * Returns the value of translation message for key `manage.user`.
   * Default value: `Identity providers`
   */
  manageUser: string;

}

export interface I18n$IdentityProvider$Action {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `connect`.
   * Default value: `Connect`
   */
  connect: string;

  /**
   * Returns the value of translation message for key `disconnect`.
   * Default value: `Disconnect`
   */
  disconnect: string;


  /**
   * Returns the value of translation message for key `connect.done`.
   * Default value: `You can now login as {name} using {provider}`
   */
  connectDone($: {name: string | number, provider: string | number}): string;


  /**
   * Returns the value of translation message for key `disconnect.confirm`.
   * Default value: `This will disable the 'Continue with {provider}' functionality. Are you sure?`
   */
  disconnectConfirm(provider: string | number): string;


  /**
   * Returns the value of translation message for key `disconnect.done`.
   * Default value: `The login with {provider} has been disabled`
   */
  disconnectDone(provider: string | number): string;

}

export interface I18n$Operation {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `ad`.
   * Default value: `Advertisement`
   */
  ad: string;

  /**
   * Returns the value of translation message for key `transfer`.
   * Default value: `Transfer`
   */
  transfer: string;

  /**
   * Returns the value of translation message for key `fileUpload`.
   * Default value: `File upload`
   */
  fileUpload: string;

  /**
   * Returns the value of translation message for key `redirecting`.
   * Default value: `You are being redirected...`
   */
  redirecting: string;

  /**
   * Returns the value of translation message for key `alreadyExecuted`.
   * Default value: `This action was already executed. Please, restart the operation.`
   */
  alreadyExecuted: string;

}

export interface I18n$Wizard {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `alreadyExecuted`.
   * Default value: `This step was already executed`
   */
  alreadyExecuted: string;

  /**
   * Returns the value of translation message for key `emailVerificationCode`.
   * Default value: `E-mail verification code`
   */
  emailVerificationCode: string;

  /**
   * Returns the value of translation message for key `smsVerificationCode`.
   * Default value: `SMS verification code`
   */
  smsVerificationCode: string;

  /**
   * Returns the value of translation message for key `codeSent`.
   * Default value: `Code sent`
   */
  codeSent: string;


  /**
   * Returns the value of translation message for key `currentStep`.
   * Default value: `Step {current} of {total}`
   */
  currentStep($: {current: string | number, total: string | number}): string;

}

export interface I18n$Setting {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Settings`
   */
  title: string;

  /**
   * Returns the value of translation message for key `mobileTitle`.
   * Default value: `Settings`
   */
  mobileTitle: string;

  /**
   * Returns the value of translation message for key `theme`.
   * Default value: `Theme`
   */
  theme: string;

  /**
   * Returns the value of translation message for key `theme.light`.
   * Default value: `Light`
   */
  themeLight: string;

  /**
   * Returns the value of translation message for key `theme.dark`.
   * Default value: `Dark`
   */
  themeDark: string;

  /**
   * Returns the value of translation message for key `locale`.
   * Default value: `Set language`
   */
  locale: string;

  /**
   * Returns the value of translation message for key `useClassicFrontend`.
   * Default value: `Use classic frontend`
   */
  useClassicFrontend: string;

}

export interface I18n$PrivacySettings {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Privacy settings`
   */
  title: string;

  /**
   * Returns the value of translation message for key `mobileTitle`.
   * Default value: `Privacy settings`
   */
  mobileTitle: string;

  /**
   * Returns the value of translation message for key `controlsSection`.
   * Default value: `Departments allowed to access private data`
   */
  controlsSection: string;

  /**
   * Returns the value of translation message for key `information`.
   * Default value: `In this page it is possible to set whether access to those fields is restricted, and how those fields may be used.`
   */
  information: string;

  /**
   * Returns the value of translation message for key `dataSubjectToControls`.
   * Default value: `Personal data subject to privacy control:`
   */
  dataSubjectToControls: string;

  /**
   * Returns the value of translation message for key `saved`.
   * Default value: `The privacy settings were saved`
   */
  saved: string;

}

export interface I18n$Record {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `createdBy`.
   * Default value: `Created by`
   */
  createdBy: string;

  /**
   * Returns the value of translation message for key `lastModifiedBy`.
   * Default value: `Last modified by`
   */
  lastModifiedBy: string;

  /**
   * Returns the value of translation message for key `lastModificationDate`.
   * Default value: `Last modified at`
   */
  lastModificationDate: string;

  /**
   * Returns the value of translation message for key `fields`.
   * Default value: `Fields`
   */
  fields: string;


  /**
   * Returns the value of translation message for key `action`.
   * Default value: `{type} ({count})`
   */
  action($: {type: string | number, count: string | number}): string;


  /**
   * Returns the value of translation message for key `created`.
   * Default value: `The {name} was created`
   */
  created(name: string | number): string;


  /**
   * Returns the value of translation message for key `saved`.
   * Default value: `The {name} was saved`
   */
  saved(name: string | number): string;


  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  title: I18n$Record$Title;


  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  mobileTitle: I18n$Record$MobileTitle;

}

export interface I18n$Record$Title {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;


  /**
   * Returns the value of translation message for key `details`.
   * Default value: `{name} details`
   */
  details(name: string | number): string;


  /**
   * Returns the value of translation message for key `new`.
   * Default value: `Create new {name}`
   */
  new(name: string | number): string;


  /**
   * Returns the value of translation message for key `edit`.
   * Default value: `Edit {name}`
   */
  edit(name: string | number): string;

}

export interface I18n$Record$MobileTitle {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;


  /**
   * Returns the value of translation message for key `details`.
   * Default value: `{name} details`
   */
  details(name: string | number): string;


  /**
   * Returns the value of translation message for key `new`.
   * Default value: `Create new {name}`
   */
  new(name: string | number): string;


  /**
   * Returns the value of translation message for key `edit`.
   * Default value: `Edit {name}`
   */
  edit(name: string | number): string;

}

export interface I18n$UserAlert {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Search user alerts`
   */
  title: string;

  /**
   * Returns the value of translation message for key `text`.
   * Default value: `Text`
   */
  text: string;

  /**
   * Returns the value of translation message for key `mobileTitle`.
   * Default value: `User alerts`
   */
  mobileTitle: string;


  /**
   * Returns the nested accessor for translation messages in `type`.
   */
  type: I18n$UserAlert$Type;

}

export interface I18n$UserAlert$Type {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Type`
   */
  title: string;

  /**
   * Returns the value of translation message for key `custom`.
   * Default value: `Custom`
   */
  custom: string;

  /**
   * Returns the value of translation message for key `givenVeryBadRefs`.
   * Default value: `User gave too many 'very bad' references`
   */
  givenVeryBadRefs: string;

  /**
   * Returns the value of translation message for key `inconsistentBalanceBelowLimit`.
   * Default value: `Account with an inconsistent balance below the credit limit`
   */
  inconsistentBalanceBelowLimit: string;

  /**
   * Returns the value of translation message for key `insufficientBalanceForInitialCredit`.
   * Default value: `Insufficient balance for an account's initial credit`
   */
  insufficientBalanceForInitialCredit: string;

  /**
   * Returns the value of translation message for key `maxDeviceActivationAttemptsReached`.
   * Default value: `Max device activation attempts reached`
   */
  maxDeviceActivationAttemptsReached: string;

  /**
   * Returns the value of translation message for key `maxDeviceConfirmationCheckAttemptsReached`.
   * Default value: `Max device confirmation check attempts reached`
   */
  maxDeviceConfirmationCheckAttemptsReached: string;

  /**
   * Returns the value of translation message for key `maxTokenActivationAttemptsReached`.
   * Default value: `Max token activation attempts reached`
   */
  maxTokenActivationAttemptsReached: string;

  /**
   * Returns the value of translation message for key `maxUserLocalizationAttemptsReached`.
   * Default value: `Max user localization attempts reached`
   */
  maxUserLocalizationAttemptsReached: string;

  /**
   * Returns the value of translation message for key `maxVoucherRedeemAttemptsReached`.
   * Default value: `Max voucher redeem attempts reached`
   */
  maxVoucherRedeemAttemptsReached: string;

  /**
   * Returns the value of translation message for key `moveUserAutomaticallyFailed`.
   * Default value: `Move user automatically to group failed`
   */
  moveUserAutomaticallyFailed: string;

  /**
   * Returns the value of translation message for key `passwordDisabledByTries`.
   * Default value: `Password is indefinitely blocked by exceeding attempts`
   */
  passwordDisabledByTries: string;

  /**
   * Returns the value of translation message for key `passwordTemporarilyBlocked`.
   * Default value: `Password temporarily blocked by exceeding attempts`
   */
  passwordTemporarilyBlocked: string;

  /**
   * Returns the value of translation message for key `receivedVeryBadRefs`.
   * Default value: `User received too many 'very bad' references`
   */
  receivedVeryBadRefs: string;

  /**
   * Returns the value of translation message for key `scheduledPaymentFailed`.
   * Default value: `Scheduled payment failed`
   */
  scheduledPaymentFailed: string;

}

export interface I18n$SystemAlert {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;


  /**
   * Returns the nested accessor for translation messages in `type`.
   */
  type: I18n$SystemAlert$Type;

}

export interface I18n$SystemAlert$Type {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `accountBalanceFixed`.
   * Default value: `Accounts with inconsistent cached balance were fixed`
   */
  accountBalanceFixed: string;

  /**
   * Returns the value of translation message for key `accountFeeChargedNoFailures`.
   * Default value: `Account fee has finished without any failures`
   */
  accountFeeChargedNoFailures: string;

  /**
   * Returns the value of translation message for key `accountFeeChargedWithFailures`.
   * Default value: `Account fee has finished with at least one failure`
   */
  accountFeeChargedWithFailures: string;

  /**
   * Returns the value of translation message for key `applicationRestarted`.
   * Default value: `Application restarted`
   */
  applicationRestarted: string;

  /**
   * Returns the value of translation message for key `custom`.
   * Default value: `Custom`
   */
  custom: string;

  /**
   * Returns the value of translation message for key `customTranslationsInvalidated`.
   * Default value: `Custom translations invalidated`
   */
  customTranslationsInvalidated: string;

  /**
   * Returns the value of translation message for key `emailSendingFailed`.
   * Default value: `An e-mail couldn't be sent`
   */
  emailSendingFailed: string;

  /**
   * Returns the value of translation message for key `inconsistentDbSchema`.
   * Default value: `The database schema contains unexpected changes`
   */
  inconsistentDbSchema: string;

  /**
   * Returns the value of translation message for key `maxBlockedUsersReached`.
   * Default value: `The maximum blocked users from the same IP has been reached`
   */
  maxBlockedUsersReached: string;

  /**
   * Returns the value of translation message for key `maxGlobalSmsReached`.
   * Default value: `The global maximum of SMS messages per month has been reached`
   */
  maxGlobalSmsReached: string;

  /**
   * Returns the value of translation message for key `maxIncorrectLoginAttempts`.
   * Default value: `Max incorrect login attempts for invalid user`
   */
  maxIncorrectLoginAttempts: string;

  /**
   * Returns the value of translation message for key `smsSendingFailed`.
   * Default value: `An outbound SMS couldn't be sent`
   */
  smsSendingFailed: string;

}

export interface I18n$Document {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `enable`.
   * Default value: `Enable`
   */
  enable: string;

  /**
   * Returns the value of translation message for key `publiclyAccessible`.
   * Default value: `Publicly accessible`
   */
  publiclyAccessible: string;

  /**
   * Returns the value of translation message for key `userVisible`.
   * Default value: `Visible for members`
   */
  userVisible: string;

  /**
   * Returns the value of translation message for key `brokerVisible`.
   * Default value: `Visible for brokers`
   */
  brokerVisible: string;

  /**
   * Returns the value of translation message for key `brokerManageable`.
   * Default value: `Manageable by broker`
   */
  brokerManageable: string;

  /**
   * Returns the value of translation message for key `replaceDocument`.
   * Default value: `Replace document`
   */
  replaceDocument: string;

  /**
   * Returns the value of translation message for key `newDocument`.
   * Default value: `New document`
   */
  newDocument: string;

  /**
   * Returns the value of translation message for key `document`.
   * Default value: `Document`
   */
  document: string;

  /**
   * Returns the value of translation message for key `savedSuccessfully`.
   * Default value: `Saved successfully`
   */
  savedSuccessfully: string;

  /**
   * Returns the value of translation message for key `process`.
   * Default value: `Process dynamic document`
   */
  process: string;

  /**
   * Returns the value of translation message for key `error.noFile`.
   * Default value: `There is no file attached to this document because it was not uploaded yet.`
   */
  errorNoFile: string;


  /**
   * Returns the value of translation message for key `action`.
   * Default value: `Documents ({count})`
   */
  action(count: string | number): string;


  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  title: I18n$Document$Title;


  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  mobileTitle: I18n$Document$MobileTitle;

}

export interface I18n$Document$Title {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `new`.
   * Default value: `Create new document`
   */
  new: string;

  /**
   * Returns the value of translation message for key `view`.
   * Default value: `Document details`
   */
  view: string;

  /**
   * Returns the value of translation message for key `edit`.
   * Default value: `Document details`
   */
  edit: string;

  /**
   * Returns the value of translation message for key `list`.
   * Default value: `Documents`
   */
  list: string;

  /**
   * Returns the value of translation message for key `search`.
   * Default value: `Documents`
   */
  search: string;

}

export interface I18n$Document$MobileTitle {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `new`.
   * Default value: `Create new document`
   */
  new: string;

  /**
   * Returns the value of translation message for key `view`.
   * Default value: `Document details`
   */
  view: string;

  /**
   * Returns the value of translation message for key `edit`.
   * Default value: `Document details`
   */
  edit: string;

  /**
   * Returns the value of translation message for key `list`.
   * Default value: `Documents`
   */
  list: string;

  /**
   * Returns the value of translation message for key `search`.
   * Default value: `Documents`
   */
  search: string;

}

export interface I18n$Token {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `value`.
   * Default value: `Value`
   */
  value: string;

  /**
   * Returns the value of translation message for key `label`.
   * Default value: `Label`
   */
  label: string;

  /**
   * Returns the value of translation message for key `activationDeadline`.
   * Default value: `Activation deadline`
   */
  activationDeadline: string;

  /**
   * Returns the value of translation message for key `noDeadline`.
   * Default value: `No deadline`
   */
  noDeadline: string;

  /**
   * Returns the value of translation message for key `expiryDate`.
   * Default value: `Expiry date`
   */
  expiryDate: string;

  /**
   * Returns the value of translation message for key `noExpiryDate`.
   * Default value: `Without expiry`
   */
  noExpiryDate: string;

  /**
   * Returns the value of translation message for key `activationDate`.
   * Default value: `Activation date`
   */
  activationDate: string;

  /**
   * Returns the value of translation message for key `creationDate`.
   * Default value: `Creation date`
   */
  creationDate: string;

  /**
   * Returns the value of translation message for key `activationBeginDate`.
   * Default value: `Activation begin date`
   */
  activationBeginDate: string;

  /**
   * Returns the value of translation message for key `activationEndDate`.
   * Default value: `Activation end date`
   */
  activationEndDate: string;

  /**
   * Returns the value of translation message for key `expiryBeginDate`.
   * Default value: `Expiry begin date`
   */
  expiryBeginDate: string;

  /**
   * Returns the value of translation message for key `expiryEndDate`.
   * Default value: `Expiry end date`
   */
  expiryEndDate: string;

  /**
   * Returns the value of translation message for key `unassigned`.
   * Default value: `Unassigned`
   */
  unassigned: string;


  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  title: I18n$Token$Title;


  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  mobileTitle: I18n$Token$MobileTitle;


  /**
   * Returns the nested accessor for translation messages in `action`.
   */
  action: I18n$Token$Action;


  /**
   * Returns the nested accessor for translation messages in `status`.
   */
  status: I18n$Token$Status;

}

export interface I18n$Token$Title {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `assign`.
   * Default value: `Assign`
   */
  assign: string;


  /**
   * Returns the value of translation message for key `new`.
   * Default value: `{0} details`
   */
  new($0: string | number): string;


  /**
   * Returns the value of translation message for key `view`.
   * Default value: `{0} details`
   */
  view($0: string | number): string;

}

export interface I18n$Token$MobileTitle {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;


  /**
   * Returns the value of translation message for key `view`.
   * Default value: `{0} details`
   */
  view($0: string | number): string;

}

export interface I18n$Token$Action {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `activate`.
   * Default value: `Activate`
   */
  activate: string;

  /**
   * Returns the value of translation message for key `assign`.
   * Default value: `Assign`
   */
  assign: string;

  /**
   * Returns the value of translation message for key `block`.
   * Default value: `Block`
   */
  block: string;

  /**
   * Returns the value of translation message for key `cancel`.
   * Default value: `Cancel`
   */
  cancel: string;

  /**
   * Returns the value of translation message for key `changeDeadline`.
   * Default value: `Change deadline`
   */
  changeDeadline: string;

  /**
   * Returns the value of translation message for key `changeExpiry`.
   * Default value: `Change expiry`
   */
  changeExpiry: string;

  /**
   * Returns the value of translation message for key `unblock`.
   * Default value: `Unblock`
   */
  unblock: string;


  /**
   * Returns the nested accessor for translation messages in `done`.
   */
  done: I18n$Token$Action$Done;


  /**
   * Returns the nested accessor for translation messages in `message`.
   */
  message: I18n$Token$Action$Message;

}

export interface I18n$Token$Action$Done {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `activated`.
   * Default value: `The token was activated`
   */
  activated: string;

  /**
   * Returns the value of translation message for key `assigned`.
   * Default value: `The token was assigned`
   */
  assigned: string;

  /**
   * Returns the value of translation message for key `blocked`.
   * Default value: `The token was blocked`
   */
  blocked: string;

  /**
   * Returns the value of translation message for key `canceled`.
   * Default value: `The token was canceled`
   */
  canceled: string;

  /**
   * Returns the value of translation message for key `created`.
   * Default value: `The token was created`
   */
  created: string;

  /**
   * Returns the value of translation message for key `deadlineChanged`.
   * Default value: `The token deadline was changed`
   */
  deadlineChanged: string;

  /**
   * Returns the value of translation message for key `expiryChanged`.
   * Default value: `The token expiry was changed`
   */
  expiryChanged: string;

  /**
   * Returns the value of translation message for key `unblocked`.
   * Default value: `The token was unblocked`
   */
  unblocked: string;

}

export interface I18n$Token$Action$Message {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `cancel`.
   * Default value: `You are about to cancel the token. Be aware that this is a permanent action. Are you sure you want to change the token status to canceled?`
   */
  cancel: string;

}

export interface I18n$Token$Status {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `activationExpired`.
   * Default value: `Activation expired`
   */
  activationExpired: string;

  /**
   * Returns the value of translation message for key `active`.
   * Default value: `Active`
   */
  active: string;

  /**
   * Returns the value of translation message for key `blocked`.
   * Default value: `Blocked`
   */
  blocked: string;

  /**
   * Returns the value of translation message for key `canceled`.
   * Default value: `Canceled`
   */
  canceled: string;

  /**
   * Returns the value of translation message for key `expired`.
   * Default value: `Expired`
   */
  expired: string;

  /**
   * Returns the value of translation message for key `pending`.
   * Default value: `Pending activation`
   */
  pending: string;

  /**
   * Returns the value of translation message for key `unassigned`.
   * Default value: `Unassigned`
   */
  unassigned: string;

}

export interface I18n$Reference {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `set`.
   * Default value: `Set reference`
   */
  set: string;

  /**
   * Returns the value of translation message for key `referenceSet`.
   * Default value: `The reference has been given successfully`
   */
  referenceSet: string;

  /**
   * Returns the value of translation message for key `from`.
   * Default value: `From`
   */
  from: string;

  /**
   * Returns the value of translation message for key `to`.
   * Default value: `To`
   */
  to: string;

  /**
   * Returns the value of translation message for key `notGiven`.
   * Default value: `Not given yet`
   */
  notGiven: string;

  /**
   * Returns the value of translation message for key `value`.
   * Default value: `Value`
   */
  value: string;

  /**
   * Returns the value of translation message for key `allTime`.
   * Default value: `All time`
   */
  allTime: string;

  /**
   * Returns the value of translation message for key `last30Days`.
   * Default value: `Last 30 days`
   */
  last30Days: string;

  /**
   * Returns the value of translation message for key `total`.
   * Default value: `Total`
   */
  total: string;

  /**
   * Returns the value of translation message for key `percentagePositive`.
   * Default value: `Percentage positive`
   */
  percentagePositive: string;


  /**
   * Returns the nested accessor for translation messages in `count`.
   */
  count: I18n$Reference$Count;


  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  title: I18n$Reference$Title;


  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  mobileTitle: I18n$Reference$MobileTitle;


  /**
   * Returns the nested accessor for translation messages in `direction`.
   */
  direction: I18n$Reference$Direction;


  /**
   * Returns the nested accessor for translation messages in `level`.
   */
  level: I18n$Reference$Level;

}

export interface I18n$Reference$Count {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `single`.
   * Default value: `(1 reference)`
   */
  single: string;


  /**
   * Returns the value of translation message for key `multi`.
   * Default value: `({count} references)`
   */
  multi(count: string | number): string;

}

export interface I18n$Reference$Title {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `search`.
   * Default value: `References`
   */
  search: string;

  /**
   * Returns the value of translation message for key `searchGiven`.
   * Default value: `Given references`
   */
  searchGiven: string;

  /**
   * Returns the value of translation message for key `searchReceived`.
   * Default value: `Received references`
   */
  searchReceived: string;

  /**
   * Returns the value of translation message for key `view`.
   * Default value: `Reference details`
   */
  view: string;

  /**
   * Returns the value of translation message for key `set`.
   * Default value: `Set new reference`
   */
  set: string;

  /**
   * Returns the value of translation message for key `edit`.
   * Default value: `Edit given reference`
   */
  edit: string;

}

export interface I18n$Reference$MobileTitle {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `search`.
   * Default value: `References`
   */
  search: string;

  /**
   * Returns the value of translation message for key `searchGiven`.
   * Default value: `Given references`
   */
  searchGiven: string;

  /**
   * Returns the value of translation message for key `searchReceived`.
   * Default value: `Received references`
   */
  searchReceived: string;

  /**
   * Returns the value of translation message for key `view`.
   * Default value: `Reference details`
   */
  view: string;

  /**
   * Returns the value of translation message for key `set`.
   * Default value: `Set new reference`
   */
  set: string;

}

export interface I18n$Reference$Direction {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `direction`.
   * Default value: `Direction`
   */
  direction: string;

  /**
   * Returns the value of translation message for key `received`.
   * Default value: `Received`
   */
  received: string;

  /**
   * Returns the value of translation message for key `given`.
   * Default value: `Given`
   */
  given: string;

}

export interface I18n$Reference$Level {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `level`.
   * Default value: `Level`
   */
  level: string;

  /**
   * Returns the value of translation message for key `bad`.
   * Default value: `Bad`
   */
  bad: string;

  /**
   * Returns the value of translation message for key `veryBad`.
   * Default value: `Very bad`
   */
  veryBad: string;

  /**
   * Returns the value of translation message for key `good`.
   * Default value: `Good`
   */
  good: string;

  /**
   * Returns the value of translation message for key `veryGood`.
   * Default value: `Very good`
   */
  veryGood: string;

  /**
   * Returns the value of translation message for key `neutral`.
   * Default value: `Neutral`
   */
  neutral: string;

}

export interface I18n$Feedback {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `addUserToIgnoreList`.
   * Default value: `Add user to ignore list`
   */
  addUserToIgnoreList: string;

  /**
   * Returns the value of translation message for key `administrationComment`.
   * Default value: `Administration comment`
   */
  administrationComment: string;

  /**
   * Returns the value of translation message for key `viewAwaitingFeedback`.
   * Default value: `View payments awaiting feedback`
   */
  viewAwaitingFeedback: string;

  /**
   * Returns the value of translation message for key `remove.confirm`.
   * Default value: `Are you sure you want to remove this payment feedback?`
   */
  removeConfirm: string;

  /**
   * Returns the value of translation message for key `remove.done`.
   * Default value: `The payment feedback was removed`
   */
  removeDone: string;

  /**
   * Returns the value of translation message for key `buyerFeedback`.
   * Default value: `Buyer feedback`
   */
  buyerFeedback: string;

  /**
   * Returns the value of translation message for key `sellerReply`.
   * Default value: `Seller reply`
   */
  sellerReply: string;

  /**
   * Returns the value of translation message for key `reply`.
   * Default value: `Reply`
   */
  reply: string;

  /**
   * Returns the value of translation message for key `feedbackReplied`.
   * Default value: `Feedback replied successfully`
   */
  feedbackReplied: string;

  /**
   * Returns the value of translation message for key `daysToReplyFeedbackCommentUnlimited`.
   * Default value: `You can give a reply anytime but be aware once you reply it cannot be changed anymore.`
   */
  daysToReplyFeedbackCommentUnlimited: string;

  /**
   * Returns the value of translation message for key `feedbackSet`.
   * Default value: `The payment feedback has been given successfully`
   */
  feedbackSet: string;

  /**
   * Returns the value of translation message for key `paymentFeedbackDisabledFor`.
   * Default value: `Payment feedback disabled for`
   */
  paymentFeedbackDisabledFor: string;

  /**
   * Returns the value of translation message for key `settings`.
   * Default value: `Feedback settings`
   */
  settings: string;


  /**
   * Returns the value of translation message for key `addIgnoredUserDone`.
   * Default value: `{user} added as ignored for payment feedback`
   */
  addIgnoredUserDone(user: string | number): string;


  /**
   * Returns the value of translation message for key `removeUser`.
   * Default value: `Are you sure you want to remove user {user}`
   */
  removeUser(user: string | number): string;


  /**
   * Returns the value of translation message for key `daysToGiveFeedbackComment`.
   * Default value: `You can give feedback until {deadline}. Be aware that feedback cannot be given after due date.`
   */
  daysToGiveFeedbackComment(deadline: string | number): string;


  /**
   * Returns the value of translation message for key `daysToChangeFeedbackComment`.
   * Default value: `You can still change your feedback until {deadline}. Be aware you cannot change your feedback anymore once the receiver replied.`
   */
  daysToChangeFeedbackComment(deadline: string | number): string;


  /**
   * Returns the value of translation message for key `daysToReplyFeedbackComment`.
   * Default value: `You can give a reply until {deadline}. Be aware once you give a reply it cannot be changed anymore.`
   */
  daysToReplyFeedbackComment(deadline: string | number): string;


  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  title: I18n$Feedback$Title;


  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  mobileTitle: I18n$Feedback$MobileTitle;


  /**
   * Returns the nested accessor for translation messages in `count`.
   */
  count: I18n$Feedback$Count;

}

export interface I18n$Feedback$Title {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `search`.
   * Default value: `Feedbacks`
   */
  search: string;

  /**
   * Returns the value of translation message for key `searchGiven`.
   * Default value: `Given feedback`
   */
  searchGiven: string;

  /**
   * Returns the value of translation message for key `searchReceived`.
   * Default value: `Received feedback`
   */
  searchReceived: string;

  /**
   * Returns the value of translation message for key `searchAwaiting`.
   * Default value: `Payments awaiting feedback`
   */
  searchAwaiting: string;

  /**
   * Returns the value of translation message for key `view`.
   * Default value: `Feedback details`
   */
  view: string;

  /**
   * Returns the value of translation message for key `set`.
   * Default value: `Give payment feedback`
   */
  set: string;

  /**
   * Returns the value of translation message for key `edit`.
   * Default value: `Payment feedback details`
   */
  edit: string;

  /**
   * Returns the value of translation message for key `ignoredUsers`.
   * Default value: `Payment feedback ignored users`
   */
  ignoredUsers: string;

}

export interface I18n$Feedback$MobileTitle {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `search`.
   * Default value: `Feedbacks`
   */
  search: string;

  /**
   * Returns the value of translation message for key `searchGiven`.
   * Default value: `Given feedback`
   */
  searchGiven: string;

  /**
   * Returns the value of translation message for key `searchReceived`.
   * Default value: `Received feedback`
   */
  searchReceived: string;

  /**
   * Returns the value of translation message for key `searchAwaiting`.
   * Default value: `Payments awaiting feedback`
   */
  searchAwaiting: string;

  /**
   * Returns the value of translation message for key `view`.
   * Default value: `Feedback details`
   */
  view: string;

  /**
   * Returns the value of translation message for key `ignoredUsers`.
   * Default value: `Payment feedback ignored users`
   */
  ignoredUsers: string;

}

export interface I18n$Feedback$Count {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `single`.
   * Default value: `(1 rating)`
   */
  single: string;


  /**
   * Returns the value of translation message for key `multi`.
   * Default value: `({count} ratings)`
   */
  multi(count: string | number): string;

}

export interface I18n$Product {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `assign`.
   * Default value: `Assign`
   */
  assign: string;

  /**
   * Returns the value of translation message for key `unassign`.
   * Default value: `Unassign`
   */
  unassign: string;

  /**
   * Returns the value of translation message for key `assignIndividualProduct`.
   * Default value: `Assign individual product`
   */
  assignIndividualProduct: string;

  /**
   * Returns the value of translation message for key `userAccount`.
   * Default value: `User account`
   */
  userAccount: string;

  /**
   * Returns the value of translation message for key `product`.
   * Default value: `Product`
   */
  product: string;

  /**
   * Returns the value of translation message for key `action`.
   * Default value: `Action`
   */
  action: string;

  /**
   * Returns the value of translation message for key `productAssigned`.
   * Default value: `The product was assigned to the user`
   */
  productAssigned: string;


  /**
   * Returns the nested accessor for translation messages in `title`.
   */
  title: I18n$Product$Title;


  /**
   * Returns the nested accessor for translation messages in `mobileTitle`.
   */
  mobileTitle: I18n$Product$MobileTitle;


  /**
   * Returns the nested accessor for translation messages in `assigned`.
   */
  assigned: I18n$Product$Assigned;


  /**
   * Returns the nested accessor for translation messages in `kind`.
   */
  kind: I18n$Product$Kind;

}

export interface I18n$Product$Title {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `list`.
   * Default value: `Assigned products`
   */
  list: string;

  /**
   * Returns the value of translation message for key `history`.
   * Default value: `Assigned products history`
   */
  history: string;

}

export interface I18n$Product$MobileTitle {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `list`.
   * Default value: `Assigned products`
   */
  list: string;

  /**
   * Returns the value of translation message for key `history`.
   * Default value: `Assigned products history`
   */
  history: string;

}

export interface I18n$Product$Assigned {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `individual`.
   * Default value: `Products assigned individually to user`
   */
  individual: string;

  /**
   * Returns the value of translation message for key `group`.
   * Default value: `Products assigned to user's group`
   */
  group: string;

  /**
   * Returns the value of translation message for key `groupSet`.
   * Default value: `Products assigned to group set`
   */
  groupSet: string;

}

export interface I18n$Product$Kind {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `administrator`.
   * Default value: `Administrator`
   */
  administrator: string;

  /**
   * Returns the value of translation message for key `broker`.
   * Default value: `Broker`
   */
  broker: string;

  /**
   * Returns the value of translation message for key `member`.
   * Default value: `Member`
   */
  member: string;

}

export interface I18n$Invite {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Invite user to try the system`
   */
  title: string;

  /**
   * Returns the value of translation message for key `mobileTitle`.
   * Default value: `Invite user`
   */
  mobileTitle: string;

  /**
   * Returns the value of translation message for key `toEmail`.
   * Default value: `To e-mail`
   */
  toEmail: string;

  /**
   * Returns the value of translation message for key `toEmails`.
   * Default value: `To e-mails`
   */
  toEmails: string;

  /**
   * Returns the value of translation message for key `subject`.
   * Default value: `Subject`
   */
  subject: string;

  /**
   * Returns the value of translation message for key `body`.
   * Default value: `Body`
   */
  body: string;

  /**
   * Returns the value of translation message for key `assignBroker`.
   * Default value: `Become broker when user registers`
   */
  assignBroker: string;

  /**
   * Returns the value of translation message for key `send`.
   * Default value: `Send`
   */
  send: string;

  /**
   * Returns the value of translation message for key `sent.single`.
   * Default value: `The invitation e-mail was sent`
   */
  sentSingle: string;

  /**
   * Returns the value of translation message for key `sent.multiple`.
   * Default value: `The invitation e-mails were sent`
   */
  sentMultiple: string;

}

export interface I18n$Consent {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `authorize`.
   * Default value: `Authorize`
   */
  authorize: string;

  /**
   * Returns the value of translation message for key `deny`.
   * Default value: `Deny`
   */
  deny: string;

  /**
   * Returns the value of translation message for key `privacyPolicy`.
   * Default value: `Privacy policy`
   */
  privacyPolicy: string;

  /**
   * Returns the value of translation message for key `termsOfService`.
   * Default value: `Terms of service`
   */
  termsOfService: string;


  /**
   * Returns the value of translation message for key `title`.
   * Default value: `Authorize {client} to access your {app} account?`
   */
  title($: {client: string | number, app: string | number}): string;


  /**
   * Returns the value of translation message for key `titleOpenidOnly`.
   * Default value: `Authorize {client} to validate your {app} identity?`
   */
  titleOpenidOnly($: {client: string | number, app: string | number}): string;


  /**
   * Returns the nested accessor for translation messages in `expiration`.
   */
  expiration: I18n$Consent$Expiration;


  /**
   * Returns the nested accessor for translation messages in `permission`.
   */
  permission: I18n$Consent$Permission;

}

export interface I18n$Consent$Expiration {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `never`.
   * Default value: `The authorization won't expire, but can be manually revoked.`
   */
  never: string;


  /**
   * Returns the value of translation message for key `time`.
   * Default value: `The authorization will expire in {time}.`
   */
  time(time: string | number): string;

}

export interface I18n$Consent$Permission {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;


  /**
   * Returns the value of translation message for key `openIdOnly`.
   * Default value: `{client} will not have access to any of your personal data.`
   */
  openIdOnly(client: string | number): string;


  /**
   * Returns the value of translation message for key `requesting`.
   * Default value: `{client} is requesting:`
   */
  requesting(client: string | number): string;

}

export interface I18n$Unsubscribe {

  /**
   * Observable that indicates when the translations have been initialized
   */
  initialized$: BehaviorSubject<boolean>;

  /**
   * Initializes the underlying translations
   */
  $initialize(translations: {[key: string]: any}): void;

  /**
   * Sets the default translations, which are used in case the actual translation isn't found
   */
  $defaults(defaults: {[key: string]: any}): void;

  /**
   * Returns the value of translation message for key `title`.
   * Default value: `E-mail unsubscription`
   */
  title: string;

  /**
   * Returns the value of translation message for key `message.confirm`.
   * Default value: `Are you sure you want to proceed?`
   */
  messageConfirm: string;

  /**
   * Returns the value of translation message for key `message.yes`.
   * Default value: `Yes, unsubscribe`
   */
  messageYes: string;

  /**
   * Returns the value of translation message for key `message.no`.
   * Default value: `No, keep me subscribed`
   */
  messageNo: string;

  /**
   * Returns the value of translation message for key `done.proceed`.
   * Default value: `Proceed`
   */
  doneProceed: string;


  /**
   * Returns the value of translation message for key `message.notification`.
   * Default value: `{name}, you are about to unsubscribe the e-mail address <b>{email}</b> from e-mails generated by notifications.`
   */
  messageNotification($: {name: string | number, email: string | number}): string;


  /**
   * Returns the value of translation message for key `message.message`.
   * Default value: `{name}, you are about to unsubscribe the e-mail address <b>{email}</b> from e-mails generated from internal messages.`
   */
  messageMessage($: {name: string | number, email: string | number}): string;


  /**
   * Returns the value of translation message for key `message.mailing`.
   * Default value: `{name}, you are about to unsubscribe the e-mail address <b>{email}</b> from mailing lists.`
   */
  messageMailing($: {name: string | number, email: string | number}): string;


  /**
   * Returns the value of translation message for key `message.settings`.
   * Default value: `You can manage your <a href="{url}">notification settings here</a>.`
   */
  messageSettings(url: string | number): string;


  /**
   * Returns the value of translation message for key `done.notification`.
   * Default value: `The e-mail address <b>{email}</b> will no longer receive notification e-mails.`
   */
  doneNotification(email: string | number): string;


  /**
   * Returns the value of translation message for key `done.message`.
   * Default value: `The e-mail address <b>{email}</b> will no longer receive e-mails from internal messages.`
   */
  doneMessage(email: string | number): string;


  /**
   * Returns the value of translation message for key `done.mailing`.
   * Default value: `The e-mail address <b>{email}</b> will no longer receive e-mails from mailing lists.`
   */
  doneMailing(email: string | number): string;

}

