/* tslint:disable */
/* eslint-disable */

/**
 * Determines the type of content of a content page in the modern frontend.
 * Possible values are:
 * - `content`: The page displays the content inline.
 * - `iframe`: The page displays an inline frame embedding an external URL
 * - `operation`: The page runs a custom operation of scope `menu`
 * - `url`: The page should open an external URL
 * - `wizard`: The page runs a wizard of scope `menu`
 */
export enum FrontendPageTypeEnum {
  CONTENT = 'content',
  IFRAME = 'iframe',
  OPERATION = 'operation',
  URL = 'url',
  WIZARD = 'wizard'
}
