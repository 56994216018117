/* tslint:disable */
/* eslint-disable */

/**
 * The text alignment
 * Possible values are:
 * - `center`: The text is center-aligned
 * - `left`: The text is left-aligned
 * - `right`: The text is right-aligned
 */
export enum ReceiptPartAlignEnum {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right'
}
