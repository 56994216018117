/* tslint:disable */
/* eslint-disable */

/**
 * The type of theme image to request
 * Possible values are:
 * - `background`: A background image
 * - `custom`: A custom theme image
 * - `mapMarker`: A map marker
 * - `mobile`: An image used by the mobile application
 */
export enum ThemeImageKind {
  BACKGROUND = 'background',
  CUSTOM = 'custom',
  MAP_MARKER = 'mapMarker',
  MOBILE = 'mobile'
}
