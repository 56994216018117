/* tslint:disable */

import { Translations } from './translations';
import { I18n } from './i18n';

export class I18nMeta {

  /**
   * Locales for which translations should be available
   */
  static locales(): string[] {
    return [
      'af',
      'ar',
      'az',
      'ca',
      'cs',
      'de_CH',
      'de',
      'el',
      'en',
      'es',
      'et',
      'eu',
      'fr',
      'hu',
      'in',
      'it',
      'iw',
      'ja',
      'lt',
      'ms',
      'nl',
      'no',
      'pl',
      'pt_BR',
      'pt',
      'ro',
      'ru',
      'sk',
      'sl',
      'sr',
      'sv',
      'sw',
      'th',
      'tr',
      'uk',
      'vi',
      'zh'
    ];
  }

  /**
   * Returns the file name which contains the translations for the given locale
   */
  static fileName(locale: string): string {
    switch (locale) {
      case 'af': return 'i18n.af.json';
      case 'ar': return 'i18n.ar.json';
      case 'az': return 'i18n.az.json';
      case 'ca': return 'i18n.ca.json';
      case 'cs': return 'i18n.cs.json';
      case 'de_CH': return 'i18n.de_CH.json';
      case 'de': return 'i18n.de.json';
      case 'el': return 'i18n.el.json';
      case 'es': return 'i18n.es.json';
      case 'et': return 'i18n.et.json';
      case 'eu': return 'i18n.eu.json';
      case 'fr': return 'i18n.fr.json';
      case 'hu': return 'i18n.hu.json';
      case 'in': return 'i18n.in.json';
      case 'it': return 'i18n.it.json';
      case 'iw': return 'i18n.iw.json';
      case 'ja': return 'i18n.ja.json';
      case 'lt': return 'i18n.lt.json';
      case 'ms': return 'i18n.ms.json';
      case 'nl': return 'i18n.nl.json';
      case 'no': return 'i18n.no.json';
      case 'pl': return 'i18n.pl.json';
      case 'pt_BR': return 'i18n.pt_BR.json';
      case 'pt': return 'i18n.pt.json';
      case 'ro': return 'i18n.ro.json';
      case 'ru': return 'i18n.ru.json';
      case 'sk': return 'i18n.sk.json';
      case 'sl': return 'i18n.sl.json';
      case 'sr': return 'i18n.sr.json';
      case 'sv': return 'i18n.sv.json';
      case 'sw': return 'i18n.sw.json';
      case 'th': return 'i18n.th.json';
      case 'tr': return 'i18n.tr.json';
      case 'uk': return 'i18n.uk.json';
      case 'vi': return 'i18n.vi.json';
      case 'zh': return 'i18n.zh.json';
      default: return 'i18n.json';
    }
  }

  /**
   * Returns a hash for the file contents on the moment it was compiled
   */
  static contentHash(locale: string): string {
    switch (locale) {
      case 'af': return '7bbe686dcfca7f3d99578185561286ec9d6f6328';
      case 'ar': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      case 'az': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      case 'ca': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      case 'cs': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      case 'de_CH': return 'dc7999c6bc7f80a7be0832ea217e10376be50c13';
      case 'de': return '7ac4e0860f086afd5d63e9657de36de508b83d98';
      case 'el': return 'd88a35d76625f828d4b86984635d88c59f4c4b03';
      case 'es': return '73eb7999cd5a69a38cdb483fa0db615fad18d89f';
      case 'et': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      case 'eu': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      case 'fr': return '3fcccd7fa9e6cae1db04e9853645c86d46d6be59';
      case 'hu': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      case 'in': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      case 'it': return '46626f6d02fff73014272fea0632d9e44304b5b3';
      case 'iw': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      case 'ja': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      case 'lt': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      case 'ms': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      case 'nl': return 'c696af6aa694715711bd28afc26bab06d256e817';
      case 'no': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      case 'pl': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      case 'pt_BR': return 'a3d1e5685136c80e369bd8b86a07a0067f48105a';
      case 'pt': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      case 'ro': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      case 'ru': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      case 'sk': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      case 'sl': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      case 'sr': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      case 'sv': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      case 'sw': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      case 'th': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      case 'tr': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      case 'uk': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      case 'vi': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      case 'zh': return 'bf21a9e8fbc5a3846fb05b4fa0859e0917b2202f';
      default: return '1d1499fdd12cf589cf8d31e9f2327369fb241e1a';
    }
  }

  private static _instance: I18n;

  /**
   * Returns the translations implementation.
   * The result is cached.
   */
  static get(): I18n {
    if (!I18nMeta._instance) {
      I18nMeta._instance = this._txI18n().object();
    }
    return I18nMeta._instance;
  }

  private static _txI18n(): Translations<any> {
    const tx = new Translations();

    tx.nested(
      I18nMeta._txI18n$General(`general`),
      I18nMeta._txI18n$Error(`error`),
      I18nMeta._txI18n$Menu(`menu`),
      I18nMeta._txI18n$Login(`login`),
      I18nMeta._txI18n$Password(`password`),
      I18nMeta._txI18n$PendingAgreements(`pendingAgreements`),
      I18nMeta._txI18n$SecurityQuestion(`securityQuestion`),
      I18nMeta._txI18n$Dashboard(`dashboard`),
      I18nMeta._txI18n$Account(`account`),
      I18nMeta._txI18n$Transaction(`transaction`),
      I18nMeta._txI18n$Voucher(`voucher`),
      I18nMeta._txI18n$Field(`field`),
      I18nMeta._txI18n$User(`user`),
      I18nMeta._txI18n$OperatorGroup(`operatorGroup`),
      I18nMeta._txI18n$UserStatus(`userStatus`),
      I18nMeta._txI18n$Agreements(`agreements`),
      I18nMeta._txI18n$GroupMembership(`groupMembership`),
      I18nMeta._txI18n$Brokers(`brokers`),
      I18nMeta._txI18n$Phone(`phone`),
      I18nMeta._txI18n$Address(`address`),
      I18nMeta._txI18n$Ad(`ad`),
      I18nMeta._txI18n$Message(`message`),
      I18nMeta._txI18n$Notification(`notification`),
      I18nMeta._txI18n$NotificationSettings(`notificationSettings`),
      I18nMeta._txI18n$ConnectedUser(`connectedUser`),
      I18nMeta._txI18n$IdentityProvider(`identityProvider`),
      I18nMeta._txI18n$Operation(`operation`),
      I18nMeta._txI18n$Wizard(`wizard`),
      I18nMeta._txI18n$Setting(`setting`),
      I18nMeta._txI18n$PrivacySettings(`privacySettings`),
      I18nMeta._txI18n$Record(`record`),
      I18nMeta._txI18n$UserAlert(`userAlert`),
      I18nMeta._txI18n$SystemAlert(`systemAlert`),
      I18nMeta._txI18n$Document(`document`),
      I18nMeta._txI18n$Token(`token`),
      I18nMeta._txI18n$Reference(`reference`),
      I18nMeta._txI18n$Feedback(`feedback`),
      I18nMeta._txI18n$Product(`product`),
      I18nMeta._txI18n$Invite(`invite`),
      I18nMeta._txI18n$Consent(`consent`),
      I18nMeta._txI18n$Unsubscribe(`unsubscribe`),
    );
    return tx;
  }

  private static _txI18n$General(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `by`,
      `changedBy`,
      `type`,
      `category`,
      `period`,
      `periodTo`,
      `now`,
      `action`,
      `actions`,
      `add`,
      `date`,
      `performedBy`,
      `downloadUrl`,
      `status`,
      `comments`,
      `futureDate`,
      `beginDate`,
      `endDate`,
      `creationDate`,
      `expirationDate`,
      `expirationBeginDate`,
      `expirationEndDate`,
      `newExpirationDate`,
      `notApplied`,
      `user`,
      `operator`,
      `name`,
      `to`,
      `description`,
      `details`,
      `download`,
      `image`,
      `keywords`,
      `distanceFilter`,
      `orderBy`,
      `orderByRelevance`,
      `print`,
      `printed`,
      `all`,
      `copyToClipboard`,
      `copyToClipboardDone`,
      `showFilters`,
      `hideFilters`,
      `moreFilters`,
      `showMoreFilters`,
      `lessFilters`,
      `showLessFilters`,
      `enabled`,
      `disabled`,
      `back`,
      `previous`,
      `next`,
      `view`,
      `skip`,
      `viewHistory`,
      `edit`,
      `confirm`,
      `cancel`,
      `close`,
      `submit`,
      `finish`,
      `remove`,
      `remoteAddress`,
      `save`,
      `addNew`,
      `yes`,
      `no`,
      `notSet`,
      `share`,
      `tiledView`,
      `listView`,
      `categoriesView`,
      `map`,
      `mapView`,
      `noOptionsSelected`,
      `noOptionSelected`,
      `removeItemConfirm`,
      `removeItemDone`,
      `removeAllItemsConfirm`,
      `currency`,
      `images`,
      `internalName`,
      `usersOfBroker`,
      `administration`,
      `maskHelp`,
      `sendCode`,
      `reloadPage`,
      `rangeTo`
    );    tx.positional(`downloadAs`, "format");
    tx.positional(`removeConfirm`, "item");
    tx.positional(`removeDone`, "item");
    tx.positional(`removeTooltip`, "item");
    tx.positional(`sentCodeTo`, "to");

    tx.nested(
      I18nMeta._txI18n$General$SendMedium(`sendMedium`),
      I18nMeta._txI18n$General$SessionExpired(`sessionExpired`),
      I18nMeta._txI18n$General$Month(`month`),
      I18nMeta._txI18n$General$TimeField(`timeField`),
      I18nMeta._txI18n$General$Weekday(`weekday`),
      I18nMeta._txI18n$General$Range(`range`),
      I18nMeta._txI18n$General$FileSize(`fileSize`),
      I18nMeta._txI18n$General$Results(`results`),
      I18nMeta._txI18n$General$Geolocation(`geolocation`),
    );
    return tx;
  }

  private static _txI18n$General$SendMedium(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `email`,
      `sms`
    );
    return tx;
  }

  private static _txI18n$General$SessionExpired(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `title`,
      `message`,
      `loginAgain`
    );
    return tx;
  }

  private static _txI18n$General$Month(property: string): Translations<any> {
    const tx = new Translations(property);

    tx.nested(
      I18nMeta._txI18n$General$Month$Long(`long`),
      I18nMeta._txI18n$General$Month$Short(`short`),
    );
    return tx;
  }

  private static _txI18n$General$Month$Long(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `jan`,
      `feb`,
      `mar`,
      `apr`,
      `may`,
      `jun`,
      `jul`,
      `aug`,
      `sep`,
      `oct`,
      `nov`,
      `dec`
    );
    return tx;
  }

  private static _txI18n$General$Month$Short(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `jan`,
      `feb`,
      `mar`,
      `apr`,
      `may`,
      `jun`,
      `jul`,
      `aug`,
      `sep`,
      `oct`,
      `nov`,
      `dec`
    );
    return tx;
  }

  private static _txI18n$General$TimeField(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.named(`pattern`);

    tx.nested(
      I18nMeta._txI18n$General$TimeField$Plural(`plural`),
      I18nMeta._txI18n$General$TimeField$Singular(`singular`),
    );
    return tx;
  }

  private static _txI18n$General$TimeField$Plural(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `days`,
      `hours`,
      `millis`,
      `minutes`,
      `months`,
      `seconds`,
      `weeks`,
      `years`
    );
    return tx;
  }

  private static _txI18n$General$TimeField$Singular(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `day`,
      `hour`,
      `milli`,
      `minute`,
      `month`,
      `second`,
      `week`,
      `year`
    );
    return tx;
  }

  private static _txI18n$General$Weekday(property: string): Translations<any> {
    const tx = new Translations(property);

    tx.nested(
      I18nMeta._txI18n$General$Weekday$Min(`min`),
      I18nMeta._txI18n$General$Weekday$Long(`long`),
    );
    return tx;
  }

  private static _txI18n$General$Weekday$Min(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `sun`,
      `mon`,
      `tue`,
      `wed`,
      `thu`,
      `fri`,
      `sat`
    );
    return tx;
  }

  private static _txI18n$General$Weekday$Long(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `sun`,
      `mon`,
      `tue`,
      `wed`,
      `thu`,
      `fri`,
      `sat`
    );
    return tx;
  }

  private static _txI18n$General$Range(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.named(`fromTo`);
    tx.positional(`from`, "min");
    tx.positional(`to`, "max");

    return tx;
  }

  private static _txI18n$General$FileSize(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.positional(`b`, "n");
    tx.positional(`k`, "n");
    tx.positional(`m`, "n");

    return tx;
  }

  private static _txI18n$General$Results(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `none`,
      `nextXxs`,
      `previousXxs`
    );    tx.named(`withTotal`);
    tx.named(`noTotal`);

    return tx;
  }

  private static _txI18n$General$Geolocation(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `kilometersFrom`,
      `milesFrom`,
      `current`,
      `myAddress`
    );
    return tx;
  }

  private static _txI18n$Error(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `general`,
      `serverOffline`,
      `invalidRequest`,
      `networkInaccessible`,
      `queryParse`,
      `validation`,
      `staleEntity`,
      `removeDataInUse`,
      `login`,
      `remoteAddressBlocked`,
      `permission`,
      `emailSending`,
      `smsSending`,
      `loggedOut`,
      `illegalAction`
    );    tx.positional(`uploadSizeExceeded`, "size");
    tx.positional(`maxItems`, "max");

    tx.nested(
      I18nMeta._txI18n$Error$NotFound(`notFound`),
      I18nMeta._txI18n$Error$Unauthorized(`unauthorized`),
      I18nMeta._txI18n$Error$SecurityAnswer(`securityAnswer`),
      I18nMeta._txI18n$Error$Field(`field`),
      I18nMeta._txI18n$Error$Geolocation(`geolocation`),
    );
    return tx;
  }

  private static _txI18n$Error$NotFound(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `location`
    );    tx.named(`typeKey`);
    tx.positional(`type`, "type");

    return tx;
  }

  private static _txI18n$Error$Unauthorized(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `address`,
      `url`
    );
    return tx;
  }

  private static _txI18n$Error$SecurityAnswer(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `invalid`,
      `disabled`
    );
    return tx;
  }

  private static _txI18n$Error$Field(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `invalid`,
      `required`,
      `passwordsMatch`,
      `number`,
      `date`,
      `invalidValuePattern`
    );    tx.positional(`minLength`, "min");
    tx.positional(`minDate`, "min");
    tx.positional(`maxDate`, "max");

    return tx;
  }

  private static _txI18n$Error$Geolocation(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `denied`,
      `deniedNoAddressAvailable`,
      `unavailable`,
      `general`
    );
    return tx;
  }

  private static _txI18n$Menu(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `menu`,
      `login`,
      `language`,
      `register`,
      `logout`,
      `shoppingCart`,
      `home`,
      `dashboard`,
      `banking`,
      `bankingAccountsSummary`,
      `bankingAccountVisibility`,
      `bankingPayUser`,
      `bankingPaySystem`,
      `bankingPaySelf`,
      `bankingPaymentRequests`,
      `bankingPaymentRequestsOverview`,
      `bankingExternalPayments`,
      `bankingExternalPaymentsOverview`,
      `bankingPos`,
      `bankingReceiveQrPayment`,
      `bankingScheduledPayments`,
      `bankingScheduledPaymentsOverview`,
      `bankingPendingMyAuth`,
      `bankingAuthorizations`,
      `bankingVouchers`,
      `bankingMyVouchers`,
      `bankingVoucherTransactions`,
      `bankingVoucherTransactionsRedeems`,
      `bankingTransfersOverview`,
      `bankingUserBalancesOverview`,
      `bankingAccountBalanceLimits`,
      `bankingAccountPaymentLimits`,
      `operators`,
      `operatorsOperators`,
      `operatorsRegister`,
      `operatorsGroups`,
      `brokering`,
      `brokeringUsers`,
      `brokeringRegister`,
      `marketplace`,
      `marketplaceUsers`,
      `marketplaceRegister`,
      `marketplaceDirectory`,
      `marketplaceUserSearch`,
      `marketplaceBusinessDirectory`,
      `marketplaceSystemMessages`,
      `marketplaceAdvertisements`,
      `marketplaceAdInterests`,
      `marketplaceDeliveryMethods`,
      `marketplaceInviteUsers`,
      `marketplaceWebshopSettings`,
      `marketplaceMyAdvertisements`,
      `marketplaceMyPurchases`,
      `marketplaceMySales`,
      `marketplaceMyWebshop`,
      `marketplaceUnansweredQuestions`,
      `marketplaceConnectedUsers`,
      `marketplaceUserAlerts`,
      `personal`,
      `personalDocuments`,
      `personalViewProfile`,
      `personalEditProfile`,
      `personalContacts`,
      `personalPassword`,
      `personalPasswords`,
      `personalIdentityProviders`,
      `personalAgreements`,
      `personalMessages`,
      `personalNotifications`,
      `personalSettings`,
      `personalReferences`,
      `personalFeedbacks`,
      `content`
    );
    return tx;
  }

  private static _txI18n$Login(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `title`,
      `mobileTitle`,
      `titleConfirmation`,
      `mobileTitleConfirmation`,
      `message`,
      `disconnected`,
      `principal`,
      `password`,
      `forgotPassword`,
      `register`,
      `confirmationText`
    );
    tx.nested(
      I18nMeta._txI18n$Login$Error(`error`),
    );
    return tx;
  }

  private static _txI18n$Login$Error(property: string): Translations<any> {
    const tx = new Translations(property);

    tx.nested(
      I18nMeta._txI18n$Login$Error$User(`user`),
      I18nMeta._txI18n$Login$Error$Password(`password`),
      I18nMeta._txI18n$Login$Error$Confirmation(`confirmation`),
    );
    return tx;
  }

  private static _txI18n$Login$Error$User(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `blocked`,
      `disabled`,
      `pending`
    );
    return tx;
  }

  private static _txI18n$Login$Error$Password(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `expired`,
      `disabled`,
      `reset`,
      `indefinitelyBlocked`,
      `temporarilyBlocked`
    );
    return tx;
  }

  private static _txI18n$Login$Error$Confirmation(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `missingDevice`
    );    tx.positional(`missingPassword`);
    tx.positional(`missingBoth`);

    return tx;
  }

  private static _txI18n$Password(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `confirmMode`,
      `confirmModeDevice`,
      `confirmModePassword`,
      `oldPassword`,
      `newPassword`,
      `passwordConfirmation`
    );    tx.positional(`confirmPasswordPlaceholder`, "password");
    tx.positional(`otpCountdownLabel`, "seconds");
    tx.positional(`otpReceiveBySent`, "medium");
    tx.positional(`otpSent`, "dest");
    tx.positional(`otpSentToMail`, "mail");
    tx.positional(`otpSentToPhone`, "phone");
    tx.positional(`statusSince`, "date");

    tx.nested(
      I18nMeta._txI18n$Password$ConfirmMessage(`confirmMessage`),
      I18nMeta._txI18n$Password$Error(`error`),
      I18nMeta._txI18n$Password$Title(`title`),
      I18nMeta._txI18n$Password$MobileTitle(`mobileTitle`),
      I18nMeta._txI18n$Password$Forgotten(`forgotten`),
      I18nMeta._txI18n$Password$Expired(`expired`),
      I18nMeta._txI18n$Password$Status(`status`),
      I18nMeta._txI18n$Password$Action(`action`),
    );
    return tx;
  }

  private static _txI18n$Password$ConfirmMessage(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `activeDevice`,
      `activeDeviceOrRenewablePassword`,
      `activeDeviceOrRenewablePasswordNoMediums`,
      `activeDeviceOrNotActiveRenewablePassword`,
      `activeRenewablePassword`,
      `activeRenewablePasswordNoMediums`,
      `notActiveDevice`,
      `notActiveRenewablePassword`
    );    tx.positional(`activeDeviceOrPassword`, "password");
    tx.positional(`activePassword`, "password");
    tx.positional(`notActiveDeviceOrPassword`, "password");
    tx.positional(`notActiveDeviceOrRenewablePasswordNoMediums`, "password");
    tx.positional(`notActivePassword`, "password");
    tx.positional(`notActiveRenewablePasswordNoMediums`, "password");

    return tx;
  }

  private static _txI18n$Password$Error(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `indefinitelyBlocked`,
      `temporarilyBlocked`,
      `expired`,
      `otpInvalidated`
    );    tx.positional(`invalid`, "type");

    return tx;
  }

  private static _txI18n$Password$Title(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `changeSelf`,
      `changeUser`,
      `forgotten`,
      `manageSingleSelf`,
      `manageMultipleSelf`,
      `manageSingleUser`,
      `manageMultipleUser`,
      `securityAnswer`,
      `history`
    );
    return tx;
  }

  private static _txI18n$Password$MobileTitle(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `changeSelf`,
      `forgotten`,
      `manageSingleSelf`,
      `manageMultipleSelf`,
      `manageSingleUser`,
      `manageMultipleUser`,
      `securityAnswer`,
      `history`
    );
    return tx;
  }

  private static _txI18n$Password$Forgotten(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `preface`,
      `principal`,
      `captcha`,
      `code`,
      `sendMedium`,
      `generate`,
      `generatedDoneEmail`,
      `generatedDoneSms`,
      `manualDone`,
      `titleChangePassword`,
      `invalidUser`
    );    tx.positional(`codeSent`, "to");
    tx.positional(`codeBlocked`, "status");

    return tx;
  }

  private static _txI18n$Password$Expired(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `messageManual`,
      `messageGenerated`,
      `generateNew`
    );    tx.named(`generatedValue`);
    tx.positional(`preface`, "type");
    tx.positional(`changed`, "type");

    return tx;
  }

  private static _txI18n$Password$Status(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `active`,
      `disabled`,
      `expired`,
      `indefinitelyBlocked`,
      `neverCreated`,
      `pending`,
      `reset`,
      `temporarilyBlocked`
    );
    return tx;
  }

  private static _txI18n$Password$Action(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `change`,
      `unblock`,
      `activate`,
      `enable`,
      `disable`,
      `resetAndSend`,
      `resetAndSendEmail`,
      `resetAndSendSms`,
      `resetGenerated`,
      `allowGeneration`
    );    tx.named(`changeGeneratedDone`);
    tx.positional(`changeDone`, "type");
    tx.positional(`changeGeneratedConfirm`, "type");
    tx.positional(`unblockConfirm`, "type");
    tx.positional(`unblockDone`, "type");
    tx.positional(`activateConfirm`, "type");
    tx.positional(`enableConfirm`, "type");
    tx.positional(`enableDone`, "type");
    tx.positional(`disableConfirm`, "type");
    tx.positional(`disableDone`, "type");
    tx.positional(`resetAndSendEmailConfirm`, "type");
    tx.positional(`resetAndSendEmailDone`, "type");
    tx.positional(`resetAndSendSmsConfirm`, "type");
    tx.positional(`resetAndSendSmsDone`, "type");
    tx.positional(`resetGeneratedConfirm`, "type");
    tx.positional(`resetGeneratedDone`, "type");
    tx.positional(`allowGenerationConfirm`, "type");
    tx.positional(`allowGenerationDone`, "type");

    return tx;
  }

  private static _txI18n$PendingAgreements(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `messagePreviouslyAccepted`,
      `messageFirstTime`,
      `preface`
    );
    tx.nested(
      I18nMeta._txI18n$PendingAgreements$Title(`title`),
      I18nMeta._txI18n$PendingAgreements$MobileTitle(`mobileTitle`),
    );
    return tx;
  }

  private static _txI18n$PendingAgreements$Title(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `previouslyAccepted`,
      `firstTime`
    );
    return tx;
  }

  private static _txI18n$PendingAgreements$MobileTitle(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `previouslyAccepted`,
      `firstTime`
    );
    return tx;
  }

  private static _txI18n$SecurityQuestion(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `message`,
      `question`,
      `answer`,
      `set`
    );
    return tx;
  }

  private static _txI18n$Dashboard(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `quickAccess`,
      `accounts`,
      `accountStatus`,
      `lastPayments`,
      `noPayments`,
      `latestAds`,
      `latestUsers`
    );    tx.named(`quickAccessShortcutTemplate`);

    tx.nested(
      I18nMeta._txI18n$Dashboard$Action(`action`),
      I18nMeta._txI18n$Dashboard$Passwords(`passwords`),
    );
    return tx;
  }

  private static _txI18n$Dashboard$Action(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `account`,
      `accounts`,
      `payUser`,
      `paySystem`,
      `pos`,
      `receiveQrPayment`,
      `contacts`,
      `directory`,
      `advertisements`,
      `editProfile`,
      `password`,
      `passwords`,
      `switchTheme`,
      `classicFrontend`,
      `redeem`,
      `topUp`,
      `voucherTransactions`,
      `voucherTransactionsRedeems`,
      `buy`,
      `send`,
      `vouchers`,
      `sendPaymentRequestToUser`,
      `sendPaymentRequestToSystem`,
      `paymentRequests`,
      `settings`,
      `scheduledPayments`,
      `payExternalUser`
    );
    return tx;
  }

  private static _txI18n$Dashboard$Passwords(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `securityAnswer`,
      `proceed`
    );    tx.positional(`expired`, "type");
    tx.positional(`reset`, "type");
    tx.positional(`pending`, "type");
    tx.positional(`neverCreated`, "type");

    return tx;
  }

  private static _txI18n$Account(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `account`,
      `number`,
      `transferFilter`,
      `minAmount`,
      `maxAmount`,
      `direction`,
      `incoming`,
      `outgoing`,
      `balance`,
      `reservedAmount`,
      `availableBalance`,
      `negativeLimit`,
      `positiveLimit`,
      `totalIncome`,
      `totalOutflow`,
      `netInflow`,
      `system`
    );    tx.positional(`accountInformation`, "accountName");
    tx.positional(`balanceOn`, "date");

    tx.nested(
      I18nMeta._txI18n$Account$MobileTitle(`mobileTitle`),
      I18nMeta._txI18n$Account$Title(`title`),
      I18nMeta._txI18n$Account$OrderBy(`orderBy`),
      I18nMeta._txI18n$Account$UserBalances(`userBalances`),
      I18nMeta._txI18n$Account$Limits(`limits`),
      I18nMeta._txI18n$Account$VisibilitySettings(`visibilitySettings`),
      I18nMeta._txI18n$Account$BalanceLimits(`balanceLimits`),
      I18nMeta._txI18n$Account$PaymentLimits(`paymentLimits`),
    );
    return tx;
  }

  private static _txI18n$Account$MobileTitle(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `account`,
      `accountsSummary`
    );
    return tx;
  }

  private static _txI18n$Account$Title(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `accountsSummary`
    );
    return tx;
  }

  private static _txI18n$Account$OrderBy(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `dateDesc`,
      `dateAsc`,
      `amountAsc`,
      `amountDesc`
    );
    return tx;
  }

  private static _txI18n$Account$UserBalances(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `negativeSince`,
      `title`,
      `mobileTitle`,
      `minBalance`,
      `maxBalance`,
      `balancesSum`,
      `balancesAverage`,
      `balancesCount`,
      `total`,
      `fromYellowBalance`,
      `toYellowBalance`,
      `beginNegativeSince`,
      `endNegativeSince`
    );
    tx.nested(
      I18nMeta._txI18n$Account$UserBalances$OrderBy(`orderBy`),
    );
    return tx;
  }

  private static _txI18n$Account$UserBalances$OrderBy(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `alphabeticallyDesc`,
      `alphabeticallyAsc`,
      `balanceAsc`,
      `balanceDesc`
    );
    return tx;
  }

  private static _txI18n$Account$Limits(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `unlimited`,
      `mode`,
      `personalized`,
      `productDefault`
    );
    return tx;
  }

  private static _txI18n$Account$VisibilitySettings(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `title`,
      `mobileTitle`,
      `information`,
      `accounts`,
      `saved`
    );
    return tx;
  }

  private static _txI18n$Account$BalanceLimits(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `negativeLimit`,
      `positiveLimit`,
      `personalizedNegativeLimit`,
      `personalizedPositiveLimit`,
      `fromNegativeLimit`,
      `toNegativeLimit`,
      `fromPositiveLimit`,
      `toPositiveLimit`,
      `negative`,
      `positive`,
      `confirm`,
      `saved`
    );
    tx.nested(
      I18nMeta._txI18n$Account$BalanceLimits$Title(`title`),
      I18nMeta._txI18n$Account$BalanceLimits$MobileTitle(`mobileTitle`),
    );
    return tx;
  }

  private static _txI18n$Account$BalanceLimits$Title(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `list`,
      `view`,
      `edit`,
      `history`,
      `overview`
    );
    return tx;
  }

  private static _txI18n$Account$BalanceLimits$MobileTitle(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `list`,
      `view`,
      `edit`,
      `history`,
      `overview`
    );
    return tx;
  }

  private static _txI18n$Account$PaymentLimits(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `paymentLimit`,
      `dailyLimit`,
      `weeklyLimit`,
      `monthlyLimit`,
      `yearlyLimit`,
      `personalizedPaymentLimit`,
      `personalizedDailyLimit`,
      `personalizedWeeklyLimit`,
      `personalizedMonthlyLimit`,
      `personalizedYearlyLimit`,
      `fromPaymentLimit`,
      `fromDailyLimit`,
      `fromWeeklyLimit`,
      `fromMonthlyLimit`,
      `fromYearlyLimit`,
      `toPaymentLimit`,
      `toDailyLimit`,
      `toWeeklyLimit`,
      `toMonthlyLimit`,
      `toYearlyLimit`,
      `definedInPaymentType`,
      `confirm`,
      `saved`
    );
    tx.nested(
      I18nMeta._txI18n$Account$PaymentLimits$Title(`title`),
      I18nMeta._txI18n$Account$PaymentLimits$MobileTitle(`mobileTitle`),
    );
    return tx;
  }

  private static _txI18n$Account$PaymentLimits$Title(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `list`,
      `view`,
      `edit`,
      `history`,
      `overview`
    );
    return tx;
  }

  private static _txI18n$Account$PaymentLimits$MobileTitle(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `list`,
      `view`,
      `edit`,
      `history`,
      `overview`
    );
    return tx;
  }

  private static _txI18n$Transaction(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `number`,
      `amount`,
      `type`,
      `fromTo`,
      `from`,
      `to`,
      `sender`,
      `receiver`,
      `senderOrReceiver`,
      `sent`,
      `received`,
      `direction`,
      `account`,
      `fromAccount`,
      `fromUser`,
      `toAccount`,
      `toUser`,
      `requiresAuthorization`,
      `appliedFees`,
      `totalAmount`,
      `dueAmount`,
      `dueDate`,
      `processDate`,
      `processDateTooltip`,
      `nextOccurrence`,
      `channel`,
      `receivedBy`,
      `chargedBack`,
      `chargebackOf`,
      `chargedBackBy`,
      `awaitingAuthorizationBy`,
      `awaitingAuthorizationByAdmin`,
      `awaitingAuthorizationByBroker`,
      `awaitingAuthorizationByMissingBroker`,
      `awaitingAuthorizationByPayer`,
      `awaitingAuthorizationByReceiver`,
      `authorizationComments`,
      `actionPerformedBy`,
      `accountBalance`,
      `installments`,
      `installmentNumber`,
      `occurrences`,
      `occurrenceInterval`,
      `feesOnAuthorization`,
      `noAccounts`,
      `noExternalPaymentPrincipalTypes`,
      `noTypes`,
      `noTypesSelection`,
      `transferKinds`,
      `transferStatus`,
      `authorizationRoles`,
      `scheduling`,
      `schedulingDirect`,
      `schedulingSingle`,
      `schedulingInstallments`,
      `schedulingRecurring`,
      `recurringPayment`,
      `recurringPaymentNowManual`,
      `installmentsCount`,
      `firstInstallment`,
      `firstInstallmentDate`,
      `repeatUntil`,
      `repeatUntilManual`,
      `repeatUntilFixed`,
      `occurrencesCount`,
      `firstOccurrence`,
      `firstOccurrenceDate`,
      `processed`,
      `pending`,
      `paymentRequestSent`,
      `externalPaymentPerformed`,
      `doneNew`,
      `doneAddReceiverToContacts`,
      `ticketShareTitle`,
      `ticketShareUrl`,
      `generatedTransaction`,
      `viewAuthorizations`,
      `viewThisTransfer`,
      `viewThisVoucher`,
      `payExternalUser`,
      `sendPaymentRequestAsSelfToSystem`,
      `sendPaymentRequestAsSelfToUser`,
      `sendPaymentRequestAsUserToSystem`,
      `sendPaymentRequestAsUserToUser`,
      `sendPaymentRequestFromSystemToUser`,
      `authorizePending`,
      `authorizePendingDoneStillPending`,
      `authorizePendingDone`,
      `denyPending`,
      `denyPendingDone`,
      `cancelAuthorization`,
      `cancelAuthorizationDone`,
      `blockScheduling`,
      `blockSchedulingMessage`,
      `blockSchedulingDone`,
      `unblockScheduling`,
      `unblockSchedulingMessage`,
      `unblockSchedulingDone`,
      `cancelScheduled`,
      `cancelScheduledMessage`,
      `cancelScheduledDone`,
      `settleScheduled`,
      `settleScheduledMessage`,
      `settleScheduledDone`,
      `processScheduled`,
      `processScheduledMessage`,
      `processScheduledDone`,
      `blockRecurring`,
      `blockRecurringMessage`,
      `blockRecurringDone`,
      `unblockRecurring`,
      `unblockRecurringMessage`,
      `unblockRecurringDone`,
      `cancelRecurring`,
      `cancelRecurringMessage`,
      `cancelRecurringDone`,
      `editRecurring`,
      `editRecurringDone`,
      `chargebackTransfer`,
      `chargebackTransferMessage`,
      `chargebackTransferDone`,
      `processInstallment`,
      `processInstallmentDone`,
      `settleInstallment`,
      `settleInstallmentDone`,
      `processFailedOccurrence`,
      `processFailedOccurrenceDone`,
      `acceptPaymentRequest`,
      `paymentRequestSchedulingNow`,
      `paymentRequestSchedulingDate`,
      `cancelPaymentRequest`,
      `cancelPaymentRequestDone`,
      `cancelExternalPayment`,
      `cancelExternalPaymentDone`,
      `rejectPaymentRequest`,
      `rejectPaymentRequestDone`,
      `changePaymentRequestExpiration`,
      `changePaymentRequestExpirationDone`,
      `reschedulePaymentRequest`,
      `reschedulePaymentRequestDone`,
      `reschedulePaymentRequestRescheduleTo`,
      `reschedulePaymentRequestRescheduleToDate`,
      `confirmPaymentRequest`,
      `firstOccurrenceIsImmediate`,
      `firstInstallmentIsImmediate`,
      `voucherRedeemed`,
      `voucherToppedUp`
    );    tx.named(`fromToText`);
    tx.named(`installmentNumberOfTotal`);
    tx.named(`recurringPaymentDateFixed`);
    tx.named(`ticketShareText`);
    tx.positional(`awaitingAuthorizationByOperatorOwner`, "user");
    tx.positional(`noVisibleAccounts`);
    tx.positional(`recurringPaymentDateManual`, "date");
    tx.positional(`recurringPaymentNowFixed`, "count");
    tx.positional(`recurringPaymentOccurrenceInterval`, "interval");
    tx.positional(`repeatUntilNumberOfOccurrences`, "number");
    tx.positional(`paymentRequestAcceptScheduled`, "date");
    tx.positional(`doneUserAddedToContacts`, "contact");
    tx.positional(`processInstallmentMessage`, "number");
    tx.positional(`settleInstallmentMessage`, "number");
    tx.positional(`processFailedOccurrenceMessage`, "number");
    tx.positional(`paymentRequestSchedulingExpiration`, "date");

    tx.nested(
      I18nMeta._txI18n$Transaction$Transferkind(`transferkind`),
      I18nMeta._txI18n$Transaction$SchedulingStatus(`schedulingStatus`),
      I18nMeta._txI18n$Transaction$Status(`status`),
      I18nMeta._txI18n$Transaction$Title(`title`),
      I18nMeta._txI18n$Transaction$MobileTitle(`mobileTitle`),
      I18nMeta._txI18n$Transaction$ConfirmMessage(`confirmMessage`),
      I18nMeta._txI18n$Transaction$Error(`error`),
    );
    return tx;
  }

  private static _txI18n$Transaction$Transferkind(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `accountFee`,
      `chargeback`,
      `import`,
      `initialCredit`,
      `installment`,
      `payment`,
      `transferFee`
    );
    return tx;
  }

  private static _txI18n$Transaction$SchedulingStatus(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `direct`,
      `closedRecurring`,
      `canceledRecurring`
    );    tx.named(`openInstallments`);
    tx.positional(`scheduledToDate`, "date");
    tx.positional(`closedInstallments`, "count");
    tx.positional(`openRecurring`, "date");

    return tx;
  }

  private static _txI18n$Transaction$Status(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `open`,
      `closed`,
      `canceled`,
      `expired`,
      `approved`,
      `processed`,
      `authorized`,
      `pending`,
      `pendingAuthorization`,
      `denied`,
      `blocked`,
      `scheduled`,
      `failed`,
      `settled`
    );
    return tx;
  }

  private static _txI18n$Transaction$Title(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `pos`,
      `paymentSystemToSystem`,
      `paymentSystemToUser`,
      `paymentToSystem`,
      `paymentToSelf`,
      `paymentToUser`,
      `paymentConfirmation`,
      `pendingMyAuthorization`,
      `authorizations`,
      `authorizationHistory`,
      `scheduled`,
      `detailsPayment`,
      `detailsScheduled`,
      `detailsRecurring`,
      `detailsRequest`,
      `detailsChargeback`,
      `detailsTicket`,
      `detailsExternal`,
      `detailsTransfer`,
      `parentTransfer`,
      `childTransfer`,
      `childTransfers`,
      `transfersOverview`,
      `receiveQrPayment`,
      `paymentRequestSystemToUser`,
      `paymentRequestToSystem`,
      `paymentRequestToUser`,
      `paymentRequests`,
      `paymentRequestsOverview`,
      `externalPayments`,
      `externalPaymentsOverview`,
      `externalPaymentFromSystem`,
      `externalPaymentFromUser`,
      `sentVoucher`,
      `boughtVoucher`,
      `boughtVouchers`,
      `editRecurringPayment`
    );
    return tx;
  }

  private static _txI18n$Transaction$MobileTitle(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `pos`,
      `paymentSystemToSystem`,
      `paymentSystemToUser`,
      `paymentToSystem`,
      `paymentToSelf`,
      `paymentToUser`,
      `paymentConfirmation`,
      `pendingMyAuthorization`,
      `authorizations`,
      `authorizationHistory`,
      `scheduled`,
      `detailsPayment`,
      `detailsScheduled`,
      `detailsRecurring`,
      `detailsRequest`,
      `detailsChargeback`,
      `detailsTicket`,
      `detailsExternal`,
      `detailsTransfer`,
      `parentTransfer`,
      `childTransfer`,
      `childTransfers`,
      `transfersOverview`,
      `receiveQrPayment`,
      `paymentRequestSystemToUser`,
      `paymentRequestToSystem`,
      `paymentRequestToUser`,
      `paymentRequests`,
      `paymentRequestsOverview`,
      `externalPayments`,
      `externalPaymentsOverview`,
      `externalPaymentFromSystem`,
      `externalPaymentFromUser`,
      `sentVoucher`,
      `boughtVoucher`,
      `boughtVouchers`,
      `editRecurringPayment`
    );
    return tx;
  }

  private static _txI18n$Transaction$ConfirmMessage(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `activeDevice`,
      `activeDeviceOrRenewablePassword`,
      `activeDeviceOrRenewablePasswordNoMediums`,
      `activeDeviceOrNotActiveRenewablePassword`,
      `activeRenewablePassword`,
      `activeRenewablePasswordNoMediums`,
      `notActiveDevice`,
      `notActiveRenewablePassword`
    );    tx.positional(`activeDeviceOrPassword`, "password");
    tx.positional(`activePassword`, "password");
    tx.positional(`notActiveDeviceOrPassword`, "password");
    tx.positional(`notActiveDeviceOrRenewablePasswordNoMediums`, "password");
    tx.positional(`notActivePassword`, "password");
    tx.positional(`notActiveRenewablePasswordNoMediums`, "password");

    return tx;
  }

  private static _txI18n$Transaction$Error(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `minTime`,
      `balance`,
      `upperLimit`
    );    tx.positional(`dailyAmount`, "amount");
    tx.positional(`dailyCount`, "count");
    tx.positional(`weeklyAmount`, "amount");
    tx.positional(`weeklyCount`, "count");
    tx.positional(`monthlyAmount`, "amount");
    tx.positional(`monthlyCount`, "count");
    tx.positional(`yearlyAmount`, "amount");

    return tx;
  }

  private static _txI18n$Voucher(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `configuration`,
      `originAccount`,
      `numberOfVouchers`,
      `amountPerVoucher`,
      `totalAmount`,
      `balance`,
      `buyerGroups`,
      `pin`,
      `token`,
      `transactions`,
      `transactionsRedeems`,
      `owner`,
      `sentToEmail`,
      `email`,
      `mobilePhone`,
      `enableNotifications`,
      `notificationsEnabled`,
      `expirationDateComments`,
      `changeExpirationDate`,
      `expirationDateChanged`,
      `assign`,
      `assigned`,
      `refundPayment`,
      `refundDate`,
      `otherCategory`,
      `showCategories`,
      `listTypes`,
      `voucher`
    );    tx.positional(`personal`, "buyer");

    tx.nested(
      I18nMeta._txI18n$Voucher$Error(`error`),
      I18nMeta._txI18n$Voucher$Redeem(`redeem`),
      I18nMeta._txI18n$Voucher$TopUp(`topUp`),
      I18nMeta._txI18n$Voucher$Transaction(`transaction`),
      I18nMeta._txI18n$Voucher$Status(`status`),
      I18nMeta._txI18n$Voucher$MobileTitle(`mobileTitle`),
      I18nMeta._txI18n$Voucher$Title(`title`),
      I18nMeta._txI18n$Voucher$CreationType(`creationType`),
      I18nMeta._txI18n$Voucher$Buy(`buy`),
      I18nMeta._txI18n$Voucher$Send(`send`),
      I18nMeta._txI18n$Voucher$Generate(`generate`),
      I18nMeta._txI18n$Voucher$PinStatusForRedeem(`pinStatusForRedeem`),
      I18nMeta._txI18n$Voucher$UnblockPin(`unblockPin`),
      I18nMeta._txI18n$Voucher$Cancel(`cancel`),
      I18nMeta._txI18n$Voucher$ResendEmail(`resendEmail`),
      I18nMeta._txI18n$Voucher$NotificationSettings(`notificationSettings`),
      I18nMeta._txI18n$Voucher$ChangePin(`changePin`),
      I18nMeta._txI18n$Voucher$Sort(`sort`),
      I18nMeta._txI18n$Voucher$Info(`info`),
    );
    return tx;
  }

  private static _txI18n$Voucher$Error(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.named(`totalOpenAmount`);

    tx.nested(
      I18nMeta._txI18n$Voucher$Error$Buy(`buy`),
      I18nMeta._txI18n$Voucher$Error$Redeem(`redeem`),
      I18nMeta._txI18n$Voucher$Error$TopUp(`topUp`),
    );
    return tx;
  }

  private static _txI18n$Voucher$Error$Buy(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `notAllowedForUser`
    );    tx.named(`amountForPeriod`);
    tx.named(`openAmount`);

    return tx;
  }

  private static _txI18n$Voucher$Error$Redeem(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `user`,
      `userBlocked`,
      `insufficientBalance`
    );    tx.positional(`status`, "status");
    tx.positional(`notAllowedYet`, "beginDate");
    tx.positional(`notAllowedToday`, "allowedDays");
    tx.positional(`insufficientBalanceBalance`, "balance");

    return tx;
  }

  private static _txI18n$Voucher$Error$TopUp(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `alreadyActivated`,
      `maxBalanceReached`
    );    tx.positional(`status`, "status");
    tx.positional(`activationExpired`, "date");

    return tx;
  }

  private static _txI18n$Voucher$Redeem(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `confirm`,
      `done`,
      `redeem`,
      `afterDate`,
      `onDays`,
      `onDaysAny`,
      `redeemer`,
      `date`,
      `amount`,
      `by`,
      `payment`
    );
    return tx;
  }

  private static _txI18n$Voucher$TopUp(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `confirm`,
      `done`,
      `topUp`,
      `sendPinBy`,
      `sendNotificationsBy`,
      `dontSendNotifications`,
      `manualPin`,
      `pin`,
      `pinConfirm`
    );
    return tx;
  }

  private static _txI18n$Voucher$Transaction(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `amount`,
      `date`,
      `user`,
      `userGroups`,
      `payment`,
      `kind`,
      `redeem`,
      `topUp`,
      `chargeback`,
      `searchResultChargebackTitle`,
      `chargebackOf`,
      `chargedBackBy`,
      `viewTransaction`
    );
    return tx;
  }

  private static _txI18n$Voucher$Status(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `redeemed`,
      `inactive`,
      `pending`,
      `open`,
      `expired`,
      `canceled`,
      `activationExpired`,
      `blocked`
    );
    return tx;
  }

  private static _txI18n$Voucher$MobileTitle(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `buy`,
      `buyConfirmation`,
      `send`,
      `sendConfirmation`,
      `redeem`,
      `topUp`,
      `redeemDetails`,
      `topUpDetails`,
      `chargebackDetails`,
      `transactions`,
      `transactionsRedeems`,
      `search`,
      `generate`,
      `generateConfirmation`
    );
    return tx;
  }

  private static _txI18n$Voucher$Title(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `buy`,
      `buyConfirmation`,
      `send`,
      `sendConfirmation`,
      `redeem`,
      `topUp`,
      `redeemDetails`,
      `topUpDetails`,
      `chargebackDetails`,
      `transactions`,
      `transactionsRedeems`,
      `search`,
      `generate`,
      `generateConfirmation`
    );
    return tx;
  }

  private static _txI18n$Voucher$CreationType(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `label`,
      `userLabel`,
      `generated`,
      `bought`,
      `sent`
    );
    return tx;
  }

  private static _txI18n$Voucher$Buy(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `amountRange`,
      `minimumAmount`,
      `maximumAmount`,
      `buy`,
      `buyer`,
      `done`,
      `minTimeToRedeem`,
      `payment`,
      `usage`,
      `usageSelf`,
      `usageGift`,
      `usageAlwaysSelf`
    );    tx.positional(`minTimeToRedeemAfterBuy`, "interval");

    return tx;
  }

  private static _txI18n$Voucher$Send(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `send`,
      `issuer`,
      `message`
    );    tx.positional(`done`, "email");

    return tx;
  }

  private static _txI18n$Voucher$Generate(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `generate`,
      `done`
    );
    return tx;
  }

  private static _txI18n$Voucher$PinStatusForRedeem(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `status`,
      `blocked`,
      `notUsed`,
      `required`,
      `subsequent`
    );
    return tx;
  }

  private static _txI18n$Voucher$UnblockPin(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `unblockPin`,
      `done`
    );
    return tx;
  }

  private static _txI18n$Voucher$Cancel(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `cancelAndRefund`,
      `done`,
      `packConfirmation`,
      `refundConfirmation`,
      `confirmation`
    );
    return tx;
  }

  private static _txI18n$Voucher$ResendEmail(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `label`,
      `done`
    );    tx.positional(`confirmation`, "email");

    return tx;
  }

  private static _txI18n$Voucher$NotificationSettings(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `label`,
      `title`,
      `done`
    );
    return tx;
  }

  private static _txI18n$Voucher$ChangePin(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `done`,
      `label`,
      `title`,
      `oldPin`,
      `newPin`,
      `pinConfirmation`
    );
    return tx;
  }

  private static _txI18n$Voucher$Sort(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `creationDateDesc`,
      `creationDateAsc`,
      `expirationDateDesc`,
      `expirationDateAsc`,
      `redeemDateDesc`,
      `redeemDateAsc`
    );
    return tx;
  }

  private static _txI18n$Voucher$Info(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `title`,
      `messageToken`,
      `messagePin`,
      `messagePinBlockedVoucher`,
      `home`,
      `exit`,
      `unblock`
    );
    tx.nested(
      I18nMeta._txI18n$Voucher$Info$ForgotPin(`forgotPin`),
      I18nMeta._txI18n$Voucher$Info$ActivateGift(`activateGift`),
      I18nMeta._txI18n$Voucher$Info$ChangePin(`changePin`),
    );
    return tx;
  }

  private static _txI18n$Voucher$Info$ForgotPin(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `title`,
      `action`
    );    tx.named(`messageAllMediums`);
    tx.positional(`message`, "medium");
    tx.positional(`pinSentTo`, "to");

    return tx;
  }

  private static _txI18n$Voucher$Info$ActivateGift(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `title`
    );    tx.positional(`message`, "issuer");
    tx.positional(`agreement`, "issuer");

    return tx;
  }

  private static _txI18n$Voucher$Info$ChangePin(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `pinChanged`,
      `title`,
      `oldPin`,
      `newPin`,
      `newPinConfirmation`,
      `action`
    );
    return tx;
  }

  private static _txI18n$Field(property: string): Translations<any> {
    const tx = new Translations(property);

    tx.nested(
      I18nMeta._txI18n$Field$DistanceSelection(`distanceSelection`),
      I18nMeta._txI18n$Field$Privacy(`privacy`),
      I18nMeta._txI18n$Field$Image(`image`),
      I18nMeta._txI18n$Field$File(`file`),
      I18nMeta._txI18n$Field$User(`user`),
      I18nMeta._txI18n$Field$Camera(`camera`),
      I18nMeta._txI18n$Field$Html(`html`),
    );
    return tx;
  }

  private static _txI18n$Field$DistanceSelection(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `clear`,
      `selectTooltip`
    );    tx.positional(`errorAddressNotLocated`, "address");

    return tx;
  }

  private static _txI18n$Field$Privacy(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `name`,
      `privateTooltip`,
      `publicTooltip`
    );
    return tx;
  }

  private static _txI18n$Field$Image(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `upload`,
      `camera`,
      `manage`,
      `remove`,
      `manageMessage`,
      `manageAfterConfirm`
    );
    return tx;
  }

  private static _txI18n$Field$File(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `upload`,
      `manage`,
      `remove`,
      `manageMessage`
    );
    return tx;
  }

  private static _txI18n$Field$User(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `placeholderAllowSearch`,
      `placeholderPrincipal`,
      `contactTooltip`,
      `contactTitle`,
      `scanQrCodeTooltip`,
      `locateTooltip`
    );
    return tx;
  }

  private static _txI18n$Field$Camera(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `titleCapture`,
      `titleQrCode`,
      `capture`,
      `noCameras`,
      `noPermission`
    );    tx.positional(`fileName`, "timestamp");

    return tx;
  }

  private static _txI18n$Field$Html(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `undo`,
      `redo`,
      `font`,
      `fontDefault`,
      `fontSansSerif`,
      `fontSerif`,
      `fontMonospace`,
      `size`,
      `size1`,
      `size2`,
      `size3`,
      `size4`,
      `size5`,
      `size6`,
      `size7`,
      `block`,
      `blockH1`,
      `blockH2`,
      `blockH3`,
      `blockH4`,
      `blockH5`,
      `blockH6`,
      `blockP`,
      `blockPre`,
      `alignLeft`,
      `alignRight`,
      `alignCenter`,
      `alignJustify`,
      `bold`,
      `italic`,
      `underline`,
      `strikethrough`,
      `removeFormat`,
      `listBulleted`,
      `listNumbered`,
      `indentLess`,
      `indentMore`,
      `source`,
      `unlink`
    );
    tx.nested(
      I18nMeta._txI18n$Field$Html$Link(`link`),
      I18nMeta._txI18n$Field$Html$Image(`image`),
    );
    return tx;
  }

  private static _txI18n$Field$Html$Link(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `tooltip`,
      `title`,
      `href`,
      `text`
    );
    return tx;
  }

  private static _txI18n$Field$Html$Image(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `tooltip`,
      `titleInsert`,
      `titleProperties`,
      `sourceUrl`,
      `url`,
      `sourceExisting`,
      `noImages`,
      `upload`,
      `width`,
      `height`,
      `contrain`,
      `float`,
      `floatNone`,
      `floatLeft`,
      `floatRight`,
      `marginTop`,
      `marginBottom`,
      `marginLeft`,
      `marginRight`,
      `border`,
      `textExample`
    );
    return tx;
  }

  private static _txI18n$User(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `statusEmptyLabel`,
      `connected`,
      `connectionStatus`,
      `neverLogged`,
      `group`,
      `broker`,
      `groups`,
      `groupSet`,
      `groupFilter`,
      `registrationDate`,
      `activationDate`,
      `name`,
      `username`,
      `email`,
      `emailPending`,
      `operatorOwner`,
      `operatorNoGroup`,
      `operatorRegistrationAddAnother`,
      `noImageRequired`,
      `noImage`,
      `noImages`,
      `addressDefine`,
      `addressAdd`,
      `addressRemove`,
      `addressNone`,
      `phoneAdd`,
      `phoneAddMobile`,
      `phoneAddLandLine`,
      `phoneRemove`,
      `phoneNone`,
      `contactInfo`,
      `contactInfoAdd`,
      `contactInfoRemove`,
      `contactInfoNone`,
      `contactInfoAtAddress`,
      `profileSaved`,
      `newEmailConfirmed`,
      `securityQuestionMessage`,
      `securityQuestionEmpty`,
      `captcha`,
      `contact`,
      `invitedBy`,
      `invitedByMe`,
      `creationBegin`,
      `creationEnd`,
      `acceptedAgreements`,
      `notAcceptedAgreements`,
      `assignedProducts`,
      `beginUserActivationDate`,
      `endUserActivationDate`,
      `beginLastLoginDate`,
      `endLastLoginDate`,
      `activities`
    );    tx.positional(`disconnectedStatus`, "date");
    tx.positional(`operatorRegistrationActive`, "operator");
    tx.positional(`operatorRegistrationInactive`, "operator");
    tx.positional(`operatorRegistrationPending`, "operator");
    tx.positional(`passwordConfirmation`, "type");

    tx.nested(
      I18nMeta._txI18n$User$Title(`title`),
      I18nMeta._txI18n$User$MobileTitle(`mobileTitle`),
      I18nMeta._txI18n$User$Profile(`profile`),
      I18nMeta._txI18n$User$Registration(`registration`),
      I18nMeta._txI18n$User$OrderBy(`orderBy`),
    );
    return tx;
  }

  private static _txI18n$User$Title(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `myProfile`,
      `editProfile`,
      `image`,
      `images`,
      `fields`,
      `phones`,
      `addresses`,
      `contactInfos`,
      `confirmation`,
      `registration`,
      `registrationConfirmation`,
      `registrationDone`,
      `contactList`,
      `addContact`,
      `search`,
      `directory`,
      `myOperators`,
      `userOperators`,
      `operatorRegistration`,
      `myBrokerings`,
      `userBrokerings`
    );
    return tx;
  }

  private static _txI18n$User$MobileTitle(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `myProfile`,
      `userProfile`,
      `editProfile`,
      `registration`,
      `registrationConfirmation`,
      `registrationDone`,
      `contactList`,
      `search`,
      `directory`,
      `myOperators`,
      `userOperators`,
      `operatorRegistration`,
      `myBrokerings`,
      `userBrokerings`
    );
    return tx;
  }

  private static _txI18n$User$Profile(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `accountsSummary`,
      `accountVisibility`,
      `noPermission`,
      `showActions`,
      `hideActions`,
      `banking`,
      `management`,
      `edit`,
      `managePasswords`,
      `viewIdentityProviders`,
      `addContact`,
      `removeContact`,
      `pay`,
      `paySystemToUser`,
      `payAsUserToSystem`,
      `payAsUserToUser`,
      `payAsUserToSelf`,
      `requestPayment`,
      `purchases`,
      `sales`,
      `viewScheduledPayments`,
      `viewAuthorizedPayments`,
      `paymentRequests`,
      `externalPayments`,
      `viewAds`,
      `viewBrokerings`,
      `viewBrokers`,
      `viewOperators`,
      `viewOperatorGroups`,
      `viewWebshop`,
      `adInterests`,
      `deliveryMethods`,
      `webshopSettings`,
      `statusUser`,
      `statusOperator`,
      `groupUser`,
      `groupOperator`,
      `agreements`,
      `vouchers`,
      `voucherBuy`,
      `voucherSend`,
      `voucherTransactions`,
      `voucherTransactionsRedeems`,
      `voucherRedeem`,
      `voucherTopUp`,
      `notificationSettings`,
      `products`,
      `accountsBalanceLimits`,
      `accountsPaymentLimits`,
      `privacySettings`,
      `references`,
      `feedbacks`,
      `activityInPeriod`,
      `activityAllTime`,
      `sendMessage`
    );    tx.positional(`addContactDone`, "user");
    tx.positional(`removeContactDone`, "user");
    tx.positional(`viewAccount`, "account");
    tx.positional(`balance`, "balance");
    tx.positional(`availableBalance`, "balance");
    tx.positional(`transactions`, "transactions");
    tx.positional(`totalReceived`, "received");
    tx.positional(`totalPaid`, "paid");

    return tx;
  }

  private static _txI18n$User$Registration(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `groupPublic`,
      `groupManager`,
      `skipActivationEmail`,
      `passwordAssign`,
      `passwordForceChange`,
      `activePublic`,
      `inactivePublic`,
      `pendingPublic`,
      `principalMultiplePreface`,
      `generatedPasswordsNone`,
      `viewProfile`,
      `registerAnotherUser`
    );    tx.named(`principalSingle`);
    tx.named(`principalMultipleItem`);
    tx.positional(`activeManager`, "user");
    tx.positional(`inactiveManager`, "user");
    tx.positional(`pendingManager`, "user");
    tx.positional(`generatedPasswordsSingle`, "type");
    tx.positional(`generatedPasswordsMultiple`, "types");

    return tx;
  }

  private static _txI18n$User$OrderBy(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `alphabeticallyAsc`,
      `alphabeticallyDesc`
    );
    return tx;
  }

  private static _txI18n$OperatorGroup(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `accountAccess`,
      `accountAccessFull`,
      `accountAccessIncoming`,
      `accountAccessOutgoing`,
      `accountAccessOwn`,
      `accountAccessNone`,
      `performPayments`,
      `performPaymentsRequiresAuthorizationView`,
      `performPaymentsRequiresAuthorization`,
      `performPaymentsMaxAmountPerDay`,
      `authorizePayments`,
      `paymentNotifications`,
      `paymentNotificationsAbove`,
      `paymentNotificationsBelow`,
      `chargebackPayments`,
      `receivePayments`,
      `requestPayments`,
      `voucherTransactions`,
      `voucherTransactionsRedeems`,
      `restrictPaymentsToUsers`,
      `editOwnProfile`,
      `notifications`,
      `viewAdvertisements`,
      `manageAdvertisements`,
      `messages`,
      `records`,
      `brokering`,
      `runOperations`,
      `created`,
      `saved`
    );    tx.positional(`performPaymentsMaxAmountPerDayView`, "max");

    tx.nested(
      I18nMeta._txI18n$OperatorGroup$Title(`title`),
      I18nMeta._txI18n$OperatorGroup$MobileTitle(`mobileTitle`),
      I18nMeta._txI18n$OperatorGroup$Tokens(`tokens`),
    );
    return tx;
  }

  private static _txI18n$OperatorGroup$Title(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `list`,
      `view`,
      `new`,
      `edit`,
      `general`,
      `accountAccess`,
      `generalAccount`
    );
    return tx;
  }

  private static _txI18n$OperatorGroup$MobileTitle(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `list`,
      `view`,
      `new`,
      `edit`,
      `general`,
      `accountAccess`,
      `generalAccount`
    );
    return tx;
  }

  private static _txI18n$OperatorGroup$Tokens(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `label`,
      `enable`,
      `block`,
      `unblock`,
      `cancel`
    );
    return tx;
  }

  private static _txI18n$UserStatus(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `current`,
      `new`,
      `active`,
      `blocked`,
      `disabled`,
      `pending`,
      `purged`,
      `removed`
    );
    tx.nested(
      I18nMeta._txI18n$UserStatus$Confirm(`confirm`),
      I18nMeta._txI18n$UserStatus$Done(`done`),
      I18nMeta._txI18n$UserStatus$Title(`title`),
      I18nMeta._txI18n$UserStatus$MobileTitle(`mobileTitle`),
    );
    return tx;
  }

  private static _txI18n$UserStatus$Confirm(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.positional(`active`, "user");
    tx.positional(`blocked`, "user");
    tx.positional(`disabled`, "user");
    tx.positional(`purged`, "user");
    tx.positional(`removed`, "user");

    return tx;
  }

  private static _txI18n$UserStatus$Done(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.positional(`active`, "user");
    tx.positional(`blocked`, "user");
    tx.positional(`disabled`, "user");
    tx.positional(`purged`, "user");
    tx.positional(`removed`, "user");

    return tx;
  }

  private static _txI18n$UserStatus$Title(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `statusUser`,
      `statusOperator`,
      `changeUser`,
      `changeOperator`,
      `historyUser`,
      `historyOperator`
    );
    return tx;
  }

  private static _txI18n$UserStatus$MobileTitle(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `statusUser`,
      `statusOperator`,
      `changeUser`,
      `changeOperator`,
      `historyUser`,
      `historyOperator`
    );
    return tx;
  }

  private static _txI18n$Agreements(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `agreement`,
      `required`,
      `optional`,
      `optionalSaved`,
      `accepted`,
      `noAccepted`,
      `message`
    );
    tx.nested(
      I18nMeta._txI18n$Agreements$Title(`title`),
      I18nMeta._txI18n$Agreements$MobileTitle(`mobileTitle`),
    );
    return tx;
  }

  private static _txI18n$Agreements$Title(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `accepted`,
      `history`
    );
    return tx;
  }

  private static _txI18n$Agreements$MobileTitle(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `accepted`,
      `history`
    );
    return tx;
  }

  private static _txI18n$GroupMembership(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `current`,
      `new`,
      `doneUser`,
      `doneOperator`
    );    tx.named(`confirm`);
    tx.positional(`confirmAliasOperator`, "user");

    tx.nested(
      I18nMeta._txI18n$GroupMembership$Title(`title`),
      I18nMeta._txI18n$GroupMembership$MobileTitle(`mobileTitle`),
    );
    return tx;
  }

  private static _txI18n$GroupMembership$Title(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `groupUser`,
      `groupOperator`,
      `historyUser`,
      `historyOperator`
    );
    return tx;
  }

  private static _txI18n$GroupMembership$MobileTitle(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `groupUser`,
      `groupOperator`,
      `historyUser`,
      `historyOperator`
    );
    return tx;
  }

  private static _txI18n$Brokers(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `mainBroker`,
      `setMain`,
      `broker`,
      `brokerAdded`
    );
    tx.nested(
      I18nMeta._txI18n$Brokers$Title(`title`),
      I18nMeta._txI18n$Brokers$MobileTitle(`mobileTitle`),
      I18nMeta._txI18n$Brokers$Action(`action`),
    );
    return tx;
  }

  private static _txI18n$Brokers$Title(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `list`,
      `new`,
      `history`
    );
    return tx;
  }

  private static _txI18n$Brokers$MobileTitle(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `list`,
      `new`,
      `history`
    );
    return tx;
  }

  private static _txI18n$Brokers$Action(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `add`,
      `remove`,
      `setMain`
    );
    return tx;
  }

  private static _txI18n$Phone(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `mobile`,
      `landLine`,
      `nameMobile`,
      `nameLandLine`,
      `numberMobile`,
      `numberLandLine`,
      `phoneNumber`,
      `extension`,
      `enabledSms`,
      `verified`
    );    tx.named(`numberExtensionValue`);

    tx.nested(
      I18nMeta._txI18n$Phone$Error(`error`),
      I18nMeta._txI18n$Phone$Verify(`verify`),
    );
    return tx;
  }

  private static _txI18n$Phone$Error(property: string): Translations<any> {
    const tx = new Translations(property);

    tx.nested(
      I18nMeta._txI18n$Phone$Error$Verify(`verify`),
    );
    return tx;
  }

  private static _txI18n$Phone$Error$Verify(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `expired`,
      `invalid`,
      `maxAttempts`
    );
    return tx;
  }

  private static _txI18n$Phone$Verify(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `code`,
      `send`,
      `message`
    );    tx.positional(`title`, "number");
    tx.positional(`sendDisabled`, "seconds");
    tx.positional(`done`, "number");

    return tx;
  }

  private static _txI18n$Address(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `address`,
      `line1`,
      `line2`,
      `buildingNumber`,
      `city`,
      `complement`,
      `country`,
      `neighborhood`,
      `poBox`,
      `region`,
      `street`,
      `zip`
    );
    return tx;
  }

  private static _txI18n$Ad(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `accept`,
      `acceptOrder`,
      `addedProduct`,
      `addedProductOrder`,
      `addedProductAlreadyInCart`,
      `addToCart`,
      `adInterestCreated`,
      `adInterestSaved`,
      `addProducts`,
      `adSaved`,
      `adHidden`,
      `adUnhidden`,
      `allowDecimalQuantity`,
      `answer`,
      `authorize`,
      `authorized`,
      `askQuestion`,
      `available`,
      `notAvailable`,
      `backToDraft`,
      `basePrice`,
      `beginDate`,
      `cartEmpty`,
      `checkout`,
      `changeQuantity`,
      `chargeType`,
      `chooseSeller`,
      `chooseDeliveryMethod`,
      `chooseDeliveryAddress`,
      `choosePaymentType`,
      `customAddress`,
      `customDeliveryMethod`,
      `customQuantity`,
      `discount`,
      `endDate`,
      `deliveryAddress`,
      `deliveryInformation`,
      `deliveryPrice`,
      `deliveryMethod`,
      `deliveryMethods`,
      `deliveryPriceToBeConfirmed`,
      `deliveryMethodCreated`,
      `deliveryMethodSaved`,
      `deliveryType`,
      `deliver`,
      `pickup`,
      `pickupAddress`,
      `fixed`,
      `hasImages`,
      `noImages`,
      `item`,
      `items`,
      `itemsNone`,
      `itemNotAvailable`,
      `itemsNoLongerAvailable`,
      `itemsOutOfStock`,
      `minPrice`,
      `maxPrice`,
      `minDeliveryTime`,
      `maxDeliveryTime`,
      `matchFields`,
      `name`,
      `negotiated`,
      `noDeliveryMethodsAvailable`,
      `owner`,
      `price`,
      `questionAsked`,
      `questionRemoved`,
      `categories`,
      `creationDate`,
      `showAllCategories`,
      `showCategories`,
      `rootCategory`,
      `orderAccepted`,
      `orderRejected`,
      `orderSavedAsDraft`,
      `orderSubmittedToBuyer`,
      `publicationPeriod`,
      `productNumberMask`,
      `promotionalPeriod`,
      `promotionalPrice`,
      `products`,
      `product`,
      `generated`,
      `manual`,
      `number`,
      `orderNumber`,
      `prefix`,
      `length`,
      `suffix`,
      `quantity`,
      `question`,
      `questionAnswered`,
      `reject`,
      `rejectOrder`,
      `rejected`,
      `showAddressOnMap`,
      `saveAndInsertNew`,
      `saveDraft`,
      `setAsDraft`,
      `setPromotionalPeriod`,
      `submitForAuthorization`,
      `submitToBuyer`,
      `submitToBuyerConfirmation`,
      `subtotal`,
      `stockType`,
      `pendingForAuth`,
      `hide`,
      `unhide`,
      `setDeliveryMethod`,
      `lowStockNotification`,
      `stockQuantity`,
      `unlimited`,
      `unitPrice`,
      `minAllowedInCart`,
      `maxAllowedInCart`,
      `productNumber`,
      `outOfStock`,
      `authorizationNotes`,
      `questionsAndAnswers`,
      `removeQuestion`,
      `reserveAmount`,
      `order`,
      `orderWaitingForSellersApproval`,
      `total`,
      `toBeConfirmedBySeller`,
      `webshopSettingsSaved`,
      `requiringMyConfirmation`,
      `buyer`,
      `seller`,
      `stock`,
      `waitingForBuyersApproval`,
      `waitingForSellersApproval`,
      `remarks`,
      `listAds`
    );    tx.positional(`articlesNotice`, "notice");
    tx.positional(`lowBalance`, "currency");
    tx.positional(`byOwner`, "owner");

    tx.nested(
      I18nMeta._txI18n$Ad$AdjustedQuantity(`adjustedQuantity`),
      I18nMeta._txI18n$Ad$Error(`error`),
      I18nMeta._txI18n$Ad$Type(`type`),
      I18nMeta._txI18n$Ad$OrderBy(`orderBy`),
      I18nMeta._txI18n$Ad$Status(`status`),
      I18nMeta._txI18n$Ad$OrderStatus(`orderStatus`),
      I18nMeta._txI18n$Ad$Title(`title`),
      I18nMeta._txI18n$Ad$MobileTitle(`mobileTitle`),
    );
    return tx;
  }

  private static _txI18n$Ad$AdjustedQuantity(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `max`,
      `min`,
      `stock`
    );
    return tx;
  }

  private static _txI18n$Ad$Error(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `cannotBuyFromSeller`,
      `cannotProceedToCheckout`,
      `notEnoughStock`
    );
    return tx;
  }

  private static _txI18n$Ad$Type(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `simple`,
      `webshop`
    );
    return tx;
  }

  private static _txI18n$Ad$OrderBy(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `date`,
      `distance`,
      `priceAsc`,
      `priceDesc`,
      `relevance`
    );
    return tx;
  }

  private static _txI18n$Ad$Status(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `active`,
      `disabled`,
      `draft`,
      `expired`,
      `hidden`,
      `pending`,
      `scheduled`
    );
    return tx;
  }

  private static _txI18n$Ad$OrderStatus(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `draft`,
      `completed`,
      `rejectedByBuyer`,
      `rejectedBySeller`,
      `paymentPending`,
      `paymentDenied`,
      `paymentCanceled`,
      `paymentExpired`,
      `pendingBuyer`,
      `pendingSeller`,
      `disposed`
    );
    return tx;
  }

  private static _txI18n$Ad$Title(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `search`,
      `myAdvertisements`,
      `myWebshop`,
      `viewAdvertisements`,
      `viewWebshop`,
      `new`,
      `edit`,
      `purchases`,
      `sales`,
      `order`,
      `newOrder`,
      `shoppingCart`,
      `history`,
      `deliveryAddress`,
      `deliveryMethod`,
      `deliveryMethodNew`,
      `deliveryMethods`,
      `paymentType`,
      `confirmOrder`,
      `webshopSettings`,
      `unansweredQuestions`,
      `answerQuestion`,
      `adInterests`,
      `adInterestNew`,
      `adInterest`
    );
    return tx;
  }

  private static _txI18n$Ad$MobileTitle(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `search`,
      `myAdvertisements`,
      `myWebshop`,
      `viewAdvertisements`,
      `viewWebshop`,
      `details`,
      `new`,
      `edit`,
      `purchases`,
      `sales`,
      `order`,
      `newOrder`,
      `shoppingCart`,
      `history`,
      `deliveryAddress`,
      `deliveryMethod`,
      `deliveryMethodNew`,
      `deliveryMethods`,
      `paymentType`,
      `confirmOrder`,
      `webshopSettings`,
      `unansweredQuestions`,
      `answerQuestion`,
      `adInterests`,
      `adInterestNew`,
      `adInterest`
    );
    return tx;
  }

  private static _txI18n$Message(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `sendToBrokered`,
      `from`,
      `fromSentTo`,
      `markAsUnreadDone`,
      `messageRestored`,
      `replies`,
      `moveToTrashDone`,
      `removeDone`,
      `sentBy`,
      `sentTo`,
      `sendTo`,
      `subject`,
      `toUsers`,
      `toGroups`,
      `send`,
      `messageSent`
    );    tx.named(`replyBody`);

    tx.nested(
      I18nMeta._txI18n$Message$Title(`title`),
      I18nMeta._txI18n$Message$MobileTitle(`mobileTitle`),
      I18nMeta._txI18n$Message$Actions(`actions`),
      I18nMeta._txI18n$Message$MessageBox(`messageBox`),
      I18nMeta._txI18n$Message$MessageDestination(`messageDestination`),
    );
    return tx;
  }

  private static _txI18n$Message$Title(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `search`,
      `details`,
      `reply`,
      `newMessage`
    );
    return tx;
  }

  private static _txI18n$Message$MobileTitle(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `search`,
      `details`,
      `reply`,
      `newMessage`
    );
    return tx;
  }

  private static _txI18n$Message$Actions(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `newMessage`,
      `moveToTrash`,
      `moveAllToTrash`,
      `remove`,
      `reply`,
      `markAsUnread`,
      `restore`
    );
    return tx;
  }

  private static _txI18n$Message$MessageBox(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `messageBox`,
      `inbox`,
      `sent`,
      `trash`
    );
    return tx;
  }

  private static _txI18n$Message$MessageDestination(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `user`,
      `group`,
      `brokered`,
      `system`
    );
    return tx;
  }

  private static _txI18n$Notification(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `message`,
      `title`,
      `mobileTitle`,
      `onlyUnread`
    );
    tx.nested(
      I18nMeta._txI18n$Notification$Actions(`actions`),
      I18nMeta._txI18n$Notification$Admin(`admin`),
      I18nMeta._txI18n$Notification$User(`user`),
    );
    return tx;
  }

  private static _txI18n$Notification$Actions(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `remove`,
      `removeAll`,
      `markAllRead`,
      `settings`
    );
    return tx;
  }

  private static _txI18n$Notification$Admin(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `adPendingAuthorization`,
      `applicationErrors`,
      `externalPaymentExpired`,
      `externalPaymentPerformedFailed`,
      `generatedVouchersAboutToExpire`,
      `generatedVouchersExpired`,
      `networkCreated`,
      `paymentAwaitingAuthorization`,
      `paymentPerformed`,
      `systemAlert`,
      `userAlert`,
      `userRegistration`,
      `voucherBuyingAboutToExpire`
    );
    return tx;
  }

  private static _txI18n$Notification$User(property: string): Translations<any> {
    const tx = new Translations(property);

    tx.nested(
      I18nMeta._txI18n$Notification$User$Account(`account`),
      I18nMeta._txI18n$Notification$User$Brokering(`brokering`),
      I18nMeta._txI18n$Notification$User$Buyer(`buyer`),
      I18nMeta._txI18n$Notification$User$Feedback(`feedback`),
      I18nMeta._txI18n$Notification$User$Personal(`personal`),
      I18nMeta._txI18n$Notification$User$Reference(`reference`),
      I18nMeta._txI18n$Notification$User$Seller(`seller`),
    );
    return tx;
  }

  private static _txI18n$Notification$User$Account(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `allNonSmsPerformedPayments`,
      `authorizedPaymentCanceled`,
      `authorizedPaymentDenied`,
      `authorizedPaymentExpired`,
      `authorizedPaymentSucceeded`,
      `voucherAboutToExpire`,
      `voucherExpirationDateChanged`,
      `voucherExpired`,
      `voucherPinBlocked`,
      `voucherTopUp`,
      `voucherRedeem`,
      `externalPaymentExpired`,
      `externalPaymentPerformedFailed`,
      `externalPaymentReceivedFailed`,
      `incomingRecurringPaymentCanceled`,
      `incomingRecurringPaymentFailed`,
      `incomingRecurringPaymentReceived`,
      `incomingScheduledPaymentCanceled`,
      `incomingScheduledPaymentFailed`,
      `incomingScheduledPaymentReceived`,
      `limitChange`,
      `paymentAwaitingAuthorization`,
      `paymentReceived`,
      `paymentRequestCanceled`,
      `paymentRequestDenied`,
      `paymentRequestExpirationDateChanged`,
      `paymentRequestExpired`,
      `paymentRequestProcessed`,
      `paymentRequestReceived`,
      `recurringPaymentFailed`,
      `recurringPaymentOcurrenceProcessed`,
      `scheduledPaymentFailed`,
      `scheduledPaymentInstallmentProcessed`,
      `scheduledPaymentRequestFailed`,
      `sentPaymentRequestExpirationDateChanged`,
      `smsPerformedPayment`,
      `ticketWebhookFailed`
    );
    tx.nested(
      I18nMeta._txI18n$Notification$User$Account$Operator(`operator`),
    );
    return tx;
  }

  private static _txI18n$Notification$User$Account$Operator(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `authorizedPaymentApprovedStillPending`,
      `authorizedPaymentCanceled`,
      `authorizedPaymentDenied`,
      `authorizedPaymentExpired`,
      `authorizedPaymentSucceeded`,
      `paymentAwaitingAuthorization`
    );
    return tx;
  }

  private static _txI18n$Notification$User$Brokering(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `adPendingAuthorization`,
      `memberAssigned`,
      `memberUnassigned`
    );
    return tx;
  }

  private static _txI18n$Notification$User$Buyer(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `adInterestNotification`,
      `adQuestionAnswered`,
      `orderCanceled`,
      `orderPaymentCanceled`,
      `orderPaymentDenied`,
      `orderPaymentExpired`,
      `orderPending`,
      `orderPendingAuthorization`,
      `orderPendingDeliveryData`,
      `orderProcessedBySeller`,
      `orderRejectedBySeller`,
      `buyerSalePending`,
      `buyerSaleRejectedBySeller`
    );
    return tx;
  }

  private static _txI18n$Notification$User$Feedback(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `changed`,
      `created`,
      `expirationReminder`,
      `optional`,
      `replyCreated`,
      `required`
    );
    return tx;
  }

  private static _txI18n$Notification$User$Personal(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `brokerAssigned`,
      `brokerUnassigned`,
      `maxSmsPerMonthReached`,
      `newToken`,
      `newTokenPendingActivation`,
      `passwordStatusChanged`,
      `tokenStatusChanged`,
      `userStatusChanged`
    );
    return tx;
  }

  private static _txI18n$Notification$User$Reference(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `changed`,
      `created`
    );
    return tx;
  }

  private static _txI18n$Notification$User$Seller(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `adAuthorized`,
      `adExpired`,
      `adLowStock`,
      `adOutOfStock`,
      `adQuestionCreated`,
      `adRejected`,
      `orderCanceled`,
      `orderCreated`,
      `orderPaymentCanceled`,
      `orderPaymentDenied`,
      `orderPaymentExpired`,
      `orderPendingAuthorization`,
      `orderPendingDeliveryData`,
      `orderProcessedByBuyer`,
      `orderRejectedByBuyer`,
      `saleProcessedByBuyer`
    );
    return tx;
  }

  private static _txI18n$NotificationSettings(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `title`,
      `mobileTitle`,
      `notAvailableSettings`,
      `forwardMessages`,
      `emailMailings`,
      `smsMailings`,
      `internalNotification`,
      `accounts`,
      `personal`,
      `brokering`,
      `payments`,
      `marketplaceAsBuyer`,
      `marketplaceAsSeller`,
      `feedbackAndReferences`,
      `paymentNotificationsAbove`,
      `paymentNotificationsBelow`,
      `saved`,
      `enableAll`,
      `disableAll`
    );    tx.named(`smsCount`);

    return tx;
  }

  private static _txI18n$ConnectedUser(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `title`,
      `mobileTitle`,
      `loggedInAt`,
      `channel`,
      `ipAddress`,
      `disconnect`,
      `showConnected`
    );    tx.positional(`disconnected`, "user");

    tx.nested(
      I18nMeta._txI18n$ConnectedUser$ViewConnected(`viewConnected`),
    );
    return tx;
  }

  private static _txI18n$ConnectedUser$ViewConnected(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `operator`,
      `broker`,
      `admin`,
      `member`
    );
    return tx;
  }

  private static _txI18n$IdentityProvider(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `registration`,
      `noResults`,
      `disconnected`
    );    tx.named(`loginNoMatch`);
    tx.named(`loginNoEmail`);
    tx.positional(`popup`, "provider");
    tx.positional(`login`, "provider");
    tx.positional(`registrationDisclaimer`, "app");
    tx.positional(`connectedOn`, "date");

    tx.nested(
      I18nMeta._txI18n$IdentityProvider$Title(`title`),
      I18nMeta._txI18n$IdentityProvider$MobileTitle(`mobileTitle`),
      I18nMeta._txI18n$IdentityProvider$Action(`action`),
    );
    return tx;
  }

  private static _txI18n$IdentityProvider$Title(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `manageSelf`,
      `manageUser`
    );
    return tx;
  }

  private static _txI18n$IdentityProvider$MobileTitle(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `manageSelf`,
      `manageUser`
    );
    return tx;
  }

  private static _txI18n$IdentityProvider$Action(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `connect`,
      `disconnect`
    );    tx.named(`connectDone`);
    tx.positional(`disconnectConfirm`, "provider");
    tx.positional(`disconnectDone`, "provider");

    return tx;
  }

  private static _txI18n$Operation(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `ad`,
      `transfer`,
      `fileUpload`,
      `redirecting`,
      `alreadyExecuted`
    );
    return tx;
  }

  private static _txI18n$Wizard(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `alreadyExecuted`,
      `emailVerificationCode`,
      `smsVerificationCode`,
      `codeSent`
    );    tx.named(`currentStep`);

    return tx;
  }

  private static _txI18n$Setting(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `title`,
      `mobileTitle`,
      `theme`,
      `themeLight`,
      `themeDark`,
      `locale`,
      `useClassicFrontend`
    );
    return tx;
  }

  private static _txI18n$PrivacySettings(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `title`,
      `mobileTitle`,
      `controlsSection`,
      `information`,
      `dataSubjectToControls`,
      `saved`
    );
    return tx;
  }

  private static _txI18n$Record(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `createdBy`,
      `lastModifiedBy`,
      `lastModificationDate`,
      `fields`
    );    tx.named(`action`);
    tx.positional(`created`, "name");
    tx.positional(`saved`, "name");

    tx.nested(
      I18nMeta._txI18n$Record$Title(`title`),
      I18nMeta._txI18n$Record$MobileTitle(`mobileTitle`),
    );
    return tx;
  }

  private static _txI18n$Record$Title(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.positional(`details`, "name");
    tx.positional(`new`, "name");
    tx.positional(`edit`, "name");

    return tx;
  }

  private static _txI18n$Record$MobileTitle(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.positional(`details`, "name");
    tx.positional(`new`, "name");
    tx.positional(`edit`, "name");

    return tx;
  }

  private static _txI18n$UserAlert(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `title`,
      `text`,
      `mobileTitle`
    );
    tx.nested(
      I18nMeta._txI18n$UserAlert$Type(`type`),
    );
    return tx;
  }

  private static _txI18n$UserAlert$Type(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `title`,
      `custom`,
      `givenVeryBadRefs`,
      `inconsistentBalanceBelowLimit`,
      `insufficientBalanceForInitialCredit`,
      `maxDeviceActivationAttemptsReached`,
      `maxDeviceConfirmationCheckAttemptsReached`,
      `maxTokenActivationAttemptsReached`,
      `maxUserLocalizationAttemptsReached`,
      `maxVoucherRedeemAttemptsReached`,
      `moveUserAutomaticallyFailed`,
      `passwordDisabledByTries`,
      `passwordTemporarilyBlocked`,
      `receivedVeryBadRefs`,
      `scheduledPaymentFailed`
    );
    return tx;
  }

  private static _txI18n$SystemAlert(property: string): Translations<any> {
    const tx = new Translations(property);

    tx.nested(
      I18nMeta._txI18n$SystemAlert$Type(`type`),
    );
    return tx;
  }

  private static _txI18n$SystemAlert$Type(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `accountBalanceFixed`,
      `accountFeeChargedNoFailures`,
      `accountFeeChargedWithFailures`,
      `applicationRestarted`,
      `custom`,
      `customTranslationsInvalidated`,
      `emailSendingFailed`,
      `inconsistentDbSchema`,
      `maxBlockedUsersReached`,
      `maxGlobalSmsReached`,
      `maxIncorrectLoginAttempts`,
      `smsSendingFailed`
    );
    return tx;
  }

  private static _txI18n$Document(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `enable`,
      `publiclyAccessible`,
      `userVisible`,
      `brokerVisible`,
      `brokerManageable`,
      `replaceDocument`,
      `newDocument`,
      `document`,
      `savedSuccessfully`,
      `process`,
      `errorNoFile`
    );    tx.positional(`action`, "count");

    tx.nested(
      I18nMeta._txI18n$Document$Title(`title`),
      I18nMeta._txI18n$Document$MobileTitle(`mobileTitle`),
    );
    return tx;
  }

  private static _txI18n$Document$Title(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `new`,
      `view`,
      `edit`,
      `list`,
      `search`
    );
    return tx;
  }

  private static _txI18n$Document$MobileTitle(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `new`,
      `view`,
      `edit`,
      `list`,
      `search`
    );
    return tx;
  }

  private static _txI18n$Token(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `value`,
      `label`,
      `activationDeadline`,
      `noDeadline`,
      `expiryDate`,
      `noExpiryDate`,
      `activationDate`,
      `creationDate`,
      `activationBeginDate`,
      `activationEndDate`,
      `expiryBeginDate`,
      `expiryEndDate`,
      `unassigned`
    );
    tx.nested(
      I18nMeta._txI18n$Token$Title(`title`),
      I18nMeta._txI18n$Token$MobileTitle(`mobileTitle`),
      I18nMeta._txI18n$Token$Action(`action`),
      I18nMeta._txI18n$Token$Status(`status`),
    );
    return tx;
  }

  private static _txI18n$Token$Title(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `assign`
    );    tx.positional(`new`);
    tx.positional(`view`);

    return tx;
  }

  private static _txI18n$Token$MobileTitle(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.positional(`view`);

    return tx;
  }

  private static _txI18n$Token$Action(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `activate`,
      `assign`,
      `block`,
      `cancel`,
      `changeDeadline`,
      `changeExpiry`,
      `unblock`
    );
    tx.nested(
      I18nMeta._txI18n$Token$Action$Done(`done`),
      I18nMeta._txI18n$Token$Action$Message(`message`),
    );
    return tx;
  }

  private static _txI18n$Token$Action$Done(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `activated`,
      `assigned`,
      `blocked`,
      `canceled`,
      `created`,
      `deadlineChanged`,
      `expiryChanged`,
      `unblocked`
    );
    return tx;
  }

  private static _txI18n$Token$Action$Message(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `cancel`
    );
    return tx;
  }

  private static _txI18n$Token$Status(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `activationExpired`,
      `active`,
      `blocked`,
      `canceled`,
      `expired`,
      `pending`,
      `unassigned`
    );
    return tx;
  }

  private static _txI18n$Reference(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `set`,
      `referenceSet`,
      `from`,
      `to`,
      `notGiven`,
      `value`,
      `allTime`,
      `last30Days`,
      `total`,
      `percentagePositive`
    );
    tx.nested(
      I18nMeta._txI18n$Reference$Count(`count`),
      I18nMeta._txI18n$Reference$Title(`title`),
      I18nMeta._txI18n$Reference$MobileTitle(`mobileTitle`),
      I18nMeta._txI18n$Reference$Direction(`direction`),
      I18nMeta._txI18n$Reference$Level(`level`),
    );
    return tx;
  }

  private static _txI18n$Reference$Count(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `single`
    );    tx.positional(`multi`, "count");

    return tx;
  }

  private static _txI18n$Reference$Title(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `search`,
      `searchGiven`,
      `searchReceived`,
      `view`,
      `set`,
      `edit`
    );
    return tx;
  }

  private static _txI18n$Reference$MobileTitle(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `search`,
      `searchGiven`,
      `searchReceived`,
      `view`,
      `set`
    );
    return tx;
  }

  private static _txI18n$Reference$Direction(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `direction`,
      `received`,
      `given`
    );
    return tx;
  }

  private static _txI18n$Reference$Level(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `level`,
      `bad`,
      `veryBad`,
      `good`,
      `veryGood`,
      `neutral`
    );
    return tx;
  }

  private static _txI18n$Feedback(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `addUserToIgnoreList`,
      `administrationComment`,
      `viewAwaitingFeedback`,
      `removeConfirm`,
      `removeDone`,
      `buyerFeedback`,
      `sellerReply`,
      `reply`,
      `feedbackReplied`,
      `daysToReplyFeedbackCommentUnlimited`,
      `feedbackSet`,
      `paymentFeedbackDisabledFor`,
      `settings`
    );    tx.positional(`addIgnoredUserDone`, "user");
    tx.positional(`removeUser`, "user");
    tx.positional(`daysToGiveFeedbackComment`, "deadline");
    tx.positional(`daysToChangeFeedbackComment`, "deadline");
    tx.positional(`daysToReplyFeedbackComment`, "deadline");

    tx.nested(
      I18nMeta._txI18n$Feedback$Title(`title`),
      I18nMeta._txI18n$Feedback$MobileTitle(`mobileTitle`),
      I18nMeta._txI18n$Feedback$Count(`count`),
    );
    return tx;
  }

  private static _txI18n$Feedback$Title(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `search`,
      `searchGiven`,
      `searchReceived`,
      `searchAwaiting`,
      `view`,
      `set`,
      `edit`,
      `ignoredUsers`
    );
    return tx;
  }

  private static _txI18n$Feedback$MobileTitle(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `search`,
      `searchGiven`,
      `searchReceived`,
      `searchAwaiting`,
      `view`,
      `ignoredUsers`
    );
    return tx;
  }

  private static _txI18n$Feedback$Count(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `single`
    );    tx.positional(`multi`, "count");

    return tx;
  }

  private static _txI18n$Product(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `assign`,
      `unassign`,
      `assignIndividualProduct`,
      `userAccount`,
      `product`,
      `action`,
      `productAssigned`
    );
    tx.nested(
      I18nMeta._txI18n$Product$Title(`title`),
      I18nMeta._txI18n$Product$MobileTitle(`mobileTitle`),
      I18nMeta._txI18n$Product$Assigned(`assigned`),
      I18nMeta._txI18n$Product$Kind(`kind`),
    );
    return tx;
  }

  private static _txI18n$Product$Title(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `list`,
      `history`
    );
    return tx;
  }

  private static _txI18n$Product$MobileTitle(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `list`,
      `history`
    );
    return tx;
  }

  private static _txI18n$Product$Assigned(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `individual`,
      `group`,
      `groupSet`
    );
    return tx;
  }

  private static _txI18n$Product$Kind(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `administrator`,
      `broker`,
      `member`
    );
    return tx;
  }

  private static _txI18n$Invite(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `title`,
      `mobileTitle`,
      `toEmail`,
      `toEmails`,
      `subject`,
      `body`,
      `assignBroker`,
      `send`,
      `sentSingle`,
      `sentMultiple`
    );
    return tx;
  }

  private static _txI18n$Consent(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `authorize`,
      `deny`,
      `privacyPolicy`,
      `termsOfService`
    );    tx.named(`title`);
    tx.named(`titleOpenidOnly`);

    tx.nested(
      I18nMeta._txI18n$Consent$Expiration(`expiration`),
      I18nMeta._txI18n$Consent$Permission(`permission`),
    );
    return tx;
  }

  private static _txI18n$Consent$Expiration(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `never`
    );    tx.positional(`time`, "time");

    return tx;
  }

  private static _txI18n$Consent$Permission(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.positional(`openIdOnly`, "client");
    tx.positional(`requesting`, "client");

    return tx;
  }

  private static _txI18n$Unsubscribe(property: string): Translations<any> {
    const tx = new Translations(property);
    tx.simple(
      `title`,
      `messageConfirm`,
      `messageYes`,
      `messageNo`,
      `doneProceed`
    );    tx.named(`messageNotification`);
    tx.named(`messageMessage`);
    tx.named(`messageMailing`);
    tx.positional(`messageSettings`, "url");
    tx.positional(`doneNotification`, "email");
    tx.positional(`doneMessage`, "email");
    tx.positional(`doneMailing`, "email");

    return tx;
  }



}
