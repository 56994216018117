/* tslint:disable */
/* eslint-disable */

/**
 * How the products are delivered.
 * Possible values are:
 * - `deliver`: The products are delivered to the buyer's address.
 * - `pickup`: The products are delivered to the seller's pickup point
 */
export enum DeliveryMethodTypeEnum {
  DELIVER = 'deliver',
  PICKUP = 'pickup'
}
