/* tslint:disable */
/* eslint-disable */

/**
 * Indicates how a voucher was created.
 * Possible values are:
 * - `bought`: The voucher was bought by an user
 * - `generated`: The voucher was generated by an administrator
 * - `sent`: The voucher was bought by an user and sent to an external user
 */
export enum VoucherCreationTypeEnum {
  BOUGHT = 'bought',
  GENERATED = 'generated',
  SENT = 'sent'
}
