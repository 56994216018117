/* tslint:disable */
/* eslint-disable */

/**
 * The possible destinations to send messages to
 * Possible values are:
 * - `brokered`: A message sent to brokered users. Only for brokers.
 * - `group`: A message sent to a group of users. Only for administrators.
 * - `system`: A message sent to system. Only for members and brokers.
 * - `user`: A message sent to a specific user.
 */
export enum MessageDestinationEnum {
  BROKERED = 'brokered',
  GROUP = 'group',
  SYSTEM = 'system',
  USER = 'user'
}
