/* tslint:disable */
/* eslint-disable */

/**
 * Possible message boxes
 * Possible values are:
 * - `inbox`: The received messages
 * - `sent`: The sent messages
 * - `trash`: The trash messages
 */
export enum MessageBoxEnum {
  INBOX = 'inbox',
  SENT = 'sent',
  TRASH = 'trash'
}
