/* tslint:disable */
/* eslint-disable */

/**
 * The type of e-mail which is being unsubscribed
 * Possible values are:
 * - `mailing`: An e-mail for a mailing list
 * - `message`: An e-mail which forwards an internal message
 * - `notification`: An e-mail which was generated by a notification
 */
export enum EmailUnsubscribeKind {
  MAILING = 'mailing',
  MESSAGE = 'message',
  NOTIFICATION = 'notification'
}
