/* tslint:disable */
/* eslint-disable */

/**
 * The voucher search result order.
 * Possible values are:
 * - `creationDateAsc`: Order by creation date, ascending
 * - `creationDateDesc`: Order by creation date, descending (default)
 * - `expirationDateAsc`: Order by expiration date, ascending
 * - `expirationDateDesc`: Order by expiration date, descending
 * - `redeemDateAsc`: Deprecated: This value is no longer used in the vouchers search, only in the transactions search.. Order by redeem date, descending
 * - `redeemDateDesc`: Deprecated: This value is no longer used in the vouchers search, only in the transactions search.. Order by redeem date, ascending
 */
export enum VoucherOrderByEnum {
  CREATION_DATE_ASC = 'creationDateAsc',
  CREATION_DATE_DESC = 'creationDateDesc',
  EXPIRATION_DATE_ASC = 'expirationDateAsc',
  EXPIRATION_DATE_DESC = 'expirationDateDesc',
  REDEEM_DATE_ASC = 'redeemDateAsc',
  REDEEM_DATE_DESC = 'redeemDateDesc'
}
