/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MessageBoxEnum } from '../models/message-box-enum';
import { MessageDataForReply } from '../models/message-data-for-reply';
import { MessageDataForSearch } from '../models/message-data-for-search';
import { MessageDataForSend } from '../models/message-data-for-send';
import { MessageDestinationEnum } from '../models/message-destination-enum';
import { MessageResult } from '../models/message-result';
import { MessageView } from '../models/message-view';
import { MessagesStatus } from '../models/messages-status';
import { ReplyMessage } from '../models/reply-message';
import { SendMessage } from '../models/send-message';


/**
 * Provides access to send messages between users.
 */
@Injectable({
  providedIn: 'root',
})
export class MessagesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getMessageDataForSearch
   */
  static readonly GetMessageDataForSearchPath = '/messages/data-for-search';

  /**
   * Returns configuration data for searching messages.
   *
   * Returns configuration data for searching messages.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMessageDataForSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMessageDataForSearch$Response(params?: {

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;
  }): Observable<StrictHttpResponse<MessageDataForSearch>> {

    const rb = new RequestBuilder(this.rootUrl, MessagesService.GetMessageDataForSearchPath, 'get');
    if (params) {
      rb.query('fields', params.fields, {"explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageDataForSearch>;
      })
    );
  }

  /**
   * Returns configuration data for searching messages.
   *
   * Returns configuration data for searching messages.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMessageDataForSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMessageDataForSearch(params?: {

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;
  }): Observable<MessageDataForSearch> {

    return this.getMessageDataForSearch$Response(params).pipe(
      map((r: StrictHttpResponse<MessageDataForSearch>) => r.body as MessageDataForSearch)
    );
  }

  /**
   * Path part for operation getMessageDataForSend
   */
  static readonly GetMessageDataForSendPath = '/messages/data-for-send';

  /**
   * Returns configuration data for sending messages.
   *
   * Returns configuration data for sending messages.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMessageDataForSend()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMessageDataForSend$Response(params?: {

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

    /**
     * A user identification value, such as id, username, e-mail, phone, etc. Id is always allowed, others depend on Cyclos configuration. Note that a valid numeric value is always considered as id. For example, when using another identification method that can be numeric only, prefix the value with a single quote (like in Excel spreadsheets), for example, &#x60;&#x27;1234567890&#x60;;
     */
    user?: string;
  }): Observable<StrictHttpResponse<MessageDataForSend>> {

    const rb = new RequestBuilder(this.rootUrl, MessagesService.GetMessageDataForSendPath, 'get');
    if (params) {
      rb.query('fields', params.fields, {"explode":false});
      rb.query('user', params.user, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageDataForSend>;
      })
    );
  }

  /**
   * Returns configuration data for sending messages.
   *
   * Returns configuration data for sending messages.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMessageDataForSend$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMessageDataForSend(params?: {

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

    /**
     * A user identification value, such as id, username, e-mail, phone, etc. Id is always allowed, others depend on Cyclos configuration. Note that a valid numeric value is always considered as id. For example, when using another identification method that can be numeric only, prefix the value with a single quote (like in Excel spreadsheets), for example, &#x60;&#x27;1234567890&#x60;;
     */
    user?: string;
  }): Observable<MessageDataForSend> {

    return this.getMessageDataForSend$Response(params).pipe(
      map((r: StrictHttpResponse<MessageDataForSend>) => r.body as MessageDataForSend)
    );
  }

  /**
   * Path part for operation searchMessages
   */
  static readonly SearchMessagesPath = '/messages';

  /**
   * Searches for the messages of a user.
   *
   * Returns the messages (paged) in descending order (newest first) a user has received, sent or moved to trash.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchMessages()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchMessages$Response(params?: {

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

    /**
     * The category to filter by.
     */
    category?: string;
    destination?: MessageDestinationEnum;

    /**
     * The keywords to filter by. Used to match the subject or the body of the messages.
     */
    keywords?: string;
    messageBox?: MessageBoxEnum;

    /**
     * Boolean value indicating wether return only the unread messages
     */
    onlyUnread?: boolean;

    /**
     * The page number (zero-based) of the search. The default value is zero.
     */
    page?: number;

    /**
     * The maximum number of records that will be returned on the search. The default value is 40. The maximum number of returned results is configured in Cyclos, and even if more than that is requested, it will be limited by that setting.
     */
    pageSize?: number;

    /**
     * The minimum / maximum date. Is expressed an array, with the lower bound as first element, and the upper bound as second element. When only one element, will have just the lower bound. To specify only the upper bound, prefix the value with a comma.
     */
    period?: Array<string>;

    /**
     * When set to true the result will not include the total record count, only the information on whether there are more records. Depending on the server-side configuration, this can be always true. Otherwise, if the server allows total count, and the client doesn&#x27;t need it, setting this to true can increase performance a bit.
     */
    skipTotalCount?: boolean;

    /**
     * Either the id or identifier of the from / to user
     */
    user?: string;
  }): Observable<StrictHttpResponse<Array<MessageResult>>> {

    const rb = new RequestBuilder(this.rootUrl, MessagesService.SearchMessagesPath, 'get');
    if (params) {
      rb.query('fields', params.fields, {"explode":false});
      rb.query('category', params.category, {});
      rb.query('destination', params.destination, {});
      rb.query('keywords', params.keywords, {});
      rb.query('messageBox', params.messageBox, {});
      rb.query('onlyUnread', params.onlyUnread, {});
      rb.query('page', params.page, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('period', params.period, {});
      rb.query('skipTotalCount', params.skipTotalCount, {});
      rb.query('user', params.user, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MessageResult>>;
      })
    );
  }

  /**
   * Searches for the messages of a user.
   *
   * Returns the messages (paged) in descending order (newest first) a user has received, sent or moved to trash.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchMessages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchMessages(params?: {

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

    /**
     * The category to filter by.
     */
    category?: string;
    destination?: MessageDestinationEnum;

    /**
     * The keywords to filter by. Used to match the subject or the body of the messages.
     */
    keywords?: string;
    messageBox?: MessageBoxEnum;

    /**
     * Boolean value indicating wether return only the unread messages
     */
    onlyUnread?: boolean;

    /**
     * The page number (zero-based) of the search. The default value is zero.
     */
    page?: number;

    /**
     * The maximum number of records that will be returned on the search. The default value is 40. The maximum number of returned results is configured in Cyclos, and even if more than that is requested, it will be limited by that setting.
     */
    pageSize?: number;

    /**
     * The minimum / maximum date. Is expressed an array, with the lower bound as first element, and the upper bound as second element. When only one element, will have just the lower bound. To specify only the upper bound, prefix the value with a comma.
     */
    period?: Array<string>;

    /**
     * When set to true the result will not include the total record count, only the information on whether there are more records. Depending on the server-side configuration, this can be always true. Otherwise, if the server allows total count, and the client doesn&#x27;t need it, setting this to true can increase performance a bit.
     */
    skipTotalCount?: boolean;

    /**
     * Either the id or identifier of the from / to user
     */
    user?: string;
  }): Observable<Array<MessageResult>> {

    return this.searchMessages$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MessageResult>>) => r.body as Array<MessageResult>)
    );
  }

  /**
   * Path part for operation sendMessage
   */
  static readonly SendMessagePath = '/messages';

  /**
   * Sends a new message.
   *
   * Sends a new user / system message.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendMessage$Response(params: {

    /**
     * The message to be send.
     */
    body: SendMessage
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MessagesService.SendMessagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Sends a new message.
   *
   * Sends a new user / system message.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sendMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendMessage(params: {

    /**
     * The message to be send.
     */
    body: SendMessage
  }): Observable<string> {

    return this.sendMessage$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation viewMessage
   */
  static readonly ViewMessagePath = '/messages/{id}';

  /**
   * Returns the message details.
   *
   * Returns the message details from its id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `viewMessage()` instead.
   *
   * This method doesn't expect any request body.
   */
  viewMessage$Response(params: {

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

    /**
     * The object identification
     */
    id: string;
  }): Observable<StrictHttpResponse<MessageView>> {

    const rb = new RequestBuilder(this.rootUrl, MessagesService.ViewMessagePath, 'get');
    if (params) {
      rb.query('fields', params.fields, {"explode":false});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageView>;
      })
    );
  }

  /**
   * Returns the message details.
   *
   * Returns the message details from its id.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `viewMessage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  viewMessage(params: {

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

    /**
     * The object identification
     */
    id: string;
  }): Observable<MessageView> {

    return this.viewMessage$Response(params).pipe(
      map((r: StrictHttpResponse<MessageView>) => r.body as MessageView)
    );
  }

  /**
   * Path part for operation deleteMessage
   */
  static readonly DeleteMessagePath = '/messages/{id}';

  /**
   * Removes a message.
   *
   * Removes a message for the authenticated user by id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMessage()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMessage$Response(params: {

    /**
     * The object identification
     */
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MessagesService.DeleteMessagePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Removes a message.
   *
   * Removes a message for the authenticated user by id.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteMessage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMessage(params: {

    /**
     * The object identification
     */
    id: string;
  }): Observable<void> {

    return this.deleteMessage$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation markMessagesAsRead
   */
  static readonly MarkMessagesAsReadPath = '/messages/mark-as-read';

  /**
   * Marks a list of messages as read.
   *
   * Marks a list of messages, given by id, as read.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markMessagesAsRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  markMessagesAsRead$Response(params: {

    /**
     * The messages (comma-separated list of identifiers) to mark as read.
     */
    ids: Array<string>;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MessagesService.MarkMessagesAsReadPath, 'post');
    if (params) {
      rb.query('ids', params.ids, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Marks a list of messages as read.
   *
   * Marks a list of messages, given by id, as read.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `markMessagesAsRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  markMessagesAsRead(params: {

    /**
     * The messages (comma-separated list of identifiers) to mark as read.
     */
    ids: Array<string>;
  }): Observable<void> {

    return this.markMessagesAsRead$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation markMessagesAsUnread
   */
  static readonly MarkMessagesAsUnreadPath = '/messages/mark-as-unread';

  /**
   * Marks a list of messages as unread.
   *
   * Marks a list of messages, given by id, as unread.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markMessagesAsUnread()` instead.
   *
   * This method doesn't expect any request body.
   */
  markMessagesAsUnread$Response(params: {

    /**
     * The messages (comma-separated list of identifiers) to mark as unread.
     */
    ids: Array<string>;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MessagesService.MarkMessagesAsUnreadPath, 'post');
    if (params) {
      rb.query('ids', params.ids, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Marks a list of messages as unread.
   *
   * Marks a list of messages, given by id, as unread.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `markMessagesAsUnread$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  markMessagesAsUnread(params: {

    /**
     * The messages (comma-separated list of identifiers) to mark as unread.
     */
    ids: Array<string>;
  }): Observable<void> {

    return this.markMessagesAsUnread$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation moveMessagesToTrash
   */
  static readonly MoveMessagesToTrashPath = '/messages/move-to-trash';

  /**
   * Moves a list of messages to the trash message box.
   *
   * Moves a list of messages, given by id, to the trash message box.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moveMessagesToTrash()` instead.
   *
   * This method doesn't expect any request body.
   */
  moveMessagesToTrash$Response(params: {

    /**
     * The messages (comma-separated list of identifiers) to move to trash.
     */
    ids: Array<string>;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MessagesService.MoveMessagesToTrashPath, 'post');
    if (params) {
      rb.query('ids', params.ids, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Moves a list of messages to the trash message box.
   *
   * Moves a list of messages, given by id, to the trash message box.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `moveMessagesToTrash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  moveMessagesToTrash(params: {

    /**
     * The messages (comma-separated list of identifiers) to move to trash.
     */
    ids: Array<string>;
  }): Observable<void> {

    return this.moveMessagesToTrash$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation restoreMessagesFromTrash
   */
  static readonly RestoreMessagesFromTrashPath = '/messages/restore-from-trash';

  /**
   * Restores a list of messages from the trash message box.
   *
   * Restores a list of messages, given by id, previously moved to the trash message box.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restoreMessagesFromTrash()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreMessagesFromTrash$Response(params: {

    /**
     * The messages (comma-separated list of identifiers) to restore from trash.
     */
    ids: Array<string>;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MessagesService.RestoreMessagesFromTrashPath, 'post');
    if (params) {
      rb.query('ids', params.ids, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Restores a list of messages from the trash message box.
   *
   * Restores a list of messages, given by id, previously moved to the trash message box.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `restoreMessagesFromTrash$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreMessagesFromTrash(params: {

    /**
     * The messages (comma-separated list of identifiers) to restore from trash.
     */
    ids: Array<string>;
  }): Observable<void> {

    return this.restoreMessagesFromTrash$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation messagesStatus
   */
  static readonly MessagesStatusPath = '/messages/status';

  /**
   * Returns information about the received messages.
   *
   * Returns information about the status of the received messages: unread, new and last view date.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagesStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagesStatus$Response(params?: {
  }): Observable<StrictHttpResponse<MessagesStatus>> {

    const rb = new RequestBuilder(this.rootUrl, MessagesService.MessagesStatusPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessagesStatus>;
      })
    );
  }

  /**
   * Returns information about the received messages.
   *
   * Returns information about the status of the received messages: unread, new and last view date.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `messagesStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagesStatus(params?: {
  }): Observable<MessagesStatus> {

    return this.messagesStatus$Response(params).pipe(
      map((r: StrictHttpResponse<MessagesStatus>) => r.body as MessagesStatus)
    );
  }

  /**
   * Path part for operation updateLastViewDateForMessages
   */
  static readonly UpdateLastViewDateForMessagesPath = '/messages/viewed';

  /**
   * Updates the last view date for the messages.
   *
   * Updates the last view date for the messages with the current date. This new date will be used as the base to calculate the number of new messages returned by the `GET /messages/status` operation.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateLastViewDateForMessages()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateLastViewDateForMessages$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MessagesService.UpdateLastViewDateForMessagesPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Updates the last view date for the messages.
   *
   * Updates the last view date for the messages with the current date. This new date will be used as the base to calculate the number of new messages returned by the `GET /messages/status` operation.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateLastViewDateForMessages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateLastViewDateForMessages(params?: {
  }): Observable<void> {

    return this.updateLastViewDateForMessages$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getMessageDataForReply
   */
  static readonly GetMessageDataForReplyPath = '/messages/{id}/data-for-reply';

  /**
   * Returns configuration data for replying a message.
   *
   * Returns configuration data for replying a message.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMessageDataForReply()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMessageDataForReply$Response(params: {

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

    /**
     * The object identification
     */
    id: string;
  }): Observable<StrictHttpResponse<MessageDataForReply>> {

    const rb = new RequestBuilder(this.rootUrl, MessagesService.GetMessageDataForReplyPath, 'get');
    if (params) {
      rb.query('fields', params.fields, {"explode":false});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageDataForReply>;
      })
    );
  }

  /**
   * Returns configuration data for replying a message.
   *
   * Returns configuration data for replying a message.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMessageDataForReply$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMessageDataForReply(params: {

    /**
     * Select which fields to include on returned data. On the beginning of this page is an explanation on how this parameter works.
     */
    fields?: Array<string>;

    /**
     * The object identification
     */
    id: string;
  }): Observable<MessageDataForReply> {

    return this.getMessageDataForReply$Response(params).pipe(
      map((r: StrictHttpResponse<MessageDataForReply>) => r.body as MessageDataForReply)
    );
  }

  /**
   * Path part for operation replyMessage
   */
  static readonly ReplyMessagePath = '/messages/{id}/reply';

  /**
   * Replies a message.
   *
   * Replies a message.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `replyMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replyMessage$Response(params: {

    /**
     * The object identification
     */
    id: string;

    /**
     * The reply message.
     */
    body: ReplyMessage
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MessagesService.ReplyMessagePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Replies a message.
   *
   * Replies a message.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `replyMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replyMessage(params: {

    /**
     * The object identification
     */
    id: string;

    /**
     * The reply message.
     */
    body: ReplyMessage
  }): Observable<string> {

    return this.replyMessage$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
