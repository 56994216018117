/* tslint:disable */
/* eslint-disable */

/**
 * Indicates when the amount is set for generated vouchers
 * Possible values are:
 * - `activation`: Only if the generation status is inactive. The amount is set when the voucher is activated via a top-up. Only a single top-up is allowed.
 * - `dynamic`: The voucher amount is dynamic, indicating it can be topped-up multiple times.
 * - `generation`: The voucher amount is set when the voucher is generated.
 */
export enum VoucherGenerationAmountEnum {
  ACTIVATION = 'activation',
  DYNAMIC = 'dynamic',
  GENERATION = 'generation'
}
