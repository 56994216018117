/* tslint:disable */
/* eslint-disable */

/**
 * Possibles captcha provider.
 * Possible values are:
 * - `internal`: Internal CAPTCHA provider using images
 * - `recaptchaV2`: reCAPTCHA v2
 */
export enum CaptchaProviderEnum {
  INTERNAL = 'internal',
  RECAPTCHA_V_2 = 'recaptchaV2'
}
