/* tslint:disable */
/* eslint-disable */

/**
 * Indicates how the voucher PIN is handled when an inactive voucher is activated
 * Possible values are:
 * - `input`: The PIN is entered in a pinpad / keyboard at the moment the voucher is activated.
 * - `none`: The PIN is not handled - it should be delivered to users using other means
 * - `send`: The PIN is sent either via e-mail or SMS message (if outbound SMS is enabled). When the voucher is being activated, either e-mail address or mobile phone number are required.
 */
export enum VoucherPinOnActivationEnum {
  INPUT = 'input',
  NONE = 'none',
  SEND = 'send'
}
