<ng-container *ngIf="(visibleActions$ | async)?.length > 0">
  <!-- We have actions. They may be grouped or not -->
  <div class="heading-actions"
    *ngIf="(groupActions$ | async); else rootActions" dropdown
    placement="bottom right" #dropdown="bs-dropdown"
    (onShown)="onShown(dropdown)" (onHidden)="onHidden()">
    <button type="button" dropdownToggle id="heading-action-toggle"
      class="heading-action heading-action-dropdown" [ngClass]="{
          'dropdown-toggle': layout.gtxxs$ | async
        }" (click)="blurIfClick('heading-action-toggle', $event)">
      <icon *ngIf="(layout.xxs$ | async); else actionsLabel"
        [icon]="SvgIcon.ThreeDotsVertical" class="mx-2"></icon>
      <ng-template #actionsLabel>
        <div class="action-label">
          {{ i18n.general.actions }}
        </div>
      </ng-template>
    </button>
    <div class="heading-actions-menu dropdown-menu dropdown-menu-right"
      *dropdownMenu role="listbox" id="heading-actions-menu"
      attr.aria-labelledby="id"
      (click)="$event.preventDefault(); $event.stopPropagation();">
      <a #actionLink tabindex="0"
        *ngFor="let action of visibleActions$.value; let first = first; let i = index;"
        class="heading-action-option" [ngClass]="'heading-action-' + i"
        href="#"
        (click)="dropdown.hide(); clickAction(action, actionLink); $event.stopPropagation(); $event.preventDefault()">
        <icon size="1.5rem" [icon]="action.icon"></icon>
        <div class="heading-action-option-text">{{ action.label }}</div>
      </a>
    </div>
  </div>
</ng-container>
<ng-template #rootActions>
  <!-- When we group actions or we're on mobile, show the gray buttons -->
  <div
    *ngIf="(groupActions$ | async) || (layout.ltsm$ | async); else plainButtons"
    class="d-flex heading-actions">
    <button #actionButton *ngFor="let action of visibleActions$.value"
      type="button" class="heading-action heading-action-with-icon d-flex"
      (click)="action.onClick($event, actionButton)">
      <icon size="1.3rem" [icon]="action.icon"></icon>
      <div class="action-label">{{ action.label }}</div>
    </button>
  </div>
</ng-template>
<ng-template #plainButtons>
  <!-- Show separated buttons -->
  <div class="heading-actions-plain-buttons">
    <heading-action-button *ngFor="let action of visibleActions$.value"
      [action]="action"></heading-action-button>
  </div>
</ng-template>
