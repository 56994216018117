/* tslint:disable */
/* eslint-disable */

/**
 * Error codes for 403 Forbidden HTTP status.
 * Possible values are:
 * - `devicePinRemoved`: The device pin was removed by exceeding the allowed attempts.
 * - `disabledPassword`: The password being used was disabled.
 * - `expiredPassword`: The password being used has expired.
 * - `illegalAction`: Attempt to perform an action that is not allowed on this context.
 * - `inaccessibleChannel`: This channel cannot be accessed by the user.
 * - `inaccessibleGroup`: An administrator logging in another user which is not of the managed groups. Should be handled generically, in case more group-specific login retrictions are added to Cyclos.
 * - `inaccessibleNetwork`: A restricted global administrator is trying to login to a network they can't access.
 * - `inaccessiblePrincipal`: The used identification method (principal type) cannot be used in this channel.
 * - `indefinitelyBlocked`: The password was indefinitely blocked by exceeding the allowed attempts.
 * - `invalidDeviceActivationCode`: The device activation code was no valid.
 * - `invalidDeviceConfirmation`: The device confirmation being used is invalid (normally as a confirmation password).
 * - `invalidPassword`: The password being used is invalid (normally the confirmation password).
 * - `operatorWithPendingAgreements`: The operator cannot access because his owner member has pending agreements.
 * - `otpInvalidated`: The OTP was invalidated.
 * - `pendingAgreements`: There is at least one agreement which needs to be accepted in order to access the system.
 * - `permissionDenied`: The operation was denied because a required permission was not granted.
 * - `resetPassword`: The password being used was manually reset.
 * - `temporarilyBlocked`: The password was temporarily blocked by exceeding the allowed attempts.
 */
export enum ForbiddenErrorCode {
  DEVICE_PIN_REMOVED = 'devicePinRemoved',
  DISABLED_PASSWORD = 'disabledPassword',
  EXPIRED_PASSWORD = 'expiredPassword',
  ILLEGAL_ACTION = 'illegalAction',
  INACCESSIBLE_CHANNEL = 'inaccessibleChannel',
  INACCESSIBLE_GROUP = 'inaccessibleGroup',
  INACCESSIBLE_NETWORK = 'inaccessibleNetwork',
  INACCESSIBLE_PRINCIPAL = 'inaccessiblePrincipal',
  INDEFINITELY_BLOCKED = 'indefinitelyBlocked',
  INVALID_DEVICE_ACTIVATION_CODE = 'invalidDeviceActivationCode',
  INVALID_DEVICE_CONFIRMATION = 'invalidDeviceConfirmation',
  INVALID_PASSWORD = 'invalidPassword',
  OPERATOR_WITH_PENDING_AGREEMENTS = 'operatorWithPendingAgreements',
  OTP_INVALIDATED = 'otpInvalidated',
  PENDING_AGREEMENTS = 'pendingAgreements',
  PERMISSION_DENIED = 'permissionDenied',
  RESET_PASSWORD = 'resetPassword',
  TEMPORARILY_BLOCKED = 'temporarilyBlocked'
}
