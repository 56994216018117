/* tslint:disable */
/* eslint-disable */

/**
 * An element shown in the quick access on the dashboard of the modern frontend.
 * Possible values are:
 * - `account`: Account history
 * - `buyVoucher`: Buy voucher
 * - `contacts`: View contacts
 * - `editProfile`: Edit own profile
 * - `passwords`: Manage own passwords
 * - `payExternalUser`: Pay external user
 * - `paySystem`: Pay to a system account
 * - `payUser`: Pay to a user
 * - `paymentRequests`: Payment requests
 * - `pos`: Receive payment (POS)
 * - `receiveQrPayment`: Receive QR-code payment
 * - `redeemVoucher`: Redeem voucher
 * - `requestPaymentFromSystem`: Request payment from system
 * - `requestPaymentFromUser`: Request payment from user
 * - `scheduledPayments`: Scheduled payments
 * - `searchAds`: Search advertisements (marketplace)
 * - `searchUsers`: Search users (user directory)
 * - `sendVoucher`: Send voucher
 * - `settings`: Settings
 * - `switchTheme`: Switch between light and dark themes
 * - `topUpVoucher`: Top-up voucher
 * - `useClassicFrontend`: Use classic frontend
 * - `voucherTransactions`: Search voucher transactions
 * - `vouchers`: Search vouchers
 */
export enum FrontendQuickAccessTypeEnum {
  ACCOUNT = 'account',
  BUY_VOUCHER = 'buyVoucher',
  CONTACTS = 'contacts',
  EDIT_PROFILE = 'editProfile',
  PASSWORDS = 'passwords',
  PAY_EXTERNAL_USER = 'payExternalUser',
  PAY_SYSTEM = 'paySystem',
  PAY_USER = 'payUser',
  PAYMENT_REQUESTS = 'paymentRequests',
  POS = 'pos',
  RECEIVE_QR_PAYMENT = 'receiveQrPayment',
  REDEEM_VOUCHER = 'redeemVoucher',
  REQUEST_PAYMENT_FROM_SYSTEM = 'requestPaymentFromSystem',
  REQUEST_PAYMENT_FROM_USER = 'requestPaymentFromUser',
  SCHEDULED_PAYMENTS = 'scheduledPayments',
  SEARCH_ADS = 'searchAds',
  SEARCH_USERS = 'searchUsers',
  SEND_VOUCHER = 'sendVoucher',
  SETTINGS = 'settings',
  SWITCH_THEME = 'switchTheme',
  TOP_UP_VOUCHER = 'topUpVoucher',
  USE_CLASSIC_FRONTEND = 'useClassicFrontend',
  VOUCHER_TRANSACTIONS = 'voucherTransactions',
  VOUCHERS = 'vouchers'
}
