/* tslint:disable */
/* eslint-disable */

/**
 * Result type for customizable results.
 * Possible values are:
 * - `list`: Results are a regular table, without thumbnail
 * - `list_thumb`: Results are a list with thumbnail
 * - `map`: Results are a map with address locations
 * - `tiled`: Results are tiled boxes
 */
export enum ResultTypeEnum {
  LIST = 'list',
  LIST_THUMB = 'list_thumb',
  MAP = 'map',
  TILED = 'tiled'
}
