/* tslint:disable */
/* eslint-disable */

/**
 * Error codes for 503 Service Unavailable entity HTTP status. It means that some required service couldn't be contacted
 * Possible values are:
 * - `emailSending`: An error has occurred trying to send the a required email
 * - `smsSending`: An error has occurred trying to send a required SMS message
 */
export enum UnavailableErrorCode {
  EMAIL_SENDING = 'emailSending',
  SMS_SENDING = 'smsSending'
}
