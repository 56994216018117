/* tslint:disable */
/* eslint-disable */

/**
 * The kind of a message
 * Possible values are:
 * - `incoming`: A message received
 * - `outgoing`: A message sent
 */
export enum MessageKind {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing'
}
