/* tslint:disable */
/* eslint-disable */

/**
 * The status of a recurring payment.
 * Possible values are:
 * - `blocked`: The recurring payment is blocked and won't be processed on due dates
 * - `canceled`: The recurring payment was manually canceled
 * - `closed`: The recurring payment is closed, as the last scheduled occurrence was processed
 * - `open`: The recurring payment is open, as there are more future occurrences
 */
export enum RecurringPaymentStatusEnum {
  BLOCKED = 'blocked',
  CANCELED = 'canceled',
  CLOSED = 'closed',
  OPEN = 'open'
}
