/* tslint:disable */
/* eslint-disable */

/**
 * The status of an external payment.
 * Possible values are:
 * - `canceled`: The external payment was canceled
 * - `expired`: The external payment has expired without the external user being registered
 * - `failed`: The external payment processing has failed
 * - `pending`: The external payment is pending processing
 * - `processed`: The external payment has been processed
 */
export enum ExternalPaymentStatusEnum {
  CANCELED = 'canceled',
  EXPIRED = 'expired',
  FAILED = 'failed',
  PENDING = 'pending',
  PROCESSED = 'processed'
}
