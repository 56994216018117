<div class="page-content card d-flex flex-grow-1" [ngClass]="{
  'layout-normal': layout === 'normal',
  'layout-centered': layout === 'centered',
  'last': last,
  'mode-normal': mode === 'normal' || mode === 'fullHeight',
  'mode-table': mode === 'table',
  'mode-tight': mode === 'tight' || mode === 'fullHeightTight',
  'mode-view-form': mode === 'viewForm',
  'mode-filters': mode === 'filters',
  'mode-empty-filters': mode === 'emptyFilters',
  'mode-transparent': mode === 'transparent',
  'mode-empty': mode === 'empty',
  'full-height': mode === 'fullHeight' || mode === 'fullHeightTight',
  'print-content-only': printContentOnly
}">
  <div *ngIf="heading != null && layoutService.gtxxs$ | async"
    class="content-title d-flex"
    [ngClass]="{ 'd-print-none': printContentOnly }">
    <button type="button" tabindex="0"
      *ngIf="!hideBack && (breadcrumb.breadcrumb$ | async)?.length > 1"
      class="back-button btn btn-transparent" (click)="breadcrumb.back()">
      <icon [icon]="SvgIcon.ArrowLeft"></icon>
    </button>
    <div class="title-text flex-grow-1 d-flex align-items-center">
      {{ (layoutService.gtxs$ | async) ? heading || mobileHeading :
      mobileHeading || heading }}
    </div>

    <heading-actions root
      *ngIf="(layoutService.ltsm$ | async) || headingActions?.length > 0"
      [headingActions]="headingActions"></heading-actions>
  </div>
  <actions-toolbar
    *ngIf="(layoutService.gtxs$ | async) && headingActions?.length > 0"
    [headingActions]="headingActions"></actions-toolbar>
  <div *ngIf="mode !== 'empty'"
    class="page-content-body card-body d-flex flex-column">
    <ng-content></ng-content>
  </div>
</div>
