<ng-container *ngIf="image; else iconTemplate">
  <ng-container *ngIf="url">
    <div class="avatar-container" [ngClass]="{
      'round-borders': url != null && roundBorders,
      'full-size': fullSize
    }" [ngStyle]="{
        backgroundImage: 'url(' + url + ')',
        backgroundSize: zoom ? 'cover' : 'contain',
        backgroundPosition: zoom ? 'center 30%' : 'center',
        width: fullSize ? '' : size + 'px',
        height: fullSize ? '' : size + 'px'
      }">
    </div>
  </ng-container>
</ng-container>

<ng-template #iconTemplate>
  <div class="avatar-container" (click)="click.emit($event)" [ngClass]="{
      'full-size': fullSize
    }" [ngStyle]="{
      width: fullSize ? '' : size + 'px',
      height: fullSize ? '' : size + 'px'
    }">
    <icon [icon]="icon" [size]="iconSize || size"
      [ngStyle]="{color: iconColor}"></icon>
  </div>
</ng-template>
