/* tslint:disable */
/* eslint-disable */

/**
 * The voucher PIN status for redeeming.
 * Possible values are:
 * - `blocked`: The voucher PIN has been temporarily blocked by exceeding attempts.
 * - `notUsed`: The voucher PIN is not used for redeems.
 * - `required`: The voucher PIN is required for redeems.
 * - `subsequent`: The voucher PIN is used only on subsequent redeems, not on the first one. As the current voucher never had a redeem, is not used.
 */
export enum VoucherPinStatusForRedeemEnum {
  BLOCKED = 'blocked',
  NOT_USED = 'notUsed',
  REQUIRED = 'required',
  SUBSEQUENT = 'subsequent'
}
