/* tslint:disable */
/* eslint-disable */

/**
 * A kind of voucher transaction
 * Possible values are:
 * - `chargeback`: A voucher transaction was charged back
 * - `redeem`: A voucher was redeemed, that means there was a payment from system to a user
 * - `topUp`: A voucher was topped-up, that means there was a payment from a user to system
 */
export enum VoucherTransactionKind {
  CHARGEBACK = 'chargeback',
  REDEEM = 'redeem',
  TOP_UP = 'topUp'
}
