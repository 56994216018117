/* tslint:disable */
/* eslint-disable */

/**
 * Indicates if bought vouchers can be / are gift
 * Possible values are:
 * - `always`: Bought vouchers are always gift
 * - `choose`: The user chooses whether vouchers are gift when buying
 * - `never`: Bought vouchers are never gift
 */
export enum VoucherGiftEnum {
  ALWAYS = 'always',
  CHOOSE = 'choose',
  NEVER = 'never'
}
