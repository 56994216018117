/* tslint:disable */
/* eslint-disable */

/**
 * Possible actions that could be confirmed with a device for a voucher.
 * Possible values are:
 * - `cancel`: Cancel the voucher
 * - `changeExpiration`: Change the expiration date of a voucher
 * - `changeNotificationSettings`: Change the voucher notification settings, including the e-mail the voucher was sent to
 * - `changePin`: Change the voucher pin
 */
export enum VoucherActionEnum {
  CANCEL = 'cancel',
  CHANGE_EXPIRATION = 'changeExpiration',
  CHANGE_NOTIFICATION_SETTINGS = 'changeNotificationSettings',
  CHANGE_PIN = 'changePin'
}
